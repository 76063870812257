import { LabelingData } from './types';

export const labelingData: LabelingData = {
  UK: {
    manufacturer:
      'DGNCT \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: {
      title: 'Authorized representative in the European Union',
      address:
        'FREYR LIFE SCIENCES LTD\n' +
        '9 Greyfriars Road, Reading, Berkshire, \n' +
        'United Kingdom RG1 1NU',
      phoneNumber: '+44 2037012379',
      email: 'CH-REP@freyrsolutions.com',
    },
    lastUpdate: '',
    UDI: '',
    productVersion: '4.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  EU: {
    manufacturer:
      'DGNCT \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: {
      title: 'Authorized representative in the European Union',
      address:
        'EUROPEAN REPRESENTATIVE \n' +
        'AFINA s.r.o.\n' +
        'Kaprova 42/14\n' +
        '110 00, Prague, Czech Republic\n' +
        'ICO 25056557',
      phoneNumber: '+420-608-049-029',
      email: 'ingo@getce.eu',
    },
    lastUpdate: '',
    UDI: '',
    productVersion: '4.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  Switzerland: {
    manufacturer:
      'DGNCT \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: {
      title: 'Authorized representative in the European Union',
      address:
        'Freyr Life Sciences GmbH,\n' +
        'Bahnhofplatz,\n' +
        'CH-6300 Zug,\n' +
        'Switzerland',
      phoneNumber: '+41 225087129',
      email: 'CH-REP@freyrsolutions.com',
    },
    lastUpdate: '',
    UDI: '',
    productVersion: '4.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  US: {
    manufacturer:
      'Diagnocat \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: undefined,
    lastUpdate: '',
    UDI: '',
    productVersion: '4.0',
    userManual: '',
    marking: undefined,
  },

  Mexico: {
    manufacturer:
      'DGNCT \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: undefined,
    lastUpdate: '',
    UDI: '',
    productVersion: '4.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  Canada: {
    manufacturer:
      'DGNCT \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: undefined,
    lastUpdate: '',
    UDI: '',
    productVersion: '4.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  Israel: {
    manufacturer:
      'DGNCT \n' +
      '333 Southeast 2nd Avenue \n' +
      '20th Floor – #563 \n' +
      'Miami, FL 33131',
    contactInfo: undefined,
    lastUpdate: '',
    UDI: '',
    productVersion: '4.0',
    userManual: 'https://diagnocat.com/legal-info/user-manual/',
    marking: { CE: true, MD: true },
  },

  CIS: {
    manufacturer:
      'Diagnocat LLC\n' +
      '21 Palekhskaya Street, Floor/Office/Room 1/II-III/13 Yaroslavl Municipal District, Moscow Intra-City Area 129337\n' +
      'Russian Federation',
    contactInfo: undefined,
    lastUpdate: '',
    UDI: null,
    productVersion: '2.0',
    userManual: '',
    marking: undefined,
  },
};
