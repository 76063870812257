/* eslint-disable */
import Long from 'long';
import { grpc } from '@improbable-eng/grpc-web';
import * as _m0 from 'protobufjs/minimal';
import {
  ConditionCode,
  conditionCodeFromJSON,
  conditionCodeToJSON,
} from '../../model/dto_report_condition_codes';
import { Observable } from 'rxjs';
import { BrowserHeaders } from 'browser-headers';
import { share } from 'rxjs/operators';
import {
  Report,
  ReportSettings,
  ReportPrintSettings,
} from '../../model/dto_report';
import { Patient } from '../../model/dto_patient';
import { Study } from '../../model/dto_study';
import { Empty } from '../../google/protobuf/empty';
import { Tooth } from '../../model/dto_report_tooth';
import { Condition } from '../../model/dto_report_condition';
import { ToothLandmark } from '../../model/dto_report_landmark';
import {
  Asset,
  AssetType,
  assetTypeFromJSON,
  assetTypeToJSON,
} from '../../model/dto_asset';
import { User } from '../../model/dto_user';
import { ToothNumeration } from '../../model/dto_report_common';
import { ReportRequest } from '../../model/dto_report_request';
import { MedicalImageViewOptions } from '../../model/dto_common_image_view_options';
import { Annotation } from '../../model/dto_common_image_annotations';

export const protobufPackage = 'com.diagnocat.core';

export interface ReportDataStreamReq {
  /** `ReportID`: [required] */
  ReportID: string;
}

export interface ReportDataStreamResp {
  HistoricalReport: Report | undefined;
  HistoricalPatient: Patient | undefined;
  HistoricalStudy: Study | undefined;
  /** `EndOfHistoricalStudies` comes right after all historical studies are received */
  EndOfHistoricalStudies: Empty | undefined;
  /** `HistoricalTooth`: all report teeth without filters */
  HistoricalTooth: Tooth | undefined;
  /** `EndOfHistoricalTeeth` comes right after all historical teeth are received */
  EndOfHistoricalTeeth: Empty | undefined;
  /** `HistoricalCondition`: all report conditions without filters */
  HistoricalCondition: Condition | undefined;
  /** `EndOfHistoricalConditions` comes right after all historical conditions are received */
  EndOfHistoricalConditions: Empty | undefined;
  HistoricalToothLandmark: ToothLandmark | undefined;
  /** `EndOfHistoricalTeethLandmarks` comes right after all historical teeth_landmarks are received */
  EndOfHistoricalTeethLandmarks: Empty | undefined;
  HistoricalAsset: Asset | undefined;
  EndOfHistoricalAssetsType: AssetType | undefined;
  /** `EndOfHistoricalAssets` comes right after all historical report assets are received */
  EndOfHistoricalAssets: Empty | undefined;
  HistoricalUser: User | undefined;
  /** `EndOfHistoricalUsers` comes right after all historical users are received */
  EndOfHistoricalUsers: Empty | undefined;
  UpdatedReport: Report | undefined;
  UpdatedPatient: Patient | undefined;
  UpdatedStudy: Study | undefined;
  UpdatedTooth: Tooth | undefined;
  UpdatedCondition: Condition | undefined;
  UpdatedToothLandmark: ToothLandmark | undefined;
  UpdatedAsset: Asset | undefined;
  UpdatedUser: User | undefined;
  CreatedTooth: Tooth | undefined;
  CreatedCondition: Condition | undefined;
  CreatedToothLandmark: ToothLandmark | undefined;
  CreatedAsset: Asset | undefined;
  ToothConditionsHint:
    | ReportDataStreamResp_AllowedToothConditionsHint
    | undefined;
  /** `Ping` - just a stub ping message to ensure that the stream won't break by timeout */
  Ping: Empty | undefined;
}

export interface ReportDataStreamResp_AllowedToothConditionsHint {
  ToothID: string;
  ConditionCodes: ConditionCode[];
}

export interface ReportDataStreamResp_TeethNumerationsByConditionCode {
  Code: ConditionCode;
  TeethNumerations: ToothNumeration[];
}

export interface RequestReportReq {
  /** `Request`: [required] */
  Request: ReportRequest | undefined;
}

export interface RequestReportResp {
  Report: Report | undefined;
}

export interface DeleteReportReq {
  /** `ReportID`: [required] */
  ReportID: string;
}

export interface DeleteReportResp {
  DeletedReportID: string;
  DeletedToothIDs: string[];
  DeletedConditionIDs: string[];
  DeletedAssetIDs: string[];
  DeletedTeethLandmarkIDs: string[];
  /** display asset may change */
  Patient: Patient | undefined;
  /** display asset may change */
  Study: Study | undefined;
}

export interface SetROIReq {
  /** `ReportID`: [required] */
  ReportID: string;
  /** `ROIToothIDs`: [optional]: array of tooth identifiers */
  ROIToothIDs: string[];
}

export interface SetROIResp {
  AllReportTeeth: Tooth[];
  /** `Report`: [optional]: if there was an un-signing */
  Report: Report | undefined;
}

export interface SetReportConclusionReq {
  /** `ReportID`: [required] */
  ReportID: string;
  /** `LegacyConclusion`: [optional]: https://draftjs.org/ */
  LegacyConclusion: string;
}

export interface SetReportConclusionResp {
  Report: Report | undefined;
}

export interface SetReportSettingsReq {
  /** `ReportID`: [required] */
  ReportID: string;
  /** `Settings`: [required] */
  Settings: ReportSettings | undefined;
}

export interface SetReportSettingsResp {
  Report: Report | undefined;
  TeethConditions: Condition[];
}

export interface SetReportPrintSettingsReq {
  /** `ReportID`: [required] */
  ReportID: string;
  /** `PrintSettings`: [required] */
  PrintSettings: ReportPrintSettings | undefined;
}

export interface SetReportPrintSettingsResp {
  Report: Report | undefined;
}

export interface SignReportReq {
  /** `ReportID`: [required] */
  ReportID: string;
}

export interface SignReportResp {
  Report: Report | undefined;
}

export interface SetReportMedicalImageViewOptionsReq {
  /** `ReportID`: [required] */
  ReportID: string;
  /** `MedicalImageViewOptions`: [optional] */
  MedicalImageViewOptions: MedicalImageViewOptions | undefined;
}

export interface SetReportMedicalImageViewOptionsResp {
  Report: Report | undefined;
}

export interface SetImageMetaViewOptionsReq {
  /** `ReportID`: [required] */
  ReportID: string;
  /** `ImageMetaID`: [required] */
  ImageMetaID: string;
  /** `MedicalImageViewOptions`: [optional] */
  MedicalImageViewOptions: MedicalImageViewOptions | undefined;
}

export interface SetImageMetaViewOptionsResp {
  Report: Report | undefined;
}

/**
 * //////////////////////////////////////////////////////////////
 * //////// @exclude TOOTH
 * //////////////////////////////////////////////////////////////
 */
export interface SetReportToothCommentReq {
  /** `ToothID`: [required] */
  ToothID: string;
  /** `Comment`: [optional] */
  Comment: string;
}

export interface SetReportToothCommentResp {
  Tooth: Tooth | undefined;
  /** if there was an un-signing */
  Report?: Report | undefined;
}

export interface SetReportToothNumerationReq {
  /** `ToothID`: [required] */
  ToothID: string;
  /** `ToothNumeration`: [required] */
  ToothNumeration: ToothNumeration | undefined;
}

export interface SetReportToothNumerationResp {
  Tooth: Tooth | undefined;
  /** if there was an un-signing */
  Report?: Report | undefined;
}

export interface ApproveReportToothReq {
  /** `ToothID`: [required] */
  ToothID: string;
  /** `ToothConditionIDs`: [optional] */
  ToothConditionIDs: string[];
}

export interface ApproveReportToothResp {
  Tooth: Tooth | undefined;
  Conditions: Condition[];
}

export interface DisapproveReportToothReq {
  /** `ToothID`: [required] */
  ToothID: string;
}

export interface DisapproveReportToothResp {
  Tooth: Tooth | undefined;
  /** if there was an un-signing */
  Report?: Report | undefined;
}

export interface ApproveAllTeethAndSignReportReq {
  /** `ReportID`: [required] */
  ReportID: string;
  /** `TeethConditionIDs`: [optional] */
  TeethConditionIDs: string[];
}

export interface ApproveAllTeethAndSignReportResp {
  Report: Report | undefined;
  Teeth: Tooth[];
  Conditions: Condition[];
}

export interface ResetReportToothReq {
  /** `ToothID`: [required] */
  ToothID: string;
}

export interface ResetReportToothResp {
  Teeth: Tooth[];
  Conditions: Condition[];
  ToothLandmarks: ToothLandmark[];
  /** if there was an un-signing */
  Report?: Report | undefined;
}

export interface AddToothDisplaySliceReq {
  /** `ToothID`: [required] */
  ToothID: string;
  /** `AssetID`: [required] */
  AssetID: string;
}

export interface AddToothDisplaySliceResp {
  Tooth: Tooth | undefined;
  /** if there was an un-signing */
  Report?: Report | undefined;
}

export interface RemoveToothDisplaySliceReq {
  /** `ToothID`: [required] */
  ToothID: string;
  /** `AssetID`: [required] */
  AssetID: string;
}

export interface RemoveToothDisplaySliceResp {
  Tooth: Tooth | undefined;
  /** if there was an un-signing */
  Report?: Report | undefined;
}

export interface SetToothLocalizationCropTopLayerAnnotationsReq {
  /** `LocalizationID`: [required] */
  LocalizationID: string;
  /** `Annotations`: [optional] */
  Annotations: Annotation[];
}

export interface SetToothLocalizationCropTopLayerAnnotationsResp {
  Tooth: Tooth | undefined;
}

export interface SetToothLocalizationCropMedicalImageViewOptionsReq {
  /** `LocalizationID`: [required] */
  LocalizationID: string;
  /** `MedicalImageViewOptions`: [optional] */
  MedicalImageViewOptions: MedicalImageViewOptions | undefined;
}

export interface SetToothLocalizationCropMedicalImageViewOptionsResp {
  Tooth: Tooth | undefined;
}

export interface ChangeToothLocalizationNumerationReq {
  /** `ToothLocalizationID`: [required] */
  ToothLocalizationID: string;
  /** `Numeration`: [required] */
  Numeration: ToothNumeration | undefined;
}

export interface ChangeToothLocalizationNumerationResp {
  Teeth: Tooth[];
  Conditions: Condition[];
  TeethLandmarks: ToothLandmark[];
  /** if there was an un-signing */
  Report?: Report | undefined;
}

export interface CreateReportToothConditionsReq {
  /** `ToothID`: [required] */
  ToothID: string;
  /** `Conditions`: [required] */
  Conditions: CreateReportToothConditionsReq_Condition[];
}

export interface CreateReportToothConditionsReq_Condition {
  /** `Code`: [required] */
  Code: ConditionCode;
  /** `ParentConditionID`: [optional] */
  ParentConditionID: string;
}

/** Condition with UserDecision == PositiveDecision */
export interface CreateReportToothConditionsResp {
  Conditions: Condition[];
  Tooth: Tooth | undefined;
  Report: Report | undefined;
}

export interface SetReportToothConditionsUserDecisionReq {
  /** `PositiveDecisionConditionIDs` ||/&& `NegativeDecisionConditionIDs`: [required] */
  PositiveDecisionConditionIDs: string[];
  NegativeDecisionConditionIDs: string[];
}

export interface SetReportToothConditionsUserDecisionResp {
  Conditions: Condition[];
  Tooth: Tooth | undefined;
  Report: Report | undefined;
}

function createBaseReportDataStreamReq(): ReportDataStreamReq {
  return { ReportID: '' };
}

export const ReportDataStreamReq = {
  encode(
    message: ReportDataStreamReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportDataStreamReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportDataStreamReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportDataStreamReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
    };
  },

  toJSON(message: ReportDataStreamReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportDataStreamReq>, I>>(
    object: I,
  ): ReportDataStreamReq {
    const message = createBaseReportDataStreamReq();
    message.ReportID = object.ReportID ?? '';
    return message;
  },
};

function createBaseReportDataStreamResp(): ReportDataStreamResp {
  return {
    HistoricalReport: undefined,
    HistoricalPatient: undefined,
    HistoricalStudy: undefined,
    EndOfHistoricalStudies: undefined,
    HistoricalTooth: undefined,
    EndOfHistoricalTeeth: undefined,
    HistoricalCondition: undefined,
    EndOfHistoricalConditions: undefined,
    HistoricalToothLandmark: undefined,
    EndOfHistoricalTeethLandmarks: undefined,
    HistoricalAsset: undefined,
    EndOfHistoricalAssetsType: undefined,
    EndOfHistoricalAssets: undefined,
    HistoricalUser: undefined,
    EndOfHistoricalUsers: undefined,
    UpdatedReport: undefined,
    UpdatedPatient: undefined,
    UpdatedStudy: undefined,
    UpdatedTooth: undefined,
    UpdatedCondition: undefined,
    UpdatedToothLandmark: undefined,
    UpdatedAsset: undefined,
    UpdatedUser: undefined,
    CreatedTooth: undefined,
    CreatedCondition: undefined,
    CreatedToothLandmark: undefined,
    CreatedAsset: undefined,
    ToothConditionsHint: undefined,
    Ping: undefined,
  };
}

export const ReportDataStreamResp = {
  encode(
    message: ReportDataStreamResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.HistoricalReport !== undefined) {
      Report.encode(
        message.HistoricalReport,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.HistoricalPatient !== undefined) {
      Patient.encode(
        message.HistoricalPatient,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.HistoricalStudy !== undefined) {
      Study.encode(message.HistoricalStudy, writer.uint32(26).fork()).ldelim();
    }
    if (message.EndOfHistoricalStudies !== undefined) {
      Empty.encode(
        message.EndOfHistoricalStudies,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.HistoricalTooth !== undefined) {
      Tooth.encode(message.HistoricalTooth, writer.uint32(42).fork()).ldelim();
    }
    if (message.EndOfHistoricalTeeth !== undefined) {
      Empty.encode(
        message.EndOfHistoricalTeeth,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    if (message.HistoricalCondition !== undefined) {
      Condition.encode(
        message.HistoricalCondition,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalConditions !== undefined) {
      Empty.encode(
        message.EndOfHistoricalConditions,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    if (message.HistoricalToothLandmark !== undefined) {
      ToothLandmark.encode(
        message.HistoricalToothLandmark,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    if (message.EndOfHistoricalTeethLandmarks !== undefined) {
      Empty.encode(
        message.EndOfHistoricalTeethLandmarks,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    if (message.HistoricalAsset !== undefined) {
      Asset.encode(message.HistoricalAsset, writer.uint32(90).fork()).ldelim();
    }
    if (message.EndOfHistoricalAssetsType !== undefined) {
      writer.uint32(488).int32(message.EndOfHistoricalAssetsType);
    }
    if (message.EndOfHistoricalAssets !== undefined) {
      Empty.encode(
        message.EndOfHistoricalAssets,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.HistoricalUser !== undefined) {
      User.encode(message.HistoricalUser, writer.uint32(106).fork()).ldelim();
    }
    if (message.EndOfHistoricalUsers !== undefined) {
      Empty.encode(
        message.EndOfHistoricalUsers,
        writer.uint32(114).fork(),
      ).ldelim();
    }
    if (message.UpdatedReport !== undefined) {
      Report.encode(message.UpdatedReport, writer.uint32(170).fork()).ldelim();
    }
    if (message.UpdatedPatient !== undefined) {
      Patient.encode(
        message.UpdatedPatient,
        writer.uint32(178).fork(),
      ).ldelim();
    }
    if (message.UpdatedStudy !== undefined) {
      Study.encode(message.UpdatedStudy, writer.uint32(186).fork()).ldelim();
    }
    if (message.UpdatedTooth !== undefined) {
      Tooth.encode(message.UpdatedTooth, writer.uint32(194).fork()).ldelim();
    }
    if (message.UpdatedCondition !== undefined) {
      Condition.encode(
        message.UpdatedCondition,
        writer.uint32(202).fork(),
      ).ldelim();
    }
    if (message.UpdatedToothLandmark !== undefined) {
      ToothLandmark.encode(
        message.UpdatedToothLandmark,
        writer.uint32(210).fork(),
      ).ldelim();
    }
    if (message.UpdatedAsset !== undefined) {
      Asset.encode(message.UpdatedAsset, writer.uint32(218).fork()).ldelim();
    }
    if (message.UpdatedUser !== undefined) {
      User.encode(message.UpdatedUser, writer.uint32(226).fork()).ldelim();
    }
    if (message.CreatedTooth !== undefined) {
      Tooth.encode(message.CreatedTooth, writer.uint32(250).fork()).ldelim();
    }
    if (message.CreatedCondition !== undefined) {
      Condition.encode(
        message.CreatedCondition,
        writer.uint32(258).fork(),
      ).ldelim();
    }
    if (message.CreatedToothLandmark !== undefined) {
      ToothLandmark.encode(
        message.CreatedToothLandmark,
        writer.uint32(266).fork(),
      ).ldelim();
    }
    if (message.CreatedAsset !== undefined) {
      Asset.encode(message.CreatedAsset, writer.uint32(274).fork()).ldelim();
    }
    if (message.ToothConditionsHint !== undefined) {
      ReportDataStreamResp_AllowedToothConditionsHint.encode(
        message.ToothConditionsHint,
        writer.uint32(410).fork(),
      ).ldelim();
    }
    if (message.Ping !== undefined) {
      Empty.encode(message.Ping, writer.uint32(810).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportDataStreamResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportDataStreamResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.HistoricalReport = Report.decode(reader, reader.uint32());
          break;
        case 2:
          message.HistoricalPatient = Patient.decode(reader, reader.uint32());
          break;
        case 3:
          message.HistoricalStudy = Study.decode(reader, reader.uint32());
          break;
        case 4:
          message.EndOfHistoricalStudies = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 5:
          message.HistoricalTooth = Tooth.decode(reader, reader.uint32());
          break;
        case 6:
          message.EndOfHistoricalTeeth = Empty.decode(reader, reader.uint32());
          break;
        case 7:
          message.HistoricalCondition = Condition.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 8:
          message.EndOfHistoricalConditions = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 9:
          message.HistoricalToothLandmark = ToothLandmark.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10:
          message.EndOfHistoricalTeethLandmarks = Empty.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 11:
          message.HistoricalAsset = Asset.decode(reader, reader.uint32());
          break;
        case 61:
          message.EndOfHistoricalAssetsType = reader.int32() as any;
          break;
        case 12:
          message.EndOfHistoricalAssets = Empty.decode(reader, reader.uint32());
          break;
        case 13:
          message.HistoricalUser = User.decode(reader, reader.uint32());
          break;
        case 14:
          message.EndOfHistoricalUsers = Empty.decode(reader, reader.uint32());
          break;
        case 21:
          message.UpdatedReport = Report.decode(reader, reader.uint32());
          break;
        case 22:
          message.UpdatedPatient = Patient.decode(reader, reader.uint32());
          break;
        case 23:
          message.UpdatedStudy = Study.decode(reader, reader.uint32());
          break;
        case 24:
          message.UpdatedTooth = Tooth.decode(reader, reader.uint32());
          break;
        case 25:
          message.UpdatedCondition = Condition.decode(reader, reader.uint32());
          break;
        case 26:
          message.UpdatedToothLandmark = ToothLandmark.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 27:
          message.UpdatedAsset = Asset.decode(reader, reader.uint32());
          break;
        case 28:
          message.UpdatedUser = User.decode(reader, reader.uint32());
          break;
        case 31:
          message.CreatedTooth = Tooth.decode(reader, reader.uint32());
          break;
        case 32:
          message.CreatedCondition = Condition.decode(reader, reader.uint32());
          break;
        case 33:
          message.CreatedToothLandmark = ToothLandmark.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 34:
          message.CreatedAsset = Asset.decode(reader, reader.uint32());
          break;
        case 51:
          message.ToothConditionsHint =
            ReportDataStreamResp_AllowedToothConditionsHint.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 101:
          message.Ping = Empty.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportDataStreamResp {
    return {
      HistoricalReport: isSet(object.HistoricalReport)
        ? Report.fromJSON(object.HistoricalReport)
        : undefined,
      HistoricalPatient: isSet(object.HistoricalPatient)
        ? Patient.fromJSON(object.HistoricalPatient)
        : undefined,
      HistoricalStudy: isSet(object.HistoricalStudy)
        ? Study.fromJSON(object.HistoricalStudy)
        : undefined,
      EndOfHistoricalStudies: isSet(object.EndOfHistoricalStudies)
        ? Empty.fromJSON(object.EndOfHistoricalStudies)
        : undefined,
      HistoricalTooth: isSet(object.HistoricalTooth)
        ? Tooth.fromJSON(object.HistoricalTooth)
        : undefined,
      EndOfHistoricalTeeth: isSet(object.EndOfHistoricalTeeth)
        ? Empty.fromJSON(object.EndOfHistoricalTeeth)
        : undefined,
      HistoricalCondition: isSet(object.HistoricalCondition)
        ? Condition.fromJSON(object.HistoricalCondition)
        : undefined,
      EndOfHistoricalConditions: isSet(object.EndOfHistoricalConditions)
        ? Empty.fromJSON(object.EndOfHistoricalConditions)
        : undefined,
      HistoricalToothLandmark: isSet(object.HistoricalToothLandmark)
        ? ToothLandmark.fromJSON(object.HistoricalToothLandmark)
        : undefined,
      EndOfHistoricalTeethLandmarks: isSet(object.EndOfHistoricalTeethLandmarks)
        ? Empty.fromJSON(object.EndOfHistoricalTeethLandmarks)
        : undefined,
      HistoricalAsset: isSet(object.HistoricalAsset)
        ? Asset.fromJSON(object.HistoricalAsset)
        : undefined,
      EndOfHistoricalAssetsType: isSet(object.EndOfHistoricalAssetsType)
        ? assetTypeFromJSON(object.EndOfHistoricalAssetsType)
        : undefined,
      EndOfHistoricalAssets: isSet(object.EndOfHistoricalAssets)
        ? Empty.fromJSON(object.EndOfHistoricalAssets)
        : undefined,
      HistoricalUser: isSet(object.HistoricalUser)
        ? User.fromJSON(object.HistoricalUser)
        : undefined,
      EndOfHistoricalUsers: isSet(object.EndOfHistoricalUsers)
        ? Empty.fromJSON(object.EndOfHistoricalUsers)
        : undefined,
      UpdatedReport: isSet(object.UpdatedReport)
        ? Report.fromJSON(object.UpdatedReport)
        : undefined,
      UpdatedPatient: isSet(object.UpdatedPatient)
        ? Patient.fromJSON(object.UpdatedPatient)
        : undefined,
      UpdatedStudy: isSet(object.UpdatedStudy)
        ? Study.fromJSON(object.UpdatedStudy)
        : undefined,
      UpdatedTooth: isSet(object.UpdatedTooth)
        ? Tooth.fromJSON(object.UpdatedTooth)
        : undefined,
      UpdatedCondition: isSet(object.UpdatedCondition)
        ? Condition.fromJSON(object.UpdatedCondition)
        : undefined,
      UpdatedToothLandmark: isSet(object.UpdatedToothLandmark)
        ? ToothLandmark.fromJSON(object.UpdatedToothLandmark)
        : undefined,
      UpdatedAsset: isSet(object.UpdatedAsset)
        ? Asset.fromJSON(object.UpdatedAsset)
        : undefined,
      UpdatedUser: isSet(object.UpdatedUser)
        ? User.fromJSON(object.UpdatedUser)
        : undefined,
      CreatedTooth: isSet(object.CreatedTooth)
        ? Tooth.fromJSON(object.CreatedTooth)
        : undefined,
      CreatedCondition: isSet(object.CreatedCondition)
        ? Condition.fromJSON(object.CreatedCondition)
        : undefined,
      CreatedToothLandmark: isSet(object.CreatedToothLandmark)
        ? ToothLandmark.fromJSON(object.CreatedToothLandmark)
        : undefined,
      CreatedAsset: isSet(object.CreatedAsset)
        ? Asset.fromJSON(object.CreatedAsset)
        : undefined,
      ToothConditionsHint: isSet(object.ToothConditionsHint)
        ? ReportDataStreamResp_AllowedToothConditionsHint.fromJSON(
            object.ToothConditionsHint,
          )
        : undefined,
      Ping: isSet(object.Ping) ? Empty.fromJSON(object.Ping) : undefined,
    };
  },

  toJSON(message: ReportDataStreamResp): unknown {
    const obj: any = {};
    message.HistoricalReport !== undefined &&
      (obj.HistoricalReport = message.HistoricalReport
        ? Report.toJSON(message.HistoricalReport)
        : undefined);
    message.HistoricalPatient !== undefined &&
      (obj.HistoricalPatient = message.HistoricalPatient
        ? Patient.toJSON(message.HistoricalPatient)
        : undefined);
    message.HistoricalStudy !== undefined &&
      (obj.HistoricalStudy = message.HistoricalStudy
        ? Study.toJSON(message.HistoricalStudy)
        : undefined);
    message.EndOfHistoricalStudies !== undefined &&
      (obj.EndOfHistoricalStudies = message.EndOfHistoricalStudies
        ? Empty.toJSON(message.EndOfHistoricalStudies)
        : undefined);
    message.HistoricalTooth !== undefined &&
      (obj.HistoricalTooth = message.HistoricalTooth
        ? Tooth.toJSON(message.HistoricalTooth)
        : undefined);
    message.EndOfHistoricalTeeth !== undefined &&
      (obj.EndOfHistoricalTeeth = message.EndOfHistoricalTeeth
        ? Empty.toJSON(message.EndOfHistoricalTeeth)
        : undefined);
    message.HistoricalCondition !== undefined &&
      (obj.HistoricalCondition = message.HistoricalCondition
        ? Condition.toJSON(message.HistoricalCondition)
        : undefined);
    message.EndOfHistoricalConditions !== undefined &&
      (obj.EndOfHistoricalConditions = message.EndOfHistoricalConditions
        ? Empty.toJSON(message.EndOfHistoricalConditions)
        : undefined);
    message.HistoricalToothLandmark !== undefined &&
      (obj.HistoricalToothLandmark = message.HistoricalToothLandmark
        ? ToothLandmark.toJSON(message.HistoricalToothLandmark)
        : undefined);
    message.EndOfHistoricalTeethLandmarks !== undefined &&
      (obj.EndOfHistoricalTeethLandmarks = message.EndOfHistoricalTeethLandmarks
        ? Empty.toJSON(message.EndOfHistoricalTeethLandmarks)
        : undefined);
    message.HistoricalAsset !== undefined &&
      (obj.HistoricalAsset = message.HistoricalAsset
        ? Asset.toJSON(message.HistoricalAsset)
        : undefined);
    message.EndOfHistoricalAssetsType !== undefined &&
      (obj.EndOfHistoricalAssetsType =
        message.EndOfHistoricalAssetsType !== undefined
          ? assetTypeToJSON(message.EndOfHistoricalAssetsType)
          : undefined);
    message.EndOfHistoricalAssets !== undefined &&
      (obj.EndOfHistoricalAssets = message.EndOfHistoricalAssets
        ? Empty.toJSON(message.EndOfHistoricalAssets)
        : undefined);
    message.HistoricalUser !== undefined &&
      (obj.HistoricalUser = message.HistoricalUser
        ? User.toJSON(message.HistoricalUser)
        : undefined);
    message.EndOfHistoricalUsers !== undefined &&
      (obj.EndOfHistoricalUsers = message.EndOfHistoricalUsers
        ? Empty.toJSON(message.EndOfHistoricalUsers)
        : undefined);
    message.UpdatedReport !== undefined &&
      (obj.UpdatedReport = message.UpdatedReport
        ? Report.toJSON(message.UpdatedReport)
        : undefined);
    message.UpdatedPatient !== undefined &&
      (obj.UpdatedPatient = message.UpdatedPatient
        ? Patient.toJSON(message.UpdatedPatient)
        : undefined);
    message.UpdatedStudy !== undefined &&
      (obj.UpdatedStudy = message.UpdatedStudy
        ? Study.toJSON(message.UpdatedStudy)
        : undefined);
    message.UpdatedTooth !== undefined &&
      (obj.UpdatedTooth = message.UpdatedTooth
        ? Tooth.toJSON(message.UpdatedTooth)
        : undefined);
    message.UpdatedCondition !== undefined &&
      (obj.UpdatedCondition = message.UpdatedCondition
        ? Condition.toJSON(message.UpdatedCondition)
        : undefined);
    message.UpdatedToothLandmark !== undefined &&
      (obj.UpdatedToothLandmark = message.UpdatedToothLandmark
        ? ToothLandmark.toJSON(message.UpdatedToothLandmark)
        : undefined);
    message.UpdatedAsset !== undefined &&
      (obj.UpdatedAsset = message.UpdatedAsset
        ? Asset.toJSON(message.UpdatedAsset)
        : undefined);
    message.UpdatedUser !== undefined &&
      (obj.UpdatedUser = message.UpdatedUser
        ? User.toJSON(message.UpdatedUser)
        : undefined);
    message.CreatedTooth !== undefined &&
      (obj.CreatedTooth = message.CreatedTooth
        ? Tooth.toJSON(message.CreatedTooth)
        : undefined);
    message.CreatedCondition !== undefined &&
      (obj.CreatedCondition = message.CreatedCondition
        ? Condition.toJSON(message.CreatedCondition)
        : undefined);
    message.CreatedToothLandmark !== undefined &&
      (obj.CreatedToothLandmark = message.CreatedToothLandmark
        ? ToothLandmark.toJSON(message.CreatedToothLandmark)
        : undefined);
    message.CreatedAsset !== undefined &&
      (obj.CreatedAsset = message.CreatedAsset
        ? Asset.toJSON(message.CreatedAsset)
        : undefined);
    message.ToothConditionsHint !== undefined &&
      (obj.ToothConditionsHint = message.ToothConditionsHint
        ? ReportDataStreamResp_AllowedToothConditionsHint.toJSON(
            message.ToothConditionsHint,
          )
        : undefined);
    message.Ping !== undefined &&
      (obj.Ping = message.Ping ? Empty.toJSON(message.Ping) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportDataStreamResp>, I>>(
    object: I,
  ): ReportDataStreamResp {
    const message = createBaseReportDataStreamResp();
    message.HistoricalReport =
      object.HistoricalReport !== undefined && object.HistoricalReport !== null
        ? Report.fromPartial(object.HistoricalReport)
        : undefined;
    message.HistoricalPatient =
      object.HistoricalPatient !== undefined &&
      object.HistoricalPatient !== null
        ? Patient.fromPartial(object.HistoricalPatient)
        : undefined;
    message.HistoricalStudy =
      object.HistoricalStudy !== undefined && object.HistoricalStudy !== null
        ? Study.fromPartial(object.HistoricalStudy)
        : undefined;
    message.EndOfHistoricalStudies =
      object.EndOfHistoricalStudies !== undefined &&
      object.EndOfHistoricalStudies !== null
        ? Empty.fromPartial(object.EndOfHistoricalStudies)
        : undefined;
    message.HistoricalTooth =
      object.HistoricalTooth !== undefined && object.HistoricalTooth !== null
        ? Tooth.fromPartial(object.HistoricalTooth)
        : undefined;
    message.EndOfHistoricalTeeth =
      object.EndOfHistoricalTeeth !== undefined &&
      object.EndOfHistoricalTeeth !== null
        ? Empty.fromPartial(object.EndOfHistoricalTeeth)
        : undefined;
    message.HistoricalCondition =
      object.HistoricalCondition !== undefined &&
      object.HistoricalCondition !== null
        ? Condition.fromPartial(object.HistoricalCondition)
        : undefined;
    message.EndOfHistoricalConditions =
      object.EndOfHistoricalConditions !== undefined &&
      object.EndOfHistoricalConditions !== null
        ? Empty.fromPartial(object.EndOfHistoricalConditions)
        : undefined;
    message.HistoricalToothLandmark =
      object.HistoricalToothLandmark !== undefined &&
      object.HistoricalToothLandmark !== null
        ? ToothLandmark.fromPartial(object.HistoricalToothLandmark)
        : undefined;
    message.EndOfHistoricalTeethLandmarks =
      object.EndOfHistoricalTeethLandmarks !== undefined &&
      object.EndOfHistoricalTeethLandmarks !== null
        ? Empty.fromPartial(object.EndOfHistoricalTeethLandmarks)
        : undefined;
    message.HistoricalAsset =
      object.HistoricalAsset !== undefined && object.HistoricalAsset !== null
        ? Asset.fromPartial(object.HistoricalAsset)
        : undefined;
    message.EndOfHistoricalAssetsType =
      object.EndOfHistoricalAssetsType ?? undefined;
    message.EndOfHistoricalAssets =
      object.EndOfHistoricalAssets !== undefined &&
      object.EndOfHistoricalAssets !== null
        ? Empty.fromPartial(object.EndOfHistoricalAssets)
        : undefined;
    message.HistoricalUser =
      object.HistoricalUser !== undefined && object.HistoricalUser !== null
        ? User.fromPartial(object.HistoricalUser)
        : undefined;
    message.EndOfHistoricalUsers =
      object.EndOfHistoricalUsers !== undefined &&
      object.EndOfHistoricalUsers !== null
        ? Empty.fromPartial(object.EndOfHistoricalUsers)
        : undefined;
    message.UpdatedReport =
      object.UpdatedReport !== undefined && object.UpdatedReport !== null
        ? Report.fromPartial(object.UpdatedReport)
        : undefined;
    message.UpdatedPatient =
      object.UpdatedPatient !== undefined && object.UpdatedPatient !== null
        ? Patient.fromPartial(object.UpdatedPatient)
        : undefined;
    message.UpdatedStudy =
      object.UpdatedStudy !== undefined && object.UpdatedStudy !== null
        ? Study.fromPartial(object.UpdatedStudy)
        : undefined;
    message.UpdatedTooth =
      object.UpdatedTooth !== undefined && object.UpdatedTooth !== null
        ? Tooth.fromPartial(object.UpdatedTooth)
        : undefined;
    message.UpdatedCondition =
      object.UpdatedCondition !== undefined && object.UpdatedCondition !== null
        ? Condition.fromPartial(object.UpdatedCondition)
        : undefined;
    message.UpdatedToothLandmark =
      object.UpdatedToothLandmark !== undefined &&
      object.UpdatedToothLandmark !== null
        ? ToothLandmark.fromPartial(object.UpdatedToothLandmark)
        : undefined;
    message.UpdatedAsset =
      object.UpdatedAsset !== undefined && object.UpdatedAsset !== null
        ? Asset.fromPartial(object.UpdatedAsset)
        : undefined;
    message.UpdatedUser =
      object.UpdatedUser !== undefined && object.UpdatedUser !== null
        ? User.fromPartial(object.UpdatedUser)
        : undefined;
    message.CreatedTooth =
      object.CreatedTooth !== undefined && object.CreatedTooth !== null
        ? Tooth.fromPartial(object.CreatedTooth)
        : undefined;
    message.CreatedCondition =
      object.CreatedCondition !== undefined && object.CreatedCondition !== null
        ? Condition.fromPartial(object.CreatedCondition)
        : undefined;
    message.CreatedToothLandmark =
      object.CreatedToothLandmark !== undefined &&
      object.CreatedToothLandmark !== null
        ? ToothLandmark.fromPartial(object.CreatedToothLandmark)
        : undefined;
    message.CreatedAsset =
      object.CreatedAsset !== undefined && object.CreatedAsset !== null
        ? Asset.fromPartial(object.CreatedAsset)
        : undefined;
    message.ToothConditionsHint =
      object.ToothConditionsHint !== undefined &&
      object.ToothConditionsHint !== null
        ? ReportDataStreamResp_AllowedToothConditionsHint.fromPartial(
            object.ToothConditionsHint,
          )
        : undefined;
    message.Ping =
      object.Ping !== undefined && object.Ping !== null
        ? Empty.fromPartial(object.Ping)
        : undefined;
    return message;
  },
};

function createBaseReportDataStreamResp_AllowedToothConditionsHint(): ReportDataStreamResp_AllowedToothConditionsHint {
  return { ToothID: '', ConditionCodes: [] };
}

export const ReportDataStreamResp_AllowedToothConditionsHint = {
  encode(
    message: ReportDataStreamResp_AllowedToothConditionsHint,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    writer.uint32(18).fork();
    for (const v of message.ConditionCodes) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportDataStreamResp_AllowedToothConditionsHint {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportDataStreamResp_AllowedToothConditionsHint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ConditionCodes.push(reader.int32() as any);
            }
          } else {
            message.ConditionCodes.push(reader.int32() as any);
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportDataStreamResp_AllowedToothConditionsHint {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      ConditionCodes: Array.isArray(object?.ConditionCodes)
        ? object.ConditionCodes.map((e: any) => conditionCodeFromJSON(e))
        : [],
    };
  },

  toJSON(message: ReportDataStreamResp_AllowedToothConditionsHint): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    if (message.ConditionCodes) {
      obj.ConditionCodes = message.ConditionCodes.map((e) =>
        conditionCodeToJSON(e),
      );
    } else {
      obj.ConditionCodes = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportDataStreamResp_AllowedToothConditionsHint>,
      I
    >,
  >(object: I): ReportDataStreamResp_AllowedToothConditionsHint {
    const message = createBaseReportDataStreamResp_AllowedToothConditionsHint();
    message.ToothID = object.ToothID ?? '';
    message.ConditionCodes = object.ConditionCodes?.map((e) => e) || [];
    return message;
  },
};

function createBaseReportDataStreamResp_TeethNumerationsByConditionCode(): ReportDataStreamResp_TeethNumerationsByConditionCode {
  return { Code: 0, TeethNumerations: [] };
}

export const ReportDataStreamResp_TeethNumerationsByConditionCode = {
  encode(
    message: ReportDataStreamResp_TeethNumerationsByConditionCode,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Code !== 0) {
      writer.uint32(8).int32(message.Code);
    }
    for (const v of message.TeethNumerations) {
      ToothNumeration.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportDataStreamResp_TeethNumerationsByConditionCode {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseReportDataStreamResp_TeethNumerationsByConditionCode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Code = reader.int32() as any;
          break;
        case 2:
          message.TeethNumerations.push(
            ToothNumeration.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportDataStreamResp_TeethNumerationsByConditionCode {
    return {
      Code: isSet(object.Code) ? conditionCodeFromJSON(object.Code) : 0,
      TeethNumerations: Array.isArray(object?.TeethNumerations)
        ? object.TeethNumerations.map((e: any) => ToothNumeration.fromJSON(e))
        : [],
    };
  },

  toJSON(
    message: ReportDataStreamResp_TeethNumerationsByConditionCode,
  ): unknown {
    const obj: any = {};
    message.Code !== undefined &&
      (obj.Code = conditionCodeToJSON(message.Code));
    if (message.TeethNumerations) {
      obj.TeethNumerations = message.TeethNumerations.map((e) =>
        e ? ToothNumeration.toJSON(e) : undefined,
      );
    } else {
      obj.TeethNumerations = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportDataStreamResp_TeethNumerationsByConditionCode>,
      I
    >,
  >(object: I): ReportDataStreamResp_TeethNumerationsByConditionCode {
    const message =
      createBaseReportDataStreamResp_TeethNumerationsByConditionCode();
    message.Code = object.Code ?? 0;
    message.TeethNumerations =
      object.TeethNumerations?.map((e) => ToothNumeration.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRequestReportReq(): RequestReportReq {
  return { Request: undefined };
}

export const RequestReportReq = {
  encode(
    message: RequestReportReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Request !== undefined) {
      ReportRequest.encode(message.Request, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RequestReportReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Request = ReportRequest.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RequestReportReq {
    return {
      Request: isSet(object.Request)
        ? ReportRequest.fromJSON(object.Request)
        : undefined,
    };
  },

  toJSON(message: RequestReportReq): unknown {
    const obj: any = {};
    message.Request !== undefined &&
      (obj.Request = message.Request
        ? ReportRequest.toJSON(message.Request)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RequestReportReq>, I>>(
    object: I,
  ): RequestReportReq {
    const message = createBaseRequestReportReq();
    message.Request =
      object.Request !== undefined && object.Request !== null
        ? ReportRequest.fromPartial(object.Request)
        : undefined;
    return message;
  },
};

function createBaseRequestReportResp(): RequestReportResp {
  return { Report: undefined };
}

export const RequestReportResp = {
  encode(
    message: RequestReportResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RequestReportResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestReportResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RequestReportResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: RequestReportResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RequestReportResp>, I>>(
    object: I,
  ): RequestReportResp {
    const message = createBaseRequestReportResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseDeleteReportReq(): DeleteReportReq {
  return { ReportID: '' };
}

export const DeleteReportReq = {
  encode(
    message: DeleteReportReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteReportReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteReportReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
    };
  },

  toJSON(message: DeleteReportReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteReportReq>, I>>(
    object: I,
  ): DeleteReportReq {
    const message = createBaseDeleteReportReq();
    message.ReportID = object.ReportID ?? '';
    return message;
  },
};

function createBaseDeleteReportResp(): DeleteReportResp {
  return {
    DeletedReportID: '',
    DeletedToothIDs: [],
    DeletedConditionIDs: [],
    DeletedAssetIDs: [],
    DeletedTeethLandmarkIDs: [],
    Patient: undefined,
    Study: undefined,
  };
}

export const DeleteReportResp = {
  encode(
    message: DeleteReportResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DeletedReportID !== '') {
      writer.uint32(10).string(message.DeletedReportID);
    }
    for (const v of message.DeletedToothIDs) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.DeletedConditionIDs) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.DeletedAssetIDs) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.DeletedTeethLandmarkIDs) {
      writer.uint32(58).string(v!);
    }
    if (message.Patient !== undefined) {
      Patient.encode(message.Patient, writer.uint32(42).fork()).ldelim();
    }
    if (message.Study !== undefined) {
      Study.encode(message.Study, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteReportResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteReportResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DeletedReportID = reader.string();
          break;
        case 2:
          message.DeletedToothIDs.push(reader.string());
          break;
        case 3:
          message.DeletedConditionIDs.push(reader.string());
          break;
        case 4:
          message.DeletedAssetIDs.push(reader.string());
          break;
        case 7:
          message.DeletedTeethLandmarkIDs.push(reader.string());
          break;
        case 5:
          message.Patient = Patient.decode(reader, reader.uint32());
          break;
        case 6:
          message.Study = Study.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeleteReportResp {
    return {
      DeletedReportID: isSet(object.DeletedReportID)
        ? String(object.DeletedReportID)
        : '',
      DeletedToothIDs: Array.isArray(object?.DeletedToothIDs)
        ? object.DeletedToothIDs.map((e: any) => String(e))
        : [],
      DeletedConditionIDs: Array.isArray(object?.DeletedConditionIDs)
        ? object.DeletedConditionIDs.map((e: any) => String(e))
        : [],
      DeletedAssetIDs: Array.isArray(object?.DeletedAssetIDs)
        ? object.DeletedAssetIDs.map((e: any) => String(e))
        : [],
      DeletedTeethLandmarkIDs: Array.isArray(object?.DeletedTeethLandmarkIDs)
        ? object.DeletedTeethLandmarkIDs.map((e: any) => String(e))
        : [],
      Patient: isSet(object.Patient)
        ? Patient.fromJSON(object.Patient)
        : undefined,
      Study: isSet(object.Study) ? Study.fromJSON(object.Study) : undefined,
    };
  },

  toJSON(message: DeleteReportResp): unknown {
    const obj: any = {};
    message.DeletedReportID !== undefined &&
      (obj.DeletedReportID = message.DeletedReportID);
    if (message.DeletedToothIDs) {
      obj.DeletedToothIDs = message.DeletedToothIDs.map((e) => e);
    } else {
      obj.DeletedToothIDs = [];
    }
    if (message.DeletedConditionIDs) {
      obj.DeletedConditionIDs = message.DeletedConditionIDs.map((e) => e);
    } else {
      obj.DeletedConditionIDs = [];
    }
    if (message.DeletedAssetIDs) {
      obj.DeletedAssetIDs = message.DeletedAssetIDs.map((e) => e);
    } else {
      obj.DeletedAssetIDs = [];
    }
    if (message.DeletedTeethLandmarkIDs) {
      obj.DeletedTeethLandmarkIDs = message.DeletedTeethLandmarkIDs.map(
        (e) => e,
      );
    } else {
      obj.DeletedTeethLandmarkIDs = [];
    }
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? Patient.toJSON(message.Patient)
        : undefined);
    message.Study !== undefined &&
      (obj.Study = message.Study ? Study.toJSON(message.Study) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeleteReportResp>, I>>(
    object: I,
  ): DeleteReportResp {
    const message = createBaseDeleteReportResp();
    message.DeletedReportID = object.DeletedReportID ?? '';
    message.DeletedToothIDs = object.DeletedToothIDs?.map((e) => e) || [];
    message.DeletedConditionIDs =
      object.DeletedConditionIDs?.map((e) => e) || [];
    message.DeletedAssetIDs = object.DeletedAssetIDs?.map((e) => e) || [];
    message.DeletedTeethLandmarkIDs =
      object.DeletedTeethLandmarkIDs?.map((e) => e) || [];
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? Patient.fromPartial(object.Patient)
        : undefined;
    message.Study =
      object.Study !== undefined && object.Study !== null
        ? Study.fromPartial(object.Study)
        : undefined;
    return message;
  },
};

function createBaseSetROIReq(): SetROIReq {
  return { ReportID: '', ROIToothIDs: [] };
}

export const SetROIReq = {
  encode(
    message: SetROIReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    for (const v of message.ROIToothIDs) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetROIReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetROIReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.ROIToothIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetROIReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      ROIToothIDs: Array.isArray(object?.ROIToothIDs)
        ? object.ROIToothIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: SetROIReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    if (message.ROIToothIDs) {
      obj.ROIToothIDs = message.ROIToothIDs.map((e) => e);
    } else {
      obj.ROIToothIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetROIReq>, I>>(
    object: I,
  ): SetROIReq {
    const message = createBaseSetROIReq();
    message.ReportID = object.ReportID ?? '';
    message.ROIToothIDs = object.ROIToothIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetROIResp(): SetROIResp {
  return { AllReportTeeth: [], Report: undefined };
}

export const SetROIResp = {
  encode(
    message: SetROIResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.AllReportTeeth) {
      Tooth.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetROIResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetROIResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.AllReportTeeth.push(Tooth.decode(reader, reader.uint32()));
          break;
        case 2:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetROIResp {
    return {
      AllReportTeeth: Array.isArray(object?.AllReportTeeth)
        ? object.AllReportTeeth.map((e: any) => Tooth.fromJSON(e))
        : [],
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SetROIResp): unknown {
    const obj: any = {};
    if (message.AllReportTeeth) {
      obj.AllReportTeeth = message.AllReportTeeth.map((e) =>
        e ? Tooth.toJSON(e) : undefined,
      );
    } else {
      obj.AllReportTeeth = [];
    }
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetROIResp>, I>>(
    object: I,
  ): SetROIResp {
    const message = createBaseSetROIResp();
    message.AllReportTeeth =
      object.AllReportTeeth?.map((e) => Tooth.fromPartial(e)) || [];
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseSetReportConclusionReq(): SetReportConclusionReq {
  return { ReportID: '', LegacyConclusion: '' };
}

export const SetReportConclusionReq = {
  encode(
    message: SetReportConclusionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    if (message.LegacyConclusion !== '') {
      writer.uint32(18).string(message.LegacyConclusion);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportConclusionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportConclusionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.LegacyConclusion = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportConclusionReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      LegacyConclusion: isSet(object.LegacyConclusion)
        ? String(object.LegacyConclusion)
        : '',
    };
  },

  toJSON(message: SetReportConclusionReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.LegacyConclusion !== undefined &&
      (obj.LegacyConclusion = message.LegacyConclusion);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportConclusionReq>, I>>(
    object: I,
  ): SetReportConclusionReq {
    const message = createBaseSetReportConclusionReq();
    message.ReportID = object.ReportID ?? '';
    message.LegacyConclusion = object.LegacyConclusion ?? '';
    return message;
  },
};

function createBaseSetReportConclusionResp(): SetReportConclusionResp {
  return { Report: undefined };
}

export const SetReportConclusionResp = {
  encode(
    message: SetReportConclusionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportConclusionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportConclusionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportConclusionResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SetReportConclusionResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportConclusionResp>, I>>(
    object: I,
  ): SetReportConclusionResp {
    const message = createBaseSetReportConclusionResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseSetReportSettingsReq(): SetReportSettingsReq {
  return { ReportID: '', Settings: undefined };
}

export const SetReportSettingsReq = {
  encode(
    message: SetReportSettingsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    if (message.Settings !== undefined) {
      ReportSettings.encode(
        message.Settings,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportSettingsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportSettingsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.Settings = ReportSettings.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportSettingsReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      Settings: isSet(object.Settings)
        ? ReportSettings.fromJSON(object.Settings)
        : undefined,
    };
  },

  toJSON(message: SetReportSettingsReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.Settings !== undefined &&
      (obj.Settings = message.Settings
        ? ReportSettings.toJSON(message.Settings)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportSettingsReq>, I>>(
    object: I,
  ): SetReportSettingsReq {
    const message = createBaseSetReportSettingsReq();
    message.ReportID = object.ReportID ?? '';
    message.Settings =
      object.Settings !== undefined && object.Settings !== null
        ? ReportSettings.fromPartial(object.Settings)
        : undefined;
    return message;
  },
};

function createBaseSetReportSettingsResp(): SetReportSettingsResp {
  return { Report: undefined, TeethConditions: [] };
}

export const SetReportSettingsResp = {
  encode(
    message: SetReportSettingsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.TeethConditions) {
      Condition.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportSettingsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportSettingsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        case 2:
          message.TeethConditions.push(
            Condition.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportSettingsResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
      TeethConditions: Array.isArray(object?.TeethConditions)
        ? object.TeethConditions.map((e: any) => Condition.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetReportSettingsResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    if (message.TeethConditions) {
      obj.TeethConditions = message.TeethConditions.map((e) =>
        e ? Condition.toJSON(e) : undefined,
      );
    } else {
      obj.TeethConditions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportSettingsResp>, I>>(
    object: I,
  ): SetReportSettingsResp {
    const message = createBaseSetReportSettingsResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    message.TeethConditions =
      object.TeethConditions?.map((e) => Condition.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetReportPrintSettingsReq(): SetReportPrintSettingsReq {
  return { ReportID: '', PrintSettings: undefined };
}

export const SetReportPrintSettingsReq = {
  encode(
    message: SetReportPrintSettingsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    if (message.PrintSettings !== undefined) {
      ReportPrintSettings.encode(
        message.PrintSettings,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportPrintSettingsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportPrintSettingsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.PrintSettings = ReportPrintSettings.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportPrintSettingsReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      PrintSettings: isSet(object.PrintSettings)
        ? ReportPrintSettings.fromJSON(object.PrintSettings)
        : undefined,
    };
  },

  toJSON(message: SetReportPrintSettingsReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.PrintSettings !== undefined &&
      (obj.PrintSettings = message.PrintSettings
        ? ReportPrintSettings.toJSON(message.PrintSettings)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportPrintSettingsReq>, I>>(
    object: I,
  ): SetReportPrintSettingsReq {
    const message = createBaseSetReportPrintSettingsReq();
    message.ReportID = object.ReportID ?? '';
    message.PrintSettings =
      object.PrintSettings !== undefined && object.PrintSettings !== null
        ? ReportPrintSettings.fromPartial(object.PrintSettings)
        : undefined;
    return message;
  },
};

function createBaseSetReportPrintSettingsResp(): SetReportPrintSettingsResp {
  return { Report: undefined };
}

export const SetReportPrintSettingsResp = {
  encode(
    message: SetReportPrintSettingsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportPrintSettingsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportPrintSettingsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportPrintSettingsResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SetReportPrintSettingsResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportPrintSettingsResp>, I>>(
    object: I,
  ): SetReportPrintSettingsResp {
    const message = createBaseSetReportPrintSettingsResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseSignReportReq(): SignReportReq {
  return { ReportID: '' };
}

export const SignReportReq = {
  encode(
    message: SignReportReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignReportReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignReportReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
    };
  },

  toJSON(message: SignReportReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignReportReq>, I>>(
    object: I,
  ): SignReportReq {
    const message = createBaseSignReportReq();
    message.ReportID = object.ReportID ?? '';
    return message;
  },
};

function createBaseSignReportResp(): SignReportResp {
  return { Report: undefined };
}

export const SignReportResp = {
  encode(
    message: SignReportResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SignReportResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSignReportResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SignReportResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SignReportResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SignReportResp>, I>>(
    object: I,
  ): SignReportResp {
    const message = createBaseSignReportResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseSetReportMedicalImageViewOptionsReq(): SetReportMedicalImageViewOptionsReq {
  return { ReportID: '', MedicalImageViewOptions: undefined };
}

export const SetReportMedicalImageViewOptionsReq = {
  encode(
    message: SetReportMedicalImageViewOptionsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    if (message.MedicalImageViewOptions !== undefined) {
      MedicalImageViewOptions.encode(
        message.MedicalImageViewOptions,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportMedicalImageViewOptionsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportMedicalImageViewOptionsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.MedicalImageViewOptions = MedicalImageViewOptions.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportMedicalImageViewOptionsReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      MedicalImageViewOptions: isSet(object.MedicalImageViewOptions)
        ? MedicalImageViewOptions.fromJSON(object.MedicalImageViewOptions)
        : undefined,
    };
  },

  toJSON(message: SetReportMedicalImageViewOptionsReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.MedicalImageViewOptions !== undefined &&
      (obj.MedicalImageViewOptions = message.MedicalImageViewOptions
        ? MedicalImageViewOptions.toJSON(message.MedicalImageViewOptions)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetReportMedicalImageViewOptionsReq>, I>,
  >(object: I): SetReportMedicalImageViewOptionsReq {
    const message = createBaseSetReportMedicalImageViewOptionsReq();
    message.ReportID = object.ReportID ?? '';
    message.MedicalImageViewOptions =
      object.MedicalImageViewOptions !== undefined &&
      object.MedicalImageViewOptions !== null
        ? MedicalImageViewOptions.fromPartial(object.MedicalImageViewOptions)
        : undefined;
    return message;
  },
};

function createBaseSetReportMedicalImageViewOptionsResp(): SetReportMedicalImageViewOptionsResp {
  return { Report: undefined };
}

export const SetReportMedicalImageViewOptionsResp = {
  encode(
    message: SetReportMedicalImageViewOptionsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportMedicalImageViewOptionsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportMedicalImageViewOptionsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportMedicalImageViewOptionsResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SetReportMedicalImageViewOptionsResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetReportMedicalImageViewOptionsResp>, I>,
  >(object: I): SetReportMedicalImageViewOptionsResp {
    const message = createBaseSetReportMedicalImageViewOptionsResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseSetImageMetaViewOptionsReq(): SetImageMetaViewOptionsReq {
  return { ReportID: '', ImageMetaID: '', MedicalImageViewOptions: undefined };
}

export const SetImageMetaViewOptionsReq = {
  encode(
    message: SetImageMetaViewOptionsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    if (message.ImageMetaID !== '') {
      writer.uint32(18).string(message.ImageMetaID);
    }
    if (message.MedicalImageViewOptions !== undefined) {
      MedicalImageViewOptions.encode(
        message.MedicalImageViewOptions,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetImageMetaViewOptionsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetImageMetaViewOptionsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.ImageMetaID = reader.string();
          break;
        case 3:
          message.MedicalImageViewOptions = MedicalImageViewOptions.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetImageMetaViewOptionsReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      ImageMetaID: isSet(object.ImageMetaID) ? String(object.ImageMetaID) : '',
      MedicalImageViewOptions: isSet(object.MedicalImageViewOptions)
        ? MedicalImageViewOptions.fromJSON(object.MedicalImageViewOptions)
        : undefined,
    };
  },

  toJSON(message: SetImageMetaViewOptionsReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.ImageMetaID !== undefined &&
      (obj.ImageMetaID = message.ImageMetaID);
    message.MedicalImageViewOptions !== undefined &&
      (obj.MedicalImageViewOptions = message.MedicalImageViewOptions
        ? MedicalImageViewOptions.toJSON(message.MedicalImageViewOptions)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetImageMetaViewOptionsReq>, I>>(
    object: I,
  ): SetImageMetaViewOptionsReq {
    const message = createBaseSetImageMetaViewOptionsReq();
    message.ReportID = object.ReportID ?? '';
    message.ImageMetaID = object.ImageMetaID ?? '';
    message.MedicalImageViewOptions =
      object.MedicalImageViewOptions !== undefined &&
      object.MedicalImageViewOptions !== null
        ? MedicalImageViewOptions.fromPartial(object.MedicalImageViewOptions)
        : undefined;
    return message;
  },
};

function createBaseSetImageMetaViewOptionsResp(): SetImageMetaViewOptionsResp {
  return { Report: undefined };
}

export const SetImageMetaViewOptionsResp = {
  encode(
    message: SetImageMetaViewOptionsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetImageMetaViewOptionsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetImageMetaViewOptionsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetImageMetaViewOptionsResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SetImageMetaViewOptionsResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetImageMetaViewOptionsResp>, I>>(
    object: I,
  ): SetImageMetaViewOptionsResp {
    const message = createBaseSetImageMetaViewOptionsResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseSetReportToothCommentReq(): SetReportToothCommentReq {
  return { ToothID: '', Comment: '' };
}

export const SetReportToothCommentReq = {
  encode(
    message: SetReportToothCommentReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.Comment !== '') {
      writer.uint32(18).string(message.Comment);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothCommentReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothCommentReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.Comment = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothCommentReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      Comment: isSet(object.Comment) ? String(object.Comment) : '',
    };
  },

  toJSON(message: SetReportToothCommentReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.Comment !== undefined && (obj.Comment = message.Comment);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothCommentReq>, I>>(
    object: I,
  ): SetReportToothCommentReq {
    const message = createBaseSetReportToothCommentReq();
    message.ToothID = object.ToothID ?? '';
    message.Comment = object.Comment ?? '';
    return message;
  },
};

function createBaseSetReportToothCommentResp(): SetReportToothCommentResp {
  return { Tooth: undefined, Report: undefined };
}

export const SetReportToothCommentResp = {
  encode(
    message: SetReportToothCommentResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothCommentResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothCommentResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        case 2:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothCommentResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SetReportToothCommentResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothCommentResp>, I>>(
    object: I,
  ): SetReportToothCommentResp {
    const message = createBaseSetReportToothCommentResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseSetReportToothNumerationReq(): SetReportToothNumerationReq {
  return { ToothID: '', ToothNumeration: undefined };
}

export const SetReportToothNumerationReq = {
  encode(
    message: SetReportToothNumerationReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.ToothNumeration !== undefined) {
      ToothNumeration.encode(
        message.ToothNumeration,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothNumerationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothNumerationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.ToothNumeration = ToothNumeration.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothNumerationReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      ToothNumeration: isSet(object.ToothNumeration)
        ? ToothNumeration.fromJSON(object.ToothNumeration)
        : undefined,
    };
  },

  toJSON(message: SetReportToothNumerationReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.ToothNumeration !== undefined &&
      (obj.ToothNumeration = message.ToothNumeration
        ? ToothNumeration.toJSON(message.ToothNumeration)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothNumerationReq>, I>>(
    object: I,
  ): SetReportToothNumerationReq {
    const message = createBaseSetReportToothNumerationReq();
    message.ToothID = object.ToothID ?? '';
    message.ToothNumeration =
      object.ToothNumeration !== undefined && object.ToothNumeration !== null
        ? ToothNumeration.fromPartial(object.ToothNumeration)
        : undefined;
    return message;
  },
};

function createBaseSetReportToothNumerationResp(): SetReportToothNumerationResp {
  return { Tooth: undefined, Report: undefined };
}

export const SetReportToothNumerationResp = {
  encode(
    message: SetReportToothNumerationResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothNumerationResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothNumerationResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        case 2:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothNumerationResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SetReportToothNumerationResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<SetReportToothNumerationResp>, I>>(
    object: I,
  ): SetReportToothNumerationResp {
    const message = createBaseSetReportToothNumerationResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseApproveReportToothReq(): ApproveReportToothReq {
  return { ToothID: '', ToothConditionIDs: [] };
}

export const ApproveReportToothReq = {
  encode(
    message: ApproveReportToothReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    for (const v of message.ToothConditionIDs) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ApproveReportToothReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveReportToothReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.ToothConditionIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ApproveReportToothReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      ToothConditionIDs: Array.isArray(object?.ToothConditionIDs)
        ? object.ToothConditionIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: ApproveReportToothReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    if (message.ToothConditionIDs) {
      obj.ToothConditionIDs = message.ToothConditionIDs.map((e) => e);
    } else {
      obj.ToothConditionIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ApproveReportToothReq>, I>>(
    object: I,
  ): ApproveReportToothReq {
    const message = createBaseApproveReportToothReq();
    message.ToothID = object.ToothID ?? '';
    message.ToothConditionIDs = object.ToothConditionIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseApproveReportToothResp(): ApproveReportToothResp {
  return { Tooth: undefined, Conditions: [] };
}

export const ApproveReportToothResp = {
  encode(
    message: ApproveReportToothResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Conditions) {
      Condition.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ApproveReportToothResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveReportToothResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        case 2:
          message.Conditions.push(Condition.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ApproveReportToothResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) => Condition.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ApproveReportToothResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ApproveReportToothResp>, I>>(
    object: I,
  ): ApproveReportToothResp {
    const message = createBaseApproveReportToothResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    message.Conditions =
      object.Conditions?.map((e) => Condition.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDisapproveReportToothReq(): DisapproveReportToothReq {
  return { ToothID: '' };
}

export const DisapproveReportToothReq = {
  encode(
    message: DisapproveReportToothReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DisapproveReportToothReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDisapproveReportToothReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DisapproveReportToothReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
    };
  },

  toJSON(message: DisapproveReportToothReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DisapproveReportToothReq>, I>>(
    object: I,
  ): DisapproveReportToothReq {
    const message = createBaseDisapproveReportToothReq();
    message.ToothID = object.ToothID ?? '';
    return message;
  },
};

function createBaseDisapproveReportToothResp(): DisapproveReportToothResp {
  return { Tooth: undefined, Report: undefined };
}

export const DisapproveReportToothResp = {
  encode(
    message: DisapproveReportToothResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): DisapproveReportToothResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDisapproveReportToothResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        case 2:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DisapproveReportToothResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: DisapproveReportToothResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DisapproveReportToothResp>, I>>(
    object: I,
  ): DisapproveReportToothResp {
    const message = createBaseDisapproveReportToothResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseApproveAllTeethAndSignReportReq(): ApproveAllTeethAndSignReportReq {
  return { ReportID: '', TeethConditionIDs: [] };
}

export const ApproveAllTeethAndSignReportReq = {
  encode(
    message: ApproveAllTeethAndSignReportReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    for (const v of message.TeethConditionIDs) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ApproveAllTeethAndSignReportReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveAllTeethAndSignReportReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.TeethConditionIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ApproveAllTeethAndSignReportReq {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      TeethConditionIDs: Array.isArray(object?.TeethConditionIDs)
        ? object.TeethConditionIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: ApproveAllTeethAndSignReportReq): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    if (message.TeethConditionIDs) {
      obj.TeethConditionIDs = message.TeethConditionIDs.map((e) => e);
    } else {
      obj.TeethConditionIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ApproveAllTeethAndSignReportReq>, I>>(
    object: I,
  ): ApproveAllTeethAndSignReportReq {
    const message = createBaseApproveAllTeethAndSignReportReq();
    message.ReportID = object.ReportID ?? '';
    message.TeethConditionIDs = object.TeethConditionIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseApproveAllTeethAndSignReportResp(): ApproveAllTeethAndSignReportResp {
  return { Report: undefined, Teeth: [], Conditions: [] };
}

export const ApproveAllTeethAndSignReportResp = {
  encode(
    message: ApproveAllTeethAndSignReportResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Teeth) {
      Tooth.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.Conditions) {
      Condition.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ApproveAllTeethAndSignReportResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApproveAllTeethAndSignReportResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        case 2:
          message.Teeth.push(Tooth.decode(reader, reader.uint32()));
          break;
        case 3:
          message.Conditions.push(Condition.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ApproveAllTeethAndSignReportResp {
    return {
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
      Teeth: Array.isArray(object?.Teeth)
        ? object.Teeth.map((e: any) => Tooth.fromJSON(e))
        : [],
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) => Condition.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ApproveAllTeethAndSignReportResp): unknown {
    const obj: any = {};
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    if (message.Teeth) {
      obj.Teeth = message.Teeth.map((e) => (e ? Tooth.toJSON(e) : undefined));
    } else {
      obj.Teeth = [];
    }
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ApproveAllTeethAndSignReportResp>, I>,
  >(object: I): ApproveAllTeethAndSignReportResp {
    const message = createBaseApproveAllTeethAndSignReportResp();
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    message.Teeth = object.Teeth?.map((e) => Tooth.fromPartial(e)) || [];
    message.Conditions =
      object.Conditions?.map((e) => Condition.fromPartial(e)) || [];
    return message;
  },
};

function createBaseResetReportToothReq(): ResetReportToothReq {
  return { ToothID: '' };
}

export const ResetReportToothReq = {
  encode(
    message: ResetReportToothReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetReportToothReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetReportToothReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ResetReportToothReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
    };
  },

  toJSON(message: ResetReportToothReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ResetReportToothReq>, I>>(
    object: I,
  ): ResetReportToothReq {
    const message = createBaseResetReportToothReq();
    message.ToothID = object.ToothID ?? '';
    return message;
  },
};

function createBaseResetReportToothResp(): ResetReportToothResp {
  return { Teeth: [], Conditions: [], ToothLandmarks: [], Report: undefined };
}

export const ResetReportToothResp = {
  encode(
    message: ResetReportToothResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Teeth) {
      Tooth.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Conditions) {
      Condition.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.ToothLandmarks) {
      ToothLandmark.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ResetReportToothResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetReportToothResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Teeth.push(Tooth.decode(reader, reader.uint32()));
          break;
        case 2:
          message.Conditions.push(Condition.decode(reader, reader.uint32()));
          break;
        case 3:
          message.ToothLandmarks.push(
            ToothLandmark.decode(reader, reader.uint32()),
          );
          break;
        case 4:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ResetReportToothResp {
    return {
      Teeth: Array.isArray(object?.Teeth)
        ? object.Teeth.map((e: any) => Tooth.fromJSON(e))
        : [],
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) => Condition.fromJSON(e))
        : [],
      ToothLandmarks: Array.isArray(object?.ToothLandmarks)
        ? object.ToothLandmarks.map((e: any) => ToothLandmark.fromJSON(e))
        : [],
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: ResetReportToothResp): unknown {
    const obj: any = {};
    if (message.Teeth) {
      obj.Teeth = message.Teeth.map((e) => (e ? Tooth.toJSON(e) : undefined));
    } else {
      obj.Teeth = [];
    }
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    if (message.ToothLandmarks) {
      obj.ToothLandmarks = message.ToothLandmarks.map((e) =>
        e ? ToothLandmark.toJSON(e) : undefined,
      );
    } else {
      obj.ToothLandmarks = [];
    }
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ResetReportToothResp>, I>>(
    object: I,
  ): ResetReportToothResp {
    const message = createBaseResetReportToothResp();
    message.Teeth = object.Teeth?.map((e) => Tooth.fromPartial(e)) || [];
    message.Conditions =
      object.Conditions?.map((e) => Condition.fromPartial(e)) || [];
    message.ToothLandmarks =
      object.ToothLandmarks?.map((e) => ToothLandmark.fromPartial(e)) || [];
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseAddToothDisplaySliceReq(): AddToothDisplaySliceReq {
  return { ToothID: '', AssetID: '' };
}

export const AddToothDisplaySliceReq = {
  encode(
    message: AddToothDisplaySliceReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.AssetID !== '') {
      writer.uint32(18).string(message.AssetID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddToothDisplaySliceReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddToothDisplaySliceReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.AssetID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddToothDisplaySliceReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      AssetID: isSet(object.AssetID) ? String(object.AssetID) : '',
    };
  },

  toJSON(message: AddToothDisplaySliceReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.AssetID !== undefined && (obj.AssetID = message.AssetID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddToothDisplaySliceReq>, I>>(
    object: I,
  ): AddToothDisplaySliceReq {
    const message = createBaseAddToothDisplaySliceReq();
    message.ToothID = object.ToothID ?? '';
    message.AssetID = object.AssetID ?? '';
    return message;
  },
};

function createBaseAddToothDisplaySliceResp(): AddToothDisplaySliceResp {
  return { Tooth: undefined, Report: undefined };
}

export const AddToothDisplaySliceResp = {
  encode(
    message: AddToothDisplaySliceResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AddToothDisplaySliceResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddToothDisplaySliceResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        case 2:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AddToothDisplaySliceResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: AddToothDisplaySliceResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AddToothDisplaySliceResp>, I>>(
    object: I,
  ): AddToothDisplaySliceResp {
    const message = createBaseAddToothDisplaySliceResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseRemoveToothDisplaySliceReq(): RemoveToothDisplaySliceReq {
  return { ToothID: '', AssetID: '' };
}

export const RemoveToothDisplaySliceReq = {
  encode(
    message: RemoveToothDisplaySliceReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    if (message.AssetID !== '') {
      writer.uint32(18).string(message.AssetID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemoveToothDisplaySliceReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveToothDisplaySliceReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.AssetID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveToothDisplaySliceReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      AssetID: isSet(object.AssetID) ? String(object.AssetID) : '',
    };
  },

  toJSON(message: RemoveToothDisplaySliceReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    message.AssetID !== undefined && (obj.AssetID = message.AssetID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RemoveToothDisplaySliceReq>, I>>(
    object: I,
  ): RemoveToothDisplaySliceReq {
    const message = createBaseRemoveToothDisplaySliceReq();
    message.ToothID = object.ToothID ?? '';
    message.AssetID = object.AssetID ?? '';
    return message;
  },
};

function createBaseRemoveToothDisplaySliceResp(): RemoveToothDisplaySliceResp {
  return { Tooth: undefined, Report: undefined };
}

export const RemoveToothDisplaySliceResp = {
  encode(
    message: RemoveToothDisplaySliceResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): RemoveToothDisplaySliceResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveToothDisplaySliceResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        case 2:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RemoveToothDisplaySliceResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: RemoveToothDisplaySliceResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RemoveToothDisplaySliceResp>, I>>(
    object: I,
  ): RemoveToothDisplaySliceResp {
    const message = createBaseRemoveToothDisplaySliceResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseSetToothLocalizationCropTopLayerAnnotationsReq(): SetToothLocalizationCropTopLayerAnnotationsReq {
  return { LocalizationID: '', Annotations: [] };
}

export const SetToothLocalizationCropTopLayerAnnotationsReq = {
  encode(
    message: SetToothLocalizationCropTopLayerAnnotationsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.LocalizationID !== '') {
      writer.uint32(10).string(message.LocalizationID);
    }
    for (const v of message.Annotations) {
      Annotation.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetToothLocalizationCropTopLayerAnnotationsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetToothLocalizationCropTopLayerAnnotationsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.LocalizationID = reader.string();
          break;
        case 2:
          message.Annotations.push(Annotation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetToothLocalizationCropTopLayerAnnotationsReq {
    return {
      LocalizationID: isSet(object.LocalizationID)
        ? String(object.LocalizationID)
        : '',
      Annotations: Array.isArray(object?.Annotations)
        ? object.Annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SetToothLocalizationCropTopLayerAnnotationsReq): unknown {
    const obj: any = {};
    message.LocalizationID !== undefined &&
      (obj.LocalizationID = message.LocalizationID);
    if (message.Annotations) {
      obj.Annotations = message.Annotations.map((e) =>
        e ? Annotation.toJSON(e) : undefined,
      );
    } else {
      obj.Annotations = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<SetToothLocalizationCropTopLayerAnnotationsReq>,
      I
    >,
  >(object: I): SetToothLocalizationCropTopLayerAnnotationsReq {
    const message = createBaseSetToothLocalizationCropTopLayerAnnotationsReq();
    message.LocalizationID = object.LocalizationID ?? '';
    message.Annotations =
      object.Annotations?.map((e) => Annotation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSetToothLocalizationCropTopLayerAnnotationsResp(): SetToothLocalizationCropTopLayerAnnotationsResp {
  return { Tooth: undefined };
}

export const SetToothLocalizationCropTopLayerAnnotationsResp = {
  encode(
    message: SetToothLocalizationCropTopLayerAnnotationsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetToothLocalizationCropTopLayerAnnotationsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetToothLocalizationCropTopLayerAnnotationsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetToothLocalizationCropTopLayerAnnotationsResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(message: SetToothLocalizationCropTopLayerAnnotationsResp): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<SetToothLocalizationCropTopLayerAnnotationsResp>,
      I
    >,
  >(object: I): SetToothLocalizationCropTopLayerAnnotationsResp {
    const message = createBaseSetToothLocalizationCropTopLayerAnnotationsResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

function createBaseSetToothLocalizationCropMedicalImageViewOptionsReq(): SetToothLocalizationCropMedicalImageViewOptionsReq {
  return { LocalizationID: '', MedicalImageViewOptions: undefined };
}

export const SetToothLocalizationCropMedicalImageViewOptionsReq = {
  encode(
    message: SetToothLocalizationCropMedicalImageViewOptionsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.LocalizationID !== '') {
      writer.uint32(10).string(message.LocalizationID);
    }
    if (message.MedicalImageViewOptions !== undefined) {
      MedicalImageViewOptions.encode(
        message.MedicalImageViewOptions,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetToothLocalizationCropMedicalImageViewOptionsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseSetToothLocalizationCropMedicalImageViewOptionsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.LocalizationID = reader.string();
          break;
        case 2:
          message.MedicalImageViewOptions = MedicalImageViewOptions.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetToothLocalizationCropMedicalImageViewOptionsReq {
    return {
      LocalizationID: isSet(object.LocalizationID)
        ? String(object.LocalizationID)
        : '',
      MedicalImageViewOptions: isSet(object.MedicalImageViewOptions)
        ? MedicalImageViewOptions.fromJSON(object.MedicalImageViewOptions)
        : undefined,
    };
  },

  toJSON(message: SetToothLocalizationCropMedicalImageViewOptionsReq): unknown {
    const obj: any = {};
    message.LocalizationID !== undefined &&
      (obj.LocalizationID = message.LocalizationID);
    message.MedicalImageViewOptions !== undefined &&
      (obj.MedicalImageViewOptions = message.MedicalImageViewOptions
        ? MedicalImageViewOptions.toJSON(message.MedicalImageViewOptions)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<SetToothLocalizationCropMedicalImageViewOptionsReq>,
      I
    >,
  >(object: I): SetToothLocalizationCropMedicalImageViewOptionsReq {
    const message =
      createBaseSetToothLocalizationCropMedicalImageViewOptionsReq();
    message.LocalizationID = object.LocalizationID ?? '';
    message.MedicalImageViewOptions =
      object.MedicalImageViewOptions !== undefined &&
      object.MedicalImageViewOptions !== null
        ? MedicalImageViewOptions.fromPartial(object.MedicalImageViewOptions)
        : undefined;
    return message;
  },
};

function createBaseSetToothLocalizationCropMedicalImageViewOptionsResp(): SetToothLocalizationCropMedicalImageViewOptionsResp {
  return { Tooth: undefined };
}

export const SetToothLocalizationCropMedicalImageViewOptionsResp = {
  encode(
    message: SetToothLocalizationCropMedicalImageViewOptionsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetToothLocalizationCropMedicalImageViewOptionsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseSetToothLocalizationCropMedicalImageViewOptionsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetToothLocalizationCropMedicalImageViewOptionsResp {
    return {
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
    };
  },

  toJSON(
    message: SetToothLocalizationCropMedicalImageViewOptionsResp,
  ): unknown {
    const obj: any = {};
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<SetToothLocalizationCropMedicalImageViewOptionsResp>,
      I
    >,
  >(object: I): SetToothLocalizationCropMedicalImageViewOptionsResp {
    const message =
      createBaseSetToothLocalizationCropMedicalImageViewOptionsResp();
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    return message;
  },
};

function createBaseChangeToothLocalizationNumerationReq(): ChangeToothLocalizationNumerationReq {
  return { ToothLocalizationID: '', Numeration: undefined };
}

export const ChangeToothLocalizationNumerationReq = {
  encode(
    message: ChangeToothLocalizationNumerationReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothLocalizationID !== '') {
      writer.uint32(10).string(message.ToothLocalizationID);
    }
    if (message.Numeration !== undefined) {
      ToothNumeration.encode(
        message.Numeration,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ChangeToothLocalizationNumerationReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChangeToothLocalizationNumerationReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothLocalizationID = reader.string();
          break;
        case 2:
          message.Numeration = ToothNumeration.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChangeToothLocalizationNumerationReq {
    return {
      ToothLocalizationID: isSet(object.ToothLocalizationID)
        ? String(object.ToothLocalizationID)
        : '',
      Numeration: isSet(object.Numeration)
        ? ToothNumeration.fromJSON(object.Numeration)
        : undefined,
    };
  },

  toJSON(message: ChangeToothLocalizationNumerationReq): unknown {
    const obj: any = {};
    message.ToothLocalizationID !== undefined &&
      (obj.ToothLocalizationID = message.ToothLocalizationID);
    message.Numeration !== undefined &&
      (obj.Numeration = message.Numeration
        ? ToothNumeration.toJSON(message.Numeration)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ChangeToothLocalizationNumerationReq>, I>,
  >(object: I): ChangeToothLocalizationNumerationReq {
    const message = createBaseChangeToothLocalizationNumerationReq();
    message.ToothLocalizationID = object.ToothLocalizationID ?? '';
    message.Numeration =
      object.Numeration !== undefined && object.Numeration !== null
        ? ToothNumeration.fromPartial(object.Numeration)
        : undefined;
    return message;
  },
};

function createBaseChangeToothLocalizationNumerationResp(): ChangeToothLocalizationNumerationResp {
  return { Teeth: [], Conditions: [], TeethLandmarks: [], Report: undefined };
}

export const ChangeToothLocalizationNumerationResp = {
  encode(
    message: ChangeToothLocalizationNumerationResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Teeth) {
      Tooth.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.Conditions) {
      Condition.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.TeethLandmarks) {
      ToothLandmark.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ChangeToothLocalizationNumerationResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChangeToothLocalizationNumerationResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Teeth.push(Tooth.decode(reader, reader.uint32()));
          break;
        case 2:
          message.Conditions.push(Condition.decode(reader, reader.uint32()));
          break;
        case 3:
          message.TeethLandmarks.push(
            ToothLandmark.decode(reader, reader.uint32()),
          );
          break;
        case 4:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ChangeToothLocalizationNumerationResp {
    return {
      Teeth: Array.isArray(object?.Teeth)
        ? object.Teeth.map((e: any) => Tooth.fromJSON(e))
        : [],
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) => Condition.fromJSON(e))
        : [],
      TeethLandmarks: Array.isArray(object?.TeethLandmarks)
        ? object.TeethLandmarks.map((e: any) => ToothLandmark.fromJSON(e))
        : [],
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: ChangeToothLocalizationNumerationResp): unknown {
    const obj: any = {};
    if (message.Teeth) {
      obj.Teeth = message.Teeth.map((e) => (e ? Tooth.toJSON(e) : undefined));
    } else {
      obj.Teeth = [];
    }
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    if (message.TeethLandmarks) {
      obj.TeethLandmarks = message.TeethLandmarks.map((e) =>
        e ? ToothLandmark.toJSON(e) : undefined,
      );
    } else {
      obj.TeethLandmarks = [];
    }
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ChangeToothLocalizationNumerationResp>, I>,
  >(object: I): ChangeToothLocalizationNumerationResp {
    const message = createBaseChangeToothLocalizationNumerationResp();
    message.Teeth = object.Teeth?.map((e) => Tooth.fromPartial(e)) || [];
    message.Conditions =
      object.Conditions?.map((e) => Condition.fromPartial(e)) || [];
    message.TeethLandmarks =
      object.TeethLandmarks?.map((e) => ToothLandmark.fromPartial(e)) || [];
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseCreateReportToothConditionsReq(): CreateReportToothConditionsReq {
  return { ToothID: '', Conditions: [] };
}

export const CreateReportToothConditionsReq = {
  encode(
    message: CreateReportToothConditionsReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ToothID !== '') {
      writer.uint32(10).string(message.ToothID);
    }
    for (const v of message.Conditions) {
      CreateReportToothConditionsReq_Condition.encode(
        v!,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateReportToothConditionsReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateReportToothConditionsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ToothID = reader.string();
          break;
        case 2:
          message.Conditions.push(
            CreateReportToothConditionsReq_Condition.decode(
              reader,
              reader.uint32(),
            ),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateReportToothConditionsReq {
    return {
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) =>
            CreateReportToothConditionsReq_Condition.fromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: CreateReportToothConditionsReq): unknown {
    const obj: any = {};
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? CreateReportToothConditionsReq_Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateReportToothConditionsReq>, I>>(
    object: I,
  ): CreateReportToothConditionsReq {
    const message = createBaseCreateReportToothConditionsReq();
    message.ToothID = object.ToothID ?? '';
    message.Conditions =
      object.Conditions?.map((e) =>
        CreateReportToothConditionsReq_Condition.fromPartial(e),
      ) || [];
    return message;
  },
};

function createBaseCreateReportToothConditionsReq_Condition(): CreateReportToothConditionsReq_Condition {
  return { Code: 0, ParentConditionID: '' };
}

export const CreateReportToothConditionsReq_Condition = {
  encode(
    message: CreateReportToothConditionsReq_Condition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Code !== 0) {
      writer.uint32(8).int32(message.Code);
    }
    if (message.ParentConditionID !== '') {
      writer.uint32(18).string(message.ParentConditionID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateReportToothConditionsReq_Condition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateReportToothConditionsReq_Condition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Code = reader.int32() as any;
          break;
        case 2:
          message.ParentConditionID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateReportToothConditionsReq_Condition {
    return {
      Code: isSet(object.Code) ? conditionCodeFromJSON(object.Code) : 0,
      ParentConditionID: isSet(object.ParentConditionID)
        ? String(object.ParentConditionID)
        : '',
    };
  },

  toJSON(message: CreateReportToothConditionsReq_Condition): unknown {
    const obj: any = {};
    message.Code !== undefined &&
      (obj.Code = conditionCodeToJSON(message.Code));
    message.ParentConditionID !== undefined &&
      (obj.ParentConditionID = message.ParentConditionID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<CreateReportToothConditionsReq_Condition>, I>,
  >(object: I): CreateReportToothConditionsReq_Condition {
    const message = createBaseCreateReportToothConditionsReq_Condition();
    message.Code = object.Code ?? 0;
    message.ParentConditionID = object.ParentConditionID ?? '';
    return message;
  },
};

function createBaseCreateReportToothConditionsResp(): CreateReportToothConditionsResp {
  return { Conditions: [], Tooth: undefined, Report: undefined };
}

export const CreateReportToothConditionsResp = {
  encode(
    message: CreateReportToothConditionsResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Conditions) {
      Condition.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(18).fork()).ldelim();
    }
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): CreateReportToothConditionsResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateReportToothConditionsResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Conditions.push(Condition.decode(reader, reader.uint32()));
          break;
        case 2:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        case 3:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateReportToothConditionsResp {
    return {
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) => Condition.fromJSON(e))
        : [],
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: CreateReportToothConditionsResp): unknown {
    const obj: any = {};
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<CreateReportToothConditionsResp>, I>>(
    object: I,
  ): CreateReportToothConditionsResp {
    const message = createBaseCreateReportToothConditionsResp();
    message.Conditions =
      object.Conditions?.map((e) => Condition.fromPartial(e)) || [];
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

function createBaseSetReportToothConditionsUserDecisionReq(): SetReportToothConditionsUserDecisionReq {
  return { PositiveDecisionConditionIDs: [], NegativeDecisionConditionIDs: [] };
}

export const SetReportToothConditionsUserDecisionReq = {
  encode(
    message: SetReportToothConditionsUserDecisionReq,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.PositiveDecisionConditionIDs) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.NegativeDecisionConditionIDs) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothConditionsUserDecisionReq {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothConditionsUserDecisionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PositiveDecisionConditionIDs.push(reader.string());
          break;
        case 2:
          message.NegativeDecisionConditionIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothConditionsUserDecisionReq {
    return {
      PositiveDecisionConditionIDs: Array.isArray(
        object?.PositiveDecisionConditionIDs,
      )
        ? object.PositiveDecisionConditionIDs.map((e: any) => String(e))
        : [],
      NegativeDecisionConditionIDs: Array.isArray(
        object?.NegativeDecisionConditionIDs,
      )
        ? object.NegativeDecisionConditionIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: SetReportToothConditionsUserDecisionReq): unknown {
    const obj: any = {};
    if (message.PositiveDecisionConditionIDs) {
      obj.PositiveDecisionConditionIDs =
        message.PositiveDecisionConditionIDs.map((e) => e);
    } else {
      obj.PositiveDecisionConditionIDs = [];
    }
    if (message.NegativeDecisionConditionIDs) {
      obj.NegativeDecisionConditionIDs =
        message.NegativeDecisionConditionIDs.map((e) => e);
    } else {
      obj.NegativeDecisionConditionIDs = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetReportToothConditionsUserDecisionReq>, I>,
  >(object: I): SetReportToothConditionsUserDecisionReq {
    const message = createBaseSetReportToothConditionsUserDecisionReq();
    message.PositiveDecisionConditionIDs =
      object.PositiveDecisionConditionIDs?.map((e) => e) || [];
    message.NegativeDecisionConditionIDs =
      object.NegativeDecisionConditionIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetReportToothConditionsUserDecisionResp(): SetReportToothConditionsUserDecisionResp {
  return { Conditions: [], Tooth: undefined, Report: undefined };
}

export const SetReportToothConditionsUserDecisionResp = {
  encode(
    message: SetReportToothConditionsUserDecisionResp,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Conditions) {
      Condition.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.Tooth !== undefined) {
      Tooth.encode(message.Tooth, writer.uint32(18).fork()).ldelim();
    }
    if (message.Report !== undefined) {
      Report.encode(message.Report, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): SetReportToothConditionsUserDecisionResp {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetReportToothConditionsUserDecisionResp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Conditions.push(Condition.decode(reader, reader.uint32()));
          break;
        case 2:
          message.Tooth = Tooth.decode(reader, reader.uint32());
          break;
        case 3:
          message.Report = Report.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SetReportToothConditionsUserDecisionResp {
    return {
      Conditions: Array.isArray(object?.Conditions)
        ? object.Conditions.map((e: any) => Condition.fromJSON(e))
        : [],
      Tooth: isSet(object.Tooth) ? Tooth.fromJSON(object.Tooth) : undefined,
      Report: isSet(object.Report) ? Report.fromJSON(object.Report) : undefined,
    };
  },

  toJSON(message: SetReportToothConditionsUserDecisionResp): unknown {
    const obj: any = {};
    if (message.Conditions) {
      obj.Conditions = message.Conditions.map((e) =>
        e ? Condition.toJSON(e) : undefined,
      );
    } else {
      obj.Conditions = [];
    }
    message.Tooth !== undefined &&
      (obj.Tooth = message.Tooth ? Tooth.toJSON(message.Tooth) : undefined);
    message.Report !== undefined &&
      (obj.Report = message.Report ? Report.toJSON(message.Report) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<SetReportToothConditionsUserDecisionResp>, I>,
  >(object: I): SetReportToothConditionsUserDecisionResp {
    const message = createBaseSetReportToothConditionsUserDecisionResp();
    message.Conditions =
      object.Conditions?.map((e) => Condition.fromPartial(e)) || [];
    message.Tooth =
      object.Tooth !== undefined && object.Tooth !== null
        ? Tooth.fromPartial(object.Tooth)
        : undefined;
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? Report.fromPartial(object.Report)
        : undefined;
    return message;
  },
};

/**
 * Common errors:
 * - type: RequiredArgumentMissingError, Argument: ReportID
 * - type: NotFoundError, Entity: report (if report not found by ReportID)
 * - type: BadRequestError, Reason: report deleted (if remote report found by ReportID)
 * - type: NotFoundError, Entity: tooth (if tooth not found by ToothID)
 */
export interface ReportService {
  ReportDataStream(
    request: DeepPartial<ReportDataStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<ReportDataStreamResp>;
  /**
   * Request report
   *
   * Permission: `PatientPermissions.CanRequestReport`
   */
  RequestReport(
    request: DeepPartial<RequestReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<RequestReportResp>;
  /**
   * Mark report as deleted using report id
   * - this report mark as deleted
   * - all invitations to this report are closed
   *
   * Permission: `ReportPermissions.CanDelete`
   */
  DeleteReport(
    request: DeepPartial<DeleteReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteReportResp>;
  /**
   * Set region of interest (slice of tooth identifiers) and un-sign report
   *
   * Permission: `ReportPermissions.CanChangeROI`
   */
  SetROI(
    request: DeepPartial<SetROIReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetROIResp>;
  /**
   * Set legacy conclusion and un-sign report
   *
   * Permission: `ReportPermissions.CanChangeConclusion`
   */
  SetReportConclusion(
    request: DeepPartial<SetReportConclusionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportConclusionResp>;
  /**
   * Set report settings and un-sign report
   *
   * Permission: `ReportPermissions.CanChangeSettings`
   */
  SetReportSettings(
    request: DeepPartial<SetReportSettingsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportSettingsResp>;
  /** Permission: `ReportPermissions.CanChangeSettings` */
  SetReportPrintSettings(
    request: DeepPartial<SetReportPrintSettingsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportPrintSettingsResp>;
  /** Permission: `ReportPermissions.CanSign` */
  SignReport(
    request: DeepPartial<SignReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<SignReportResp>;
  /** Permission: `ReportPermissions.CanChangeReportAssets` */
  SetReportMedicalImageViewOptions(
    request: DeepPartial<SetReportMedicalImageViewOptionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportMedicalImageViewOptionsResp>;
  /**
   * Set viewOptions to Report_IOXRay_GP.Image_Meta || Report_Pano_GP.Image_Meta
   *
   * Permission: `ReportPermissions.CanChangeReportAssets`
   */
  SetImageMetaViewOptions(
    request: DeepPartial<SetImageMetaViewOptionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetImageMetaViewOptionsResp>;
  /**
   * Set tooth comment, disapprove tooth and un-sign report
   *
   * Permission: `ReportPermissions.CanChangeToothComment`
   */
  SetReportToothComment(
    request: DeepPartial<SetReportToothCommentReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothCommentResp>;
  /**
   * Change tooth ISO number and SupernumeraryIndex, disapprove tooth and un-sign report
   *
   * Permission: `ReportPermissions.CanChangeToothNumeration`
   */
  SetReportToothNumeration(
    request: DeepPartial<SetReportToothNumerationReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothNumerationResp>;
  /**
   * Using this method doctor confirms that conditions on this tooth are correct
   *
   * Permission: `ReportPermissions.CanChangeToothApproved`
   */
  ApproveReportTooth(
    request: DeepPartial<ApproveReportToothReq>,
    metadata?: grpc.Metadata,
  ): Promise<ApproveReportToothResp>;
  /**
   * Disapprove tooth and un-sign report
   *
   * Permission: `ReportPermissions.CanChangeToothApproved`
   */
  DisapproveReportTooth(
    request: DeepPartial<DisapproveReportToothReq>,
    metadata?: grpc.Metadata,
  ): Promise<DisapproveReportToothResp>;
  /**
   * Approve all disapprove teeth in ROI and sign report
   * Permission: `ReportPermissions.CanChangeToothApproved` && `ReportPermissions.CanSign`
   */
  ApproveAllTeethAndSignReport(
    request: DeepPartial<ApproveAllTeethAndSignReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<ApproveAllTeethAndSignReportResp>;
  /**
   * Reset tooth && tooth conditions and un-sign report
   *
   * Permission: `ReportPermissions.CanResetTooth`
   */
  ResetReportTooth(
    request: DeepPartial<ResetReportToothReq>,
    metadata?: grpc.Metadata,
  ): Promise<ResetReportToothResp>;
  /**
   * Add Asset to tooth displaySlices, disapprove tooth and un-sign report
   *
   * Permission: `ReportPermissions.CanChangeConditions`
   */
  AddToothDisplaySlice(
    request: DeepPartial<AddToothDisplaySliceReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddToothDisplaySliceResp>;
  /**
   * Remove Asset from tooth displaySlices, disapprove tooth and un-sign report
   *
   * Permission: `ReportPermissions.CanChangeToothAssets`
   */
  RemoveToothDisplaySlice(
    request: DeepPartial<RemoveToothDisplaySliceReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveToothDisplaySliceResp>;
  /** Permission: `ReportPermissions.CanChangeToothAssets` */
  SetToothLocalizationCropTopLayerAnnotations(
    request: DeepPartial<SetToothLocalizationCropTopLayerAnnotationsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetToothLocalizationCropTopLayerAnnotationsResp>;
  /** Permission: `ReportPermissions.CanChangeToothAssets` */
  SetToothLocalizationCropMedicalImageViewOptions(
    request: DeepPartial<SetToothLocalizationCropMedicalImageViewOptionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetToothLocalizationCropMedicalImageViewOptionsResp>;
  /**
   * Change localization numeration, disapprove tooth and un-sign report
   *
   * Permission: `ReportPermissions.CanChangeToothNumeration`
   */
  ChangeToothLocalizationNumeration(
    request: DeepPartial<ChangeToothLocalizationNumerationReq>,
    metadata?: grpc.Metadata,
  ): Promise<ChangeToothLocalizationNumerationResp>;
  /**
   * Create new condition, disapprove tooth and un-sign report
   *
   * Permission: `ReportPermissions.CanChangeConditions`
   *
   * Errors:
   * - type: BadRequestError, Reason: ROI does not contain this tooth
   */
  CreateReportToothConditions(
    request: DeepPartial<CreateReportToothConditionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateReportToothConditionsResp>;
  /**
   * Set user decision for conditions bound to one tooth, disapprove tooth and un-sign report
   *
   * Permission: `ReportPermissions.CanChangeConditions`
   */
  SetReportToothConditionsUserDecision(
    request: DeepPartial<SetReportToothConditionsUserDecisionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothConditionsUserDecisionResp>;
}

export class ReportServiceClientImpl implements ReportService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.ReportDataStream = this.ReportDataStream.bind(this);
    this.RequestReport = this.RequestReport.bind(this);
    this.DeleteReport = this.DeleteReport.bind(this);
    this.SetROI = this.SetROI.bind(this);
    this.SetReportConclusion = this.SetReportConclusion.bind(this);
    this.SetReportSettings = this.SetReportSettings.bind(this);
    this.SetReportPrintSettings = this.SetReportPrintSettings.bind(this);
    this.SignReport = this.SignReport.bind(this);
    this.SetReportMedicalImageViewOptions =
      this.SetReportMedicalImageViewOptions.bind(this);
    this.SetImageMetaViewOptions = this.SetImageMetaViewOptions.bind(this);
    this.SetReportToothComment = this.SetReportToothComment.bind(this);
    this.SetReportToothNumeration = this.SetReportToothNumeration.bind(this);
    this.ApproveReportTooth = this.ApproveReportTooth.bind(this);
    this.DisapproveReportTooth = this.DisapproveReportTooth.bind(this);
    this.ApproveAllTeethAndSignReport =
      this.ApproveAllTeethAndSignReport.bind(this);
    this.ResetReportTooth = this.ResetReportTooth.bind(this);
    this.AddToothDisplaySlice = this.AddToothDisplaySlice.bind(this);
    this.RemoveToothDisplaySlice = this.RemoveToothDisplaySlice.bind(this);
    this.SetToothLocalizationCropTopLayerAnnotations =
      this.SetToothLocalizationCropTopLayerAnnotations.bind(this);
    this.SetToothLocalizationCropMedicalImageViewOptions =
      this.SetToothLocalizationCropMedicalImageViewOptions.bind(this);
    this.ChangeToothLocalizationNumeration =
      this.ChangeToothLocalizationNumeration.bind(this);
    this.CreateReportToothConditions =
      this.CreateReportToothConditions.bind(this);
    this.SetReportToothConditionsUserDecision =
      this.SetReportToothConditionsUserDecision.bind(this);
  }

  ReportDataStream(
    request: DeepPartial<ReportDataStreamReq>,
    metadata?: grpc.Metadata,
  ): Observable<ReportDataStreamResp> {
    return this.rpc.invoke(
      ReportServiceReportDataStreamDesc,
      ReportDataStreamReq.fromPartial(request),
      metadata,
    );
  }

  RequestReport(
    request: DeepPartial<RequestReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<RequestReportResp> {
    return this.rpc.unary(
      ReportServiceRequestReportDesc,
      RequestReportReq.fromPartial(request),
      metadata,
    );
  }

  DeleteReport(
    request: DeepPartial<DeleteReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteReportResp> {
    return this.rpc.unary(
      ReportServiceDeleteReportDesc,
      DeleteReportReq.fromPartial(request),
      metadata,
    );
  }

  SetROI(
    request: DeepPartial<SetROIReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetROIResp> {
    return this.rpc.unary(
      ReportServiceSetROIDesc,
      SetROIReq.fromPartial(request),
      metadata,
    );
  }

  SetReportConclusion(
    request: DeepPartial<SetReportConclusionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportConclusionResp> {
    return this.rpc.unary(
      ReportServiceSetReportConclusionDesc,
      SetReportConclusionReq.fromPartial(request),
      metadata,
    );
  }

  SetReportSettings(
    request: DeepPartial<SetReportSettingsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportSettingsResp> {
    return this.rpc.unary(
      ReportServiceSetReportSettingsDesc,
      SetReportSettingsReq.fromPartial(request),
      metadata,
    );
  }

  SetReportPrintSettings(
    request: DeepPartial<SetReportPrintSettingsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportPrintSettingsResp> {
    return this.rpc.unary(
      ReportServiceSetReportPrintSettingsDesc,
      SetReportPrintSettingsReq.fromPartial(request),
      metadata,
    );
  }

  SignReport(
    request: DeepPartial<SignReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<SignReportResp> {
    return this.rpc.unary(
      ReportServiceSignReportDesc,
      SignReportReq.fromPartial(request),
      metadata,
    );
  }

  SetReportMedicalImageViewOptions(
    request: DeepPartial<SetReportMedicalImageViewOptionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportMedicalImageViewOptionsResp> {
    return this.rpc.unary(
      ReportServiceSetReportMedicalImageViewOptionsDesc,
      SetReportMedicalImageViewOptionsReq.fromPartial(request),
      metadata,
    );
  }

  SetImageMetaViewOptions(
    request: DeepPartial<SetImageMetaViewOptionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetImageMetaViewOptionsResp> {
    return this.rpc.unary(
      ReportServiceSetImageMetaViewOptionsDesc,
      SetImageMetaViewOptionsReq.fromPartial(request),
      metadata,
    );
  }

  SetReportToothComment(
    request: DeepPartial<SetReportToothCommentReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothCommentResp> {
    return this.rpc.unary(
      ReportServiceSetReportToothCommentDesc,
      SetReportToothCommentReq.fromPartial(request),
      metadata,
    );
  }

  SetReportToothNumeration(
    request: DeepPartial<SetReportToothNumerationReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothNumerationResp> {
    return this.rpc.unary(
      ReportServiceSetReportToothNumerationDesc,
      SetReportToothNumerationReq.fromPartial(request),
      metadata,
    );
  }

  ApproveReportTooth(
    request: DeepPartial<ApproveReportToothReq>,
    metadata?: grpc.Metadata,
  ): Promise<ApproveReportToothResp> {
    return this.rpc.unary(
      ReportServiceApproveReportToothDesc,
      ApproveReportToothReq.fromPartial(request),
      metadata,
    );
  }

  DisapproveReportTooth(
    request: DeepPartial<DisapproveReportToothReq>,
    metadata?: grpc.Metadata,
  ): Promise<DisapproveReportToothResp> {
    return this.rpc.unary(
      ReportServiceDisapproveReportToothDesc,
      DisapproveReportToothReq.fromPartial(request),
      metadata,
    );
  }

  ApproveAllTeethAndSignReport(
    request: DeepPartial<ApproveAllTeethAndSignReportReq>,
    metadata?: grpc.Metadata,
  ): Promise<ApproveAllTeethAndSignReportResp> {
    return this.rpc.unary(
      ReportServiceApproveAllTeethAndSignReportDesc,
      ApproveAllTeethAndSignReportReq.fromPartial(request),
      metadata,
    );
  }

  ResetReportTooth(
    request: DeepPartial<ResetReportToothReq>,
    metadata?: grpc.Metadata,
  ): Promise<ResetReportToothResp> {
    return this.rpc.unary(
      ReportServiceResetReportToothDesc,
      ResetReportToothReq.fromPartial(request),
      metadata,
    );
  }

  AddToothDisplaySlice(
    request: DeepPartial<AddToothDisplaySliceReq>,
    metadata?: grpc.Metadata,
  ): Promise<AddToothDisplaySliceResp> {
    return this.rpc.unary(
      ReportServiceAddToothDisplaySliceDesc,
      AddToothDisplaySliceReq.fromPartial(request),
      metadata,
    );
  }

  RemoveToothDisplaySlice(
    request: DeepPartial<RemoveToothDisplaySliceReq>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveToothDisplaySliceResp> {
    return this.rpc.unary(
      ReportServiceRemoveToothDisplaySliceDesc,
      RemoveToothDisplaySliceReq.fromPartial(request),
      metadata,
    );
  }

  SetToothLocalizationCropTopLayerAnnotations(
    request: DeepPartial<SetToothLocalizationCropTopLayerAnnotationsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetToothLocalizationCropTopLayerAnnotationsResp> {
    return this.rpc.unary(
      ReportServiceSetToothLocalizationCropTopLayerAnnotationsDesc,
      SetToothLocalizationCropTopLayerAnnotationsReq.fromPartial(request),
      metadata,
    );
  }

  SetToothLocalizationCropMedicalImageViewOptions(
    request: DeepPartial<SetToothLocalizationCropMedicalImageViewOptionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetToothLocalizationCropMedicalImageViewOptionsResp> {
    return this.rpc.unary(
      ReportServiceSetToothLocalizationCropMedicalImageViewOptionsDesc,
      SetToothLocalizationCropMedicalImageViewOptionsReq.fromPartial(request),
      metadata,
    );
  }

  ChangeToothLocalizationNumeration(
    request: DeepPartial<ChangeToothLocalizationNumerationReq>,
    metadata?: grpc.Metadata,
  ): Promise<ChangeToothLocalizationNumerationResp> {
    return this.rpc.unary(
      ReportServiceChangeToothLocalizationNumerationDesc,
      ChangeToothLocalizationNumerationReq.fromPartial(request),
      metadata,
    );
  }

  CreateReportToothConditions(
    request: DeepPartial<CreateReportToothConditionsReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateReportToothConditionsResp> {
    return this.rpc.unary(
      ReportServiceCreateReportToothConditionsDesc,
      CreateReportToothConditionsReq.fromPartial(request),
      metadata,
    );
  }

  SetReportToothConditionsUserDecision(
    request: DeepPartial<SetReportToothConditionsUserDecisionReq>,
    metadata?: grpc.Metadata,
  ): Promise<SetReportToothConditionsUserDecisionResp> {
    return this.rpc.unary(
      ReportServiceSetReportToothConditionsUserDecisionDesc,
      SetReportToothConditionsUserDecisionReq.fromPartial(request),
      metadata,
    );
  }
}

export const ReportServiceDesc = {
  serviceName: 'com.diagnocat.core.ReportService',
};

export const ReportServiceReportDataStreamDesc: UnaryMethodDefinitionish = {
  methodName: 'ReportDataStream',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: true,
  requestType: {
    serializeBinary() {
      return ReportDataStreamReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ReportDataStreamResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceRequestReportDesc: UnaryMethodDefinitionish = {
  methodName: 'RequestReport',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RequestReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...RequestReportResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceDeleteReportDesc: UnaryMethodDefinitionish = {
  methodName: 'DeleteReport',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...DeleteReportResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceSetROIDesc: UnaryMethodDefinitionish = {
  methodName: 'SetROI',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetROIReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetROIResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceSetReportConclusionDesc: UnaryMethodDefinitionish = {
  methodName: 'SetReportConclusion',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetReportConclusionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetReportConclusionResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceSetReportSettingsDesc: UnaryMethodDefinitionish = {
  methodName: 'SetReportSettings',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetReportSettingsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SetReportSettingsResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceSetReportPrintSettingsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetReportPrintSettings',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetReportPrintSettingsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetReportPrintSettingsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSignReportDesc: UnaryMethodDefinitionish = {
  methodName: 'SignReport',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SignReportReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...SignReportResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceSetReportMedicalImageViewOptionsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetReportMedicalImageViewOptions',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetReportMedicalImageViewOptionsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetReportMedicalImageViewOptionsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSetImageMetaViewOptionsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetImageMetaViewOptions',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetImageMetaViewOptionsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetImageMetaViewOptionsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSetReportToothCommentDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetReportToothComment',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetReportToothCommentReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetReportToothCommentResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSetReportToothNumerationDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetReportToothNumeration',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetReportToothNumerationReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetReportToothNumerationResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceApproveReportToothDesc: UnaryMethodDefinitionish = {
  methodName: 'ApproveReportTooth',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ApproveReportToothReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ApproveReportToothResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceDisapproveReportToothDesc: UnaryMethodDefinitionish =
  {
    methodName: 'DisapproveReportTooth',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return DisapproveReportToothReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...DisapproveReportToothResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceApproveAllTeethAndSignReportDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ApproveAllTeethAndSignReport',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ApproveAllTeethAndSignReportReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...ApproveAllTeethAndSignReportResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceResetReportToothDesc: UnaryMethodDefinitionish = {
  methodName: 'ResetReportTooth',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ResetReportToothReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...ResetReportToothResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceAddToothDisplaySliceDesc: UnaryMethodDefinitionish = {
  methodName: 'AddToothDisplaySlice',
  service: ReportServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddToothDisplaySliceReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      return {
        ...AddToothDisplaySliceResp.decode(data),
        toObject() {
          return this;
        },
      };
    },
  } as any,
};

export const ReportServiceRemoveToothDisplaySliceDesc: UnaryMethodDefinitionish =
  {
    methodName: 'RemoveToothDisplaySlice',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return RemoveToothDisplaySliceReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...RemoveToothDisplaySliceResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSetToothLocalizationCropTopLayerAnnotationsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetToothLocalizationCropTopLayerAnnotations',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetToothLocalizationCropTopLayerAnnotationsReq.encode(
          this,
        ).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetToothLocalizationCropTopLayerAnnotationsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSetToothLocalizationCropMedicalImageViewOptionsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetToothLocalizationCropMedicalImageViewOptions',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetToothLocalizationCropMedicalImageViewOptionsReq.encode(
          this,
        ).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetToothLocalizationCropMedicalImageViewOptionsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceChangeToothLocalizationNumerationDesc: UnaryMethodDefinitionish =
  {
    methodName: 'ChangeToothLocalizationNumeration',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return ChangeToothLocalizationNumerationReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...ChangeToothLocalizationNumerationResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceCreateReportToothConditionsDesc: UnaryMethodDefinitionish =
  {
    methodName: 'CreateReportToothConditions',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return CreateReportToothConditionsReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...CreateReportToothConditionsResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

export const ReportServiceSetReportToothConditionsUserDecisionDesc: UnaryMethodDefinitionish =
  {
    methodName: 'SetReportToothConditionsUserDecision',
    service: ReportServiceDesc,
    requestStream: false,
    responseStream: false,
    requestType: {
      serializeBinary() {
        return SetReportToothConditionsUserDecisionReq.encode(this).finish();
      },
    } as any,
    responseType: {
      deserializeBinary(data: Uint8Array) {
        return {
          ...SetReportToothConditionsUserDecisionResp.decode(data),
          toObject() {
            return this;
          },
        };
      },
    } as any,
  };

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    streamingTransport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: grpc.Metadata;
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      streamingTransport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: grpc.Metadata;
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new Error(response.statusMessage) as any;
            err.code = response.status;
            err.metadata = response.trailers;
            reject(err);
          }
        },
      });
    });
  }

  invoke<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> {
    // Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
    const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
    const DEFAULT_TIMEOUT_TIME: number = 3_000;
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata =
      metadata && this.options.metadata
        ? new BrowserHeaders({
            ...this.options?.metadata.headersMap,
            ...metadata?.headersMap,
          })
        : metadata || this.options.metadata;
    return new Observable((observer) => {
      const upStream = () => {
        const client = grpc.invoke(methodDesc, {
          host: this.host,
          request,
          transport: this.options.streamingTransport || this.options.transport,
          metadata: maybeCombinedMetadata,
          debug: this.options.debug,
          onMessage: (next) => observer.next(next),
          onEnd: (code: grpc.Code, message: string) => {
            if (code === 0) {
              observer.complete();
            } else if (upStreamCodes.includes(code)) {
              setTimeout(upStream, DEFAULT_TIMEOUT_TIME);
            } else {
              observer.error(new Error(`Error ${code} ${message}`));
            }
          },
        });
        observer.add(() => client.close());
      };
      upStream();
    }).pipe(share());
  }
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
