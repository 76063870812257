import { FC, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';

import { CORS_POLICY } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';
import { toothModel } from '@/entities/tooth';
import { maskFiltersModel as maskFilterTypesModel } from '@/entities/maskFilters'; // TODO:[4|m] Unite two mask filtel models
import { organizationModel } from '@/entities/organization';

import { maskFiltersModel } from '@/features/maskFilters';
import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';
import { Landmark, useTeethLandmarks } from '@/features/toothLandmark';

import {
  FMXRender,
  PBLProps,
} from '../../../../shared/graphics/viewer2D/Viewer2D';

import styles from './PanowingsReportRender.module.scss';

type PanowingsReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewMode?: boolean;
};

export const PanowingsReportRender: FC<PanowingsReportRenderProps> = (
  props,
) => {
  const { className, children, previewMode } = props;

  const dispatch = useAppDispatch();

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const reportID = currentReport?.ID as string;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const mainViewRef = useRef<HTMLDivElement>(null);

  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );

  const reportReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const reportPBLReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportPBLReadyForRender,
  );

  useEffect(() => {
    FMXRender.setViewRef(mainViewRef, 'main');
    FMXRender.setCanvasRef(canvasRef);
  }, []);

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID || '',
    ),
  ) as IOXrayImageInterface[];

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface;

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface,
  );

  const bitewingsImagesInterface = [
    ...groupedIOXRayImages['MiddleLeft'],
    ...groupedIOXRayImages['MiddleRight'],
  ];

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectToothChartItems,
  );

  const teethIDs = useAppSelector(toothModel.selectors.selectROITeethIDs);

  const { getPBLsForImage } = useTeethLandmarks(teethIDs);

  // Masks
  const masks2DRenderData = useAppSelector(
    maskFiltersModel.selectors.select2DMasksRenderData,
  );

  const hideMasks = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );

  const showPBL = useAppSelector(
    maskFilterTypesModel.selectors.selectIsLandmarksShown,
  );
  const hidePBLFeatureFlag = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_PBLRulesAndMeasurements,
    ),
  );

  const teeth = useAppSelector(toothModel.selectors.selectAll);

  //render initialization
  useEffect(() => {
    const shouldRunRender =
      !!IOXRayImagesInterface.length &&
      reportReadyForRender &&
      panoImageInterface?.imageMeta;

    if (shouldRunRender) {
      FMXRender.setCredentials(CORS_POLICY);
      const matrix = [
        [[panoImageInterface]],
        [
          [
            ...groupedIOXRayImages.MiddleLeft,
            ...groupedIOXRayImages.MiddleRight,
          ],
        ],
      ];

      const gapBetweenBitewings = 60;

      const scale =
        (gapBetweenBitewings * (bitewingsImagesInterface.length - 1) +
          bitewingsImagesInterface
            .map((item) => item.originalSize.width)
            .reduce((a, b) => a + b)) /
        panoImageInterface.originalSize.width;

      const isRenderStartCorrect = FMXRender.run(
        reportID,
        matrix,
        reportViewOptions,
        { [panoImageInterface.imageMeta.ID]: { viewportType: 'pano', scale } },
        { contentGap: gapBetweenBitewings },
      );

      if (!isRenderStartCorrect) {
        return;
      }
      if (previewMode) {
        FMXRender.layoutModes.fullScreenMatrix();
        FMXRender.activateMode('printMode');
      } else {
        FMXRender.setTeeth(teeth);

        FMXRender.addEventListener('layout', (event) => {
          if (event.mode === 'focus') {
            dispatch(reportsModel.actions.setToolbarActiveControl('view'));
            dispatch(reportsModel.actions.setFocusedMetaImageID(event.id));
          }
        });
      }
    }
  }, [
    bitewingsImagesInterface,
    panoImageInterface,
    reportReadyForRender,
    previewMode,
  ]);

  useEffect(() => {
    if ((FMXRender.isRunning(), bitewingsImagesInterface.length)) {
      const matrix = [
        [[panoImageInterface]],
        [
          [
            ...groupedIOXRayImages.MiddleLeft,
            ...groupedIOXRayImages.MiddleRight,
          ],
        ],
      ];

      const gapBetweenBitewings = 60;

      const scale =
        (gapBetweenBitewings * (bitewingsImagesInterface.length - 1) +
          bitewingsImagesInterface
            .map((item) => item.originalSize.width)
            .reduce((a, b) => a + b)) /
        panoImageInterface.originalSize.width;

      FMXRender.updateLayout(
        matrix,
        { [panoImageInterface.imageMeta.ID]: { scale } },
        { contentGap: gapBetweenBitewings },
      );
    }
  }, [groupedIOXRayImages, bitewingsImagesInterface, bitewingsImagesInterface]);

  // NOTE: PBL should be setted up just one time after render is done.
  useEffect(() => {
    if (reportPBLReadyForRender && FMXRender.isRunning()) {
      if (!hidePBLFeatureFlag && !previewMode) {
        const PBLsData = bitewingsImagesInterface
          .map((data) => {
            const initalPBLList = getPBLsForImage(data.asset.ID);

            if (!initalPBLList) {
              return undefined;
            }

            const getPBL = (landmark: Landmark) => ({
              start: {
                x: landmark.lowPoint?.ModelPosition?.X || 0,
                y: landmark.lowPoint?.ModelPosition?.Y || 0,
              },
              end: {
                x: landmark.upPoint?.ModelPosition?.X || 0,
                y: landmark.upPoint?.ModelPosition?.Y || 0,
              },
              color: landmark.color,
              textProps: {
                color: landmark.color === '#D4D4D4' ? 0 : 0xffffff,
              },
            });

            return {
              imageID: data.imageMeta.ID,
              PBLs: initalPBLList.map((pbl) => {
                return getPBL(pbl);
              }),
              scale: data.imageMeta.Scale?.X || 1,
            };
          })
          .filter((data) => data) as PBLProps[];

        if (PBLsData) {
          FMXRender.setPBLs(PBLsData);
          if (showPBL) {
            FMXRender.showPBLs();
          }
        }
      }
    }
  }, [reportPBLReadyForRender]);

  useEffect(
    () => () => {
      FMXRender.shutdown();
    },
    [],
  );

  // Toggle PBL
  // TODO: Is it possible to move it on perio toggle function to avoid useEffect?
  useEffect(() => {
    if (reportReadyForRender && FMXRender.isRunning()) {
      if (!hideMasks && !hidePBLFeatureFlag && showPBL) {
        FMXRender.showPBLs();
      } else {
        FMXRender.hidePBLs();
      }
    }
  }, [reportReadyForRender, showPBL, hideMasks, hidePBLFeatureFlag]);

  // Render masks
  useEffect(() => {
    if (FMXRender.isRunning() && reportReadyForRender && !previewMode) {
      FMXRender.deleteMasks();
      if (!hideMasks && masks2DRenderData.length > 0) {
        FMXRender.addMasks(masks2DRenderData);
        FMXRender.showMasks();
      }
    }
  }, [masks2DRenderData, hideMasks]);

  // BBoxes render
  useEffect(() => {
    if (FMXRender.isRunning()) {
      if (hoveredBBoxes) {
        FMXRender.deleteConditionBoxes();
        FMXRender.addConditionBoxes(hoveredBBoxes);
      } else {
        FMXRender.deleteConditionBoxes();
      }
    }
  }, [hoveredBBoxes, reportReadyForRender]);

  return (
    <div className={cn(styles.container, className)} ref={mainViewRef}>
      <canvas ref={canvasRef} className={styles.canvas} />
      {children}
    </div>
  );
};
