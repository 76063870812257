/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Timestamp } from '../google/protobuf/timestamp';

export const protobufPackage = 'com.diagnocat.model';

export enum CalledVia {
  CalledViaUnspecified = 0,
  CalledViaClient = 1,
  CalledViaPartnerAPI = 2,
  CalledViaMLPipelines = 3,
  UNRECOGNIZED = -1,
}

export function calledViaFromJSON(object: any): CalledVia {
  switch (object) {
    case 0:
    case 'CalledViaUnspecified':
      return CalledVia.CalledViaUnspecified;
    case 1:
    case 'CalledViaClient':
      return CalledVia.CalledViaClient;
    case 2:
    case 'CalledViaPartnerAPI':
      return CalledVia.CalledViaPartnerAPI;
    case 3:
    case 'CalledViaMLPipelines':
      return CalledVia.CalledViaMLPipelines;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CalledVia.UNRECOGNIZED;
  }
}

export function calledViaToJSON(object: CalledVia): string {
  switch (object) {
    case CalledVia.CalledViaUnspecified:
      return 'CalledViaUnspecified';
    case CalledVia.CalledViaClient:
      return 'CalledViaClient';
    case CalledVia.CalledViaPartnerAPI:
      return 'CalledViaPartnerAPI';
    case CalledVia.CalledViaMLPipelines:
      return 'CalledViaMLPipelines';
    case CalledVia.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum Gender {
  GenderUnspecified = 0,
  GenderMale = 1,
  GenderFemale = 2,
  GenderOther = 3,
  UNRECOGNIZED = -1,
}

export function genderFromJSON(object: any): Gender {
  switch (object) {
    case 0:
    case 'GenderUnspecified':
      return Gender.GenderUnspecified;
    case 1:
    case 'GenderMale':
      return Gender.GenderMale;
    case 2:
    case 'GenderFemale':
      return Gender.GenderFemale;
    case 3:
    case 'GenderOther':
      return Gender.GenderOther;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Gender.UNRECOGNIZED;
  }
}

export function genderToJSON(object: Gender): string {
  switch (object) {
    case Gender.GenderUnspecified:
      return 'GenderUnspecified';
    case Gender.GenderMale:
      return 'GenderMale';
    case Gender.GenderFemale:
      return 'GenderFemale';
    case Gender.GenderOther:
      return 'GenderOther';
    case Gender.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum OptionalBoolean {
  OptionalBooleanUnspecified = 0,
  OptionalBooleanFalse = 1,
  OptionalBooleanTrue = 2,
  UNRECOGNIZED = -1,
}

export function optionalBooleanFromJSON(object: any): OptionalBoolean {
  switch (object) {
    case 0:
    case 'OptionalBooleanUnspecified':
      return OptionalBoolean.OptionalBooleanUnspecified;
    case 1:
    case 'OptionalBooleanFalse':
      return OptionalBoolean.OptionalBooleanFalse;
    case 2:
    case 'OptionalBooleanTrue':
      return OptionalBoolean.OptionalBooleanTrue;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OptionalBoolean.UNRECOGNIZED;
  }
}

export function optionalBooleanToJSON(object: OptionalBoolean): string {
  switch (object) {
    case OptionalBoolean.OptionalBooleanUnspecified:
      return 'OptionalBooleanUnspecified';
    case OptionalBoolean.OptionalBooleanFalse:
      return 'OptionalBooleanFalse';
    case OptionalBoolean.OptionalBooleanTrue:
      return 'OptionalBooleanTrue';
    case OptionalBoolean.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Revision {
  Number: number;
  UpdatedAt: Date | undefined;
  /** user identifier || system const */
  UserID: string;
}

export interface Created {
  By: string;
  At: Date | undefined;
  Via: CalledVia;
}

export interface Deleted {
  Deleted: boolean;
  By: string;
  At: Date | undefined;
  Via: CalledVia;
}

export interface Attribute {
  Key: string;
  /** bool Private = 3; // TODO */
  Value: string;
}

export interface PersonalData {
  FirstName: string;
  LastName: string;
  /** format == 2022-01-14 */
  DateOfBirth: string;
  Emails: string[];
  Phones: string[];
}

export interface RevisionNew {
  Number: number;
  /** user identifier || system const */
  By: string;
  At: Date | undefined;
  Via: CalledVia;
}

export interface DeletedNew {
  IsDeleted: boolean;
  By: string;
  At: Date | undefined;
  Via: CalledVia;
}

function createBaseRevision(): Revision {
  return { Number: 0, UpdatedAt: undefined, UserID: '' };
}

export const Revision = {
  encode(
    message: Revision,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Number !== 0) {
      writer.uint32(8).uint32(message.Number);
    }
    if (message.UpdatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.UpdatedAt),
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.UserID !== '') {
      writer.uint32(26).string(message.UserID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Revision {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevision();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Number = reader.uint32();
          break;
        case 2:
          message.UpdatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        case 3:
          message.UserID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Revision {
    return {
      Number: isSet(object.Number) ? Number(object.Number) : 0,
      UpdatedAt: isSet(object.UpdatedAt)
        ? fromJsonTimestamp(object.UpdatedAt)
        : undefined,
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
    };
  },

  toJSON(message: Revision): unknown {
    const obj: any = {};
    message.Number !== undefined && (obj.Number = Math.round(message.Number));
    message.UpdatedAt !== undefined &&
      (obj.UpdatedAt = message.UpdatedAt.toISOString());
    message.UserID !== undefined && (obj.UserID = message.UserID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Revision>, I>>(object: I): Revision {
    const message = createBaseRevision();
    message.Number = object.Number ?? 0;
    message.UpdatedAt = object.UpdatedAt ?? undefined;
    message.UserID = object.UserID ?? '';
    return message;
  },
};

function createBaseCreated(): Created {
  return { By: '', At: undefined, Via: 0 };
}

export const Created = {
  encode(
    message: Created,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.By !== '') {
      writer.uint32(10).string(message.By);
    }
    if (message.At !== undefined) {
      Timestamp.encode(
        toTimestamp(message.At),
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Via !== 0) {
      writer.uint32(24).int32(message.Via);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Created {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreated();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.By = reader.string();
          break;
        case 2:
          message.At = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.Via = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Created {
    return {
      By: isSet(object.By) ? String(object.By) : '',
      At: isSet(object.At) ? fromJsonTimestamp(object.At) : undefined,
      Via: isSet(object.Via) ? calledViaFromJSON(object.Via) : 0,
    };
  },

  toJSON(message: Created): unknown {
    const obj: any = {};
    message.By !== undefined && (obj.By = message.By);
    message.At !== undefined && (obj.At = message.At.toISOString());
    message.Via !== undefined && (obj.Via = calledViaToJSON(message.Via));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Created>, I>>(object: I): Created {
    const message = createBaseCreated();
    message.By = object.By ?? '';
    message.At = object.At ?? undefined;
    message.Via = object.Via ?? 0;
    return message;
  },
};

function createBaseDeleted(): Deleted {
  return { Deleted: false, By: '', At: undefined, Via: 0 };
}

export const Deleted = {
  encode(
    message: Deleted,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Deleted === true) {
      writer.uint32(8).bool(message.Deleted);
    }
    if (message.By !== '') {
      writer.uint32(18).string(message.By);
    }
    if (message.At !== undefined) {
      Timestamp.encode(
        toTimestamp(message.At),
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Via !== 0) {
      writer.uint32(32).int32(message.Via);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Deleted {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleted();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Deleted = reader.bool();
          break;
        case 2:
          message.By = reader.string();
          break;
        case 3:
          message.At = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 4:
          message.Via = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Deleted {
    return {
      Deleted: isSet(object.Deleted) ? Boolean(object.Deleted) : false,
      By: isSet(object.By) ? String(object.By) : '',
      At: isSet(object.At) ? fromJsonTimestamp(object.At) : undefined,
      Via: isSet(object.Via) ? calledViaFromJSON(object.Via) : 0,
    };
  },

  toJSON(message: Deleted): unknown {
    const obj: any = {};
    message.Deleted !== undefined && (obj.Deleted = message.Deleted);
    message.By !== undefined && (obj.By = message.By);
    message.At !== undefined && (obj.At = message.At.toISOString());
    message.Via !== undefined && (obj.Via = calledViaToJSON(message.Via));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Deleted>, I>>(object: I): Deleted {
    const message = createBaseDeleted();
    message.Deleted = object.Deleted ?? false;
    message.By = object.By ?? '';
    message.At = object.At ?? undefined;
    message.Via = object.Via ?? 0;
    return message;
  },
};

function createBaseAttribute(): Attribute {
  return { Key: '', Value: '' };
}

export const Attribute = {
  encode(
    message: Attribute,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Key !== '') {
      writer.uint32(10).string(message.Key);
    }
    if (message.Value !== '') {
      writer.uint32(18).string(message.Value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Attribute {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttribute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Key = reader.string();
          break;
        case 2:
          message.Value = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Attribute {
    return {
      Key: isSet(object.Key) ? String(object.Key) : '',
      Value: isSet(object.Value) ? String(object.Value) : '',
    };
  },

  toJSON(message: Attribute): unknown {
    const obj: any = {};
    message.Key !== undefined && (obj.Key = message.Key);
    message.Value !== undefined && (obj.Value = message.Value);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Attribute>, I>>(
    object: I,
  ): Attribute {
    const message = createBaseAttribute();
    message.Key = object.Key ?? '';
    message.Value = object.Value ?? '';
    return message;
  },
};

function createBasePersonalData(): PersonalData {
  return {
    FirstName: '',
    LastName: '',
    DateOfBirth: '',
    Emails: [],
    Phones: [],
  };
}

export const PersonalData = {
  encode(
    message: PersonalData,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FirstName !== '') {
      writer.uint32(10).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(18).string(message.LastName);
    }
    if (message.DateOfBirth !== '') {
      writer.uint32(26).string(message.DateOfBirth);
    }
    for (const v of message.Emails) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.Phones) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PersonalData {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePersonalData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FirstName = reader.string();
          break;
        case 2:
          message.LastName = reader.string();
          break;
        case 3:
          message.DateOfBirth = reader.string();
          break;
        case 4:
          message.Emails.push(reader.string());
          break;
        case 5:
          message.Phones.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PersonalData {
    return {
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
      DateOfBirth: isSet(object.DateOfBirth) ? String(object.DateOfBirth) : '',
      Emails: Array.isArray(object?.Emails)
        ? object.Emails.map((e: any) => String(e))
        : [],
      Phones: Array.isArray(object?.Phones)
        ? object.Phones.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: PersonalData): unknown {
    const obj: any = {};
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    message.DateOfBirth !== undefined &&
      (obj.DateOfBirth = message.DateOfBirth);
    if (message.Emails) {
      obj.Emails = message.Emails.map((e) => e);
    } else {
      obj.Emails = [];
    }
    if (message.Phones) {
      obj.Phones = message.Phones.map((e) => e);
    } else {
      obj.Phones = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PersonalData>, I>>(
    object: I,
  ): PersonalData {
    const message = createBasePersonalData();
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    message.DateOfBirth = object.DateOfBirth ?? '';
    message.Emails = object.Emails?.map((e) => e) || [];
    message.Phones = object.Phones?.map((e) => e) || [];
    return message;
  },
};

function createBaseRevisionNew(): RevisionNew {
  return { Number: 0, By: '', At: undefined, Via: 0 };
}

export const RevisionNew = {
  encode(
    message: RevisionNew,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Number !== 0) {
      writer.uint32(8).uint32(message.Number);
    }
    if (message.By !== '') {
      writer.uint32(18).string(message.By);
    }
    if (message.At !== undefined) {
      Timestamp.encode(
        toTimestamp(message.At),
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Via !== 0) {
      writer.uint32(32).int32(message.Via);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevisionNew {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevisionNew();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Number = reader.uint32();
          break;
        case 2:
          message.By = reader.string();
          break;
        case 3:
          message.At = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 4:
          message.Via = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): RevisionNew {
    return {
      Number: isSet(object.Number) ? Number(object.Number) : 0,
      By: isSet(object.By) ? String(object.By) : '',
      At: isSet(object.At) ? fromJsonTimestamp(object.At) : undefined,
      Via: isSet(object.Via) ? calledViaFromJSON(object.Via) : 0,
    };
  },

  toJSON(message: RevisionNew): unknown {
    const obj: any = {};
    message.Number !== undefined && (obj.Number = Math.round(message.Number));
    message.By !== undefined && (obj.By = message.By);
    message.At !== undefined && (obj.At = message.At.toISOString());
    message.Via !== undefined && (obj.Via = calledViaToJSON(message.Via));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<RevisionNew>, I>>(
    object: I,
  ): RevisionNew {
    const message = createBaseRevisionNew();
    message.Number = object.Number ?? 0;
    message.By = object.By ?? '';
    message.At = object.At ?? undefined;
    message.Via = object.Via ?? 0;
    return message;
  },
};

function createBaseDeletedNew(): DeletedNew {
  return { IsDeleted: false, By: '', At: undefined, Via: 0 };
}

export const DeletedNew = {
  encode(
    message: DeletedNew,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IsDeleted === true) {
      writer.uint32(8).bool(message.IsDeleted);
    }
    if (message.By !== '') {
      writer.uint32(18).string(message.By);
    }
    if (message.At !== undefined) {
      Timestamp.encode(
        toTimestamp(message.At),
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Via !== 0) {
      writer.uint32(32).int32(message.Via);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeletedNew {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeletedNew();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IsDeleted = reader.bool();
          break;
        case 2:
          message.By = reader.string();
          break;
        case 3:
          message.At = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 4:
          message.Via = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): DeletedNew {
    return {
      IsDeleted: isSet(object.IsDeleted) ? Boolean(object.IsDeleted) : false,
      By: isSet(object.By) ? String(object.By) : '',
      At: isSet(object.At) ? fromJsonTimestamp(object.At) : undefined,
      Via: isSet(object.Via) ? calledViaFromJSON(object.Via) : 0,
    };
  },

  toJSON(message: DeletedNew): unknown {
    const obj: any = {};
    message.IsDeleted !== undefined && (obj.IsDeleted = message.IsDeleted);
    message.By !== undefined && (obj.By = message.By);
    message.At !== undefined && (obj.At = message.At.toISOString());
    message.Via !== undefined && (obj.Via = calledViaToJSON(message.Via));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<DeletedNew>, I>>(
    object: I,
  ): DeletedNew {
    const message = createBaseDeletedNew();
    message.IsDeleted = object.IsDeleted ?? false;
    message.By = object.By ?? '';
    message.At = object.At ?? undefined;
    message.Via = object.Via ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
