import { FC } from 'react';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { FMXRender } from '@/shared/graphics/viewer2D/Viewer2D';
import { scrollToElementByID } from '@/shared/lib';

import { toothModel } from '@/entities/tooth';
import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';

import { RenderViewSlider } from '@/features/renderViewSlider';

import styles from './IOXRayImageSlider.module.scss';

type IOXRayImageSliderProps = {
  className?: string;
};

export const IOXRayImageSlider: FC<IOXRayImageSliderProps> = (props) => {
  const { className } = props;

  const dispatch = useAppDispatch();

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  const reportID = currentReport?.ID;

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID as string,
    ),
  );

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface as IOXrayImageInterface[],
  );

  const imageMetaIDs = Object.values(groupedIOXRayImages).flatMap(
    (imageItems) => imageItems.map((imageItem) => imageItem.imageMeta.ID),
  );

  const focusedImageMetaID = useAppSelector(
    reportsModel.selectors.selectFocusedImageMetaID,
  );

  const teethIDs = useAppSelector(toothModel.selectors.selectROITeethIDs);
  const activeToothID = useAppSelector(
    reportsModel.selectors.selectActiveToothID,
  );

  // select data by navigation mode
  const isMatrixMode = navigationMode === FMXNavigationMode.MatrixView;
  const itemIDs = isMatrixMode ? imageMetaIDs : teethIDs;
  const activeItemID = isMatrixMode ? focusedImageMetaID : activeToothID;

  const singleImage = IOXRayImagesInterface.length === 1;

  const updateActiveToothID = (newItemID: string) => {
    FMXRender.activateMode('mainMode');
    newItemID
      ? FMXRender.layoutModes.mode === 'crop'
        ? FMXRender.layoutModes.crop(newItemID)
        : FMXRender.layoutModes.focus(newItemID)
      : FMXRender.layoutModes.matrix();
    dispatch(reportsModel.actions.setToolbarActiveControl('view'));
    if (isMatrixMode) {
      dispatch(reportsModel.actions.setFocusedMetaImageID(newItemID));
    } else {
      dispatch(reportsModel.actions.setActiveToothID(newItemID));
      scrollToElementByID(newItemID, 'smooth', 'start');
    }
  };

  if (singleImage) {
    return null;
  }

  return (
    <RenderViewSlider
      updateActiveItem={updateActiveToothID}
      itemIDs={itemIDs as string[]}
      activeItemID={activeItemID}
      className={cn(styles.container, className)}
    />
  );
};
