import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import {
  getConditionIDsByColor,
  useToothConditions,
} from '@/entities/condition';

export const useApproveTooth = (tooth: Tooth) => {
  const [isApproveToothLoading, setApproveToothLoading] = useState(false);

  const report = useAppSelector(
    reportsModel.selectors.selectByID(tooth?.ReportID),
  );
  const { generalConditionItems } = useToothConditions({
    toothID: tooth?.ID,
    showLowProbability: report?.Settings?.LowProbabilityMode ?? false,
  });

  const { checkReportSignature } = useCheckReportSignature();

  const yellowConditionsIDs = getConditionIDsByColor(
    'yellow',
    generalConditionItems,
  );

  const dispatch = useAppDispatch();

  const toggleApproveTooth = () => {
    checkReportSignature({
      onSignatureChecked: async () => {
        setApproveToothLoading(true);

        try {
          const currentTooth = !tooth?.IsApproved
            ? await dispatch(
                reportsModel.thunks.setReportToothApproved({
                  ToothID: tooth.ID,
                  ToothConditionIDs: yellowConditionsIDs,
                }),
              ).unwrap()
            : await dispatch(
                reportsModel.thunks.setReportToothDisapproved({
                  ToothID: tooth.ID,
                }),
              ).unwrap();

          if (currentTooth?.Tooth) {
            dispatch(toothModel.actions.setNewestOne(currentTooth.Tooth));
          }
        } finally {
          setApproveToothLoading(false);
        }
      },
    });
  };

  return { toggleApproveTooth, isApproveToothLoading };
};
