import { useIntl } from 'react-intl';
import cn from 'classnames';
import { useMemo, useRef } from 'react';
import { isEmpty } from 'lodash';

import {
  Study,
  StudyStatus,
  StudyType,
} from '@/shared/api/protocol_gen/model/dto_study';
import { useAppSelector, useMedia } from '@/shared/hooks';

import { getImageSrc } from '@/entities/assets';
import { i18n } from '@/entities/study';
import { reportsModel } from '@/entities/reports';

import { PanowingsPreview } from '@/features/PanowingsPreview';
import { IOXRayMatrixPreview } from '@/features/IOXRayMatrixPreview';

import { UploadingStatusFrame } from '../UploadingStatusFrame/UploadingStatusFrame';
import { studyCardTitle } from '../../config';

import styles from './StudyPreview.module.scss';

type StudyPreviewProps = {
  study: Study;
};

export const StudyPreview = (props: StudyPreviewProps) => {
  const { study } = props;

  const imagePreviewRef = useRef<HTMLDivElement>(null);

  const { formatMessage, formatDate } = useIntl();

  const { isPhone } = useMedia();

  const firstStudyReport = useAppSelector(
    reportsModel.selectors.selectFirstByStudyID(study.ID),
  );

  const isReportCompleted = firstStudyReport?.Status?.Completed;

  const previewImageSrc = getImageSrc(study.DisplayAssetID, 'preview');

  const studyType = study.Type;

  const showUploadingStatusFrame =
    study.Status !== StudyStatus.StudyStatus_Finished;

  const previewType = useMemo(() => {
    if (showUploadingStatusFrame) {
      return 'uploadingStatusFrame';
    }

    if (
      studyType === StudyType.StudyType_PanoramicXRay &&
      !isEmpty(firstStudyReport?.DataPanoBitewings)
    ) {
      return 'panowingsPreview';
    }

    if (studyType === StudyType.StudyType_IntraoralXRay) {
      return 'IOXRayPreview';
    }

    return 'simplePreview';
  }, [studyType, firstStudyReport, showUploadingStatusFrame]);

  const imgTextColorStyle = showUploadingStatusFrame
    ? styles.imgTextBlack
    : styles.imgTextWhite;

  const studyAssetIDs = study.AssetIDs;

  // manual selected values
  const getIOXRayPreviewImageHeight = () => {
    if (studyAssetIDs.length <= 2) {
      return 200;
    } else if (studyAssetIDs.length <= 4) {
      return 160;
    } else if (studyAssetIDs.length <= 6) {
      return 120;
    } else if (studyAssetIDs.length <= 10) {
      return 100;
    } else if (studyAssetIDs.length <= 18) {
      return 70;
    } else {
      return 60;
    }
  };

  // TODO: [2|m] move ioxray study preview into separate component (mb IOXRayMatrixPreview and IOXRayImagesPreview)
  const renderIOXRayPreview = isReportCompleted ? (
    <IOXRayMatrixPreview reportID={firstStudyReport?.ID as string} />
  ) : (
    <div className={styles.ioxrayPreviewContainer}>
      {studyAssetIDs.map((assetID) => {
        const imageSrc = getImageSrc(assetID, 'preview');

        return (
          <img
            style={{
              height: getIOXRayPreviewImageHeight(),
              width: 'auto',
              borderRadius: '6px',
            }}
            src={imageSrc}
            alt={assetID}
          />
        );
      })}
    </div>
  );

  const isIOXRayPreview = previewType === 'IOXRayPreview';

  return (
    <div
      ref={imagePreviewRef}
      className={cn(
        styles.imgContainer,
        isIOXRayPreview ? styles.imgContainerIOXRay : '',
      )}
    >
      {previewType === 'uploadingStatusFrame' && (
        <UploadingStatusFrame study={study} />
      )}

      {previewType === 'panowingsPreview' && (
        <PanowingsPreview reportID={firstStudyReport?.ID as string} />
      )}

      {isIOXRayPreview && renderIOXRayPreview}

      {previewType === 'simplePreview' && (
        <img
          className={styles.img}
          src={previewImageSrc}
          alt={formatMessage(i18n.studyTypes[study.Type])}
        />
      )}

      {studyType === StudyType.StudyType_IntraoralXRay ? (
        <>
          <div className={styles.layer}>
            <div className={styles.info}>
              <h5
                className={cn(
                  styles.reportType,
                  isPhone ? 'h6' : 'h5',
                  imgTextColorStyle,
                )}
              >
                {formatMessage(studyCardTitle[studyType])}
              </h5>

              {study?.Created?.At && (
                <span
                  className={cn(
                    styles.date,
                    isPhone ? 'p4' : 'p3',
                    imgTextColorStyle,
                  )}
                >
                  {formatDate(study.Created.At, {
                    dateStyle: 'medium',
                  })}
                </span>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={cn(styles.imgText)}>
          <h5 className={cn('h5', imgTextColorStyle)}>
            {formatMessage(studyCardTitle[studyType])}
          </h5>

          {study?.Created?.At && (
            <span className={cn('p3', imgTextColorStyle)}>
              {formatDate(study.Created.At, {
                dateStyle: 'medium',
              })}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
