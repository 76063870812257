import { RootState } from '@/app/model/store';

import { ModalID } from '../config';

export const selectAddConditionModal = (state: RootState) =>
  state.modal.AddCondition;

export const selectMPRViewerModal = (state: RootState) =>
  state.modal.MPRFullScreenModal;

export const selectBillingInfoModal = (state: RootState) =>
  state.modal[ModalID.BillingInformationModal];

export const selectCancelSubscriptionModalData = (state: RootState) =>
  state.modal[ModalID.CancelSubscriptionModal];

export const selectUploadModal = (state: RootState) =>
  state.modal[ModalID.UploadStudy];

export const selectResetPasswordErrorModal = (state: RootState) =>
  state.modal[ModalID.ResetPasswordErrorModal];

export const selectPatientRemovalConfirmationModal = (state: RootState) =>
  state.modal[ModalID.PatientRemovalConfirmationModal];

export const selectPatientModalForm = (state: RootState) =>
  state.modal[ModalID.PatientModalForm];

export const selectMedicalImage = (state: RootState) =>
  state.modal[ModalID.ZoomedMedicalImage];

export const selectTopUpReportsModal = (state: RootState) =>
  state.modal[ModalID.TopUpReportLimitsModal];

export const selectPauseOrActivateSubscriptionModal = (state: RootState) =>
  state.modal[ModalID.PauseOrActivateSubscriptionModal];

export const selectConfirmModal = (state: RootState) =>
  state.modal[ModalID.Confirm];

export const selectAboutLabelingModal = (state: RootState) =>
  state.modal[ModalID.AboutLabelingModal];

export const selectTeethNumberingModal = (state: RootState) =>
  state.modal[ModalID.TeethNumberingModal];
