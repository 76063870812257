import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { Switch } from '@/shared/ui';

import { useCheckReportSignature, useReportSettings } from '@/entities/reports';

import styles from './ToggleLowProbability.module.scss';

type ToggleLowProbabilityProps = {
  className?: string;
};

export const ToggleLowProbability = (props: ToggleLowProbabilityProps) => {
  const { className } = props;
  const { isLoading, settings, updateSettings } = useReportSettings();

  const { checkReportSignature } = useCheckReportSignature();

  const lowProbabilityModeToggle = () => {
    checkReportSignature({
      onSignatureChecked: async () =>
        updateSettings({ LowProbabilityMode: !settings.LowProbabilityMode }),
    });
  };

  return (
    <div className={cn(styles.container, className)}>
      <h6 className={cn('h6', styles.title)}>
        <FormattedMessage
          id="reportSettings.showLowProbability"
          defaultMessage="Show low probability"
        />
      </h6>
      <Switch
        checked={settings.LowProbabilityMode}
        disabled={isLoading}
        onChange={lowProbabilityModeToggle}
      />
    </div>
  );
};
