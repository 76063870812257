/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  ReportGenerationErrorCode,
  reportGenerationErrorCodeFromJSON,
  reportGenerationErrorCodeToJSON,
} from '../model/dto_report_generation_error_codes';
import { Timestamp } from '../google/protobuf/timestamp';
import { MedicalImageFeatures } from '../model/dto_common_image_medical_features';
import { ReportIOXRayGP } from '../model/dto_report_type_ioxray_gp';
import { ReportPanoGP } from '../model/dto_report_type_pano_gp';
import { ReportCBCTGP } from '../model/dto_report_type_cbct_gp';
import {
  ReportCBCTEndo,
  ReportCBCTImplant,
  ReportCBCTMolar,
  ReportCBCTOrtho,
} from '../model/dto_report_type_specific';
import { ReportCBCTSegmentation } from '../model/dto_report_type_cbct_segmentation';
import { ReportDentalPhotoGP } from '../model/dto_report_type_photo_gp';
import { ReportCBCTIOSSuperimposition } from '../model/dto_report_type_cbct_ios_superimposition';
import { ReportStudioImplant } from '../model/dto_report_type_studio_implant';
import { ReportStudioOrtho } from '../model/dto_report_type_studio_ortho';
import { ReportPanoBitewings } from '../model/dto_report_type_pano_bitewings';
import { ReportPermissions } from '../model/dto_report_permissions';
import { Created, Revision, Deleted } from '../model/dto_base';
import {
  OrganizationJobPosition,
  organizationJobPositionFromJSON,
  organizationJobPositionToJSON,
} from '../model/dto_organization_job_position';

export const protobufPackage = 'com.diagnocat.model';

export enum ReportType {
  ReportType_InvalidValue = 0,
  ReportType_CBCT_GP = 1,
  ReportType_CBCT_Segmentation = 2,
  ReportType_CBCT_OrthoSlides = 3,
  ReportType_CBCT_Implant = 4,
  ReportType_CBCT_Endo = 5,
  ReportType_CBCT_Molar = 6,
  ReportType_Pano_GP = 7,
  ReportType_IOXRay_GP = 8,
  ReportType_DentalPhoto_GP = 9,
  ReportType_IOS_Segmentation = 10,
  ReportType_CBCT_IOS_Superimposition = 11,
  ReportType_StudioImplant = 12,
  ReportType_Photo_Ortho = 14,
  ReportType_StudioOrtho = 15,
  ReportType_Pano_Bitewings = 16,
  UNRECOGNIZED = -1,
}

export function reportTypeFromJSON(object: any): ReportType {
  switch (object) {
    case 0:
    case 'ReportType_InvalidValue':
      return ReportType.ReportType_InvalidValue;
    case 1:
    case 'ReportType_CBCT_GP':
      return ReportType.ReportType_CBCT_GP;
    case 2:
    case 'ReportType_CBCT_Segmentation':
      return ReportType.ReportType_CBCT_Segmentation;
    case 3:
    case 'ReportType_CBCT_OrthoSlides':
      return ReportType.ReportType_CBCT_OrthoSlides;
    case 4:
    case 'ReportType_CBCT_Implant':
      return ReportType.ReportType_CBCT_Implant;
    case 5:
    case 'ReportType_CBCT_Endo':
      return ReportType.ReportType_CBCT_Endo;
    case 6:
    case 'ReportType_CBCT_Molar':
      return ReportType.ReportType_CBCT_Molar;
    case 7:
    case 'ReportType_Pano_GP':
      return ReportType.ReportType_Pano_GP;
    case 8:
    case 'ReportType_IOXRay_GP':
      return ReportType.ReportType_IOXRay_GP;
    case 9:
    case 'ReportType_DentalPhoto_GP':
      return ReportType.ReportType_DentalPhoto_GP;
    case 10:
    case 'ReportType_IOS_Segmentation':
      return ReportType.ReportType_IOS_Segmentation;
    case 11:
    case 'ReportType_CBCT_IOS_Superimposition':
      return ReportType.ReportType_CBCT_IOS_Superimposition;
    case 12:
    case 'ReportType_StudioImplant':
      return ReportType.ReportType_StudioImplant;
    case 14:
    case 'ReportType_Photo_Ortho':
      return ReportType.ReportType_Photo_Ortho;
    case 15:
    case 'ReportType_StudioOrtho':
      return ReportType.ReportType_StudioOrtho;
    case 16:
    case 'ReportType_Pano_Bitewings':
      return ReportType.ReportType_Pano_Bitewings;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ReportType.UNRECOGNIZED;
  }
}

export function reportTypeToJSON(object: ReportType): string {
  switch (object) {
    case ReportType.ReportType_InvalidValue:
      return 'ReportType_InvalidValue';
    case ReportType.ReportType_CBCT_GP:
      return 'ReportType_CBCT_GP';
    case ReportType.ReportType_CBCT_Segmentation:
      return 'ReportType_CBCT_Segmentation';
    case ReportType.ReportType_CBCT_OrthoSlides:
      return 'ReportType_CBCT_OrthoSlides';
    case ReportType.ReportType_CBCT_Implant:
      return 'ReportType_CBCT_Implant';
    case ReportType.ReportType_CBCT_Endo:
      return 'ReportType_CBCT_Endo';
    case ReportType.ReportType_CBCT_Molar:
      return 'ReportType_CBCT_Molar';
    case ReportType.ReportType_Pano_GP:
      return 'ReportType_Pano_GP';
    case ReportType.ReportType_IOXRay_GP:
      return 'ReportType_IOXRay_GP';
    case ReportType.ReportType_DentalPhoto_GP:
      return 'ReportType_DentalPhoto_GP';
    case ReportType.ReportType_IOS_Segmentation:
      return 'ReportType_IOS_Segmentation';
    case ReportType.ReportType_CBCT_IOS_Superimposition:
      return 'ReportType_CBCT_IOS_Superimposition';
    case ReportType.ReportType_StudioImplant:
      return 'ReportType_StudioImplant';
    case ReportType.ReportType_Photo_Ortho:
      return 'ReportType_Photo_Ortho';
    case ReportType.ReportType_StudioOrtho:
      return 'ReportType_StudioOrtho';
    case ReportType.ReportType_Pano_Bitewings:
      return 'ReportType_Pano_Bitewings';
    case ReportType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum ReportPrintSettingsDescriptionLang {
  ReportPrintSettings_DescriptionLang_Clinical = 0,
  ReportPrintSettings_DescriptionLang_Radiological = 1,
  UNRECOGNIZED = -1,
}

export function reportPrintSettingsDescriptionLangFromJSON(
  object: any,
): ReportPrintSettingsDescriptionLang {
  switch (object) {
    case 0:
    case 'ReportPrintSettings_DescriptionLang_Clinical':
      return ReportPrintSettingsDescriptionLang.ReportPrintSettings_DescriptionLang_Clinical;
    case 1:
    case 'ReportPrintSettings_DescriptionLang_Radiological':
      return ReportPrintSettingsDescriptionLang.ReportPrintSettings_DescriptionLang_Radiological;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ReportPrintSettingsDescriptionLang.UNRECOGNIZED;
  }
}

export function reportPrintSettingsDescriptionLangToJSON(
  object: ReportPrintSettingsDescriptionLang,
): string {
  switch (object) {
    case ReportPrintSettingsDescriptionLang.ReportPrintSettings_DescriptionLang_Clinical:
      return 'ReportPrintSettings_DescriptionLang_Clinical';
    case ReportPrintSettingsDescriptionLang.ReportPrintSettings_DescriptionLang_Radiological:
      return 'ReportPrintSettings_DescriptionLang_Radiological';
    case ReportPrintSettingsDescriptionLang.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum ReportPrintSettingsPrintType {
  ReportPrintSettings_PrintType_Full = 0,
  ReportPrintSettings_PrintType_Motivation = 1,
  UNRECOGNIZED = -1,
}

export function reportPrintSettingsPrintTypeFromJSON(
  object: any,
): ReportPrintSettingsPrintType {
  switch (object) {
    case 0:
    case 'ReportPrintSettings_PrintType_Full':
      return ReportPrintSettingsPrintType.ReportPrintSettings_PrintType_Full;
    case 1:
    case 'ReportPrintSettings_PrintType_Motivation':
      return ReportPrintSettingsPrintType.ReportPrintSettings_PrintType_Motivation;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return ReportPrintSettingsPrintType.UNRECOGNIZED;
  }
}

export function reportPrintSettingsPrintTypeToJSON(
  object: ReportPrintSettingsPrintType,
): string {
  switch (object) {
    case ReportPrintSettingsPrintType.ReportPrintSettings_PrintType_Full:
      return 'ReportPrintSettings_PrintType_Full';
    case ReportPrintSettingsPrintType.ReportPrintSettings_PrintType_Motivation:
      return 'ReportPrintSettings_PrintType_Motivation';
    case ReportPrintSettingsPrintType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Report {
  ID: string;
  OrganizationID: string;
  PatientID: string;
  SourceStudyIDs: string[];
  /** if ReportType_Pano_Bitewings */
  SourceReportIDs: string[];
  Type: ReportType;
  LegacyConclusion: string;
  DisplayAssetID: string;
  MedicalImageFeatures: MedicalImageFeatures | undefined;
  DataIOXRayGP: ReportIOXRayGP | undefined;
  DataPanoGP: ReportPanoGP | undefined;
  DataCBCTGP: ReportCBCTGP | undefined;
  DataCBCTEndo: ReportCBCTEndo | undefined;
  DataCBCTImplant: ReportCBCTImplant | undefined;
  DataCBCTMolar: ReportCBCTMolar | undefined;
  DataCBCTOrtho: ReportCBCTOrtho | undefined;
  DataCBCTSegmentation: ReportCBCTSegmentation | undefined;
  DataDentalPhoto: ReportDentalPhotoGP | undefined;
  DataCBCTIOSSuperimposition: ReportCBCTIOSSuperimposition | undefined;
  DataStudioImplant: ReportStudioImplant | undefined;
  DataStudioOrtho: ReportStudioOrtho | undefined;
  DataPanoBitewings: ReportPanoBitewings | undefined;
  Settings: ReportSettings | undefined;
  PrintSettings: ReportPrintSettings | undefined;
  Signature: ReportSignature | undefined;
  Status: ReportStatus | undefined;
  PipelinesTasks: Report_PipelinesTask[];
  YourPermissions: ReportPermissions | undefined;
  /** creation information */
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
  /** removal information */
  Deleted: Deleted | undefined;
  BlackURL: string;
}

export interface Report_PipelinesTask {
  ID: string;
  Status: ReportStatus | undefined;
  SourceStudiesAssetIDs: string[];
}

export interface ReportStatus {
  Pending: boolean;
  WaitingForUploadsToComplete: boolean;
  TakenByWorker: boolean;
  InProgress: ReportProgress | undefined;
  Completed: boolean;
  Canceled: boolean;
  Failed: ReportFailed | undefined;
}

export interface ReportProgress {
  PrimaryPanoramaUploaded: boolean;
  SecondaryPanoramaUploaded: boolean;
  AllTeethLocalizationsCompleted: boolean;
  AllConditionsCompleted: boolean;
  AllAssetsUploaded: boolean;
}

export interface ReportFailed {
  Message: string;
  Code: ReportGenerationErrorCode;
}

export interface ReportSignature {
  Signed: boolean;
  UserSignatures: ReportUserSignature[];
}

export interface ReportUserSignature {
  UserID: string;
  SignedAt: Date | undefined;
  /** >> Asset.ID with AssetType_User_Signature */
  SignatureAssetID: string;
  /** >> Asset.ID with AssetType_Organization_EmployeeStamp */
  StampAssetID: string;
  UserFirstName: string;
  UserLastName: string;
  /** user job positions */
  JobPositions: OrganizationJobPosition[];
}

export interface ReportSettings {
  LowProbabilityMode: boolean;
  ShowProblemAreas: boolean;
  ShowPanoramaSplitInsteadOfSingle: boolean;
  ShowSimpleConditionView: boolean;
}

export interface ReportPrintSettings {
  DescriptionLang: ReportPrintSettingsDescriptionLang;
  PrintType: ReportPrintSettingsPrintType;
  /** default == true */
  ShowDiagnoses: boolean;
  /** default == true */
  ShowDiagnosesProbabilities: boolean;
  /** default == true */
  ShowDentalChart: boolean;
  /** default == true */
  ShowSlices: boolean;
  /** default == true */
  ShowTopJaw: boolean;
  /** default == true */
  ShowBottomJaw: boolean;
  ShowBBoxes: boolean;
  ShowReferralRecommendations: boolean;
  ShowOrthodonticScreening: boolean;
  ShowStudyMetadata: boolean;
  BlackAndWhiteMode: boolean;
  IsInverted: boolean;
}

function createBaseReport(): Report {
  return {
    ID: '',
    OrganizationID: '',
    PatientID: '',
    SourceStudyIDs: [],
    SourceReportIDs: [],
    Type: 0,
    LegacyConclusion: '',
    DisplayAssetID: '',
    MedicalImageFeatures: undefined,
    DataIOXRayGP: undefined,
    DataPanoGP: undefined,
    DataCBCTGP: undefined,
    DataCBCTEndo: undefined,
    DataCBCTImplant: undefined,
    DataCBCTMolar: undefined,
    DataCBCTOrtho: undefined,
    DataCBCTSegmentation: undefined,
    DataDentalPhoto: undefined,
    DataCBCTIOSSuperimposition: undefined,
    DataStudioImplant: undefined,
    DataStudioOrtho: undefined,
    DataPanoBitewings: undefined,
    Settings: undefined,
    PrintSettings: undefined,
    Signature: undefined,
    Status: undefined,
    PipelinesTasks: [],
    YourPermissions: undefined,
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
    BlackURL: '',
  };
}

export const Report = {
  encode(
    message: Report,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(18).string(message.OrganizationID);
    }
    if (message.PatientID !== '') {
      writer.uint32(26).string(message.PatientID);
    }
    for (const v of message.SourceStudyIDs) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.SourceReportIDs) {
      writer.uint32(42).string(v!);
    }
    if (message.Type !== 0) {
      writer.uint32(48).int32(message.Type);
    }
    if (message.LegacyConclusion !== '') {
      writer.uint32(58).string(message.LegacyConclusion);
    }
    if (message.DisplayAssetID !== '') {
      writer.uint32(66).string(message.DisplayAssetID);
    }
    if (message.MedicalImageFeatures !== undefined) {
      MedicalImageFeatures.encode(
        message.MedicalImageFeatures,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.DataIOXRayGP !== undefined) {
      ReportIOXRayGP.encode(
        message.DataIOXRayGP,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.DataPanoGP !== undefined) {
      ReportPanoGP.encode(
        message.DataPanoGP,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.DataCBCTGP !== undefined) {
      ReportCBCTGP.encode(
        message.DataCBCTGP,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    if (message.DataCBCTEndo !== undefined) {
      ReportCBCTEndo.encode(
        message.DataCBCTEndo,
        writer.uint32(834).fork(),
      ).ldelim();
    }
    if (message.DataCBCTImplant !== undefined) {
      ReportCBCTImplant.encode(
        message.DataCBCTImplant,
        writer.uint32(842).fork(),
      ).ldelim();
    }
    if (message.DataCBCTMolar !== undefined) {
      ReportCBCTMolar.encode(
        message.DataCBCTMolar,
        writer.uint32(850).fork(),
      ).ldelim();
    }
    if (message.DataCBCTOrtho !== undefined) {
      ReportCBCTOrtho.encode(
        message.DataCBCTOrtho,
        writer.uint32(858).fork(),
      ).ldelim();
    }
    if (message.DataCBCTSegmentation !== undefined) {
      ReportCBCTSegmentation.encode(
        message.DataCBCTSegmentation,
        writer.uint32(866).fork(),
      ).ldelim();
    }
    if (message.DataDentalPhoto !== undefined) {
      ReportDentalPhotoGP.encode(
        message.DataDentalPhoto,
        writer.uint32(874).fork(),
      ).ldelim();
    }
    if (message.DataCBCTIOSSuperimposition !== undefined) {
      ReportCBCTIOSSuperimposition.encode(
        message.DataCBCTIOSSuperimposition,
        writer.uint32(882).fork(),
      ).ldelim();
    }
    if (message.DataStudioImplant !== undefined) {
      ReportStudioImplant.encode(
        message.DataStudioImplant,
        writer.uint32(890).fork(),
      ).ldelim();
    }
    if (message.DataStudioOrtho !== undefined) {
      ReportStudioOrtho.encode(
        message.DataStudioOrtho,
        writer.uint32(898).fork(),
      ).ldelim();
    }
    if (message.DataPanoBitewings !== undefined) {
      ReportPanoBitewings.encode(
        message.DataPanoBitewings,
        writer.uint32(906).fork(),
      ).ldelim();
    }
    if (message.Settings !== undefined) {
      ReportSettings.encode(
        message.Settings,
        writer.uint32(1610).fork(),
      ).ldelim();
    }
    if (message.PrintSettings !== undefined) {
      ReportPrintSettings.encode(
        message.PrintSettings,
        writer.uint32(1618).fork(),
      ).ldelim();
    }
    if (message.Signature !== undefined) {
      ReportSignature.encode(
        message.Signature,
        writer.uint32(1626).fork(),
      ).ldelim();
    }
    if (message.Status !== undefined) {
      ReportStatus.encode(message.Status, writer.uint32(2410).fork()).ldelim();
    }
    for (const v of message.PipelinesTasks) {
      Report_PipelinesTask.encode(v!, writer.uint32(2418).fork()).ldelim();
    }
    if (message.YourPermissions !== undefined) {
      ReportPermissions.encode(
        message.YourPermissions,
        writer.uint32(80162).fork(),
      ).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Deleted.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    if (message.BlackURL !== '') {
      writer.uint32(7999994).string(message.BlackURL);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Report {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.OrganizationID = reader.string();
          break;
        case 3:
          message.PatientID = reader.string();
          break;
        case 4:
          message.SourceStudyIDs.push(reader.string());
          break;
        case 5:
          message.SourceReportIDs.push(reader.string());
          break;
        case 6:
          message.Type = reader.int32() as any;
          break;
        case 7:
          message.LegacyConclusion = reader.string();
          break;
        case 8:
          message.DisplayAssetID = reader.string();
          break;
        case 11:
          message.MedicalImageFeatures = MedicalImageFeatures.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 101:
          message.DataIOXRayGP = ReportIOXRayGP.decode(reader, reader.uint32());
          break;
        case 102:
          message.DataPanoGP = ReportPanoGP.decode(reader, reader.uint32());
          break;
        case 103:
          message.DataCBCTGP = ReportCBCTGP.decode(reader, reader.uint32());
          break;
        case 104:
          message.DataCBCTEndo = ReportCBCTEndo.decode(reader, reader.uint32());
          break;
        case 105:
          message.DataCBCTImplant = ReportCBCTImplant.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 106:
          message.DataCBCTMolar = ReportCBCTMolar.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 107:
          message.DataCBCTOrtho = ReportCBCTOrtho.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 108:
          message.DataCBCTSegmentation = ReportCBCTSegmentation.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 109:
          message.DataDentalPhoto = ReportDentalPhotoGP.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 110:
          message.DataCBCTIOSSuperimposition =
            ReportCBCTIOSSuperimposition.decode(reader, reader.uint32());
          break;
        case 111:
          message.DataStudioImplant = ReportStudioImplant.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 112:
          message.DataStudioOrtho = ReportStudioOrtho.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 113:
          message.DataPanoBitewings = ReportPanoBitewings.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 201:
          message.Settings = ReportSettings.decode(reader, reader.uint32());
          break;
        case 202:
          message.PrintSettings = ReportPrintSettings.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 203:
          message.Signature = ReportSignature.decode(reader, reader.uint32());
          break;
        case 301:
          message.Status = ReportStatus.decode(reader, reader.uint32());
          break;
        case 302:
          message.PipelinesTasks.push(
            Report_PipelinesTask.decode(reader, reader.uint32()),
          );
          break;
        case 10020:
          message.YourPermissions = ReportPermissions.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = Deleted.decode(reader, reader.uint32());
          break;
        case 999999:
          message.BlackURL = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Report {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      SourceStudyIDs: Array.isArray(object?.SourceStudyIDs)
        ? object.SourceStudyIDs.map((e: any) => String(e))
        : [],
      SourceReportIDs: Array.isArray(object?.SourceReportIDs)
        ? object.SourceReportIDs.map((e: any) => String(e))
        : [],
      Type: isSet(object.Type) ? reportTypeFromJSON(object.Type) : 0,
      LegacyConclusion: isSet(object.LegacyConclusion)
        ? String(object.LegacyConclusion)
        : '',
      DisplayAssetID: isSet(object.DisplayAssetID)
        ? String(object.DisplayAssetID)
        : '',
      MedicalImageFeatures: isSet(object.MedicalImageFeatures)
        ? MedicalImageFeatures.fromJSON(object.MedicalImageFeatures)
        : undefined,
      DataIOXRayGP: isSet(object.DataIOXRayGP)
        ? ReportIOXRayGP.fromJSON(object.DataIOXRayGP)
        : undefined,
      DataPanoGP: isSet(object.DataPanoGP)
        ? ReportPanoGP.fromJSON(object.DataPanoGP)
        : undefined,
      DataCBCTGP: isSet(object.DataCBCTGP)
        ? ReportCBCTGP.fromJSON(object.DataCBCTGP)
        : undefined,
      DataCBCTEndo: isSet(object.DataCBCTEndo)
        ? ReportCBCTEndo.fromJSON(object.DataCBCTEndo)
        : undefined,
      DataCBCTImplant: isSet(object.DataCBCTImplant)
        ? ReportCBCTImplant.fromJSON(object.DataCBCTImplant)
        : undefined,
      DataCBCTMolar: isSet(object.DataCBCTMolar)
        ? ReportCBCTMolar.fromJSON(object.DataCBCTMolar)
        : undefined,
      DataCBCTOrtho: isSet(object.DataCBCTOrtho)
        ? ReportCBCTOrtho.fromJSON(object.DataCBCTOrtho)
        : undefined,
      DataCBCTSegmentation: isSet(object.DataCBCTSegmentation)
        ? ReportCBCTSegmentation.fromJSON(object.DataCBCTSegmentation)
        : undefined,
      DataDentalPhoto: isSet(object.DataDentalPhoto)
        ? ReportDentalPhotoGP.fromJSON(object.DataDentalPhoto)
        : undefined,
      DataCBCTIOSSuperimposition: isSet(object.DataCBCTIOSSuperimposition)
        ? ReportCBCTIOSSuperimposition.fromJSON(
            object.DataCBCTIOSSuperimposition,
          )
        : undefined,
      DataStudioImplant: isSet(object.DataStudioImplant)
        ? ReportStudioImplant.fromJSON(object.DataStudioImplant)
        : undefined,
      DataStudioOrtho: isSet(object.DataStudioOrtho)
        ? ReportStudioOrtho.fromJSON(object.DataStudioOrtho)
        : undefined,
      DataPanoBitewings: isSet(object.DataPanoBitewings)
        ? ReportPanoBitewings.fromJSON(object.DataPanoBitewings)
        : undefined,
      Settings: isSet(object.Settings)
        ? ReportSettings.fromJSON(object.Settings)
        : undefined,
      PrintSettings: isSet(object.PrintSettings)
        ? ReportPrintSettings.fromJSON(object.PrintSettings)
        : undefined,
      Signature: isSet(object.Signature)
        ? ReportSignature.fromJSON(object.Signature)
        : undefined,
      Status: isSet(object.Status)
        ? ReportStatus.fromJSON(object.Status)
        : undefined,
      PipelinesTasks: Array.isArray(object?.PipelinesTasks)
        ? object.PipelinesTasks.map((e: any) =>
            Report_PipelinesTask.fromJSON(e),
          )
        : [],
      YourPermissions: isSet(object.YourPermissions)
        ? ReportPermissions.fromJSON(object.YourPermissions)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Deleted.fromJSON(object.Deleted)
        : undefined,
      BlackURL: isSet(object.BlackURL) ? String(object.BlackURL) : '',
    };
  },

  toJSON(message: Report): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    if (message.SourceStudyIDs) {
      obj.SourceStudyIDs = message.SourceStudyIDs.map((e) => e);
    } else {
      obj.SourceStudyIDs = [];
    }
    if (message.SourceReportIDs) {
      obj.SourceReportIDs = message.SourceReportIDs.map((e) => e);
    } else {
      obj.SourceReportIDs = [];
    }
    message.Type !== undefined && (obj.Type = reportTypeToJSON(message.Type));
    message.LegacyConclusion !== undefined &&
      (obj.LegacyConclusion = message.LegacyConclusion);
    message.DisplayAssetID !== undefined &&
      (obj.DisplayAssetID = message.DisplayAssetID);
    message.MedicalImageFeatures !== undefined &&
      (obj.MedicalImageFeatures = message.MedicalImageFeatures
        ? MedicalImageFeatures.toJSON(message.MedicalImageFeatures)
        : undefined);
    message.DataIOXRayGP !== undefined &&
      (obj.DataIOXRayGP = message.DataIOXRayGP
        ? ReportIOXRayGP.toJSON(message.DataIOXRayGP)
        : undefined);
    message.DataPanoGP !== undefined &&
      (obj.DataPanoGP = message.DataPanoGP
        ? ReportPanoGP.toJSON(message.DataPanoGP)
        : undefined);
    message.DataCBCTGP !== undefined &&
      (obj.DataCBCTGP = message.DataCBCTGP
        ? ReportCBCTGP.toJSON(message.DataCBCTGP)
        : undefined);
    message.DataCBCTEndo !== undefined &&
      (obj.DataCBCTEndo = message.DataCBCTEndo
        ? ReportCBCTEndo.toJSON(message.DataCBCTEndo)
        : undefined);
    message.DataCBCTImplant !== undefined &&
      (obj.DataCBCTImplant = message.DataCBCTImplant
        ? ReportCBCTImplant.toJSON(message.DataCBCTImplant)
        : undefined);
    message.DataCBCTMolar !== undefined &&
      (obj.DataCBCTMolar = message.DataCBCTMolar
        ? ReportCBCTMolar.toJSON(message.DataCBCTMolar)
        : undefined);
    message.DataCBCTOrtho !== undefined &&
      (obj.DataCBCTOrtho = message.DataCBCTOrtho
        ? ReportCBCTOrtho.toJSON(message.DataCBCTOrtho)
        : undefined);
    message.DataCBCTSegmentation !== undefined &&
      (obj.DataCBCTSegmentation = message.DataCBCTSegmentation
        ? ReportCBCTSegmentation.toJSON(message.DataCBCTSegmentation)
        : undefined);
    message.DataDentalPhoto !== undefined &&
      (obj.DataDentalPhoto = message.DataDentalPhoto
        ? ReportDentalPhotoGP.toJSON(message.DataDentalPhoto)
        : undefined);
    message.DataCBCTIOSSuperimposition !== undefined &&
      (obj.DataCBCTIOSSuperimposition = message.DataCBCTIOSSuperimposition
        ? ReportCBCTIOSSuperimposition.toJSON(
            message.DataCBCTIOSSuperimposition,
          )
        : undefined);
    message.DataStudioImplant !== undefined &&
      (obj.DataStudioImplant = message.DataStudioImplant
        ? ReportStudioImplant.toJSON(message.DataStudioImplant)
        : undefined);
    message.DataStudioOrtho !== undefined &&
      (obj.DataStudioOrtho = message.DataStudioOrtho
        ? ReportStudioOrtho.toJSON(message.DataStudioOrtho)
        : undefined);
    message.DataPanoBitewings !== undefined &&
      (obj.DataPanoBitewings = message.DataPanoBitewings
        ? ReportPanoBitewings.toJSON(message.DataPanoBitewings)
        : undefined);
    message.Settings !== undefined &&
      (obj.Settings = message.Settings
        ? ReportSettings.toJSON(message.Settings)
        : undefined);
    message.PrintSettings !== undefined &&
      (obj.PrintSettings = message.PrintSettings
        ? ReportPrintSettings.toJSON(message.PrintSettings)
        : undefined);
    message.Signature !== undefined &&
      (obj.Signature = message.Signature
        ? ReportSignature.toJSON(message.Signature)
        : undefined);
    message.Status !== undefined &&
      (obj.Status = message.Status
        ? ReportStatus.toJSON(message.Status)
        : undefined);
    if (message.PipelinesTasks) {
      obj.PipelinesTasks = message.PipelinesTasks.map((e) =>
        e ? Report_PipelinesTask.toJSON(e) : undefined,
      );
    } else {
      obj.PipelinesTasks = [];
    }
    message.YourPermissions !== undefined &&
      (obj.YourPermissions = message.YourPermissions
        ? ReportPermissions.toJSON(message.YourPermissions)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Deleted.toJSON(message.Deleted)
        : undefined);
    message.BlackURL !== undefined && (obj.BlackURL = message.BlackURL);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Report>, I>>(object: I): Report {
    const message = createBaseReport();
    message.ID = object.ID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.SourceStudyIDs = object.SourceStudyIDs?.map((e) => e) || [];
    message.SourceReportIDs = object.SourceReportIDs?.map((e) => e) || [];
    message.Type = object.Type ?? 0;
    message.LegacyConclusion = object.LegacyConclusion ?? '';
    message.DisplayAssetID = object.DisplayAssetID ?? '';
    message.MedicalImageFeatures =
      object.MedicalImageFeatures !== undefined &&
      object.MedicalImageFeatures !== null
        ? MedicalImageFeatures.fromPartial(object.MedicalImageFeatures)
        : undefined;
    message.DataIOXRayGP =
      object.DataIOXRayGP !== undefined && object.DataIOXRayGP !== null
        ? ReportIOXRayGP.fromPartial(object.DataIOXRayGP)
        : undefined;
    message.DataPanoGP =
      object.DataPanoGP !== undefined && object.DataPanoGP !== null
        ? ReportPanoGP.fromPartial(object.DataPanoGP)
        : undefined;
    message.DataCBCTGP =
      object.DataCBCTGP !== undefined && object.DataCBCTGP !== null
        ? ReportCBCTGP.fromPartial(object.DataCBCTGP)
        : undefined;
    message.DataCBCTEndo =
      object.DataCBCTEndo !== undefined && object.DataCBCTEndo !== null
        ? ReportCBCTEndo.fromPartial(object.DataCBCTEndo)
        : undefined;
    message.DataCBCTImplant =
      object.DataCBCTImplant !== undefined && object.DataCBCTImplant !== null
        ? ReportCBCTImplant.fromPartial(object.DataCBCTImplant)
        : undefined;
    message.DataCBCTMolar =
      object.DataCBCTMolar !== undefined && object.DataCBCTMolar !== null
        ? ReportCBCTMolar.fromPartial(object.DataCBCTMolar)
        : undefined;
    message.DataCBCTOrtho =
      object.DataCBCTOrtho !== undefined && object.DataCBCTOrtho !== null
        ? ReportCBCTOrtho.fromPartial(object.DataCBCTOrtho)
        : undefined;
    message.DataCBCTSegmentation =
      object.DataCBCTSegmentation !== undefined &&
      object.DataCBCTSegmentation !== null
        ? ReportCBCTSegmentation.fromPartial(object.DataCBCTSegmentation)
        : undefined;
    message.DataDentalPhoto =
      object.DataDentalPhoto !== undefined && object.DataDentalPhoto !== null
        ? ReportDentalPhotoGP.fromPartial(object.DataDentalPhoto)
        : undefined;
    message.DataCBCTIOSSuperimposition =
      object.DataCBCTIOSSuperimposition !== undefined &&
      object.DataCBCTIOSSuperimposition !== null
        ? ReportCBCTIOSSuperimposition.fromPartial(
            object.DataCBCTIOSSuperimposition,
          )
        : undefined;
    message.DataStudioImplant =
      object.DataStudioImplant !== undefined &&
      object.DataStudioImplant !== null
        ? ReportStudioImplant.fromPartial(object.DataStudioImplant)
        : undefined;
    message.DataStudioOrtho =
      object.DataStudioOrtho !== undefined && object.DataStudioOrtho !== null
        ? ReportStudioOrtho.fromPartial(object.DataStudioOrtho)
        : undefined;
    message.DataPanoBitewings =
      object.DataPanoBitewings !== undefined &&
      object.DataPanoBitewings !== null
        ? ReportPanoBitewings.fromPartial(object.DataPanoBitewings)
        : undefined;
    message.Settings =
      object.Settings !== undefined && object.Settings !== null
        ? ReportSettings.fromPartial(object.Settings)
        : undefined;
    message.PrintSettings =
      object.PrintSettings !== undefined && object.PrintSettings !== null
        ? ReportPrintSettings.fromPartial(object.PrintSettings)
        : undefined;
    message.Signature =
      object.Signature !== undefined && object.Signature !== null
        ? ReportSignature.fromPartial(object.Signature)
        : undefined;
    message.Status =
      object.Status !== undefined && object.Status !== null
        ? ReportStatus.fromPartial(object.Status)
        : undefined;
    message.PipelinesTasks =
      object.PipelinesTasks?.map((e) => Report_PipelinesTask.fromPartial(e)) ||
      [];
    message.YourPermissions =
      object.YourPermissions !== undefined && object.YourPermissions !== null
        ? ReportPermissions.fromPartial(object.YourPermissions)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Deleted.fromPartial(object.Deleted)
        : undefined;
    message.BlackURL = object.BlackURL ?? '';
    return message;
  },
};

function createBaseReport_PipelinesTask(): Report_PipelinesTask {
  return { ID: '', Status: undefined, SourceStudiesAssetIDs: [] };
}

export const Report_PipelinesTask = {
  encode(
    message: Report_PipelinesTask,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Status !== undefined) {
      ReportStatus.encode(message.Status, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.SourceStudiesAssetIDs) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Report_PipelinesTask {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReport_PipelinesTask();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Status = ReportStatus.decode(reader, reader.uint32());
          break;
        case 3:
          message.SourceStudiesAssetIDs.push(reader.string());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Report_PipelinesTask {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Status: isSet(object.Status)
        ? ReportStatus.fromJSON(object.Status)
        : undefined,
      SourceStudiesAssetIDs: Array.isArray(object?.SourceStudiesAssetIDs)
        ? object.SourceStudiesAssetIDs.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: Report_PipelinesTask): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Status !== undefined &&
      (obj.Status = message.Status
        ? ReportStatus.toJSON(message.Status)
        : undefined);
    if (message.SourceStudiesAssetIDs) {
      obj.SourceStudiesAssetIDs = message.SourceStudiesAssetIDs.map((e) => e);
    } else {
      obj.SourceStudiesAssetIDs = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Report_PipelinesTask>, I>>(
    object: I,
  ): Report_PipelinesTask {
    const message = createBaseReport_PipelinesTask();
    message.ID = object.ID ?? '';
    message.Status =
      object.Status !== undefined && object.Status !== null
        ? ReportStatus.fromPartial(object.Status)
        : undefined;
    message.SourceStudiesAssetIDs =
      object.SourceStudiesAssetIDs?.map((e) => e) || [];
    return message;
  },
};

function createBaseReportStatus(): ReportStatus {
  return {
    Pending: false,
    WaitingForUploadsToComplete: false,
    TakenByWorker: false,
    InProgress: undefined,
    Completed: false,
    Canceled: false,
    Failed: undefined,
  };
}

export const ReportStatus = {
  encode(
    message: ReportStatus,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Pending === true) {
      writer.uint32(8).bool(message.Pending);
    }
    if (message.WaitingForUploadsToComplete === true) {
      writer.uint32(16).bool(message.WaitingForUploadsToComplete);
    }
    if (message.TakenByWorker === true) {
      writer.uint32(24).bool(message.TakenByWorker);
    }
    if (message.InProgress !== undefined) {
      ReportProgress.encode(
        message.InProgress,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.Completed === true) {
      writer.uint32(40).bool(message.Completed);
    }
    if (message.Canceled === true) {
      writer.uint32(48).bool(message.Canceled);
    }
    if (message.Failed !== undefined) {
      ReportFailed.encode(message.Failed, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportStatus {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Pending = reader.bool();
          break;
        case 2:
          message.WaitingForUploadsToComplete = reader.bool();
          break;
        case 3:
          message.TakenByWorker = reader.bool();
          break;
        case 4:
          message.InProgress = ReportProgress.decode(reader, reader.uint32());
          break;
        case 5:
          message.Completed = reader.bool();
          break;
        case 6:
          message.Canceled = reader.bool();
          break;
        case 7:
          message.Failed = ReportFailed.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportStatus {
    return {
      Pending: isSet(object.Pending) ? Boolean(object.Pending) : false,
      WaitingForUploadsToComplete: isSet(object.WaitingForUploadsToComplete)
        ? Boolean(object.WaitingForUploadsToComplete)
        : false,
      TakenByWorker: isSet(object.TakenByWorker)
        ? Boolean(object.TakenByWorker)
        : false,
      InProgress: isSet(object.InProgress)
        ? ReportProgress.fromJSON(object.InProgress)
        : undefined,
      Completed: isSet(object.Completed) ? Boolean(object.Completed) : false,
      Canceled: isSet(object.Canceled) ? Boolean(object.Canceled) : false,
      Failed: isSet(object.Failed)
        ? ReportFailed.fromJSON(object.Failed)
        : undefined,
    };
  },

  toJSON(message: ReportStatus): unknown {
    const obj: any = {};
    message.Pending !== undefined && (obj.Pending = message.Pending);
    message.WaitingForUploadsToComplete !== undefined &&
      (obj.WaitingForUploadsToComplete = message.WaitingForUploadsToComplete);
    message.TakenByWorker !== undefined &&
      (obj.TakenByWorker = message.TakenByWorker);
    message.InProgress !== undefined &&
      (obj.InProgress = message.InProgress
        ? ReportProgress.toJSON(message.InProgress)
        : undefined);
    message.Completed !== undefined && (obj.Completed = message.Completed);
    message.Canceled !== undefined && (obj.Canceled = message.Canceled);
    message.Failed !== undefined &&
      (obj.Failed = message.Failed
        ? ReportFailed.toJSON(message.Failed)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportStatus>, I>>(
    object: I,
  ): ReportStatus {
    const message = createBaseReportStatus();
    message.Pending = object.Pending ?? false;
    message.WaitingForUploadsToComplete =
      object.WaitingForUploadsToComplete ?? false;
    message.TakenByWorker = object.TakenByWorker ?? false;
    message.InProgress =
      object.InProgress !== undefined && object.InProgress !== null
        ? ReportProgress.fromPartial(object.InProgress)
        : undefined;
    message.Completed = object.Completed ?? false;
    message.Canceled = object.Canceled ?? false;
    message.Failed =
      object.Failed !== undefined && object.Failed !== null
        ? ReportFailed.fromPartial(object.Failed)
        : undefined;
    return message;
  },
};

function createBaseReportProgress(): ReportProgress {
  return {
    PrimaryPanoramaUploaded: false,
    SecondaryPanoramaUploaded: false,
    AllTeethLocalizationsCompleted: false,
    AllConditionsCompleted: false,
    AllAssetsUploaded: false,
  };
}

export const ReportProgress = {
  encode(
    message: ReportProgress,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PrimaryPanoramaUploaded === true) {
      writer.uint32(8).bool(message.PrimaryPanoramaUploaded);
    }
    if (message.SecondaryPanoramaUploaded === true) {
      writer.uint32(16).bool(message.SecondaryPanoramaUploaded);
    }
    if (message.AllTeethLocalizationsCompleted === true) {
      writer.uint32(88).bool(message.AllTeethLocalizationsCompleted);
    }
    if (message.AllConditionsCompleted === true) {
      writer.uint32(96).bool(message.AllConditionsCompleted);
    }
    if (message.AllAssetsUploaded === true) {
      writer.uint32(104).bool(message.AllAssetsUploaded);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportProgress {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportProgress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PrimaryPanoramaUploaded = reader.bool();
          break;
        case 2:
          message.SecondaryPanoramaUploaded = reader.bool();
          break;
        case 11:
          message.AllTeethLocalizationsCompleted = reader.bool();
          break;
        case 12:
          message.AllConditionsCompleted = reader.bool();
          break;
        case 13:
          message.AllAssetsUploaded = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportProgress {
    return {
      PrimaryPanoramaUploaded: isSet(object.PrimaryPanoramaUploaded)
        ? Boolean(object.PrimaryPanoramaUploaded)
        : false,
      SecondaryPanoramaUploaded: isSet(object.SecondaryPanoramaUploaded)
        ? Boolean(object.SecondaryPanoramaUploaded)
        : false,
      AllTeethLocalizationsCompleted: isSet(
        object.AllTeethLocalizationsCompleted,
      )
        ? Boolean(object.AllTeethLocalizationsCompleted)
        : false,
      AllConditionsCompleted: isSet(object.AllConditionsCompleted)
        ? Boolean(object.AllConditionsCompleted)
        : false,
      AllAssetsUploaded: isSet(object.AllAssetsUploaded)
        ? Boolean(object.AllAssetsUploaded)
        : false,
    };
  },

  toJSON(message: ReportProgress): unknown {
    const obj: any = {};
    message.PrimaryPanoramaUploaded !== undefined &&
      (obj.PrimaryPanoramaUploaded = message.PrimaryPanoramaUploaded);
    message.SecondaryPanoramaUploaded !== undefined &&
      (obj.SecondaryPanoramaUploaded = message.SecondaryPanoramaUploaded);
    message.AllTeethLocalizationsCompleted !== undefined &&
      (obj.AllTeethLocalizationsCompleted =
        message.AllTeethLocalizationsCompleted);
    message.AllConditionsCompleted !== undefined &&
      (obj.AllConditionsCompleted = message.AllConditionsCompleted);
    message.AllAssetsUploaded !== undefined &&
      (obj.AllAssetsUploaded = message.AllAssetsUploaded);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportProgress>, I>>(
    object: I,
  ): ReportProgress {
    const message = createBaseReportProgress();
    message.PrimaryPanoramaUploaded = object.PrimaryPanoramaUploaded ?? false;
    message.SecondaryPanoramaUploaded =
      object.SecondaryPanoramaUploaded ?? false;
    message.AllTeethLocalizationsCompleted =
      object.AllTeethLocalizationsCompleted ?? false;
    message.AllConditionsCompleted = object.AllConditionsCompleted ?? false;
    message.AllAssetsUploaded = object.AllAssetsUploaded ?? false;
    return message;
  },
};

function createBaseReportFailed(): ReportFailed {
  return { Message: '', Code: 0 };
}

export const ReportFailed = {
  encode(
    message: ReportFailed,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Message !== '') {
      writer.uint32(10).string(message.Message);
    }
    if (message.Code !== 0) {
      writer.uint32(16).int32(message.Code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportFailed {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportFailed();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Message = reader.string();
          break;
        case 2:
          message.Code = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportFailed {
    return {
      Message: isSet(object.Message) ? String(object.Message) : '',
      Code: isSet(object.Code)
        ? reportGenerationErrorCodeFromJSON(object.Code)
        : 0,
    };
  },

  toJSON(message: ReportFailed): unknown {
    const obj: any = {};
    message.Message !== undefined && (obj.Message = message.Message);
    message.Code !== undefined &&
      (obj.Code = reportGenerationErrorCodeToJSON(message.Code));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportFailed>, I>>(
    object: I,
  ): ReportFailed {
    const message = createBaseReportFailed();
    message.Message = object.Message ?? '';
    message.Code = object.Code ?? 0;
    return message;
  },
};

function createBaseReportSignature(): ReportSignature {
  return { Signed: false, UserSignatures: [] };
}

export const ReportSignature = {
  encode(
    message: ReportSignature,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Signed === true) {
      writer.uint32(8).bool(message.Signed);
    }
    for (const v of message.UserSignatures) {
      ReportUserSignature.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportSignature {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportSignature();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Signed = reader.bool();
          break;
        case 2:
          message.UserSignatures.push(
            ReportUserSignature.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportSignature {
    return {
      Signed: isSet(object.Signed) ? Boolean(object.Signed) : false,
      UserSignatures: Array.isArray(object?.UserSignatures)
        ? object.UserSignatures.map((e: any) => ReportUserSignature.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ReportSignature): unknown {
    const obj: any = {};
    message.Signed !== undefined && (obj.Signed = message.Signed);
    if (message.UserSignatures) {
      obj.UserSignatures = message.UserSignatures.map((e) =>
        e ? ReportUserSignature.toJSON(e) : undefined,
      );
    } else {
      obj.UserSignatures = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportSignature>, I>>(
    object: I,
  ): ReportSignature {
    const message = createBaseReportSignature();
    message.Signed = object.Signed ?? false;
    message.UserSignatures =
      object.UserSignatures?.map((e) => ReportUserSignature.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseReportUserSignature(): ReportUserSignature {
  return {
    UserID: '',
    SignedAt: undefined,
    SignatureAssetID: '',
    StampAssetID: '',
    UserFirstName: '',
    UserLastName: '',
    JobPositions: [],
  };
}

export const ReportUserSignature = {
  encode(
    message: ReportUserSignature,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.SignedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.SignedAt),
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.SignatureAssetID !== '') {
      writer.uint32(26).string(message.SignatureAssetID);
    }
    if (message.StampAssetID !== '') {
      writer.uint32(34).string(message.StampAssetID);
    }
    if (message.UserFirstName !== '') {
      writer.uint32(42).string(message.UserFirstName);
    }
    if (message.UserLastName !== '') {
      writer.uint32(50).string(message.UserLastName);
    }
    writer.uint32(58).fork();
    for (const v of message.JobPositions) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportUserSignature {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportUserSignature();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.SignedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        case 3:
          message.SignatureAssetID = reader.string();
          break;
        case 4:
          message.StampAssetID = reader.string();
          break;
        case 5:
          message.UserFirstName = reader.string();
          break;
        case 6:
          message.UserLastName = reader.string();
          break;
        case 7:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.JobPositions.push(reader.int32() as any);
            }
          } else {
            message.JobPositions.push(reader.int32() as any);
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportUserSignature {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      SignedAt: isSet(object.SignedAt)
        ? fromJsonTimestamp(object.SignedAt)
        : undefined,
      SignatureAssetID: isSet(object.SignatureAssetID)
        ? String(object.SignatureAssetID)
        : '',
      StampAssetID: isSet(object.StampAssetID)
        ? String(object.StampAssetID)
        : '',
      UserFirstName: isSet(object.UserFirstName)
        ? String(object.UserFirstName)
        : '',
      UserLastName: isSet(object.UserLastName)
        ? String(object.UserLastName)
        : '',
      JobPositions: Array.isArray(object?.JobPositions)
        ? object.JobPositions.map((e: any) =>
            organizationJobPositionFromJSON(e),
          )
        : [],
    };
  },

  toJSON(message: ReportUserSignature): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.SignedAt !== undefined &&
      (obj.SignedAt = message.SignedAt.toISOString());
    message.SignatureAssetID !== undefined &&
      (obj.SignatureAssetID = message.SignatureAssetID);
    message.StampAssetID !== undefined &&
      (obj.StampAssetID = message.StampAssetID);
    message.UserFirstName !== undefined &&
      (obj.UserFirstName = message.UserFirstName);
    message.UserLastName !== undefined &&
      (obj.UserLastName = message.UserLastName);
    if (message.JobPositions) {
      obj.JobPositions = message.JobPositions.map((e) =>
        organizationJobPositionToJSON(e),
      );
    } else {
      obj.JobPositions = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportUserSignature>, I>>(
    object: I,
  ): ReportUserSignature {
    const message = createBaseReportUserSignature();
    message.UserID = object.UserID ?? '';
    message.SignedAt = object.SignedAt ?? undefined;
    message.SignatureAssetID = object.SignatureAssetID ?? '';
    message.StampAssetID = object.StampAssetID ?? '';
    message.UserFirstName = object.UserFirstName ?? '';
    message.UserLastName = object.UserLastName ?? '';
    message.JobPositions = object.JobPositions?.map((e) => e) || [];
    return message;
  },
};

function createBaseReportSettings(): ReportSettings {
  return {
    LowProbabilityMode: false,
    ShowProblemAreas: false,
    ShowPanoramaSplitInsteadOfSingle: false,
    ShowSimpleConditionView: false,
  };
}

export const ReportSettings = {
  encode(
    message: ReportSettings,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.LowProbabilityMode === true) {
      writer.uint32(8).bool(message.LowProbabilityMode);
    }
    if (message.ShowProblemAreas === true) {
      writer.uint32(16).bool(message.ShowProblemAreas);
    }
    if (message.ShowPanoramaSplitInsteadOfSingle === true) {
      writer.uint32(32).bool(message.ShowPanoramaSplitInsteadOfSingle);
    }
    if (message.ShowSimpleConditionView === true) {
      writer.uint32(40).bool(message.ShowSimpleConditionView);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportSettings {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.LowProbabilityMode = reader.bool();
          break;
        case 2:
          message.ShowProblemAreas = reader.bool();
          break;
        case 4:
          message.ShowPanoramaSplitInsteadOfSingle = reader.bool();
          break;
        case 5:
          message.ShowSimpleConditionView = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportSettings {
    return {
      LowProbabilityMode: isSet(object.LowProbabilityMode)
        ? Boolean(object.LowProbabilityMode)
        : false,
      ShowProblemAreas: isSet(object.ShowProblemAreas)
        ? Boolean(object.ShowProblemAreas)
        : false,
      ShowPanoramaSplitInsteadOfSingle: isSet(
        object.ShowPanoramaSplitInsteadOfSingle,
      )
        ? Boolean(object.ShowPanoramaSplitInsteadOfSingle)
        : false,
      ShowSimpleConditionView: isSet(object.ShowSimpleConditionView)
        ? Boolean(object.ShowSimpleConditionView)
        : false,
    };
  },

  toJSON(message: ReportSettings): unknown {
    const obj: any = {};
    message.LowProbabilityMode !== undefined &&
      (obj.LowProbabilityMode = message.LowProbabilityMode);
    message.ShowProblemAreas !== undefined &&
      (obj.ShowProblemAreas = message.ShowProblemAreas);
    message.ShowPanoramaSplitInsteadOfSingle !== undefined &&
      (obj.ShowPanoramaSplitInsteadOfSingle =
        message.ShowPanoramaSplitInsteadOfSingle);
    message.ShowSimpleConditionView !== undefined &&
      (obj.ShowSimpleConditionView = message.ShowSimpleConditionView);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportSettings>, I>>(
    object: I,
  ): ReportSettings {
    const message = createBaseReportSettings();
    message.LowProbabilityMode = object.LowProbabilityMode ?? false;
    message.ShowProblemAreas = object.ShowProblemAreas ?? false;
    message.ShowPanoramaSplitInsteadOfSingle =
      object.ShowPanoramaSplitInsteadOfSingle ?? false;
    message.ShowSimpleConditionView = object.ShowSimpleConditionView ?? false;
    return message;
  },
};

function createBaseReportPrintSettings(): ReportPrintSettings {
  return {
    DescriptionLang: 0,
    PrintType: 0,
    ShowDiagnoses: false,
    ShowDiagnosesProbabilities: false,
    ShowDentalChart: false,
    ShowSlices: false,
    ShowTopJaw: false,
    ShowBottomJaw: false,
    ShowBBoxes: false,
    ShowReferralRecommendations: false,
    ShowOrthodonticScreening: false,
    ShowStudyMetadata: false,
    BlackAndWhiteMode: false,
    IsInverted: false,
  };
}

export const ReportPrintSettings = {
  encode(
    message: ReportPrintSettings,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DescriptionLang !== 0) {
      writer.uint32(8).int32(message.DescriptionLang);
    }
    if (message.PrintType !== 0) {
      writer.uint32(16).int32(message.PrintType);
    }
    if (message.ShowDiagnoses === true) {
      writer.uint32(24).bool(message.ShowDiagnoses);
    }
    if (message.ShowDiagnosesProbabilities === true) {
      writer.uint32(32).bool(message.ShowDiagnosesProbabilities);
    }
    if (message.ShowDentalChart === true) {
      writer.uint32(40).bool(message.ShowDentalChart);
    }
    if (message.ShowSlices === true) {
      writer.uint32(48).bool(message.ShowSlices);
    }
    if (message.ShowTopJaw === true) {
      writer.uint32(56).bool(message.ShowTopJaw);
    }
    if (message.ShowBottomJaw === true) {
      writer.uint32(64).bool(message.ShowBottomJaw);
    }
    if (message.ShowBBoxes === true) {
      writer.uint32(72).bool(message.ShowBBoxes);
    }
    if (message.ShowReferralRecommendations === true) {
      writer.uint32(80).bool(message.ShowReferralRecommendations);
    }
    if (message.ShowOrthodonticScreening === true) {
      writer.uint32(88).bool(message.ShowOrthodonticScreening);
    }
    if (message.ShowStudyMetadata === true) {
      writer.uint32(96).bool(message.ShowStudyMetadata);
    }
    if (message.BlackAndWhiteMode === true) {
      writer.uint32(104).bool(message.BlackAndWhiteMode);
    }
    if (message.IsInverted === true) {
      writer.uint32(112).bool(message.IsInverted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportPrintSettings {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportPrintSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DescriptionLang = reader.int32() as any;
          break;
        case 2:
          message.PrintType = reader.int32() as any;
          break;
        case 3:
          message.ShowDiagnoses = reader.bool();
          break;
        case 4:
          message.ShowDiagnosesProbabilities = reader.bool();
          break;
        case 5:
          message.ShowDentalChart = reader.bool();
          break;
        case 6:
          message.ShowSlices = reader.bool();
          break;
        case 7:
          message.ShowTopJaw = reader.bool();
          break;
        case 8:
          message.ShowBottomJaw = reader.bool();
          break;
        case 9:
          message.ShowBBoxes = reader.bool();
          break;
        case 10:
          message.ShowReferralRecommendations = reader.bool();
          break;
        case 11:
          message.ShowOrthodonticScreening = reader.bool();
          break;
        case 12:
          message.ShowStudyMetadata = reader.bool();
          break;
        case 13:
          message.BlackAndWhiteMode = reader.bool();
          break;
        case 14:
          message.IsInverted = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportPrintSettings {
    return {
      DescriptionLang: isSet(object.DescriptionLang)
        ? reportPrintSettingsDescriptionLangFromJSON(object.DescriptionLang)
        : 0,
      PrintType: isSet(object.PrintType)
        ? reportPrintSettingsPrintTypeFromJSON(object.PrintType)
        : 0,
      ShowDiagnoses: isSet(object.ShowDiagnoses)
        ? Boolean(object.ShowDiagnoses)
        : false,
      ShowDiagnosesProbabilities: isSet(object.ShowDiagnosesProbabilities)
        ? Boolean(object.ShowDiagnosesProbabilities)
        : false,
      ShowDentalChart: isSet(object.ShowDentalChart)
        ? Boolean(object.ShowDentalChart)
        : false,
      ShowSlices: isSet(object.ShowSlices) ? Boolean(object.ShowSlices) : false,
      ShowTopJaw: isSet(object.ShowTopJaw) ? Boolean(object.ShowTopJaw) : false,
      ShowBottomJaw: isSet(object.ShowBottomJaw)
        ? Boolean(object.ShowBottomJaw)
        : false,
      ShowBBoxes: isSet(object.ShowBBoxes) ? Boolean(object.ShowBBoxes) : false,
      ShowReferralRecommendations: isSet(object.ShowReferralRecommendations)
        ? Boolean(object.ShowReferralRecommendations)
        : false,
      ShowOrthodonticScreening: isSet(object.ShowOrthodonticScreening)
        ? Boolean(object.ShowOrthodonticScreening)
        : false,
      ShowStudyMetadata: isSet(object.ShowStudyMetadata)
        ? Boolean(object.ShowStudyMetadata)
        : false,
      BlackAndWhiteMode: isSet(object.BlackAndWhiteMode)
        ? Boolean(object.BlackAndWhiteMode)
        : false,
      IsInverted: isSet(object.IsInverted) ? Boolean(object.IsInverted) : false,
    };
  },

  toJSON(message: ReportPrintSettings): unknown {
    const obj: any = {};
    message.DescriptionLang !== undefined &&
      (obj.DescriptionLang = reportPrintSettingsDescriptionLangToJSON(
        message.DescriptionLang,
      ));
    message.PrintType !== undefined &&
      (obj.PrintType = reportPrintSettingsPrintTypeToJSON(message.PrintType));
    message.ShowDiagnoses !== undefined &&
      (obj.ShowDiagnoses = message.ShowDiagnoses);
    message.ShowDiagnosesProbabilities !== undefined &&
      (obj.ShowDiagnosesProbabilities = message.ShowDiagnosesProbabilities);
    message.ShowDentalChart !== undefined &&
      (obj.ShowDentalChart = message.ShowDentalChart);
    message.ShowSlices !== undefined && (obj.ShowSlices = message.ShowSlices);
    message.ShowTopJaw !== undefined && (obj.ShowTopJaw = message.ShowTopJaw);
    message.ShowBottomJaw !== undefined &&
      (obj.ShowBottomJaw = message.ShowBottomJaw);
    message.ShowBBoxes !== undefined && (obj.ShowBBoxes = message.ShowBBoxes);
    message.ShowReferralRecommendations !== undefined &&
      (obj.ShowReferralRecommendations = message.ShowReferralRecommendations);
    message.ShowOrthodonticScreening !== undefined &&
      (obj.ShowOrthodonticScreening = message.ShowOrthodonticScreening);
    message.ShowStudyMetadata !== undefined &&
      (obj.ShowStudyMetadata = message.ShowStudyMetadata);
    message.BlackAndWhiteMode !== undefined &&
      (obj.BlackAndWhiteMode = message.BlackAndWhiteMode);
    message.IsInverted !== undefined && (obj.IsInverted = message.IsInverted);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportPrintSettings>, I>>(
    object: I,
  ): ReportPrintSettings {
    const message = createBaseReportPrintSettings();
    message.DescriptionLang = object.DescriptionLang ?? 0;
    message.PrintType = object.PrintType ?? 0;
    message.ShowDiagnoses = object.ShowDiagnoses ?? false;
    message.ShowDiagnosesProbabilities =
      object.ShowDiagnosesProbabilities ?? false;
    message.ShowDentalChart = object.ShowDentalChart ?? false;
    message.ShowSlices = object.ShowSlices ?? false;
    message.ShowTopJaw = object.ShowTopJaw ?? false;
    message.ShowBottomJaw = object.ShowBottomJaw ?? false;
    message.ShowBBoxes = object.ShowBBoxes ?? false;
    message.ShowReferralRecommendations =
      object.ShowReferralRecommendations ?? false;
    message.ShowOrthodonticScreening = object.ShowOrthodonticScreening ?? false;
    message.ShowStudyMetadata = object.ShowStudyMetadata ?? false;
    message.BlackAndWhiteMode = object.BlackAndWhiteMode ?? false;
    message.IsInverted = object.IsInverted ?? false;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
