import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Button, Layout } from '@/shared/ui/';

import { Header } from '@/widgets/Header';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';

import styles from './ErrorPage.module.scss';

export const ErrorPage: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout.Content>
        <Layout.Main>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.logo} />
              {/* TODO: change to intl node after integration */}
              <p className={styles.text}>
                {formatMessage({
                  id: 'errorPage.somethingWentWrong',
                  defaultMessage: 'Opps. Something went wrong',
                })}
              </p>
              <Button
                variant="secondary"
                onClick={() => window.location.replace('/')}
                className={styles.button}
              >
                {formatMessage({
                  id: 'errorPage.goToHomePage',
                  defaultMessage: 'Go to home page',
                })}
              </Button>
            </div>
          </div>
        </Layout.Main>
      </Layout.Content>

      <GetFullAccessModal />
    </Layout>
  );
};
