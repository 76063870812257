import { Outlet, useParams } from 'react-router';

import { Layout } from '@/shared/ui';

import { useReportDataStream } from '@/features/reportStream';

import { Header } from '@/widgets/Header';

export const GPReportLayout = () => {
  const { reportID = '' } = useParams();

  useReportDataStream(reportID);

  return (
    <Layout footerWithSettings fullWidth>
      <Layout.Header>
        <Header fullWidth />
      </Layout.Header>
      <Outlet />
    </Layout>
  );
};
