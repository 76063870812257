import { useParams } from 'react-router';

import { useAppSelector } from '@/shared/hooks';

import {
  FMXNavigationMode,
  ReportLayout,
  reportsModel,
} from '@/entities/reports';
import { ConfirmModal } from '@/entities/modal';

import { useReportDataStream } from '@/features/reportStream';
import { MaskFilters } from '@/features/maskFilters';
import { ConditionMaskTooltip } from '@/features/conditionMaskTooltip';

import { Header } from '@/widgets/Header';
import { Conclusion } from '@/widgets/Conclusion';
import { AddConditionModal } from '@/widgets/AddConditionModal';
import { IOXRayReportRender } from '@/widgets/IOXRayReportRender';
import { ReportActions } from '@/widgets/ReportActions';
import { Report2DToothChart } from '@/widgets/Report2DToothChart';

import { IOXRayToothCardList } from '../IOXRayToothCardList/IOXRayToothCardList';
import { IOXRayNavigation } from '../IOXRayNavigation/IOXRayNavigation';
import { Toolbar } from '../Toolbar/Toolbar';
import { IOXRayImageSlider } from '../IOXrayImageSlider/IOXRayImageSlider';
import { IOXRayChangeTeethNumberModal } from '../IOXRayChangeTeethNumberModal/IOXRayChangeTeethNumberModal';

import styles from './IOXRayReport.module.scss';

export const IOXRayReport = () => {
  const { patientID, reportID } = useParams();

  // TODO: This should be placed on parent route for the report
  useReportDataStream(reportID as string);

  // I don't like it because this selector affects on every component render on the page
  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  return (
    <ReportLayout.Container>
      <Header fullWidth />
      <ReportLayout.Main>
        <ReportLayout.LeftColumn>
          <h1>IOXRay AI Report</h1>
          <IOXRayReportRender>
            <Toolbar className={styles.toolbar} />
            <MaskFilters className={styles.maskFilters} />
            <ConditionMaskTooltip />
            <IOXRayImageSlider />
          </IOXRayReportRender>
        </ReportLayout.LeftColumn>
        <ReportLayout.RightColumn>
          {navigationMode === FMXNavigationMode.ToothChart && (
            <Report2DToothChart />
          )}
          {navigationMode === FMXNavigationMode.MatrixView && (
            <IOXRayNavigation />
          )}

          <IOXRayToothCardList
            reportID={reportID as string}
            patientID={patientID as string}
          />

          <Conclusion />

          <ReportActions
            className={styles.reportActions}
            patientID={patientID as string}
            reportID={reportID as string}
          />
        </ReportLayout.RightColumn>
      </ReportLayout.Main>
      {/* Modals */}
      <ConfirmModal />
      <AddConditionModal />
      <IOXRayChangeTeethNumberModal />
    </ReportLayout.Container>
  );
};
