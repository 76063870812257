import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import { LoadingStateType, SliceName } from '@/shared/config';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { LogicalCondition } from '../config';

/* tooth conditions data structure example
const toothConditions = {
  'ToothID-0': {
    conditions: {
      1000: {
        'ContitionID-0': { ...condition },
        'ContitionID-1': { ...condition },
      },
      1001: {
        'ContitionID-2': { ...condition },
      },
    },
    childConditions: {
      'ParentConditionID-0': {
        5000: {
          'ContitionID-3': { ...condition },
          'ContitionID-4': { ...condition },
        },
      },
    },
  },
};
*/

type ToothID = string; // Condition['Tooth']['ToothID'];
type ConditionID = Condition['ID'];
type ParentID = Condition['ParentID'];

export type ToothConditionCollectionByID = Record<ConditionID, Condition>;
export type ToothConditionCollectionByCode = Record<
  ConditionCode,
  ToothConditionCollectionByID
>;
export type ChildToothConditionCollectionByParentID = Record<
  ParentID,
  ToothConditionCollectionByCode
>;

export type ToothCondition = {
  conditions: ToothConditionCollectionByCode;
  childConditions: ChildToothConditionCollectionByParentID;
};

export type ToothConditions = Record<ToothID, ToothCondition>;

// TODO: can't get child condition without parentID
type HoveredConditionLink = {
  toothID: ToothID | null;
  code: ConditionCode | null;
  parentID: ParentID | null;
};

type LogicalConditionState = {
  toothConditions: ToothConditions;
  nonToothConditions: Condition[];
  hoveredCondition?: LogicalCondition;
  hoveredConditionLink: HoveredConditionLink;
  loading: LoadingStateType;
};

const initialState: LogicalConditionState = {
  toothConditions: {} as ToothConditions,
  nonToothConditions: [],
  hoveredConditionLink: { toothID: null, code: null, parentID: null },
  loading: 'idle',
};

export const logicalConditionSlice = createSlice({
  name: SliceName.logicalCondition,
  initialState,
  reducers: {
    // To dispatch just once after all conditions will be received from stream
    initialize: (state, action: PayloadAction<ToothConditions>) => {
      state.toothConditions = action.payload;
    },
    initializeNonToothConditions: (
      state,
      action: PayloadAction<Condition[]>,
    ) => {
      state.nonToothConditions = action.payload;
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
    addOne: (state, action: PayloadAction<Condition>) => {
      const toothID = action.payload?.Tooth?.ToothID;
      const conditionCode = action.payload?.Code;
      const parentID = action.payload?.ParentID;

      // TODO: toothID shouldn't be undefined after migration on grpc connect
      if (toothID) {
        if (parentID) {
          if (parentID in state.toothConditions[toothID].childConditions) {
            if (
              conditionCode in
              state.toothConditions[toothID].childConditions[parentID]
            ) {
              state.toothConditions[toothID].childConditions[parentID][
                conditionCode
              ][action.payload.ID] = action.payload;
            } else {
              state.toothConditions[toothID].childConditions[parentID][
                conditionCode
              ] = {
                [action.payload.ID]: action.payload,
              };
            }
          } else {
            state.toothConditions[toothID].childConditions[parentID] = {
              [conditionCode]: {
                [action.payload.ID]: action.payload,
              },
            } as ToothConditionCollectionByCode;
          }
        } else if (conditionCode in state.toothConditions[toothID].conditions) {
          state.toothConditions[toothID].conditions[conditionCode][
            action.payload.ID
          ] = action.payload;
        } else {
          state.toothConditions[toothID].conditions[conditionCode] = {
            [action.payload.ID]: action.payload,
          };
        }
      }
    },
    updateOne: (state, action: PayloadAction<Condition>) => {
      const ID = action.payload?.ID;
      const toothID = action.payload?.Tooth?.ToothID;
      const parentID = action.payload?.ParentID;
      const conditionCode = action.payload?.Code;
      const isChildCondition = parentID && conditionCode >= 5000;

      if (toothID) {
        if (isChildCondition) {
          state.toothConditions[toothID].childConditions[parentID][
            conditionCode
          ][ID] = action.payload;
        } else {
          state.toothConditions[toothID].conditions[conditionCode][ID] =
            action.payload;
        }
      }
    },
    removeOne: (
      state,
      action: PayloadAction<{ toothID: ToothID; conditionCode: ConditionCode }>,
    ) => {
      const { toothID, conditionCode } = action.payload;

      if (conditionCode >= 5000) {
        delete state.toothConditions[toothID].childConditions[conditionCode];
      } else {
        delete state.toothConditions[toothID].conditions[conditionCode];
      }
    },
    setHoveredConditionLink: (
      state,
      action: PayloadAction<HoveredConditionLink>,
    ) => {
      state.hoveredConditionLink = action.payload;
    },
    reset: () => initialState,
  },
});

export const { actions } = logicalConditionSlice;

export default logicalConditionSlice.reducer;
