import { FC, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { useAppSelector } from '@/shared/hooks';
import { FMXRender } from '@/shared/graphics/viewer2D/Viewer2D';

import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  ImageSizeType,
  getMatrixMaxHeight,
  getMatrixMaxWidth,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';
import { getImageSrc } from '@/entities/assets';
import { scrollTop2DReportRightColunm } from '@/entities/reports';

import { getImageSizeDataByType } from '../..';

import styles from './IOXRayMatrixPreview.module.scss';

type IOXRayMatrixPreviewProps = {
  className?: string;
  testID?: string;
  onImageClick?: (imageMetaID: string) => void;
  focusedImageMetaID?: string;
  reportID: string;
};

const PREVIEW_IMAGE_SIZE: ImageSizeType = 'thumbnail';

export const IOXRayMatrixPreview: FC<IOXRayMatrixPreviewProps> = (props) => {
  const { className, testID, onImageClick, focusedImageMetaID, reportID } =
    props;

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface[];

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface,
  );

  const singleImage = IOXRayImagesInterface.length === 1;
  const isImagesClickable = typeof onImageClick === 'function' && !singleImage;

  const onImageClickHandle = (assetID: string) => {
    if (isImagesClickable) {
      onImageClick(assetID);
      scrollTop2DReportRightColunm();
      FMXRender.layoutModes.focus(assetID);
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const [{ contWidth, contHeight }, setContSize] = useState({
    contWidth: 0,
    contHeight: 0,
  });

  const calculateScale = useCallback(() => {
    const wrapperWidth = contWidth;
    const wrapperHeight = contHeight;

    if (!wrapperWidth && !wrapperHeight) return 0;

    const IOXRaysOriginalWidth = getMatrixMaxWidth(
      groupedIOXRayImages,
      PREVIEW_IMAGE_SIZE,
    );
    const IOXRaysOriginalHeight = getMatrixMaxHeight(
      groupedIOXRayImages,
      PREVIEW_IMAGE_SIZE,
    );

    const IOXRAY_MATRIX_PADDINGS = 44;

    const scaleByWidth =
      (wrapperWidth - IOXRAY_MATRIX_PADDINGS) / IOXRaysOriginalWidth;
    const scaleByHeight =
      (wrapperHeight - IOXRAY_MATRIX_PADDINGS) / IOXRaysOriginalHeight;

    const IOXRaysOriginalAspect = IOXRaysOriginalHeight / IOXRaysOriginalWidth;

    const wrapperAspect = wrapperHeight / wrapperWidth;

    if (IOXRaysOriginalAspect >= wrapperAspect) {
      return scaleByHeight;
    }

    return scaleByWidth;
  }, [groupedIOXRayImages, contHeight, contWidth]);

  const scale = calculateScale();

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, height } = entry.contentRect;

        setContSize({ contWidth: width, contHeight: height });
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  return (
    <div
      className={cn(styles.container, className)}
      ref={containerRef}
      data-test={testID}
    >
      {Object.entries(groupedIOXRayImages).map(
        ([group, IOXrayImagesInterfaceList]) => (
          <div className={styles[group]} key={group}>
            {IOXrayImagesInterfaceList?.map((imageItem) => {
              const imageMetaID = imageItem.imageMeta.ID;

              const imageSize = getImageSizeDataByType(
                imageItem,
                PREVIEW_IMAGE_SIZE,
              );

              const isRotated =
                imageItem.imageMeta.OrientationAngle === 90 ||
                imageItem.imageMeta.OrientationAngle === 270;

              const adjustedWidth = isRotated
                ? imageSize.height * scale
                : imageSize.width * scale;

              const adjustedHeight = isRotated
                ? imageSize.width * scale
                : imageSize.height * scale;

              return (
                <div
                  onClick={() => {
                    onImageClickHandle(imageMetaID);
                  }}
                  key={imageMetaID}
                  className={cn(
                    styles.image,
                    isImagesClickable && styles.clickable,
                    focusedImageMetaID === imageMetaID ? styles.focused : '',
                  )}
                  style={{
                    width: adjustedWidth,
                    height: adjustedHeight,
                  }}
                >
                  <img
                    src={getImageSrc(imageItem.asset.ID, PREVIEW_IMAGE_SIZE)}
                    style={{
                      width: imageSize.width * scale,
                      height: imageSize.height * scale,
                      transformOrigin: 'center center',
                      transform: `rotate(${imageItem.imageMeta.OrientationAngle * (Math.PI / 180)}rad)`,
                    }}
                  />
                </div>
              );
            })}
          </div>
        ),
      )}
    </div>
  );
};
