/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  CBCTSectionOrientation,
  File,
  DerivedData3DModel,
  DerivedDataImage,
  cBCTSectionOrientationFromJSON,
  cBCTSectionOrientationToJSON,
} from '../model/dto_asset_common';
import { Attribute, Created, Revision, Deleted } from '../model/dto_base';
import { AssetContentDocument } from '../model/dto_asset_document';
import { MedicalImageFeatures } from '../model/dto_common_image_medical_features';
import { EmptyPayload, ReportLegacyPayload } from '../model/dto_legacy';
import {
  AssetContentGeneratedCBCTGPPanoramaReformatSplit,
  AssetContentGeneratedCBCTGPPanoramaReformatGeneral,
  AssetContentGeneratedCBCTGPToothSlice,
  AssetContentGeneratedCBCTGPMPRSubVolume,
  AssetContentGeneratedCBCTGPPeriodontiumImage,
} from '../model/dto_asset_cbct_gp';
import {
  AssetContentGeneratedCBCTEndoPanoramaSplit,
  AssetContentGeneratedCBCTEndoCrossSection,
  AssetContentGeneratedCBCTEndoGuide,
} from '../model/dto_asset_cbct_endo';
import {
  AssetContentGeneratedCBCTImplantPanorama,
  AssetContentGeneratedCBCTImplantSlice,
} from '../model/dto_asset_cbct_implant';
import {
  AssetContentGeneratedCBCTMolarPanorama,
  AssetContentGeneratedCBCTMolarGuide,
  AssetContentGeneratedCBCTMolarCrossSection,
} from '../model/dto_asset_cbct_molar';
import { AssetContentGeneratedMeshModel } from '../model/dto_asset_mesh_model';
import {
  AssetContentGeneratedCBCTTile,
  AssetContentReportStudioApplicationStateSnapshot,
  AssetContentReportStudioGeometry,
} from '../model/dto_asset_segmentron_common';
import {
  AssetContentGeneratedStudioCBCTDownscaled,
  AssetContentGeneratedStudioPanoramaReformatGeneral,
} from '../model/dto_asset_studio_common';
import {
  AssetContentReportUserUploadedToothSlice,
  AssetContentReportUserUploadedMPRPlane,
} from '../model/dto_asset_report_uploaded';
import { ToothNumeration } from '../model/dto_report_common';
import { ClosedPolygon } from '../model/dto_common_geometry';

export const protobufPackage = 'com.diagnocat.model';

export enum AssetType {
  AssetType_InvalidValue = 0,
  AssetType_User_Avatar = 1,
  AssetType_User_Signature = 2,
  AssetType_Organization_Logo = 11,
  AssetType_Organization_Stamp = 12,
  AssetType_Organization_EmployeeStamp = 13,
  AssetType_Document = 21,
  AssetType_Study_CBCT = 31,
  /** AssetType_Study_IntraoralXRay - FMX */
  AssetType_Study_IntraoralXRay = 32,
  /** AssetType_Study_PanoramicXRay - PANORAMA */
  AssetType_Study_PanoramicXRay = 33,
  AssetType_Study_IOS_Meshes = 34,
  AssetType_Study_DentalPhoto = 35,
  AssetType_Report_Generated_IOXRayImage = 40,
  AssetType_Report_Generated_CBCT_ToothPathologySlice = 41,
  AssetType_Report_Generated_CBCT_GP_PanoramaReformatSplit = 51,
  AssetType_Report_Generated_CBCT_GP_PanoramaReformatGeneral = 52,
  AssetType_Report_Generated_CBCT_GP_ToothSlice = 53,
  AssetType_Report_Generated_CBCT_GP_MPRSubVolume = 54,
  AssetType_Report_Generated_CBCT_GP_PeriodontiumImage = 55,
  AssetType_Report_Generated_CBCT_Endo_PanoramaSplit = 61,
  AssetType_Report_Generated_CBCT_Endo_CrossSection = 62,
  AssetType_Report_Generated_CBCT_Endo_Guide = 63,
  AssetType_Report_Generated_CBCT_Implant_Panorama = 71,
  AssetType_Report_Generated_CBCT_Implant_Slice = 72,
  AssetType_Report_Generated_CBCT_Molar_Panorama = 81,
  AssetType_Report_Generated_CBCT_Molar_Guide = 82,
  AssetType_Report_Generated_CBCT_Molar_CrossSection = 83,
  AssetType_Report_Generated_CBCT_Ortho_PDF = 91,
  AssetType_Report_Generated_Mesh_Model = 111,
  AssetType_Report_Generated_CBCTTile = 112,
  AssetType_Report_Generated_Studio_CBCTDownscaled = 113,
  AssetType_Report_Generated_Studio_PanoramaReformatGeneral = 114,
  AssetType_Report_Uploaded_ToothSlice = 121,
  AssetType_Report_Uploaded_MPRPlane = 122,
  AssetType_Report_Uploaded_Studio_ApplicationStateSnapshot = 131,
  AssetType_Report_Uploaded_Studio_Geometry = 132,
  AssetType_Report_Uploaded_DisplayAsset = 133,
  /** AssetType_Report_Archive - only for legacy segmentation/superimposition zips */
  AssetType_Report_Archive = 10000,
  UNRECOGNIZED = -1,
}

export function assetTypeFromJSON(object: any): AssetType {
  switch (object) {
    case 0:
    case 'AssetType_InvalidValue':
      return AssetType.AssetType_InvalidValue;
    case 1:
    case 'AssetType_User_Avatar':
      return AssetType.AssetType_User_Avatar;
    case 2:
    case 'AssetType_User_Signature':
      return AssetType.AssetType_User_Signature;
    case 11:
    case 'AssetType_Organization_Logo':
      return AssetType.AssetType_Organization_Logo;
    case 12:
    case 'AssetType_Organization_Stamp':
      return AssetType.AssetType_Organization_Stamp;
    case 13:
    case 'AssetType_Organization_EmployeeStamp':
      return AssetType.AssetType_Organization_EmployeeStamp;
    case 21:
    case 'AssetType_Document':
      return AssetType.AssetType_Document;
    case 31:
    case 'AssetType_Study_CBCT':
      return AssetType.AssetType_Study_CBCT;
    case 32:
    case 'AssetType_Study_IntraoralXRay':
      return AssetType.AssetType_Study_IntraoralXRay;
    case 33:
    case 'AssetType_Study_PanoramicXRay':
      return AssetType.AssetType_Study_PanoramicXRay;
    case 34:
    case 'AssetType_Study_IOS_Meshes':
      return AssetType.AssetType_Study_IOS_Meshes;
    case 35:
    case 'AssetType_Study_DentalPhoto':
      return AssetType.AssetType_Study_DentalPhoto;
    case 40:
    case 'AssetType_Report_Generated_IOXRayImage':
      return AssetType.AssetType_Report_Generated_IOXRayImage;
    case 41:
    case 'AssetType_Report_Generated_CBCT_ToothPathologySlice':
      return AssetType.AssetType_Report_Generated_CBCT_ToothPathologySlice;
    case 51:
    case 'AssetType_Report_Generated_CBCT_GP_PanoramaReformatSplit':
      return AssetType.AssetType_Report_Generated_CBCT_GP_PanoramaReformatSplit;
    case 52:
    case 'AssetType_Report_Generated_CBCT_GP_PanoramaReformatGeneral':
      return AssetType.AssetType_Report_Generated_CBCT_GP_PanoramaReformatGeneral;
    case 53:
    case 'AssetType_Report_Generated_CBCT_GP_ToothSlice':
      return AssetType.AssetType_Report_Generated_CBCT_GP_ToothSlice;
    case 54:
    case 'AssetType_Report_Generated_CBCT_GP_MPRSubVolume':
      return AssetType.AssetType_Report_Generated_CBCT_GP_MPRSubVolume;
    case 55:
    case 'AssetType_Report_Generated_CBCT_GP_PeriodontiumImage':
      return AssetType.AssetType_Report_Generated_CBCT_GP_PeriodontiumImage;
    case 61:
    case 'AssetType_Report_Generated_CBCT_Endo_PanoramaSplit':
      return AssetType.AssetType_Report_Generated_CBCT_Endo_PanoramaSplit;
    case 62:
    case 'AssetType_Report_Generated_CBCT_Endo_CrossSection':
      return AssetType.AssetType_Report_Generated_CBCT_Endo_CrossSection;
    case 63:
    case 'AssetType_Report_Generated_CBCT_Endo_Guide':
      return AssetType.AssetType_Report_Generated_CBCT_Endo_Guide;
    case 71:
    case 'AssetType_Report_Generated_CBCT_Implant_Panorama':
      return AssetType.AssetType_Report_Generated_CBCT_Implant_Panorama;
    case 72:
    case 'AssetType_Report_Generated_CBCT_Implant_Slice':
      return AssetType.AssetType_Report_Generated_CBCT_Implant_Slice;
    case 81:
    case 'AssetType_Report_Generated_CBCT_Molar_Panorama':
      return AssetType.AssetType_Report_Generated_CBCT_Molar_Panorama;
    case 82:
    case 'AssetType_Report_Generated_CBCT_Molar_Guide':
      return AssetType.AssetType_Report_Generated_CBCT_Molar_Guide;
    case 83:
    case 'AssetType_Report_Generated_CBCT_Molar_CrossSection':
      return AssetType.AssetType_Report_Generated_CBCT_Molar_CrossSection;
    case 91:
    case 'AssetType_Report_Generated_CBCT_Ortho_PDF':
      return AssetType.AssetType_Report_Generated_CBCT_Ortho_PDF;
    case 111:
    case 'AssetType_Report_Generated_Mesh_Model':
      return AssetType.AssetType_Report_Generated_Mesh_Model;
    case 112:
    case 'AssetType_Report_Generated_CBCTTile':
      return AssetType.AssetType_Report_Generated_CBCTTile;
    case 113:
    case 'AssetType_Report_Generated_Studio_CBCTDownscaled':
      return AssetType.AssetType_Report_Generated_Studio_CBCTDownscaled;
    case 114:
    case 'AssetType_Report_Generated_Studio_PanoramaReformatGeneral':
      return AssetType.AssetType_Report_Generated_Studio_PanoramaReformatGeneral;
    case 121:
    case 'AssetType_Report_Uploaded_ToothSlice':
      return AssetType.AssetType_Report_Uploaded_ToothSlice;
    case 122:
    case 'AssetType_Report_Uploaded_MPRPlane':
      return AssetType.AssetType_Report_Uploaded_MPRPlane;
    case 131:
    case 'AssetType_Report_Uploaded_Studio_ApplicationStateSnapshot':
      return AssetType.AssetType_Report_Uploaded_Studio_ApplicationStateSnapshot;
    case 132:
    case 'AssetType_Report_Uploaded_Studio_Geometry':
      return AssetType.AssetType_Report_Uploaded_Studio_Geometry;
    case 133:
    case 'AssetType_Report_Uploaded_DisplayAsset':
      return AssetType.AssetType_Report_Uploaded_DisplayAsset;
    case 10000:
    case 'AssetType_Report_Archive':
      return AssetType.AssetType_Report_Archive;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AssetType.UNRECOGNIZED;
  }
}

export function assetTypeToJSON(object: AssetType): string {
  switch (object) {
    case AssetType.AssetType_InvalidValue:
      return 'AssetType_InvalidValue';
    case AssetType.AssetType_User_Avatar:
      return 'AssetType_User_Avatar';
    case AssetType.AssetType_User_Signature:
      return 'AssetType_User_Signature';
    case AssetType.AssetType_Organization_Logo:
      return 'AssetType_Organization_Logo';
    case AssetType.AssetType_Organization_Stamp:
      return 'AssetType_Organization_Stamp';
    case AssetType.AssetType_Organization_EmployeeStamp:
      return 'AssetType_Organization_EmployeeStamp';
    case AssetType.AssetType_Document:
      return 'AssetType_Document';
    case AssetType.AssetType_Study_CBCT:
      return 'AssetType_Study_CBCT';
    case AssetType.AssetType_Study_IntraoralXRay:
      return 'AssetType_Study_IntraoralXRay';
    case AssetType.AssetType_Study_PanoramicXRay:
      return 'AssetType_Study_PanoramicXRay';
    case AssetType.AssetType_Study_IOS_Meshes:
      return 'AssetType_Study_IOS_Meshes';
    case AssetType.AssetType_Study_DentalPhoto:
      return 'AssetType_Study_DentalPhoto';
    case AssetType.AssetType_Report_Generated_IOXRayImage:
      return 'AssetType_Report_Generated_IOXRayImage';
    case AssetType.AssetType_Report_Generated_CBCT_ToothPathologySlice:
      return 'AssetType_Report_Generated_CBCT_ToothPathologySlice';
    case AssetType.AssetType_Report_Generated_CBCT_GP_PanoramaReformatSplit:
      return 'AssetType_Report_Generated_CBCT_GP_PanoramaReformatSplit';
    case AssetType.AssetType_Report_Generated_CBCT_GP_PanoramaReformatGeneral:
      return 'AssetType_Report_Generated_CBCT_GP_PanoramaReformatGeneral';
    case AssetType.AssetType_Report_Generated_CBCT_GP_ToothSlice:
      return 'AssetType_Report_Generated_CBCT_GP_ToothSlice';
    case AssetType.AssetType_Report_Generated_CBCT_GP_MPRSubVolume:
      return 'AssetType_Report_Generated_CBCT_GP_MPRSubVolume';
    case AssetType.AssetType_Report_Generated_CBCT_GP_PeriodontiumImage:
      return 'AssetType_Report_Generated_CBCT_GP_PeriodontiumImage';
    case AssetType.AssetType_Report_Generated_CBCT_Endo_PanoramaSplit:
      return 'AssetType_Report_Generated_CBCT_Endo_PanoramaSplit';
    case AssetType.AssetType_Report_Generated_CBCT_Endo_CrossSection:
      return 'AssetType_Report_Generated_CBCT_Endo_CrossSection';
    case AssetType.AssetType_Report_Generated_CBCT_Endo_Guide:
      return 'AssetType_Report_Generated_CBCT_Endo_Guide';
    case AssetType.AssetType_Report_Generated_CBCT_Implant_Panorama:
      return 'AssetType_Report_Generated_CBCT_Implant_Panorama';
    case AssetType.AssetType_Report_Generated_CBCT_Implant_Slice:
      return 'AssetType_Report_Generated_CBCT_Implant_Slice';
    case AssetType.AssetType_Report_Generated_CBCT_Molar_Panorama:
      return 'AssetType_Report_Generated_CBCT_Molar_Panorama';
    case AssetType.AssetType_Report_Generated_CBCT_Molar_Guide:
      return 'AssetType_Report_Generated_CBCT_Molar_Guide';
    case AssetType.AssetType_Report_Generated_CBCT_Molar_CrossSection:
      return 'AssetType_Report_Generated_CBCT_Molar_CrossSection';
    case AssetType.AssetType_Report_Generated_CBCT_Ortho_PDF:
      return 'AssetType_Report_Generated_CBCT_Ortho_PDF';
    case AssetType.AssetType_Report_Generated_Mesh_Model:
      return 'AssetType_Report_Generated_Mesh_Model';
    case AssetType.AssetType_Report_Generated_CBCTTile:
      return 'AssetType_Report_Generated_CBCTTile';
    case AssetType.AssetType_Report_Generated_Studio_CBCTDownscaled:
      return 'AssetType_Report_Generated_Studio_CBCTDownscaled';
    case AssetType.AssetType_Report_Generated_Studio_PanoramaReformatGeneral:
      return 'AssetType_Report_Generated_Studio_PanoramaReformatGeneral';
    case AssetType.AssetType_Report_Uploaded_ToothSlice:
      return 'AssetType_Report_Uploaded_ToothSlice';
    case AssetType.AssetType_Report_Uploaded_MPRPlane:
      return 'AssetType_Report_Uploaded_MPRPlane';
    case AssetType.AssetType_Report_Uploaded_Studio_ApplicationStateSnapshot:
      return 'AssetType_Report_Uploaded_Studio_ApplicationStateSnapshot';
    case AssetType.AssetType_Report_Uploaded_Studio_Geometry:
      return 'AssetType_Report_Uploaded_Studio_Geometry';
    case AssetType.AssetType_Report_Uploaded_DisplayAsset:
      return 'AssetType_Report_Uploaded_DisplayAsset';
    case AssetType.AssetType_Report_Archive:
      return 'AssetType_Report_Archive';
    case AssetType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * Asset is a group of logically connected `Files`
 * no `File` could exists without `Asset`
 */
export interface Asset {
  ID: string;
  UploadSessionIDs: string[];
  Type: AssetType;
  /** Uploaded from client */
  User: UserAsset | undefined;
  /** Uploaded from client */
  Organization: OrganizationAsset | undefined;
  /** Uploaded from client */
  Patient: PatientAsset | undefined;
  /** Uploaded from client */
  Study: StudyAsset | undefined;
  /** Generated and uploaded by pipelines */
  GeneratedReport: GeneratedReportAsset | undefined;
  /** Uploaded by user */
  Report: ReportAsset | undefined;
  Attributes: Attribute[];
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
  /** removal information */
  Deleted: Deleted | undefined;
}

export interface UserAsset {
  UserID: string;
  Avatar: AssetContentSimpleImage | undefined;
  Signature: AssetContentSimpleImage | undefined;
}

export interface OrganizationAsset {
  OrganizationID: string;
  /** only for EmployeeStamp */
  UserID: string;
  Logo: AssetContentSimpleImage | undefined;
  Stamp: AssetContentSimpleImage | undefined;
  EmployeeStamp: AssetContentSimpleImage | undefined;
}

export interface PatientAsset {
  OrganizationID: string;
  PatientID: string;
  Document: AssetContentDocument | undefined;
}

export interface StudyAsset {
  StudyID: string;
  PatientID: string;
  OrganizationID: string;
  CBCT: AssetContentStudyCBCT | undefined;
  PanoramicXRay: AssetContentStudyPanoramicXRay | undefined;
  IntraoralXRay: AssetContentStudyIntraoralXRay | undefined;
  IOSMeshes: AssetContentStudyIOSMeshes | undefined;
  DentalPhoto: AssetContentStudyDentalPhoto | undefined;
}

export interface GeneratedReportAsset {
  Belonging: ReportAssetBelonging | undefined;
  /** Optional. Applies only to medical image assets. */
  MedicalImageFeatures: MedicalImageFeatures | undefined;
  /** Variant signalling empty payload, for cases where for some LegacyPayload no Payload is emitted. */
  LegacyPayloadOnly: EmptyPayload | undefined;
  IOXRayImage: AssetContentGeneratedIOXRayImage | undefined;
  CBCTGPPanoramaReformatSplit:
    | AssetContentGeneratedCBCTGPPanoramaReformatSplit
    | undefined;
  CBCTGPPanoramaReformatGeneral:
    | AssetContentGeneratedCBCTGPPanoramaReformatGeneral
    | undefined;
  CBCTGPToothSlice: AssetContentGeneratedCBCTGPToothSlice | undefined;
  CBCTGPMPRSubVolume: AssetContentGeneratedCBCTGPMPRSubVolume | undefined;
  CBCTGPPeriodontiumImage:
    | AssetContentGeneratedCBCTGPPeriodontiumImage
    | undefined;
  CBCTEndoPanoramaSplit: AssetContentGeneratedCBCTEndoPanoramaSplit | undefined;
  CBCTEndoCrossSection: AssetContentGeneratedCBCTEndoCrossSection | undefined;
  CBCTEndoGuide: AssetContentGeneratedCBCTEndoGuide | undefined;
  CBCTImplantPanorama: AssetContentGeneratedCBCTImplantPanorama | undefined;
  CBCTImplantSlice: AssetContentGeneratedCBCTImplantSlice | undefined;
  CBCTMolarPanorama: AssetContentGeneratedCBCTMolarPanorama | undefined;
  CBCTMolarGuide: AssetContentGeneratedCBCTMolarGuide | undefined;
  CBCTMolarCrossSection: AssetContentGeneratedCBCTMolarCrossSection | undefined;
  CBCTOrthoPDF: AssetContentGeneratedCBCTOrthoPDF | undefined;
  MeshModel: AssetContentGeneratedMeshModel | undefined;
  CBCTToothPathologySlice:
    | AssetContentGeneratedCBCTToothPathologySlice
    | undefined;
  CBCTTile: AssetContentGeneratedCBCTTile | undefined;
  StudioCBCTDownscaled: AssetContentGeneratedStudioCBCTDownscaled | undefined;
  StudioPanoramaReformatGeneral:
    | AssetContentGeneratedStudioPanoramaReformatGeneral
    | undefined;
  LegacyPayloads: ReportLegacyPayload[];
}

export interface ReportAsset {
  Belonging: ReportAssetBelonging | undefined;
  /** Optional. Applies only to medical image assets. */
  MedicalImageFeatures: MedicalImageFeatures | undefined;
  UserUploadedToothSlice: AssetContentReportUserUploadedToothSlice | undefined;
  UserUploadedMPRPlane: AssetContentReportUserUploadedMPRPlane | undefined;
  StudioApplicationStateSnapshot:
    | AssetContentReportStudioApplicationStateSnapshot
    | undefined;
  StudioGeometry: AssetContentReportStudioGeometry | undefined;
  DisplayAsset: AssetContentSimpleImage | undefined;
}

export interface ReportAssetBelonging {
  ReportID: string;
  /** not provided by pipelines */
  SourceStudyIDs: string[];
  /** not provided by pipelines */
  PatientID: string;
  /** not provided by pipelines */
  OrganizationID: string;
  /** not provided by pipelines */
  ToothID: string;
}

export interface AssetContentStudyCBCT {
  DICOMFiles: File[];
  /** only for old data */
  CBCTArchive: File | undefined;
  Metadata: AssetContentStudyCBCT_CBCTMetadata | undefined;
  SeriesUID: string;
}

export interface AssetContentStudyCBCT_CBCTMetadata {
  StudyTime: number;
  InternalStudyUID: string;
  InternalSeriesUID: string;
  InternalInstanceUID: string;
  ScanUID: string;
  Manufacturer: string;
  ManufacturerModelName: string;
  SliceThickness: string;
  FOV: string;
  VoxelSize: number[];
  Exposure: string;
}

export interface AssetContentStudyIOSMeshes {
  Jaws: File[];
  /** Not used in pipelines */
  Derived: DerivedData3DModel | undefined;
}

export interface AssetContentStudyPanoramicXRay {
  PanoramaImage: File | undefined;
  /** Not used in pipelines */
  Derived: DerivedDataImage | undefined;
}

export interface AssetContentStudyIntraoralXRay {
  Image: File | undefined;
  /** TODO Metadata = 3; */
  Derived: DerivedDataImage | undefined;
}

export interface AssetContentStudyDentalPhoto {
  Image: File | undefined;
  /** Not used in pipelines */
  Derived: DerivedDataImage | undefined;
}

/**
 * //////////////////////////////////////////////////////////////
 * // ToothPathologySliceImage
 * //
 */
export interface AssetContentGeneratedCBCTToothPathologySlice {
  Slice: File | undefined;
  Orientation: CBCTSectionOrientation;
  Numeration: ToothNumeration | undefined;
  Pathology: AssetContentGeneratedCBCTToothPathologySlice_PathologyType;
  PathologyComponent: number;
  Mask: ClosedPolygon | undefined;
  Derived: DerivedDataImage | undefined;
}

export enum AssetContentGeneratedCBCTToothPathologySlice_PathologyType {
  Caries = 0,
  PeriapicalLesion = 1,
  UNRECOGNIZED = -1,
}

export function assetContentGeneratedCBCTToothPathologySlice_PathologyTypeFromJSON(
  object: any,
): AssetContentGeneratedCBCTToothPathologySlice_PathologyType {
  switch (object) {
    case 0:
    case 'Caries':
      return AssetContentGeneratedCBCTToothPathologySlice_PathologyType.Caries;
    case 1:
    case 'PeriapicalLesion':
      return AssetContentGeneratedCBCTToothPathologySlice_PathologyType.PeriapicalLesion;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AssetContentGeneratedCBCTToothPathologySlice_PathologyType.UNRECOGNIZED;
  }
}

export function assetContentGeneratedCBCTToothPathologySlice_PathologyTypeToJSON(
  object: AssetContentGeneratedCBCTToothPathologySlice_PathologyType,
): string {
  switch (object) {
    case AssetContentGeneratedCBCTToothPathologySlice_PathologyType.Caries:
      return 'Caries';
    case AssetContentGeneratedCBCTToothPathologySlice_PathologyType.PeriapicalLesion:
      return 'PeriapicalLesion';
    case AssetContentGeneratedCBCTToothPathologySlice_PathologyType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

/**
 * //////////////////////////////////////////////////////////////
 * // Primary Assets
 * //
 */
export interface AssetContentSimpleImage {
  Original: File | undefined;
  /** Not used in pipelines */
  Derived: DerivedDataImage | undefined;
}

export interface AssetContentGeneratedCBCTOrthoPDF {
  PDF: File | undefined;
}

export interface AssetContentGeneratedIOXRayImage {
  Image: File | undefined;
  Derived: DerivedDataImage | undefined;
}

function createBaseAsset(): Asset {
  return {
    ID: '',
    UploadSessionIDs: [],
    Type: 0,
    User: undefined,
    Organization: undefined,
    Patient: undefined,
    Study: undefined,
    GeneratedReport: undefined,
    Report: undefined,
    Attributes: [],
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
  };
}

export const Asset = {
  encode(message: Asset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    for (const v of message.UploadSessionIDs) {
      writer.uint32(18).string(v!);
    }
    if (message.Type !== 0) {
      writer.uint32(24).int32(message.Type);
    }
    if (message.User !== undefined) {
      UserAsset.encode(message.User, writer.uint32(810).fork()).ldelim();
    }
    if (message.Organization !== undefined) {
      OrganizationAsset.encode(
        message.Organization,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.Patient !== undefined) {
      PatientAsset.encode(message.Patient, writer.uint32(826).fork()).ldelim();
    }
    if (message.Study !== undefined) {
      StudyAsset.encode(message.Study, writer.uint32(834).fork()).ldelim();
    }
    if (message.GeneratedReport !== undefined) {
      GeneratedReportAsset.encode(
        message.GeneratedReport,
        writer.uint32(842).fork(),
      ).ldelim();
    }
    if (message.Report !== undefined) {
      ReportAsset.encode(message.Report, writer.uint32(850).fork()).ldelim();
    }
    for (const v of message.Attributes) {
      Attribute.encode(v!, writer.uint32(8002).fork()).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Deleted.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Asset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.UploadSessionIDs.push(reader.string());
          break;
        case 3:
          message.Type = reader.int32() as any;
          break;
        case 101:
          message.User = UserAsset.decode(reader, reader.uint32());
          break;
        case 102:
          message.Organization = OrganizationAsset.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 103:
          message.Patient = PatientAsset.decode(reader, reader.uint32());
          break;
        case 104:
          message.Study = StudyAsset.decode(reader, reader.uint32());
          break;
        case 105:
          message.GeneratedReport = GeneratedReportAsset.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 106:
          message.Report = ReportAsset.decode(reader, reader.uint32());
          break;
        case 1000:
          message.Attributes.push(Attribute.decode(reader, reader.uint32()));
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = Deleted.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Asset {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      UploadSessionIDs: Array.isArray(object?.UploadSessionIDs)
        ? object.UploadSessionIDs.map((e: any) => String(e))
        : [],
      Type: isSet(object.Type) ? assetTypeFromJSON(object.Type) : 0,
      User: isSet(object.User) ? UserAsset.fromJSON(object.User) : undefined,
      Organization: isSet(object.Organization)
        ? OrganizationAsset.fromJSON(object.Organization)
        : undefined,
      Patient: isSet(object.Patient)
        ? PatientAsset.fromJSON(object.Patient)
        : undefined,
      Study: isSet(object.Study)
        ? StudyAsset.fromJSON(object.Study)
        : undefined,
      GeneratedReport: isSet(object.GeneratedReport)
        ? GeneratedReportAsset.fromJSON(object.GeneratedReport)
        : undefined,
      Report: isSet(object.Report)
        ? ReportAsset.fromJSON(object.Report)
        : undefined,
      Attributes: Array.isArray(object?.Attributes)
        ? object.Attributes.map((e: any) => Attribute.fromJSON(e))
        : [],
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Deleted.fromJSON(object.Deleted)
        : undefined,
    };
  },

  toJSON(message: Asset): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    if (message.UploadSessionIDs) {
      obj.UploadSessionIDs = message.UploadSessionIDs.map((e) => e);
    } else {
      obj.UploadSessionIDs = [];
    }
    message.Type !== undefined && (obj.Type = assetTypeToJSON(message.Type));
    message.User !== undefined &&
      (obj.User = message.User ? UserAsset.toJSON(message.User) : undefined);
    message.Organization !== undefined &&
      (obj.Organization = message.Organization
        ? OrganizationAsset.toJSON(message.Organization)
        : undefined);
    message.Patient !== undefined &&
      (obj.Patient = message.Patient
        ? PatientAsset.toJSON(message.Patient)
        : undefined);
    message.Study !== undefined &&
      (obj.Study = message.Study
        ? StudyAsset.toJSON(message.Study)
        : undefined);
    message.GeneratedReport !== undefined &&
      (obj.GeneratedReport = message.GeneratedReport
        ? GeneratedReportAsset.toJSON(message.GeneratedReport)
        : undefined);
    message.Report !== undefined &&
      (obj.Report = message.Report
        ? ReportAsset.toJSON(message.Report)
        : undefined);
    if (message.Attributes) {
      obj.Attributes = message.Attributes.map((e) =>
        e ? Attribute.toJSON(e) : undefined,
      );
    } else {
      obj.Attributes = [];
    }
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Deleted.toJSON(message.Deleted)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Asset>, I>>(object: I): Asset {
    const message = createBaseAsset();
    message.ID = object.ID ?? '';
    message.UploadSessionIDs = object.UploadSessionIDs?.map((e) => e) || [];
    message.Type = object.Type ?? 0;
    message.User =
      object.User !== undefined && object.User !== null
        ? UserAsset.fromPartial(object.User)
        : undefined;
    message.Organization =
      object.Organization !== undefined && object.Organization !== null
        ? OrganizationAsset.fromPartial(object.Organization)
        : undefined;
    message.Patient =
      object.Patient !== undefined && object.Patient !== null
        ? PatientAsset.fromPartial(object.Patient)
        : undefined;
    message.Study =
      object.Study !== undefined && object.Study !== null
        ? StudyAsset.fromPartial(object.Study)
        : undefined;
    message.GeneratedReport =
      object.GeneratedReport !== undefined && object.GeneratedReport !== null
        ? GeneratedReportAsset.fromPartial(object.GeneratedReport)
        : undefined;
    message.Report =
      object.Report !== undefined && object.Report !== null
        ? ReportAsset.fromPartial(object.Report)
        : undefined;
    message.Attributes =
      object.Attributes?.map((e) => Attribute.fromPartial(e)) || [];
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Deleted.fromPartial(object.Deleted)
        : undefined;
    return message;
  },
};

function createBaseUserAsset(): UserAsset {
  return { UserID: '', Avatar: undefined, Signature: undefined };
}

export const UserAsset = {
  encode(
    message: UserAsset,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.Avatar !== undefined) {
      AssetContentSimpleImage.encode(
        message.Avatar,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.Signature !== undefined) {
      AssetContentSimpleImage.encode(
        message.Signature,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserAsset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 101:
          message.Avatar = AssetContentSimpleImage.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 102:
          message.Signature = AssetContentSimpleImage.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UserAsset {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      Avatar: isSet(object.Avatar)
        ? AssetContentSimpleImage.fromJSON(object.Avatar)
        : undefined,
      Signature: isSet(object.Signature)
        ? AssetContentSimpleImage.fromJSON(object.Signature)
        : undefined,
    };
  },

  toJSON(message: UserAsset): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.Avatar !== undefined &&
      (obj.Avatar = message.Avatar
        ? AssetContentSimpleImage.toJSON(message.Avatar)
        : undefined);
    message.Signature !== undefined &&
      (obj.Signature = message.Signature
        ? AssetContentSimpleImage.toJSON(message.Signature)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<UserAsset>, I>>(
    object: I,
  ): UserAsset {
    const message = createBaseUserAsset();
    message.UserID = object.UserID ?? '';
    message.Avatar =
      object.Avatar !== undefined && object.Avatar !== null
        ? AssetContentSimpleImage.fromPartial(object.Avatar)
        : undefined;
    message.Signature =
      object.Signature !== undefined && object.Signature !== null
        ? AssetContentSimpleImage.fromPartial(object.Signature)
        : undefined;
    return message;
  },
};

function createBaseOrganizationAsset(): OrganizationAsset {
  return {
    OrganizationID: '',
    UserID: '',
    Logo: undefined,
    Stamp: undefined,
    EmployeeStamp: undefined,
  };
}

export const OrganizationAsset = {
  encode(
    message: OrganizationAsset,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.UserID !== '') {
      writer.uint32(18).string(message.UserID);
    }
    if (message.Logo !== undefined) {
      AssetContentSimpleImage.encode(
        message.Logo,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.Stamp !== undefined) {
      AssetContentSimpleImage.encode(
        message.Stamp,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.EmployeeStamp !== undefined) {
      AssetContentSimpleImage.encode(
        message.EmployeeStamp,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrganizationAsset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.UserID = reader.string();
          break;
        case 101:
          message.Logo = AssetContentSimpleImage.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 102:
          message.Stamp = AssetContentSimpleImage.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 103:
          message.EmployeeStamp = AssetContentSimpleImage.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationAsset {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      Logo: isSet(object.Logo)
        ? AssetContentSimpleImage.fromJSON(object.Logo)
        : undefined,
      Stamp: isSet(object.Stamp)
        ? AssetContentSimpleImage.fromJSON(object.Stamp)
        : undefined,
      EmployeeStamp: isSet(object.EmployeeStamp)
        ? AssetContentSimpleImage.fromJSON(object.EmployeeStamp)
        : undefined,
    };
  },

  toJSON(message: OrganizationAsset): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.Logo !== undefined &&
      (obj.Logo = message.Logo
        ? AssetContentSimpleImage.toJSON(message.Logo)
        : undefined);
    message.Stamp !== undefined &&
      (obj.Stamp = message.Stamp
        ? AssetContentSimpleImage.toJSON(message.Stamp)
        : undefined);
    message.EmployeeStamp !== undefined &&
      (obj.EmployeeStamp = message.EmployeeStamp
        ? AssetContentSimpleImage.toJSON(message.EmployeeStamp)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationAsset>, I>>(
    object: I,
  ): OrganizationAsset {
    const message = createBaseOrganizationAsset();
    message.OrganizationID = object.OrganizationID ?? '';
    message.UserID = object.UserID ?? '';
    message.Logo =
      object.Logo !== undefined && object.Logo !== null
        ? AssetContentSimpleImage.fromPartial(object.Logo)
        : undefined;
    message.Stamp =
      object.Stamp !== undefined && object.Stamp !== null
        ? AssetContentSimpleImage.fromPartial(object.Stamp)
        : undefined;
    message.EmployeeStamp =
      object.EmployeeStamp !== undefined && object.EmployeeStamp !== null
        ? AssetContentSimpleImage.fromPartial(object.EmployeeStamp)
        : undefined;
    return message;
  },
};

function createBasePatientAsset(): PatientAsset {
  return { OrganizationID: '', PatientID: '', Document: undefined };
}

export const PatientAsset = {
  encode(
    message: PatientAsset,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.OrganizationID !== '') {
      writer.uint32(10).string(message.OrganizationID);
    }
    if (message.PatientID !== '') {
      writer.uint32(18).string(message.PatientID);
    }
    if (message.Document !== undefined) {
      AssetContentDocument.encode(
        message.Document,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PatientAsset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePatientAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.OrganizationID = reader.string();
          break;
        case 2:
          message.PatientID = reader.string();
          break;
        case 101:
          message.Document = AssetContentDocument.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): PatientAsset {
    return {
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      Document: isSet(object.Document)
        ? AssetContentDocument.fromJSON(object.Document)
        : undefined,
    };
  },

  toJSON(message: PatientAsset): unknown {
    const obj: any = {};
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.Document !== undefined &&
      (obj.Document = message.Document
        ? AssetContentDocument.toJSON(message.Document)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<PatientAsset>, I>>(
    object: I,
  ): PatientAsset {
    const message = createBasePatientAsset();
    message.OrganizationID = object.OrganizationID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.Document =
      object.Document !== undefined && object.Document !== null
        ? AssetContentDocument.fromPartial(object.Document)
        : undefined;
    return message;
  },
};

function createBaseStudyAsset(): StudyAsset {
  return {
    StudyID: '',
    PatientID: '',
    OrganizationID: '',
    CBCT: undefined,
    PanoramicXRay: undefined,
    IntraoralXRay: undefined,
    IOSMeshes: undefined,
    DentalPhoto: undefined,
  };
}

export const StudyAsset = {
  encode(
    message: StudyAsset,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.StudyID !== '') {
      writer.uint32(10).string(message.StudyID);
    }
    if (message.PatientID !== '') {
      writer.uint32(18).string(message.PatientID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(26).string(message.OrganizationID);
    }
    if (message.CBCT !== undefined) {
      AssetContentStudyCBCT.encode(
        message.CBCT,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.PanoramicXRay !== undefined) {
      AssetContentStudyPanoramicXRay.encode(
        message.PanoramicXRay,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.IntraoralXRay !== undefined) {
      AssetContentStudyIntraoralXRay.encode(
        message.IntraoralXRay,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    if (message.IOSMeshes !== undefined) {
      AssetContentStudyIOSMeshes.encode(
        message.IOSMeshes,
        writer.uint32(834).fork(),
      ).ldelim();
    }
    if (message.DentalPhoto !== undefined) {
      AssetContentStudyDentalPhoto.encode(
        message.DentalPhoto,
        writer.uint32(842).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StudyAsset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStudyAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.StudyID = reader.string();
          break;
        case 2:
          message.PatientID = reader.string();
          break;
        case 3:
          message.OrganizationID = reader.string();
          break;
        case 101:
          message.CBCT = AssetContentStudyCBCT.decode(reader, reader.uint32());
          break;
        case 102:
          message.PanoramicXRay = AssetContentStudyPanoramicXRay.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 103:
          message.IntraoralXRay = AssetContentStudyIntraoralXRay.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 104:
          message.IOSMeshes = AssetContentStudyIOSMeshes.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 105:
          message.DentalPhoto = AssetContentStudyDentalPhoto.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): StudyAsset {
    return {
      StudyID: isSet(object.StudyID) ? String(object.StudyID) : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      CBCT: isSet(object.CBCT)
        ? AssetContentStudyCBCT.fromJSON(object.CBCT)
        : undefined,
      PanoramicXRay: isSet(object.PanoramicXRay)
        ? AssetContentStudyPanoramicXRay.fromJSON(object.PanoramicXRay)
        : undefined,
      IntraoralXRay: isSet(object.IntraoralXRay)
        ? AssetContentStudyIntraoralXRay.fromJSON(object.IntraoralXRay)
        : undefined,
      IOSMeshes: isSet(object.IOSMeshes)
        ? AssetContentStudyIOSMeshes.fromJSON(object.IOSMeshes)
        : undefined,
      DentalPhoto: isSet(object.DentalPhoto)
        ? AssetContentStudyDentalPhoto.fromJSON(object.DentalPhoto)
        : undefined,
    };
  },

  toJSON(message: StudyAsset): unknown {
    const obj: any = {};
    message.StudyID !== undefined && (obj.StudyID = message.StudyID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.CBCT !== undefined &&
      (obj.CBCT = message.CBCT
        ? AssetContentStudyCBCT.toJSON(message.CBCT)
        : undefined);
    message.PanoramicXRay !== undefined &&
      (obj.PanoramicXRay = message.PanoramicXRay
        ? AssetContentStudyPanoramicXRay.toJSON(message.PanoramicXRay)
        : undefined);
    message.IntraoralXRay !== undefined &&
      (obj.IntraoralXRay = message.IntraoralXRay
        ? AssetContentStudyIntraoralXRay.toJSON(message.IntraoralXRay)
        : undefined);
    message.IOSMeshes !== undefined &&
      (obj.IOSMeshes = message.IOSMeshes
        ? AssetContentStudyIOSMeshes.toJSON(message.IOSMeshes)
        : undefined);
    message.DentalPhoto !== undefined &&
      (obj.DentalPhoto = message.DentalPhoto
        ? AssetContentStudyDentalPhoto.toJSON(message.DentalPhoto)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<StudyAsset>, I>>(
    object: I,
  ): StudyAsset {
    const message = createBaseStudyAsset();
    message.StudyID = object.StudyID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.CBCT =
      object.CBCT !== undefined && object.CBCT !== null
        ? AssetContentStudyCBCT.fromPartial(object.CBCT)
        : undefined;
    message.PanoramicXRay =
      object.PanoramicXRay !== undefined && object.PanoramicXRay !== null
        ? AssetContentStudyPanoramicXRay.fromPartial(object.PanoramicXRay)
        : undefined;
    message.IntraoralXRay =
      object.IntraoralXRay !== undefined && object.IntraoralXRay !== null
        ? AssetContentStudyIntraoralXRay.fromPartial(object.IntraoralXRay)
        : undefined;
    message.IOSMeshes =
      object.IOSMeshes !== undefined && object.IOSMeshes !== null
        ? AssetContentStudyIOSMeshes.fromPartial(object.IOSMeshes)
        : undefined;
    message.DentalPhoto =
      object.DentalPhoto !== undefined && object.DentalPhoto !== null
        ? AssetContentStudyDentalPhoto.fromPartial(object.DentalPhoto)
        : undefined;
    return message;
  },
};

function createBaseGeneratedReportAsset(): GeneratedReportAsset {
  return {
    Belonging: undefined,
    MedicalImageFeatures: undefined,
    LegacyPayloadOnly: undefined,
    IOXRayImage: undefined,
    CBCTGPPanoramaReformatSplit: undefined,
    CBCTGPPanoramaReformatGeneral: undefined,
    CBCTGPToothSlice: undefined,
    CBCTGPMPRSubVolume: undefined,
    CBCTGPPeriodontiumImage: undefined,
    CBCTEndoPanoramaSplit: undefined,
    CBCTEndoCrossSection: undefined,
    CBCTEndoGuide: undefined,
    CBCTImplantPanorama: undefined,
    CBCTImplantSlice: undefined,
    CBCTMolarPanorama: undefined,
    CBCTMolarGuide: undefined,
    CBCTMolarCrossSection: undefined,
    CBCTOrthoPDF: undefined,
    MeshModel: undefined,
    CBCTToothPathologySlice: undefined,
    CBCTTile: undefined,
    StudioCBCTDownscaled: undefined,
    StudioPanoramaReformatGeneral: undefined,
    LegacyPayloads: [],
  };
}

export const GeneratedReportAsset = {
  encode(
    message: GeneratedReportAsset,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Belonging !== undefined) {
      ReportAssetBelonging.encode(
        message.Belonging,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.MedicalImageFeatures !== undefined) {
      MedicalImageFeatures.encode(
        message.MedicalImageFeatures,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.LegacyPayloadOnly !== undefined) {
      EmptyPayload.encode(
        message.LegacyPayloadOnly,
        writer.uint32(402).fork(),
      ).ldelim();
    }
    if (message.IOXRayImage !== undefined) {
      AssetContentGeneratedIOXRayImage.encode(
        message.IOXRayImage,
        writer.uint32(410).fork(),
      ).ldelim();
    }
    if (message.CBCTGPPanoramaReformatSplit !== undefined) {
      AssetContentGeneratedCBCTGPPanoramaReformatSplit.encode(
        message.CBCTGPPanoramaReformatSplit,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.CBCTGPPanoramaReformatGeneral !== undefined) {
      AssetContentGeneratedCBCTGPPanoramaReformatGeneral.encode(
        message.CBCTGPPanoramaReformatGeneral,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.CBCTGPToothSlice !== undefined) {
      AssetContentGeneratedCBCTGPToothSlice.encode(
        message.CBCTGPToothSlice,
        writer.uint32(826).fork(),
      ).ldelim();
    }
    if (message.CBCTGPMPRSubVolume !== undefined) {
      AssetContentGeneratedCBCTGPMPRSubVolume.encode(
        message.CBCTGPMPRSubVolume,
        writer.uint32(834).fork(),
      ).ldelim();
    }
    if (message.CBCTGPPeriodontiumImage !== undefined) {
      AssetContentGeneratedCBCTGPPeriodontiumImage.encode(
        message.CBCTGPPeriodontiumImage,
        writer.uint32(842).fork(),
      ).ldelim();
    }
    if (message.CBCTEndoPanoramaSplit !== undefined) {
      AssetContentGeneratedCBCTEndoPanoramaSplit.encode(
        message.CBCTEndoPanoramaSplit,
        writer.uint32(890).fork(),
      ).ldelim();
    }
    if (message.CBCTEndoCrossSection !== undefined) {
      AssetContentGeneratedCBCTEndoCrossSection.encode(
        message.CBCTEndoCrossSection,
        writer.uint32(898).fork(),
      ).ldelim();
    }
    if (message.CBCTEndoGuide !== undefined) {
      AssetContentGeneratedCBCTEndoGuide.encode(
        message.CBCTEndoGuide,
        writer.uint32(906).fork(),
      ).ldelim();
    }
    if (message.CBCTImplantPanorama !== undefined) {
      AssetContentGeneratedCBCTImplantPanorama.encode(
        message.CBCTImplantPanorama,
        writer.uint32(970).fork(),
      ).ldelim();
    }
    if (message.CBCTImplantSlice !== undefined) {
      AssetContentGeneratedCBCTImplantSlice.encode(
        message.CBCTImplantSlice,
        writer.uint32(978).fork(),
      ).ldelim();
    }
    if (message.CBCTMolarPanorama !== undefined) {
      AssetContentGeneratedCBCTMolarPanorama.encode(
        message.CBCTMolarPanorama,
        writer.uint32(1050).fork(),
      ).ldelim();
    }
    if (message.CBCTMolarGuide !== undefined) {
      AssetContentGeneratedCBCTMolarGuide.encode(
        message.CBCTMolarGuide,
        writer.uint32(1058).fork(),
      ).ldelim();
    }
    if (message.CBCTMolarCrossSection !== undefined) {
      AssetContentGeneratedCBCTMolarCrossSection.encode(
        message.CBCTMolarCrossSection,
        writer.uint32(1066).fork(),
      ).ldelim();
    }
    if (message.CBCTOrthoPDF !== undefined) {
      AssetContentGeneratedCBCTOrthoPDF.encode(
        message.CBCTOrthoPDF,
        writer.uint32(1130).fork(),
      ).ldelim();
    }
    if (message.MeshModel !== undefined) {
      AssetContentGeneratedMeshModel.encode(
        message.MeshModel,
        writer.uint32(1290).fork(),
      ).ldelim();
    }
    if (message.CBCTToothPathologySlice !== undefined) {
      AssetContentGeneratedCBCTToothPathologySlice.encode(
        message.CBCTToothPathologySlice,
        writer.uint32(1370).fork(),
      ).ldelim();
    }
    if (message.CBCTTile !== undefined) {
      AssetContentGeneratedCBCTTile.encode(
        message.CBCTTile,
        writer.uint32(1450).fork(),
      ).ldelim();
    }
    if (message.StudioCBCTDownscaled !== undefined) {
      AssetContentGeneratedStudioCBCTDownscaled.encode(
        message.StudioCBCTDownscaled,
        writer.uint32(1458).fork(),
      ).ldelim();
    }
    if (message.StudioPanoramaReformatGeneral !== undefined) {
      AssetContentGeneratedStudioPanoramaReformatGeneral.encode(
        message.StudioPanoramaReformatGeneral,
        writer.uint32(1466).fork(),
      ).ldelim();
    }
    for (const v of message.LegacyPayloads) {
      ReportLegacyPayload.encode(v!, writer.uint32(8002).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GeneratedReportAsset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGeneratedReportAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Belonging = ReportAssetBelonging.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 11:
          message.MedicalImageFeatures = MedicalImageFeatures.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 50:
          message.LegacyPayloadOnly = EmptyPayload.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 51:
          message.IOXRayImage = AssetContentGeneratedIOXRayImage.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 101:
          message.CBCTGPPanoramaReformatSplit =
            AssetContentGeneratedCBCTGPPanoramaReformatSplit.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 102:
          message.CBCTGPPanoramaReformatGeneral =
            AssetContentGeneratedCBCTGPPanoramaReformatGeneral.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 103:
          message.CBCTGPToothSlice =
            AssetContentGeneratedCBCTGPToothSlice.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 104:
          message.CBCTGPMPRSubVolume =
            AssetContentGeneratedCBCTGPMPRSubVolume.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 105:
          message.CBCTGPPeriodontiumImage =
            AssetContentGeneratedCBCTGPPeriodontiumImage.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 111:
          message.CBCTEndoPanoramaSplit =
            AssetContentGeneratedCBCTEndoPanoramaSplit.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 112:
          message.CBCTEndoCrossSection =
            AssetContentGeneratedCBCTEndoCrossSection.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 113:
          message.CBCTEndoGuide = AssetContentGeneratedCBCTEndoGuide.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 121:
          message.CBCTImplantPanorama =
            AssetContentGeneratedCBCTImplantPanorama.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 122:
          message.CBCTImplantSlice =
            AssetContentGeneratedCBCTImplantSlice.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 131:
          message.CBCTMolarPanorama =
            AssetContentGeneratedCBCTMolarPanorama.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 132:
          message.CBCTMolarGuide = AssetContentGeneratedCBCTMolarGuide.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 133:
          message.CBCTMolarCrossSection =
            AssetContentGeneratedCBCTMolarCrossSection.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 141:
          message.CBCTOrthoPDF = AssetContentGeneratedCBCTOrthoPDF.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 161:
          message.MeshModel = AssetContentGeneratedMeshModel.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 171:
          message.CBCTToothPathologySlice =
            AssetContentGeneratedCBCTToothPathologySlice.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 181:
          message.CBCTTile = AssetContentGeneratedCBCTTile.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 182:
          message.StudioCBCTDownscaled =
            AssetContentGeneratedStudioCBCTDownscaled.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 183:
          message.StudioPanoramaReformatGeneral =
            AssetContentGeneratedStudioPanoramaReformatGeneral.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 1000:
          message.LegacyPayloads.push(
            ReportLegacyPayload.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GeneratedReportAsset {
    return {
      Belonging: isSet(object.Belonging)
        ? ReportAssetBelonging.fromJSON(object.Belonging)
        : undefined,
      MedicalImageFeatures: isSet(object.MedicalImageFeatures)
        ? MedicalImageFeatures.fromJSON(object.MedicalImageFeatures)
        : undefined,
      LegacyPayloadOnly: isSet(object.LegacyPayloadOnly)
        ? EmptyPayload.fromJSON(object.LegacyPayloadOnly)
        : undefined,
      IOXRayImage: isSet(object.IOXRayImage)
        ? AssetContentGeneratedIOXRayImage.fromJSON(object.IOXRayImage)
        : undefined,
      CBCTGPPanoramaReformatSplit: isSet(object.CBCTGPPanoramaReformatSplit)
        ? AssetContentGeneratedCBCTGPPanoramaReformatSplit.fromJSON(
            object.CBCTGPPanoramaReformatSplit,
          )
        : undefined,
      CBCTGPPanoramaReformatGeneral: isSet(object.CBCTGPPanoramaReformatGeneral)
        ? AssetContentGeneratedCBCTGPPanoramaReformatGeneral.fromJSON(
            object.CBCTGPPanoramaReformatGeneral,
          )
        : undefined,
      CBCTGPToothSlice: isSet(object.CBCTGPToothSlice)
        ? AssetContentGeneratedCBCTGPToothSlice.fromJSON(
            object.CBCTGPToothSlice,
          )
        : undefined,
      CBCTGPMPRSubVolume: isSet(object.CBCTGPMPRSubVolume)
        ? AssetContentGeneratedCBCTGPMPRSubVolume.fromJSON(
            object.CBCTGPMPRSubVolume,
          )
        : undefined,
      CBCTGPPeriodontiumImage: isSet(object.CBCTGPPeriodontiumImage)
        ? AssetContentGeneratedCBCTGPPeriodontiumImage.fromJSON(
            object.CBCTGPPeriodontiumImage,
          )
        : undefined,
      CBCTEndoPanoramaSplit: isSet(object.CBCTEndoPanoramaSplit)
        ? AssetContentGeneratedCBCTEndoPanoramaSplit.fromJSON(
            object.CBCTEndoPanoramaSplit,
          )
        : undefined,
      CBCTEndoCrossSection: isSet(object.CBCTEndoCrossSection)
        ? AssetContentGeneratedCBCTEndoCrossSection.fromJSON(
            object.CBCTEndoCrossSection,
          )
        : undefined,
      CBCTEndoGuide: isSet(object.CBCTEndoGuide)
        ? AssetContentGeneratedCBCTEndoGuide.fromJSON(object.CBCTEndoGuide)
        : undefined,
      CBCTImplantPanorama: isSet(object.CBCTImplantPanorama)
        ? AssetContentGeneratedCBCTImplantPanorama.fromJSON(
            object.CBCTImplantPanorama,
          )
        : undefined,
      CBCTImplantSlice: isSet(object.CBCTImplantSlice)
        ? AssetContentGeneratedCBCTImplantSlice.fromJSON(
            object.CBCTImplantSlice,
          )
        : undefined,
      CBCTMolarPanorama: isSet(object.CBCTMolarPanorama)
        ? AssetContentGeneratedCBCTMolarPanorama.fromJSON(
            object.CBCTMolarPanorama,
          )
        : undefined,
      CBCTMolarGuide: isSet(object.CBCTMolarGuide)
        ? AssetContentGeneratedCBCTMolarGuide.fromJSON(object.CBCTMolarGuide)
        : undefined,
      CBCTMolarCrossSection: isSet(object.CBCTMolarCrossSection)
        ? AssetContentGeneratedCBCTMolarCrossSection.fromJSON(
            object.CBCTMolarCrossSection,
          )
        : undefined,
      CBCTOrthoPDF: isSet(object.CBCTOrthoPDF)
        ? AssetContentGeneratedCBCTOrthoPDF.fromJSON(object.CBCTOrthoPDF)
        : undefined,
      MeshModel: isSet(object.MeshModel)
        ? AssetContentGeneratedMeshModel.fromJSON(object.MeshModel)
        : undefined,
      CBCTToothPathologySlice: isSet(object.CBCTToothPathologySlice)
        ? AssetContentGeneratedCBCTToothPathologySlice.fromJSON(
            object.CBCTToothPathologySlice,
          )
        : undefined,
      CBCTTile: isSet(object.CBCTTile)
        ? AssetContentGeneratedCBCTTile.fromJSON(object.CBCTTile)
        : undefined,
      StudioCBCTDownscaled: isSet(object.StudioCBCTDownscaled)
        ? AssetContentGeneratedStudioCBCTDownscaled.fromJSON(
            object.StudioCBCTDownscaled,
          )
        : undefined,
      StudioPanoramaReformatGeneral: isSet(object.StudioPanoramaReformatGeneral)
        ? AssetContentGeneratedStudioPanoramaReformatGeneral.fromJSON(
            object.StudioPanoramaReformatGeneral,
          )
        : undefined,
      LegacyPayloads: Array.isArray(object?.LegacyPayloads)
        ? object.LegacyPayloads.map((e: any) => ReportLegacyPayload.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GeneratedReportAsset): unknown {
    const obj: any = {};
    message.Belonging !== undefined &&
      (obj.Belonging = message.Belonging
        ? ReportAssetBelonging.toJSON(message.Belonging)
        : undefined);
    message.MedicalImageFeatures !== undefined &&
      (obj.MedicalImageFeatures = message.MedicalImageFeatures
        ? MedicalImageFeatures.toJSON(message.MedicalImageFeatures)
        : undefined);
    message.LegacyPayloadOnly !== undefined &&
      (obj.LegacyPayloadOnly = message.LegacyPayloadOnly
        ? EmptyPayload.toJSON(message.LegacyPayloadOnly)
        : undefined);
    message.IOXRayImage !== undefined &&
      (obj.IOXRayImage = message.IOXRayImage
        ? AssetContentGeneratedIOXRayImage.toJSON(message.IOXRayImage)
        : undefined);
    message.CBCTGPPanoramaReformatSplit !== undefined &&
      (obj.CBCTGPPanoramaReformatSplit = message.CBCTGPPanoramaReformatSplit
        ? AssetContentGeneratedCBCTGPPanoramaReformatSplit.toJSON(
            message.CBCTGPPanoramaReformatSplit,
          )
        : undefined);
    message.CBCTGPPanoramaReformatGeneral !== undefined &&
      (obj.CBCTGPPanoramaReformatGeneral = message.CBCTGPPanoramaReformatGeneral
        ? AssetContentGeneratedCBCTGPPanoramaReformatGeneral.toJSON(
            message.CBCTGPPanoramaReformatGeneral,
          )
        : undefined);
    message.CBCTGPToothSlice !== undefined &&
      (obj.CBCTGPToothSlice = message.CBCTGPToothSlice
        ? AssetContentGeneratedCBCTGPToothSlice.toJSON(message.CBCTGPToothSlice)
        : undefined);
    message.CBCTGPMPRSubVolume !== undefined &&
      (obj.CBCTGPMPRSubVolume = message.CBCTGPMPRSubVolume
        ? AssetContentGeneratedCBCTGPMPRSubVolume.toJSON(
            message.CBCTGPMPRSubVolume,
          )
        : undefined);
    message.CBCTGPPeriodontiumImage !== undefined &&
      (obj.CBCTGPPeriodontiumImage = message.CBCTGPPeriodontiumImage
        ? AssetContentGeneratedCBCTGPPeriodontiumImage.toJSON(
            message.CBCTGPPeriodontiumImage,
          )
        : undefined);
    message.CBCTEndoPanoramaSplit !== undefined &&
      (obj.CBCTEndoPanoramaSplit = message.CBCTEndoPanoramaSplit
        ? AssetContentGeneratedCBCTEndoPanoramaSplit.toJSON(
            message.CBCTEndoPanoramaSplit,
          )
        : undefined);
    message.CBCTEndoCrossSection !== undefined &&
      (obj.CBCTEndoCrossSection = message.CBCTEndoCrossSection
        ? AssetContentGeneratedCBCTEndoCrossSection.toJSON(
            message.CBCTEndoCrossSection,
          )
        : undefined);
    message.CBCTEndoGuide !== undefined &&
      (obj.CBCTEndoGuide = message.CBCTEndoGuide
        ? AssetContentGeneratedCBCTEndoGuide.toJSON(message.CBCTEndoGuide)
        : undefined);
    message.CBCTImplantPanorama !== undefined &&
      (obj.CBCTImplantPanorama = message.CBCTImplantPanorama
        ? AssetContentGeneratedCBCTImplantPanorama.toJSON(
            message.CBCTImplantPanorama,
          )
        : undefined);
    message.CBCTImplantSlice !== undefined &&
      (obj.CBCTImplantSlice = message.CBCTImplantSlice
        ? AssetContentGeneratedCBCTImplantSlice.toJSON(message.CBCTImplantSlice)
        : undefined);
    message.CBCTMolarPanorama !== undefined &&
      (obj.CBCTMolarPanorama = message.CBCTMolarPanorama
        ? AssetContentGeneratedCBCTMolarPanorama.toJSON(
            message.CBCTMolarPanorama,
          )
        : undefined);
    message.CBCTMolarGuide !== undefined &&
      (obj.CBCTMolarGuide = message.CBCTMolarGuide
        ? AssetContentGeneratedCBCTMolarGuide.toJSON(message.CBCTMolarGuide)
        : undefined);
    message.CBCTMolarCrossSection !== undefined &&
      (obj.CBCTMolarCrossSection = message.CBCTMolarCrossSection
        ? AssetContentGeneratedCBCTMolarCrossSection.toJSON(
            message.CBCTMolarCrossSection,
          )
        : undefined);
    message.CBCTOrthoPDF !== undefined &&
      (obj.CBCTOrthoPDF = message.CBCTOrthoPDF
        ? AssetContentGeneratedCBCTOrthoPDF.toJSON(message.CBCTOrthoPDF)
        : undefined);
    message.MeshModel !== undefined &&
      (obj.MeshModel = message.MeshModel
        ? AssetContentGeneratedMeshModel.toJSON(message.MeshModel)
        : undefined);
    message.CBCTToothPathologySlice !== undefined &&
      (obj.CBCTToothPathologySlice = message.CBCTToothPathologySlice
        ? AssetContentGeneratedCBCTToothPathologySlice.toJSON(
            message.CBCTToothPathologySlice,
          )
        : undefined);
    message.CBCTTile !== undefined &&
      (obj.CBCTTile = message.CBCTTile
        ? AssetContentGeneratedCBCTTile.toJSON(message.CBCTTile)
        : undefined);
    message.StudioCBCTDownscaled !== undefined &&
      (obj.StudioCBCTDownscaled = message.StudioCBCTDownscaled
        ? AssetContentGeneratedStudioCBCTDownscaled.toJSON(
            message.StudioCBCTDownscaled,
          )
        : undefined);
    message.StudioPanoramaReformatGeneral !== undefined &&
      (obj.StudioPanoramaReformatGeneral = message.StudioPanoramaReformatGeneral
        ? AssetContentGeneratedStudioPanoramaReformatGeneral.toJSON(
            message.StudioPanoramaReformatGeneral,
          )
        : undefined);
    if (message.LegacyPayloads) {
      obj.LegacyPayloads = message.LegacyPayloads.map((e) =>
        e ? ReportLegacyPayload.toJSON(e) : undefined,
      );
    } else {
      obj.LegacyPayloads = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<GeneratedReportAsset>, I>>(
    object: I,
  ): GeneratedReportAsset {
    const message = createBaseGeneratedReportAsset();
    message.Belonging =
      object.Belonging !== undefined && object.Belonging !== null
        ? ReportAssetBelonging.fromPartial(object.Belonging)
        : undefined;
    message.MedicalImageFeatures =
      object.MedicalImageFeatures !== undefined &&
      object.MedicalImageFeatures !== null
        ? MedicalImageFeatures.fromPartial(object.MedicalImageFeatures)
        : undefined;
    message.LegacyPayloadOnly =
      object.LegacyPayloadOnly !== undefined &&
      object.LegacyPayloadOnly !== null
        ? EmptyPayload.fromPartial(object.LegacyPayloadOnly)
        : undefined;
    message.IOXRayImage =
      object.IOXRayImage !== undefined && object.IOXRayImage !== null
        ? AssetContentGeneratedIOXRayImage.fromPartial(object.IOXRayImage)
        : undefined;
    message.CBCTGPPanoramaReformatSplit =
      object.CBCTGPPanoramaReformatSplit !== undefined &&
      object.CBCTGPPanoramaReformatSplit !== null
        ? AssetContentGeneratedCBCTGPPanoramaReformatSplit.fromPartial(
            object.CBCTGPPanoramaReformatSplit,
          )
        : undefined;
    message.CBCTGPPanoramaReformatGeneral =
      object.CBCTGPPanoramaReformatGeneral !== undefined &&
      object.CBCTGPPanoramaReformatGeneral !== null
        ? AssetContentGeneratedCBCTGPPanoramaReformatGeneral.fromPartial(
            object.CBCTGPPanoramaReformatGeneral,
          )
        : undefined;
    message.CBCTGPToothSlice =
      object.CBCTGPToothSlice !== undefined && object.CBCTGPToothSlice !== null
        ? AssetContentGeneratedCBCTGPToothSlice.fromPartial(
            object.CBCTGPToothSlice,
          )
        : undefined;
    message.CBCTGPMPRSubVolume =
      object.CBCTGPMPRSubVolume !== undefined &&
      object.CBCTGPMPRSubVolume !== null
        ? AssetContentGeneratedCBCTGPMPRSubVolume.fromPartial(
            object.CBCTGPMPRSubVolume,
          )
        : undefined;
    message.CBCTGPPeriodontiumImage =
      object.CBCTGPPeriodontiumImage !== undefined &&
      object.CBCTGPPeriodontiumImage !== null
        ? AssetContentGeneratedCBCTGPPeriodontiumImage.fromPartial(
            object.CBCTGPPeriodontiumImage,
          )
        : undefined;
    message.CBCTEndoPanoramaSplit =
      object.CBCTEndoPanoramaSplit !== undefined &&
      object.CBCTEndoPanoramaSplit !== null
        ? AssetContentGeneratedCBCTEndoPanoramaSplit.fromPartial(
            object.CBCTEndoPanoramaSplit,
          )
        : undefined;
    message.CBCTEndoCrossSection =
      object.CBCTEndoCrossSection !== undefined &&
      object.CBCTEndoCrossSection !== null
        ? AssetContentGeneratedCBCTEndoCrossSection.fromPartial(
            object.CBCTEndoCrossSection,
          )
        : undefined;
    message.CBCTEndoGuide =
      object.CBCTEndoGuide !== undefined && object.CBCTEndoGuide !== null
        ? AssetContentGeneratedCBCTEndoGuide.fromPartial(object.CBCTEndoGuide)
        : undefined;
    message.CBCTImplantPanorama =
      object.CBCTImplantPanorama !== undefined &&
      object.CBCTImplantPanorama !== null
        ? AssetContentGeneratedCBCTImplantPanorama.fromPartial(
            object.CBCTImplantPanorama,
          )
        : undefined;
    message.CBCTImplantSlice =
      object.CBCTImplantSlice !== undefined && object.CBCTImplantSlice !== null
        ? AssetContentGeneratedCBCTImplantSlice.fromPartial(
            object.CBCTImplantSlice,
          )
        : undefined;
    message.CBCTMolarPanorama =
      object.CBCTMolarPanorama !== undefined &&
      object.CBCTMolarPanorama !== null
        ? AssetContentGeneratedCBCTMolarPanorama.fromPartial(
            object.CBCTMolarPanorama,
          )
        : undefined;
    message.CBCTMolarGuide =
      object.CBCTMolarGuide !== undefined && object.CBCTMolarGuide !== null
        ? AssetContentGeneratedCBCTMolarGuide.fromPartial(object.CBCTMolarGuide)
        : undefined;
    message.CBCTMolarCrossSection =
      object.CBCTMolarCrossSection !== undefined &&
      object.CBCTMolarCrossSection !== null
        ? AssetContentGeneratedCBCTMolarCrossSection.fromPartial(
            object.CBCTMolarCrossSection,
          )
        : undefined;
    message.CBCTOrthoPDF =
      object.CBCTOrthoPDF !== undefined && object.CBCTOrthoPDF !== null
        ? AssetContentGeneratedCBCTOrthoPDF.fromPartial(object.CBCTOrthoPDF)
        : undefined;
    message.MeshModel =
      object.MeshModel !== undefined && object.MeshModel !== null
        ? AssetContentGeneratedMeshModel.fromPartial(object.MeshModel)
        : undefined;
    message.CBCTToothPathologySlice =
      object.CBCTToothPathologySlice !== undefined &&
      object.CBCTToothPathologySlice !== null
        ? AssetContentGeneratedCBCTToothPathologySlice.fromPartial(
            object.CBCTToothPathologySlice,
          )
        : undefined;
    message.CBCTTile =
      object.CBCTTile !== undefined && object.CBCTTile !== null
        ? AssetContentGeneratedCBCTTile.fromPartial(object.CBCTTile)
        : undefined;
    message.StudioCBCTDownscaled =
      object.StudioCBCTDownscaled !== undefined &&
      object.StudioCBCTDownscaled !== null
        ? AssetContentGeneratedStudioCBCTDownscaled.fromPartial(
            object.StudioCBCTDownscaled,
          )
        : undefined;
    message.StudioPanoramaReformatGeneral =
      object.StudioPanoramaReformatGeneral !== undefined &&
      object.StudioPanoramaReformatGeneral !== null
        ? AssetContentGeneratedStudioPanoramaReformatGeneral.fromPartial(
            object.StudioPanoramaReformatGeneral,
          )
        : undefined;
    message.LegacyPayloads =
      object.LegacyPayloads?.map((e) => ReportLegacyPayload.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseReportAsset(): ReportAsset {
  return {
    Belonging: undefined,
    MedicalImageFeatures: undefined,
    UserUploadedToothSlice: undefined,
    UserUploadedMPRPlane: undefined,
    StudioApplicationStateSnapshot: undefined,
    StudioGeometry: undefined,
    DisplayAsset: undefined,
  };
}

export const ReportAsset = {
  encode(
    message: ReportAsset,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Belonging !== undefined) {
      ReportAssetBelonging.encode(
        message.Belonging,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.MedicalImageFeatures !== undefined) {
      MedicalImageFeatures.encode(
        message.MedicalImageFeatures,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.UserUploadedToothSlice !== undefined) {
      AssetContentReportUserUploadedToothSlice.encode(
        message.UserUploadedToothSlice,
        writer.uint32(810).fork(),
      ).ldelim();
    }
    if (message.UserUploadedMPRPlane !== undefined) {
      AssetContentReportUserUploadedMPRPlane.encode(
        message.UserUploadedMPRPlane,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.StudioApplicationStateSnapshot !== undefined) {
      AssetContentReportStudioApplicationStateSnapshot.encode(
        message.StudioApplicationStateSnapshot,
        writer.uint32(890).fork(),
      ).ldelim();
    }
    if (message.StudioGeometry !== undefined) {
      AssetContentReportStudioGeometry.encode(
        message.StudioGeometry,
        writer.uint32(898).fork(),
      ).ldelim();
    }
    if (message.DisplayAsset !== undefined) {
      AssetContentSimpleImage.encode(
        message.DisplayAsset,
        writer.uint32(906).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportAsset {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Belonging = ReportAssetBelonging.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 11:
          message.MedicalImageFeatures = MedicalImageFeatures.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 101:
          message.UserUploadedToothSlice =
            AssetContentReportUserUploadedToothSlice.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 102:
          message.UserUploadedMPRPlane =
            AssetContentReportUserUploadedMPRPlane.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 111:
          message.StudioApplicationStateSnapshot =
            AssetContentReportStudioApplicationStateSnapshot.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 112:
          message.StudioGeometry = AssetContentReportStudioGeometry.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 113:
          message.DisplayAsset = AssetContentSimpleImage.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportAsset {
    return {
      Belonging: isSet(object.Belonging)
        ? ReportAssetBelonging.fromJSON(object.Belonging)
        : undefined,
      MedicalImageFeatures: isSet(object.MedicalImageFeatures)
        ? MedicalImageFeatures.fromJSON(object.MedicalImageFeatures)
        : undefined,
      UserUploadedToothSlice: isSet(object.UserUploadedToothSlice)
        ? AssetContentReportUserUploadedToothSlice.fromJSON(
            object.UserUploadedToothSlice,
          )
        : undefined,
      UserUploadedMPRPlane: isSet(object.UserUploadedMPRPlane)
        ? AssetContentReportUserUploadedMPRPlane.fromJSON(
            object.UserUploadedMPRPlane,
          )
        : undefined,
      StudioApplicationStateSnapshot: isSet(
        object.StudioApplicationStateSnapshot,
      )
        ? AssetContentReportStudioApplicationStateSnapshot.fromJSON(
            object.StudioApplicationStateSnapshot,
          )
        : undefined,
      StudioGeometry: isSet(object.StudioGeometry)
        ? AssetContentReportStudioGeometry.fromJSON(object.StudioGeometry)
        : undefined,
      DisplayAsset: isSet(object.DisplayAsset)
        ? AssetContentSimpleImage.fromJSON(object.DisplayAsset)
        : undefined,
    };
  },

  toJSON(message: ReportAsset): unknown {
    const obj: any = {};
    message.Belonging !== undefined &&
      (obj.Belonging = message.Belonging
        ? ReportAssetBelonging.toJSON(message.Belonging)
        : undefined);
    message.MedicalImageFeatures !== undefined &&
      (obj.MedicalImageFeatures = message.MedicalImageFeatures
        ? MedicalImageFeatures.toJSON(message.MedicalImageFeatures)
        : undefined);
    message.UserUploadedToothSlice !== undefined &&
      (obj.UserUploadedToothSlice = message.UserUploadedToothSlice
        ? AssetContentReportUserUploadedToothSlice.toJSON(
            message.UserUploadedToothSlice,
          )
        : undefined);
    message.UserUploadedMPRPlane !== undefined &&
      (obj.UserUploadedMPRPlane = message.UserUploadedMPRPlane
        ? AssetContentReportUserUploadedMPRPlane.toJSON(
            message.UserUploadedMPRPlane,
          )
        : undefined);
    message.StudioApplicationStateSnapshot !== undefined &&
      (obj.StudioApplicationStateSnapshot =
        message.StudioApplicationStateSnapshot
          ? AssetContentReportStudioApplicationStateSnapshot.toJSON(
              message.StudioApplicationStateSnapshot,
            )
          : undefined);
    message.StudioGeometry !== undefined &&
      (obj.StudioGeometry = message.StudioGeometry
        ? AssetContentReportStudioGeometry.toJSON(message.StudioGeometry)
        : undefined);
    message.DisplayAsset !== undefined &&
      (obj.DisplayAsset = message.DisplayAsset
        ? AssetContentSimpleImage.toJSON(message.DisplayAsset)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportAsset>, I>>(
    object: I,
  ): ReportAsset {
    const message = createBaseReportAsset();
    message.Belonging =
      object.Belonging !== undefined && object.Belonging !== null
        ? ReportAssetBelonging.fromPartial(object.Belonging)
        : undefined;
    message.MedicalImageFeatures =
      object.MedicalImageFeatures !== undefined &&
      object.MedicalImageFeatures !== null
        ? MedicalImageFeatures.fromPartial(object.MedicalImageFeatures)
        : undefined;
    message.UserUploadedToothSlice =
      object.UserUploadedToothSlice !== undefined &&
      object.UserUploadedToothSlice !== null
        ? AssetContentReportUserUploadedToothSlice.fromPartial(
            object.UserUploadedToothSlice,
          )
        : undefined;
    message.UserUploadedMPRPlane =
      object.UserUploadedMPRPlane !== undefined &&
      object.UserUploadedMPRPlane !== null
        ? AssetContentReportUserUploadedMPRPlane.fromPartial(
            object.UserUploadedMPRPlane,
          )
        : undefined;
    message.StudioApplicationStateSnapshot =
      object.StudioApplicationStateSnapshot !== undefined &&
      object.StudioApplicationStateSnapshot !== null
        ? AssetContentReportStudioApplicationStateSnapshot.fromPartial(
            object.StudioApplicationStateSnapshot,
          )
        : undefined;
    message.StudioGeometry =
      object.StudioGeometry !== undefined && object.StudioGeometry !== null
        ? AssetContentReportStudioGeometry.fromPartial(object.StudioGeometry)
        : undefined;
    message.DisplayAsset =
      object.DisplayAsset !== undefined && object.DisplayAsset !== null
        ? AssetContentSimpleImage.fromPartial(object.DisplayAsset)
        : undefined;
    return message;
  },
};

function createBaseReportAssetBelonging(): ReportAssetBelonging {
  return {
    ReportID: '',
    SourceStudyIDs: [],
    PatientID: '',
    OrganizationID: '',
    ToothID: '',
  };
}

export const ReportAssetBelonging = {
  encode(
    message: ReportAssetBelonging,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ReportID !== '') {
      writer.uint32(10).string(message.ReportID);
    }
    for (const v of message.SourceStudyIDs) {
      writer.uint32(18).string(v!);
    }
    if (message.PatientID !== '') {
      writer.uint32(26).string(message.PatientID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(34).string(message.OrganizationID);
    }
    if (message.ToothID !== '') {
      writer.uint32(42).string(message.ToothID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportAssetBelonging {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportAssetBelonging();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ReportID = reader.string();
          break;
        case 2:
          message.SourceStudyIDs.push(reader.string());
          break;
        case 3:
          message.PatientID = reader.string();
          break;
        case 4:
          message.OrganizationID = reader.string();
          break;
        case 5:
          message.ToothID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportAssetBelonging {
    return {
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      SourceStudyIDs: Array.isArray(object?.SourceStudyIDs)
        ? object.SourceStudyIDs.map((e: any) => String(e))
        : [],
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      ToothID: isSet(object.ToothID) ? String(object.ToothID) : '',
    };
  },

  toJSON(message: ReportAssetBelonging): unknown {
    const obj: any = {};
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    if (message.SourceStudyIDs) {
      obj.SourceStudyIDs = message.SourceStudyIDs.map((e) => e);
    } else {
      obj.SourceStudyIDs = [];
    }
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.ToothID !== undefined && (obj.ToothID = message.ToothID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportAssetBelonging>, I>>(
    object: I,
  ): ReportAssetBelonging {
    const message = createBaseReportAssetBelonging();
    message.ReportID = object.ReportID ?? '';
    message.SourceStudyIDs = object.SourceStudyIDs?.map((e) => e) || [];
    message.PatientID = object.PatientID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.ToothID = object.ToothID ?? '';
    return message;
  },
};

function createBaseAssetContentStudyCBCT(): AssetContentStudyCBCT {
  return {
    DICOMFiles: [],
    CBCTArchive: undefined,
    Metadata: undefined,
    SeriesUID: '',
  };
}

export const AssetContentStudyCBCT = {
  encode(
    message: AssetContentStudyCBCT,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.DICOMFiles) {
      File.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.CBCTArchive !== undefined) {
      File.encode(message.CBCTArchive, writer.uint32(18).fork()).ldelim();
    }
    if (message.Metadata !== undefined) {
      AssetContentStudyCBCT_CBCTMetadata.encode(
        message.Metadata,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.SeriesUID !== '') {
      writer.uint32(34).string(message.SeriesUID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyCBCT {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyCBCT();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DICOMFiles.push(File.decode(reader, reader.uint32()));
          break;
        case 2:
          message.CBCTArchive = File.decode(reader, reader.uint32());
          break;
        case 3:
          message.Metadata = AssetContentStudyCBCT_CBCTMetadata.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.SeriesUID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyCBCT {
    return {
      DICOMFiles: Array.isArray(object?.DICOMFiles)
        ? object.DICOMFiles.map((e: any) => File.fromJSON(e))
        : [],
      CBCTArchive: isSet(object.CBCTArchive)
        ? File.fromJSON(object.CBCTArchive)
        : undefined,
      Metadata: isSet(object.Metadata)
        ? AssetContentStudyCBCT_CBCTMetadata.fromJSON(object.Metadata)
        : undefined,
      SeriesUID: isSet(object.SeriesUID) ? String(object.SeriesUID) : '',
    };
  },

  toJSON(message: AssetContentStudyCBCT): unknown {
    const obj: any = {};
    if (message.DICOMFiles) {
      obj.DICOMFiles = message.DICOMFiles.map((e) =>
        e ? File.toJSON(e) : undefined,
      );
    } else {
      obj.DICOMFiles = [];
    }
    message.CBCTArchive !== undefined &&
      (obj.CBCTArchive = message.CBCTArchive
        ? File.toJSON(message.CBCTArchive)
        : undefined);
    message.Metadata !== undefined &&
      (obj.Metadata = message.Metadata
        ? AssetContentStudyCBCT_CBCTMetadata.toJSON(message.Metadata)
        : undefined);
    message.SeriesUID !== undefined && (obj.SeriesUID = message.SeriesUID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentStudyCBCT>, I>>(
    object: I,
  ): AssetContentStudyCBCT {
    const message = createBaseAssetContentStudyCBCT();
    message.DICOMFiles =
      object.DICOMFiles?.map((e) => File.fromPartial(e)) || [];
    message.CBCTArchive =
      object.CBCTArchive !== undefined && object.CBCTArchive !== null
        ? File.fromPartial(object.CBCTArchive)
        : undefined;
    message.Metadata =
      object.Metadata !== undefined && object.Metadata !== null
        ? AssetContentStudyCBCT_CBCTMetadata.fromPartial(object.Metadata)
        : undefined;
    message.SeriesUID = object.SeriesUID ?? '';
    return message;
  },
};

function createBaseAssetContentStudyCBCT_CBCTMetadata(): AssetContentStudyCBCT_CBCTMetadata {
  return {
    StudyTime: 0,
    InternalStudyUID: '',
    InternalSeriesUID: '',
    InternalInstanceUID: '',
    ScanUID: '',
    Manufacturer: '',
    ManufacturerModelName: '',
    SliceThickness: '',
    FOV: '',
    VoxelSize: [],
    Exposure: '',
  };
}

export const AssetContentStudyCBCT_CBCTMetadata = {
  encode(
    message: AssetContentStudyCBCT_CBCTMetadata,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.StudyTime !== 0) {
      writer.uint32(8).int64(message.StudyTime);
    }
    if (message.InternalStudyUID !== '') {
      writer.uint32(18).string(message.InternalStudyUID);
    }
    if (message.InternalSeriesUID !== '') {
      writer.uint32(26).string(message.InternalSeriesUID);
    }
    if (message.InternalInstanceUID !== '') {
      writer.uint32(34).string(message.InternalInstanceUID);
    }
    if (message.ScanUID !== '') {
      writer.uint32(42).string(message.ScanUID);
    }
    if (message.Manufacturer !== '') {
      writer.uint32(50).string(message.Manufacturer);
    }
    if (message.ManufacturerModelName !== '') {
      writer.uint32(58).string(message.ManufacturerModelName);
    }
    if (message.SliceThickness !== '') {
      writer.uint32(66).string(message.SliceThickness);
    }
    if (message.FOV !== '') {
      writer.uint32(74).string(message.FOV);
    }
    writer.uint32(82).fork();
    for (const v of message.VoxelSize) {
      writer.double(v);
    }
    writer.ldelim();
    if (message.Exposure !== '') {
      writer.uint32(90).string(message.Exposure);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyCBCT_CBCTMetadata {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyCBCT_CBCTMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.StudyTime = longToNumber(reader.int64() as Long);
          break;
        case 2:
          message.InternalStudyUID = reader.string();
          break;
        case 3:
          message.InternalSeriesUID = reader.string();
          break;
        case 4:
          message.InternalInstanceUID = reader.string();
          break;
        case 5:
          message.ScanUID = reader.string();
          break;
        case 6:
          message.Manufacturer = reader.string();
          break;
        case 7:
          message.ManufacturerModelName = reader.string();
          break;
        case 8:
          message.SliceThickness = reader.string();
          break;
        case 9:
          message.FOV = reader.string();
          break;
        case 10:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.VoxelSize.push(reader.double());
            }
          } else {
            message.VoxelSize.push(reader.double());
          }
          break;
        case 11:
          message.Exposure = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyCBCT_CBCTMetadata {
    return {
      StudyTime: isSet(object.StudyTime) ? Number(object.StudyTime) : 0,
      InternalStudyUID: isSet(object.InternalStudyUID)
        ? String(object.InternalStudyUID)
        : '',
      InternalSeriesUID: isSet(object.InternalSeriesUID)
        ? String(object.InternalSeriesUID)
        : '',
      InternalInstanceUID: isSet(object.InternalInstanceUID)
        ? String(object.InternalInstanceUID)
        : '',
      ScanUID: isSet(object.ScanUID) ? String(object.ScanUID) : '',
      Manufacturer: isSet(object.Manufacturer)
        ? String(object.Manufacturer)
        : '',
      ManufacturerModelName: isSet(object.ManufacturerModelName)
        ? String(object.ManufacturerModelName)
        : '',
      SliceThickness: isSet(object.SliceThickness)
        ? String(object.SliceThickness)
        : '',
      FOV: isSet(object.FOV) ? String(object.FOV) : '',
      VoxelSize: Array.isArray(object?.VoxelSize)
        ? object.VoxelSize.map((e: any) => Number(e))
        : [],
      Exposure: isSet(object.Exposure) ? String(object.Exposure) : '',
    };
  },

  toJSON(message: AssetContentStudyCBCT_CBCTMetadata): unknown {
    const obj: any = {};
    message.StudyTime !== undefined &&
      (obj.StudyTime = Math.round(message.StudyTime));
    message.InternalStudyUID !== undefined &&
      (obj.InternalStudyUID = message.InternalStudyUID);
    message.InternalSeriesUID !== undefined &&
      (obj.InternalSeriesUID = message.InternalSeriesUID);
    message.InternalInstanceUID !== undefined &&
      (obj.InternalInstanceUID = message.InternalInstanceUID);
    message.ScanUID !== undefined && (obj.ScanUID = message.ScanUID);
    message.Manufacturer !== undefined &&
      (obj.Manufacturer = message.Manufacturer);
    message.ManufacturerModelName !== undefined &&
      (obj.ManufacturerModelName = message.ManufacturerModelName);
    message.SliceThickness !== undefined &&
      (obj.SliceThickness = message.SliceThickness);
    message.FOV !== undefined && (obj.FOV = message.FOV);
    if (message.VoxelSize) {
      obj.VoxelSize = message.VoxelSize.map((e) => e);
    } else {
      obj.VoxelSize = [];
    }
    message.Exposure !== undefined && (obj.Exposure = message.Exposure);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentStudyCBCT_CBCTMetadata>, I>,
  >(object: I): AssetContentStudyCBCT_CBCTMetadata {
    const message = createBaseAssetContentStudyCBCT_CBCTMetadata();
    message.StudyTime = object.StudyTime ?? 0;
    message.InternalStudyUID = object.InternalStudyUID ?? '';
    message.InternalSeriesUID = object.InternalSeriesUID ?? '';
    message.InternalInstanceUID = object.InternalInstanceUID ?? '';
    message.ScanUID = object.ScanUID ?? '';
    message.Manufacturer = object.Manufacturer ?? '';
    message.ManufacturerModelName = object.ManufacturerModelName ?? '';
    message.SliceThickness = object.SliceThickness ?? '';
    message.FOV = object.FOV ?? '';
    message.VoxelSize = object.VoxelSize?.map((e) => e) || [];
    message.Exposure = object.Exposure ?? '';
    return message;
  },
};

function createBaseAssetContentStudyIOSMeshes(): AssetContentStudyIOSMeshes {
  return { Jaws: [], Derived: undefined };
}

export const AssetContentStudyIOSMeshes = {
  encode(
    message: AssetContentStudyIOSMeshes,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    for (const v of message.Jaws) {
      File.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedData3DModel.encode(
        message.Derived,
        writer.uint32(802).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyIOSMeshes {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyIOSMeshes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Jaws.push(File.decode(reader, reader.uint32()));
          break;
        case 100:
          message.Derived = DerivedData3DModel.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyIOSMeshes {
    return {
      Jaws: Array.isArray(object?.Jaws)
        ? object.Jaws.map((e: any) => File.fromJSON(e))
        : [],
      Derived: isSet(object.Derived)
        ? DerivedData3DModel.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentStudyIOSMeshes): unknown {
    const obj: any = {};
    if (message.Jaws) {
      obj.Jaws = message.Jaws.map((e) => (e ? File.toJSON(e) : undefined));
    } else {
      obj.Jaws = [];
    }
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedData3DModel.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentStudyIOSMeshes>, I>>(
    object: I,
  ): AssetContentStudyIOSMeshes {
    const message = createBaseAssetContentStudyIOSMeshes();
    message.Jaws = object.Jaws?.map((e) => File.fromPartial(e)) || [];
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedData3DModel.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentStudyPanoramicXRay(): AssetContentStudyPanoramicXRay {
  return { PanoramaImage: undefined, Derived: undefined };
}

export const AssetContentStudyPanoramicXRay = {
  encode(
    message: AssetContentStudyPanoramicXRay,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PanoramaImage !== undefined) {
      File.encode(message.PanoramaImage, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(802).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyPanoramicXRay {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyPanoramicXRay();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PanoramaImage = File.decode(reader, reader.uint32());
          break;
        case 100:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyPanoramicXRay {
    return {
      PanoramaImage: isSet(object.PanoramaImage)
        ? File.fromJSON(object.PanoramaImage)
        : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentStudyPanoramicXRay): unknown {
    const obj: any = {};
    message.PanoramaImage !== undefined &&
      (obj.PanoramaImage = message.PanoramaImage
        ? File.toJSON(message.PanoramaImage)
        : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentStudyPanoramicXRay>, I>>(
    object: I,
  ): AssetContentStudyPanoramicXRay {
    const message = createBaseAssetContentStudyPanoramicXRay();
    message.PanoramaImage =
      object.PanoramaImage !== undefined && object.PanoramaImage !== null
        ? File.fromPartial(object.PanoramaImage)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentStudyIntraoralXRay(): AssetContentStudyIntraoralXRay {
  return { Image: undefined, Derived: undefined };
}

export const AssetContentStudyIntraoralXRay = {
  encode(
    message: AssetContentStudyIntraoralXRay,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Image !== undefined) {
      File.encode(message.Image, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyIntraoralXRay {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyIntraoralXRay();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Image = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyIntraoralXRay {
    return {
      Image: isSet(object.Image) ? File.fromJSON(object.Image) : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentStudyIntraoralXRay): unknown {
    const obj: any = {};
    message.Image !== undefined &&
      (obj.Image = message.Image ? File.toJSON(message.Image) : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentStudyIntraoralXRay>, I>>(
    object: I,
  ): AssetContentStudyIntraoralXRay {
    const message = createBaseAssetContentStudyIntraoralXRay();
    message.Image =
      object.Image !== undefined && object.Image !== null
        ? File.fromPartial(object.Image)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentStudyDentalPhoto(): AssetContentStudyDentalPhoto {
  return { Image: undefined, Derived: undefined };
}

export const AssetContentStudyDentalPhoto = {
  encode(
    message: AssetContentStudyDentalPhoto,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Image !== undefined) {
      File.encode(message.Image, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentStudyDentalPhoto {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentStudyDentalPhoto();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Image = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentStudyDentalPhoto {
    return {
      Image: isSet(object.Image) ? File.fromJSON(object.Image) : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentStudyDentalPhoto): unknown {
    const obj: any = {};
    message.Image !== undefined &&
      (obj.Image = message.Image ? File.toJSON(message.Image) : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentStudyDentalPhoto>, I>>(
    object: I,
  ): AssetContentStudyDentalPhoto {
    const message = createBaseAssetContentStudyDentalPhoto();
    message.Image =
      object.Image !== undefined && object.Image !== null
        ? File.fromPartial(object.Image)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTToothPathologySlice(): AssetContentGeneratedCBCTToothPathologySlice {
  return {
    Slice: undefined,
    Orientation: 0,
    Numeration: undefined,
    Pathology: 0,
    PathologyComponent: 0,
    Mask: undefined,
    Derived: undefined,
  };
}

export const AssetContentGeneratedCBCTToothPathologySlice = {
  encode(
    message: AssetContentGeneratedCBCTToothPathologySlice,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Slice !== undefined) {
      File.encode(message.Slice, writer.uint32(10).fork()).ldelim();
    }
    if (message.Orientation !== 0) {
      writer.uint32(16).int32(message.Orientation);
    }
    if (message.Numeration !== undefined) {
      ToothNumeration.encode(
        message.Numeration,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Pathology !== 0) {
      writer.uint32(32).int32(message.Pathology);
    }
    if (message.PathologyComponent !== 0) {
      writer.uint32(40).uint32(message.PathologyComponent);
    }
    if (message.Mask !== undefined) {
      ClosedPolygon.encode(message.Mask, writer.uint32(50).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTToothPathologySlice {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTToothPathologySlice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Slice = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Orientation = reader.int32() as any;
          break;
        case 3:
          message.Numeration = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 4:
          message.Pathology = reader.int32() as any;
          break;
        case 5:
          message.PathologyComponent = reader.uint32();
          break;
        case 6:
          message.Mask = ClosedPolygon.decode(reader, reader.uint32());
          break;
        case 7:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTToothPathologySlice {
    return {
      Slice: isSet(object.Slice) ? File.fromJSON(object.Slice) : undefined,
      Orientation: isSet(object.Orientation)
        ? cBCTSectionOrientationFromJSON(object.Orientation)
        : 0,
      Numeration: isSet(object.Numeration)
        ? ToothNumeration.fromJSON(object.Numeration)
        : undefined,
      Pathology: isSet(object.Pathology)
        ? assetContentGeneratedCBCTToothPathologySlice_PathologyTypeFromJSON(
            object.Pathology,
          )
        : 0,
      PathologyComponent: isSet(object.PathologyComponent)
        ? Number(object.PathologyComponent)
        : 0,
      Mask: isSet(object.Mask)
        ? ClosedPolygon.fromJSON(object.Mask)
        : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTToothPathologySlice): unknown {
    const obj: any = {};
    message.Slice !== undefined &&
      (obj.Slice = message.Slice ? File.toJSON(message.Slice) : undefined);
    message.Orientation !== undefined &&
      (obj.Orientation = cBCTSectionOrientationToJSON(message.Orientation));
    message.Numeration !== undefined &&
      (obj.Numeration = message.Numeration
        ? ToothNumeration.toJSON(message.Numeration)
        : undefined);
    message.Pathology !== undefined &&
      (obj.Pathology =
        assetContentGeneratedCBCTToothPathologySlice_PathologyTypeToJSON(
          message.Pathology,
        ));
    message.PathologyComponent !== undefined &&
      (obj.PathologyComponent = Math.round(message.PathologyComponent));
    message.Mask !== undefined &&
      (obj.Mask = message.Mask
        ? ClosedPolygon.toJSON(message.Mask)
        : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedCBCTToothPathologySlice>,
      I
    >,
  >(object: I): AssetContentGeneratedCBCTToothPathologySlice {
    const message = createBaseAssetContentGeneratedCBCTToothPathologySlice();
    message.Slice =
      object.Slice !== undefined && object.Slice !== null
        ? File.fromPartial(object.Slice)
        : undefined;
    message.Orientation = object.Orientation ?? 0;
    message.Numeration =
      object.Numeration !== undefined && object.Numeration !== null
        ? ToothNumeration.fromPartial(object.Numeration)
        : undefined;
    message.Pathology = object.Pathology ?? 0;
    message.PathologyComponent = object.PathologyComponent ?? 0;
    message.Mask =
      object.Mask !== undefined && object.Mask !== null
        ? ClosedPolygon.fromPartial(object.Mask)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentSimpleImage(): AssetContentSimpleImage {
  return { Original: undefined, Derived: undefined };
}

export const AssetContentSimpleImage = {
  encode(
    message: AssetContentSimpleImage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Original !== undefined) {
      File.encode(message.Original, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentSimpleImage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentSimpleImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Original = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentSimpleImage {
    return {
      Original: isSet(object.Original)
        ? File.fromJSON(object.Original)
        : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentSimpleImage): unknown {
    const obj: any = {};
    message.Original !== undefined &&
      (obj.Original = message.Original
        ? File.toJSON(message.Original)
        : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentSimpleImage>, I>>(
    object: I,
  ): AssetContentSimpleImage {
    const message = createBaseAssetContentSimpleImage();
    message.Original =
      object.Original !== undefined && object.Original !== null
        ? File.fromPartial(object.Original)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedCBCTOrthoPDF(): AssetContentGeneratedCBCTOrthoPDF {
  return { PDF: undefined };
}

export const AssetContentGeneratedCBCTOrthoPDF = {
  encode(
    message: AssetContentGeneratedCBCTOrthoPDF,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PDF !== undefined) {
      File.encode(message.PDF, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedCBCTOrthoPDF {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedCBCTOrthoPDF();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PDF = File.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedCBCTOrthoPDF {
    return {
      PDF: isSet(object.PDF) ? File.fromJSON(object.PDF) : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedCBCTOrthoPDF): unknown {
    const obj: any = {};
    message.PDF !== undefined &&
      (obj.PDF = message.PDF ? File.toJSON(message.PDF) : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedCBCTOrthoPDF>, I>,
  >(object: I): AssetContentGeneratedCBCTOrthoPDF {
    const message = createBaseAssetContentGeneratedCBCTOrthoPDF();
    message.PDF =
      object.PDF !== undefined && object.PDF !== null
        ? File.fromPartial(object.PDF)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedIOXRayImage(): AssetContentGeneratedIOXRayImage {
  return { Image: undefined, Derived: undefined };
}

export const AssetContentGeneratedIOXRayImage = {
  encode(
    message: AssetContentGeneratedIOXRayImage,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Image !== undefined) {
      File.encode(message.Image, writer.uint32(10).fork()).ldelim();
    }
    if (message.Derived !== undefined) {
      DerivedDataImage.encode(
        message.Derived,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedIOXRayImage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedIOXRayImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Image = File.decode(reader, reader.uint32());
          break;
        case 2:
          message.Derived = DerivedDataImage.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedIOXRayImage {
    return {
      Image: isSet(object.Image) ? File.fromJSON(object.Image) : undefined,
      Derived: isSet(object.Derived)
        ? DerivedDataImage.fromJSON(object.Derived)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedIOXRayImage): unknown {
    const obj: any = {};
    message.Image !== undefined &&
      (obj.Image = message.Image ? File.toJSON(message.Image) : undefined);
    message.Derived !== undefined &&
      (obj.Derived = message.Derived
        ? DerivedDataImage.toJSON(message.Derived)
        : undefined);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<AssetContentGeneratedIOXRayImage>, I>,
  >(object: I): AssetContentGeneratedIOXRayImage {
    const message = createBaseAssetContentGeneratedIOXRayImage();
    message.Image =
      object.Image !== undefined && object.Image !== null
        ? File.fromPartial(object.Image)
        : undefined;
    message.Derived =
      object.Derived !== undefined && object.Derived !== null
        ? DerivedDataImage.fromPartial(object.Derived)
        : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error('Value is larger than Number.MAX_SAFE_INTEGER');
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
