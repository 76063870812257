import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

export const isConditionUncertain = (
  { Certainty }: Condition = {} as Condition,
) =>
  Certainty?.EngineDecision === Decision.PositiveDecision &&
  Certainty?.UserDecision === Decision.NoDecision &&
  Certainty?.ModelScore >= 0.3 &&
  Certainty?.ModelScore < 0.5;
