import { createSelector } from '@reduxjs/toolkit';

import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { RootState } from '@/app/model/store';

import { CONDITIONS_BY_MASK_GROUP, MaskFiltersType } from '../config';

export const selectFilters = createSelector(
  (state: RootState) => state.maskFilters.filters,
  (filters) =>
    Object.entries(filters) as [
      MaskFiltersType,
      Record<'isActive' | 'disabled', boolean>,
    ][],
);

export const selectMaskActiveConditionCodes = createSelector(
  selectFilters,
  (filters) =>
    filters.reduce((conditionsAccumulator, [filterGroup, { isActive }]) => {
      if (isActive) {
        return conditionsAccumulator.concat(
          CONDITIONS_BY_MASK_GROUP[filterGroup],
        );
      }

      return conditionsAccumulator;
    }, [] as ConditionCode[]),
);

export const selectActiveFilters = createSelector(selectFilters, (allFilters) =>
  allFilters
    .reduce(
      (filtersAccumulator, [filterGroup, { isActive }]) =>
        isActive ? filtersAccumulator.concat(filterGroup) : filtersAccumulator,
      [] as MaskFiltersType[],
    )
    .sort(),
);

export const selectFiltersIDs = (state: RootState) =>
  state.maskFilters.filterIDs;

export const selectIsLandmarksShown = (state: RootState) =>
  state.maskFilters.isLandmarksShown;
