// @ts-nocheck
import { ProcessingUnit } from 'graphics';
import { RCImageBase, RCImageProps, Repaint } from '../RCImage/RCImageBase';


export type RCCropImageCrop = { x: number, y: number, w: number, h: number }

export interface RCCropImageProps extends RCImageProps {
  crop: RCCropImageCrop
}

export const RCCropImage = (props: RCCropImageProps) => {
  const { crop, ...baseProps } = props;

  const repaint: Repaint = (context, canvas) => {

    canvas.width = crop.w;
    canvas.height = crop.h;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(ProcessingUnit.canvas,
      crop.x, crop.y, crop.w, crop.h,
      0, 0, crop.w, crop.h);
  }

  return (
    <RCImageBase {...baseProps} repaint={repaint} />
  )
};
