import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import cn from 'classnames';

import { Column, Layout } from '@/shared/ui/';
import { useAppSelector } from '@/shared/hooks';

import { organizationModel } from '@/entities/organization';

import { useReportDataStream } from '@/features/reportStream';
import { Footer } from '@/features/footer';

import { Header } from '@/widgets/Header';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { AboutLabelingModal } from '@/widgets/AboutLabelingModal';

import styles from './PreviewReport.module.scss';
import { SettingsBar } from './ui/SettingsBar/SettingsBar';
import { PreviewDocument } from './ui/PreviewDocument/PreviewDocument';
import { usePrintSettings } from './hooks/usePrintSettings';

type PreviewReportProps = unknown;

export const PreviewReport: FC<PreviewReportProps> = () => {
  const { patientID = '', reportID = '' } = useParams();

  const organization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );

  const [organizationID, setOrganizationID] = useState(organization?.ID ?? '');

  const { printSettings, updatePrintSettings, isLoading, showLowProbability } =
    usePrintSettings(reportID);

  const isPrintSettingsExsits = printSettings !== undefined;

  useReportDataStream(reportID);

  useEffect(() => {
    if (organization?.ID) {
      setOrganizationID(organization?.ID);
    }
  }, [organization]);

  return (
    <Layout>
      <Layout.Header className={styles.header}>
        <Header />
      </Layout.Header>

      <Layout.Content
        className={cn(styles.content, styles.previewReportContent)}
      >
        <Layout.Sider className={styles.sider}>
          {isPrintSettingsExsits && (
            <SettingsBar
              reportID={reportID}
              settings={printSettings}
              onChange={updatePrintSettings}
              isLoading={isLoading}
              patientID={patientID}
            />
          )}
        </Layout.Sider>

        <Layout.Main>
          <Column className={styles.previewDocument}>
            {isPrintSettingsExsits && (
              <PreviewDocument
                patientID={patientID}
                reportID={reportID}
                showLowProbability={showLowProbability}
                settings={printSettings}
                organizationID={organizationID}
              />
            )}
          </Column>
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer>
        <Footer />
      </Layout.Footer>

      <GetFullAccessModal />

      <AboutLabelingModal />
    </Layout>
  );
};
