import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  UseFormSetError,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch } from '@/shared/hooks';
import { InlineFormError, Input, Layout, Modal } from '@/shared/ui/';
import { NEW_LINE, PATHS } from '@/shared/config';
import { AuthState_AuthStatus } from '@/shared/api/protocol_gen/api/auth/dto_auth';
import { ApiError } from '@/shared/api/api';

import { ModalID, modalModel } from '@/entities/modal';
import { authModel } from '@/entities/auth';

import { CongratsModal } from '@/widgets/CongratsModal';
import { ResetPasswordErrorModal } from '@/widgets/ResetPasswordErrorModal';

import styles from './ResetPassword.module.scss';
import {
  resetPasswordSchema,
  ResetPasswordSchemaFormPayload,
} from './config/formConfig';
import { errorHandler } from './utils/errorHandler';

type ResetPasswordProps = {
  className?: string;
  testID?: string;
};

export const ResetPassword: FC<ResetPasswordProps> = (props) => {
  const { className, testID } = props;

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValid, errors },
    setError,
  } = useForm<ResetPasswordSchemaFormPayload>({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onChange',
  });

  const openResetPasswordCongratsModalHandler = (
    email: string,
    expiredTime: number,
  ) => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.CongratsModal,
        data: {
          title: formatMessage({
            id: 'resetPassword.congrats.title',
            defaultMessage: 'Check your email',
          }),
          message: formatMessage(
            {
              id: 'resetPassword.congrats.description',
              defaultMessage:
                "We have sent password reset instructions to {email}.{ newLine }Please check your email to reset password. Don't forget to also check your spam folder in case the email got sent there.",
            },
            { email, newLine: NEW_LINE },
          ),
          redirectTo: PATHS.signIn,
          buttonText: formatMessage({
            id: 'resetPassword.congrats.button',
            defaultMessage: 'Back to Sign in',
          }),
          expiredTime,
        },
      }),
    );
  };

  const onSubmit: SubmitHandler<ResetPasswordSchemaFormPayload> = async (
    data,
  ) => {
    const { email } = data;

    try {
      const recoverByEmailResponse = await dispatch(
        authModel.thunks.recoverByEmail({ Email: email }),
      ).unwrap();

      if (recoverByEmailResponse.Status === AuthState_AuthStatus.IN_PROGRESS) {
        const expiredTime = new Date(
          recoverByEmailResponse?.ExpiresAt ?? 0,
        ).getTime();

        openResetPasswordCongratsModalHandler(email, expiredTime);
      }
    } catch (error) {
      errorHandler(error as ApiError, setError as UseFormSetError<FieldValues>);
    }
  };

  return (
    <Layout>
      <Layout.Content>
        <Layout.Main>
          <div className={cn(styles.container, className)} data-testid={testID}>
            <Modal
              title={formatMessage({
                id: 'resetPassword.title',
                defaultMessage: 'Reset password',
              })}
              shouldRenderCloseIconButton={false}
              isOpen
              okButtonText={formatMessage({
                id: 'resetPassword.reset',
                defaultMessage: 'Reset',
              })}
              okButtonProps={{
                type: 'submit',
                form: 'resetPasswordForm',
                disabled: !isValid,
                loading: isSubmitting,
              }}
              cancelButtonProps={{ variant: 'tertiary' }}
              onCancel={() => navigate(PATHS.signIn)}
              shouldCloseOnOverlayClick={false}
            >
              <p className={cn(styles.info, 'p2')}>
                <FormattedMessage
                  id="resetPassword.info"
                  defaultMessage="Enter your email to get a link for setting a new password"
                />
              </p>

              <form id="resetPasswordForm" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  control={control}
                  name="email"
                  render={({
                    field: { ref, value, name, onBlur, onChange },
                    fieldState: { error },
                  }) => (
                    <Input
                      className={styles.email}
                      required
                      ref={ref}
                      value={value}
                      name={name}
                      inputMode="email"
                      type="email"
                      onBlur={onBlur}
                      onChange={onChange}
                      label={formatMessage({
                        id: 'global.email',
                        defaultMessage: 'Email',
                      })}
                      error={error?.message}
                      autoComplete="email"
                    />
                  )}
                />

                {errors.root && (
                  <InlineFormError
                    errorMessage={errors.root.message}
                    className={styles.inlineError}
                  />
                )}
              </form>
            </Modal>
          </div>
        </Layout.Main>
      </Layout.Content>

      <CongratsModal />
      <ResetPasswordErrorModal />
    </Layout>
  );
};
