import { useEffect } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { isEmpty } from 'ramda';

import { Avatar, Button } from '@/shared/ui';
import { PATHS } from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';
import catImg from '@/shared/assets/images/cat.png';

import { getOrganizationInitials } from '@/entities/organization';
import { marketingModel } from '@/entities/marketing';

import { useControlPanel } from '../../hooks/useControlPanel';
import { CurrentOrganizationRow } from '../CurrentOrganizationRow/CurrentOrganizationRow';
import { OrganizationRow } from '../OrganizationRow/OrganizationRow';

import styles from './ControlPanel.module.scss';

type MenuProps = {
  className?: string;
};

export const ControlPanel = (props: MenuProps) => {
  const { className } = props;

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    allOrganizations,
    profileAssetID,
    profileName,
    profileInitials,
    profileAvatarSize,
    currentOrganization,
    currentOrganizationName = '',
    isSingleOrganizationsUser,
    userAvatarAsset,
    userFullName,
    userInitials,
    openGetFullAccessModalHandle,
    logoutHandle,
    navigateToCompanySettingsHandle,
    organizationChangeHandle,
  } = useControlPanel();

  useEffect(() => {
    const checkMarketingQuestionnaire = async () => {
      if (currentOrganization) {
        const { Questionnaire } = await dispatch(
          marketingModel.thunks.getOrganizationOnboardingQuestionnaire({
            OrganizationID: currentOrganization.ID,
          }),
        ).unwrap();

        if (isEmpty(Questionnaire?.FocusOfInterest)) {
          navigate(PATHS.additionalInfo);
        }
      }
    };

    checkMarketingQuestionnaire();
  }, [currentOrganization]);

  return (
    <div className={cn(styles.container, className)}>
      <Popup
        trigger={
          // Popup does not work without a div
          <div>
            <div
              className={cn(
                styles.profileWrapper,
                isSingleOrganizationsUser && styles.allInitial,
              )}
            >
              <Avatar
                initials={profileInitials}
                src={profileAssetID}
                size={profileAvatarSize}
                alt={profileName}
              />

              {!isSingleOrganizationsUser && (
                <span className="p2">{currentOrganizationName}</span>
              )}
            </div>
          </div>
        }
        on="click"
        position="bottom right"
        arrow={false}
      >
        <div className={styles.dropdownContainer}>
          {currentOrganization ? (
            <CurrentOrganizationRow
              organizationName={currentOrganizationName}
              initials={profileInitials}
              avatarSrc={profileAssetID}
              onClick={navigateToCompanySettingsHandle}
            />
          ) : (
            <div className={styles.getFullAccess}>
              <img
                src={catImg}
                width={100}
                height={100}
                alt={formatMessage({
                  id: 'imgAltText.catImage',
                  defaultMessage: 'Cat image',
                })}
              />

              <div>
                <p className={cn('p2 bold', styles.purpleText)}>
                  <FormattedMessage
                    id="controlPanel.getFullAccessToDiagnocat"
                    defaultMessage="Get full access to Diagnocat"
                  />
                </p>

                <Button size="medium" onClick={openGetFullAccessModalHandle}>
                  <FormattedMessage
                    id="controlPanel.getNow"
                    defaultMessage="Get now"
                  />
                </Button>
              </div>
            </div>
          )}

          {allOrganizations?.length > 1 && (
            <>
              <div className={cn(styles.myOrganizations)}>
                <p className={cn(styles.myOrganizationsTitle, 'h6')}>
                  <FormattedMessage
                    id="controlPanel.myClinics"
                    defaultMessage="My clinics"
                  />
                </p>

                {allOrganizations.map((organization) => {
                  const isCurrentOrganization =
                    currentOrganization?.ID === organization?.ID;

                  const organizationInitials =
                    getOrganizationInitials(organization);

                  const organizationName = organization?.Name;

                  const organizationAsset = organization?.LogoAssetID;

                  return (
                    <OrganizationRow
                      key={organization?.ID}
                      organizationName={organizationName}
                      initials={organizationInitials}
                      avatarSrc={organizationAsset}
                      onClick={() => organizationChangeHandle(organization?.ID)}
                      isCurrentOrganization={isCurrentOrganization}
                    />
                  );
                })}
              </div>
              <div className={styles.myOrganizationsBorderBottom} />
            </>
          )}

          <div className={cn(styles.myProfile, styles.borderBottom)}>
            <p className="h6">
              <FormattedMessage
                id="controlPanel.myProfile"
                defaultMessage="My profile"
              />
            </p>

            <Link
              className={cn(styles.link, styles.innerRow, 'p2')}
              to={PATHS.personalSettings}
            >
              <div className={styles.info}>
                <Avatar
                  src={userAvatarAsset}
                  alt={userFullName}
                  initials={userInitials}
                />

                <p className={cn(styles.textEllipsis, styles.purpleText, 'p2')}>
                  {userFullName}
                </p>
              </div>
            </Link>
          </div>

          <Button
            size="medium"
            variant="secondary"
            onClick={() => logoutHandle()}
            className={styles.signOutButton}
          >
            <FormattedMessage id="global.signOut" defaultMessage="Sign out" />
          </Button>
        </div>
      </Popup>
    </div>
  );
};
