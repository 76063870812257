import { FC, useEffect, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';

import { CORS_POLICY } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
} from '@/entities/IOXRayImagesMatrix';
import { toothModel } from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';

import { MaskFilters, maskFiltersModel } from '@/features/maskFilters';
import { ConditionMaskTooltip } from '@/features/conditionMaskTooltip';
import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';

import { FMXRender } from '../../../../shared/graphics/viewer2D/Viewer2D';
import { Toolbar } from '../Toolbar/Toolbar';
import { PanoImageSlider } from '../PanoImageSlider/PanoImageSlider';

import styles from './PanoReportRender.module.scss';

type PanoReportRenderProps = {
  className?: string;
  reportID: string;
};

export const PanoReportRender: FC<PanoReportRenderProps> = (props) => {
  const { className, reportID } = props;

  const mainViewRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const readyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const panoAssetID = useAppSelector(
    reportsModel.selectors.selectCurrentReportPanoAssetID,
  );

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface;

  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectToothChartItems,
  )?.map((bbox) =>
    bbox.imageID === panoAssetID ? { ...bbox, imageID: 'pano' } : bbox,
  );

  // Masks
  const masks2DRenderData = useAppSelector(
    maskFiltersModel.selectors.select2DMasksRenderData,
  );

  const hideMasks = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );

  useLayoutEffect(() => {
    FMXRender.setViewRef(mainViewRef);
    FMXRender.setCanvasRef(canvasRef);
  }, []);

  const teeth = useAppSelector(toothModel.selectors.selectAll);

  useEffect(() => {
    const shouldRunRender =
      !!reportID && !!panoImageInterface?.imageMeta && readyForRender;
    if (shouldRunRender) {
      FMXRender.setCredentials(CORS_POLICY);

      const isRenderStartCorrect = FMXRender.run(
        reportID,
        [[[panoImageInterface]]],
        reportViewOptions,
        { [panoImageInterface.imageMeta.ID]: { viewportType: 'pano' } },
      );

      if (!isRenderStartCorrect) {
        return;
      }

      FMXRender.setTeeth(teeth);
      FMXRender.layoutModes.focus(panoImageInterface.imageMeta.ID);
    }
  }, [reportID, panoAssetID, readyForRender]);

  // BBoxes render
  useEffect(() => {
    if (FMXRender.isRunning()) {
      if (hoveredBBoxes) {
        FMXRender.deleteConditionBoxes();

        FMXRender.addConditionBoxes(hoveredBBoxes);
      } else {
        FMXRender.deleteConditionBoxes();
      }
    }
  }, [hoveredBBoxes]);

  // Render masks
  useEffect(() => {
    if (FMXRender.isRunning() && readyForRender) {
      FMXRender.deleteMasks();
      if (!hideMasks && masks2DRenderData.length > 0) {
        FMXRender.addMasks([
          {
            imageID: panoImageInterface.imageMeta.ID,
            groupID: masks2DRenderData[0].groupID,
            config: masks2DRenderData[0].config,
          },
        ]);
        FMXRender.showMasks();
      }
    }
  }, [masks2DRenderData, readyForRender, hideMasks]);

  useEffect(
    () => () => {
      FMXRender.shutdown();
    },
    [],
  );

  return (
    <div ref={mainViewRef} className={cn(styles.container, className)}>
      <MaskFilters className={styles.maskFilters} />
      <ConditionMaskTooltip />
      <Toolbar handleScrollToothCardsToTop={() => {}} />
      <PanoImageSlider panoImageMetaID={panoImageInterface?.imageMeta.ID} />
      <canvas ref={canvasRef} className={styles.canvas} />
    </div>
  );
};
