import { FC, memo, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import { Checkbox, MedicalImage } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';
import { MedicalImageInterface } from '@/shared/config';

import { ModalID, modalModel } from '@/entities/modal';

import styles from './GroupMedicalImagesList.module.scss';

type GroupMedicalImagesListProps = {
  focusedImageID?: string;
  zoomedImageID?: string;
  className?: string;
  medicalImages?: MedicalImageInterface[];
  selectedMedicalImages?: MedicalImageInterface[];
  canUserDeleteSlices?: boolean;
  onHoveredGuideChanged?: (assetID: string) => void;
  toggleSelected?: (medicalImage: MedicalImageInterface) => void;
  onDragMedicalImage?: (medicalImage: MedicalImageInterface) => void;
};

export const GroupMedicalImagesList: FC<GroupMedicalImagesListProps> = memo(
  (props) => {
    const {
      className,
      focusedImageID,
      zoomedImageID,
      medicalImages = [],
      selectedMedicalImages,
      onHoveredGuideChanged,
      onDragMedicalImage,
      toggleSelected,
      canUserDeleteSlices,
    } = props;

    const [showMasks, setShowMasks] = useState(true);

    const { toothID } = useParams();

    const dispatch = useAppDispatch();

    const checkIsTheMedicalImageSelected = useCallback(
      (src: string) =>
        selectedMedicalImages?.some(
          (medicalImage) => medicalImage.src === src,
        ) ?? false,
      [selectedMedicalImages],
    );

    const handleOpenModal = useCallback(
      (medicalImage?: MedicalImageInterface) => {
        if (!medicalImage) return;

        dispatch(
          modalModel.actions.openModal({
            modalID: ModalID.ZoomedMedicalImage,
            data: {
              medicalImage,
              toothID: toothID ?? '',
              isSelectionEnabled: true,
            },
          }),
        );
      },
      [toothID],
    );

    useEffect(() => {
      if (zoomedImageID !== null) {
        handleOpenModal(
          medicalImages.find((image) => image.assetID === zoomedImageID),
        );
      }
    }, [zoomedImageID]);

    const hasMasks = medicalImages.some((medicalImage) => medicalImage?.mask);

    return (
      <div
        className={cn(styles.container, className)}
        onMouseLeave={() => onHoveredGuideChanged && onHoveredGuideChanged('')}
      >
        {hasMasks && (
          <div className={styles.medicalImagesActions}>
            <Checkbox
              checked={showMasks}
              onChange={() => setShowMasks(!showMasks)}
            >
              <FormattedMessage
                id="global.showMasks"
                defaultMessage="Show Masks"
              />
            </Checkbox>
          </div>
        )}

        <div className={styles.medicalImages}>
          {medicalImages.map((medicalImage) => (
            <div
              key={medicalImage.src}
              onMouseEnter={() =>
                onHoveredGuideChanged &&
                onHoveredGuideChanged(medicalImage.assetID)
              }
              className={styles.medicalImageWrapper}
            >
              <MedicalImage
                key={medicalImage.src}
                medicalImage={medicalImage}
                onClick={() => handleOpenModal(medicalImage)}
                annotations={medicalImage.annotations}
                onDragMedicalImage={onDragMedicalImage}
                toggleSelected={toggleSelected}
                checkIsTheMedicalImageSelected={checkIsTheMedicalImageSelected}
                mask={showMasks ? medicalImage.mask : undefined}
                focused={focusedImageID === medicalImage.assetID}
                wc={medicalImage?.viewOptions?.wwwc?.wc}
                ww={medicalImage?.viewOptions?.wwwc?.ww}
                sharpness={medicalImage?.viewOptions?.sharpness}
                inverted={medicalImage?.viewOptions?.invert}
                order={medicalImage.order}
                canUserDeleteSlices={canUserDeleteSlices}
              />
            </div>
          ))}
        </div>
      </div>
    );
  },
);
