/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import {
  ReportCBCTIOSSuperimpositionOrientation,
  reportCBCTIOSSuperimpositionOrientationFromJSON,
  reportCBCTIOSSuperimpositionOrientationToJSON,
} from '../model/dto_report_type_cbct_ios_superimposition';
import { ReportCBCTIOSSegment } from '../model/dto_report_common';

export const protobufPackage = 'com.diagnocat.model';

export interface ReportRequest {
  InputCBCTGP: ReportRequest_InputTypeCBCTGP | undefined;
  InputCBCTEndo: ReportRequest_InputTypeCBCTEndo | undefined;
  InputCBCTImplant: ReportRequest_InputTypeCBCTImplant | undefined;
  InputCBCTMolar: ReportRequest_InputTypeCBCTMolar | undefined;
  InputCBCTOrtho: ReportRequest_InputTypeCBCTOrtho | undefined;
  InputPanoGP: ReportRequest_InputTypePanoGP | undefined;
  InputIOXRayGP: ReportRequest_InputTypeIOXRayGP | undefined;
  InputDentalPhotoGP: ReportRequest_InputTypeDentalPhotoGP | undefined;
  InputCBCTSegmentation: ReportRequest_InputTypeCBCTSegmentation | undefined;
  InputCBCTIOSSuperimposition:
    | ReportRequest_InputTypeCBCTIOSSuperimposition
    | undefined;
  InputStudioImplant: ReportRequest_InputTypeStudioImplant | undefined;
  InputStudioOrtho: ReportRequest_InputTypeStudioOrtho | undefined;
}

export interface ReportRequest_InputTypeCBCTGP {
  CBCTStudyID: string;
}

export interface ReportRequest_InputTypeCBCTEndo {
  CBCTStudyID: string;
  /** tooth number in ISO format (https://odonte.com/index.php/english/tooth-designation-system-iso-3950/) */
  ToothNumberISO: number;
  /** step in millimeters */
  StepMM: number;
  /** thickness of slice */
  ThicknessMM: number;
}

export interface ReportRequest_InputTypeCBCTImplant {
  CBCTStudyID: string;
  /** tooth numbers range to perform report on */
  StartToothNumberISO: number;
  /** tooth numbers range to perform report on */
  EndToothNumberISO: number;
  /** step in millimeters  default == 2 */
  StepMM: number;
  /** thickness of slice == optional */
  ThicknessMM: number;
}

export interface ReportRequest_InputTypeCBCTMolar {
  CBCTStudyID: string;
  ToothNumberISO: number;
}

export interface ReportRequest_InputTypeCBCTOrtho {
  CBCTStudyID: string;
  Language: string;
  EnableMeasures: boolean;
}

export interface ReportRequest_InputTypePanoGP {
  PanoramicXRayStudyID: string;
}

export interface ReportRequest_InputTypeIOXRayGP {
  IntraoralXRayStudyID: string;
}

export interface ReportRequest_InputTypeDentalPhotoGP {
  DentalPhotoStudyID: string;
}

export interface ReportRequest_InputTypeCBCTSegmentation {
  CBCTStudyID: string;
  Segments: ReportCBCTIOSSegment[];
}

export interface ReportRequest_InputTypeCBCTIOSSuperimposition {
  /** CBCT study identifier on which perform an alignment */
  CBCTStudyID: string;
  /** STL study identifier which should be segmented and aligned on CBCT */
  STLStudyID: string;
  /** [optional] */
  DentalPhotoStudyID: string;
  Orientation: ReportCBCTIOSSuperimpositionOrientation;
  Segments: ReportCBCTIOSSegment[];
}

export interface ReportRequest_InputTypeStudioImplant {
  CBCTStudyID: string;
  /** [optional] */
  IOSMeshesStudyID: string;
  /** [optional] */
  DentalPhotoStudyID: string;
}

export interface ReportRequest_InputTypeStudioOrtho {
  CBCTStudyID: string;
  /** [optional] */
  IOSMeshesStudyID: string;
  /** [optional] */
  DentalPhotoStudyID: string;
}

function createBaseReportRequest(): ReportRequest {
  return {
    InputCBCTGP: undefined,
    InputCBCTEndo: undefined,
    InputCBCTImplant: undefined,
    InputCBCTMolar: undefined,
    InputCBCTOrtho: undefined,
    InputPanoGP: undefined,
    InputIOXRayGP: undefined,
    InputDentalPhotoGP: undefined,
    InputCBCTSegmentation: undefined,
    InputCBCTIOSSuperimposition: undefined,
    InputStudioImplant: undefined,
    InputStudioOrtho: undefined,
  };
}

export const ReportRequest = {
  encode(
    message: ReportRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.InputCBCTGP !== undefined) {
      ReportRequest_InputTypeCBCTGP.encode(
        message.InputCBCTGP,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.InputCBCTEndo !== undefined) {
      ReportRequest_InputTypeCBCTEndo.encode(
        message.InputCBCTEndo,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.InputCBCTImplant !== undefined) {
      ReportRequest_InputTypeCBCTImplant.encode(
        message.InputCBCTImplant,
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.InputCBCTMolar !== undefined) {
      ReportRequest_InputTypeCBCTMolar.encode(
        message.InputCBCTMolar,
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.InputCBCTOrtho !== undefined) {
      ReportRequest_InputTypeCBCTOrtho.encode(
        message.InputCBCTOrtho,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.InputPanoGP !== undefined) {
      ReportRequest_InputTypePanoGP.encode(
        message.InputPanoGP,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    if (message.InputIOXRayGP !== undefined) {
      ReportRequest_InputTypeIOXRayGP.encode(
        message.InputIOXRayGP,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.InputDentalPhotoGP !== undefined) {
      ReportRequest_InputTypeDentalPhotoGP.encode(
        message.InputDentalPhotoGP,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    if (message.InputCBCTSegmentation !== undefined) {
      ReportRequest_InputTypeCBCTSegmentation.encode(
        message.InputCBCTSegmentation,
        writer.uint32(74).fork(),
      ).ldelim();
    }
    if (message.InputCBCTIOSSuperimposition !== undefined) {
      ReportRequest_InputTypeCBCTIOSSuperimposition.encode(
        message.InputCBCTIOSSuperimposition,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    if (message.InputStudioImplant !== undefined) {
      ReportRequest_InputTypeStudioImplant.encode(
        message.InputStudioImplant,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.InputStudioOrtho !== undefined) {
      ReportRequest_InputTypeStudioOrtho.encode(
        message.InputStudioOrtho,
        writer.uint32(122).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.InputCBCTGP = ReportRequest_InputTypeCBCTGP.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 2:
          message.InputCBCTEndo = ReportRequest_InputTypeCBCTEndo.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          message.InputCBCTImplant = ReportRequest_InputTypeCBCTImplant.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 4:
          message.InputCBCTMolar = ReportRequest_InputTypeCBCTMolar.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 5:
          message.InputCBCTOrtho = ReportRequest_InputTypeCBCTOrtho.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 6:
          message.InputPanoGP = ReportRequest_InputTypePanoGP.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 7:
          message.InputIOXRayGP = ReportRequest_InputTypeIOXRayGP.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 8:
          message.InputDentalPhotoGP =
            ReportRequest_InputTypeDentalPhotoGP.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 9:
          message.InputCBCTSegmentation =
            ReportRequest_InputTypeCBCTSegmentation.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 10:
          message.InputCBCTIOSSuperimposition =
            ReportRequest_InputTypeCBCTIOSSuperimposition.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 13:
          message.InputStudioImplant =
            ReportRequest_InputTypeStudioImplant.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 15:
          message.InputStudioOrtho = ReportRequest_InputTypeStudioOrtho.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest {
    return {
      InputCBCTGP: isSet(object.InputCBCTGP)
        ? ReportRequest_InputTypeCBCTGP.fromJSON(object.InputCBCTGP)
        : undefined,
      InputCBCTEndo: isSet(object.InputCBCTEndo)
        ? ReportRequest_InputTypeCBCTEndo.fromJSON(object.InputCBCTEndo)
        : undefined,
      InputCBCTImplant: isSet(object.InputCBCTImplant)
        ? ReportRequest_InputTypeCBCTImplant.fromJSON(object.InputCBCTImplant)
        : undefined,
      InputCBCTMolar: isSet(object.InputCBCTMolar)
        ? ReportRequest_InputTypeCBCTMolar.fromJSON(object.InputCBCTMolar)
        : undefined,
      InputCBCTOrtho: isSet(object.InputCBCTOrtho)
        ? ReportRequest_InputTypeCBCTOrtho.fromJSON(object.InputCBCTOrtho)
        : undefined,
      InputPanoGP: isSet(object.InputPanoGP)
        ? ReportRequest_InputTypePanoGP.fromJSON(object.InputPanoGP)
        : undefined,
      InputIOXRayGP: isSet(object.InputIOXRayGP)
        ? ReportRequest_InputTypeIOXRayGP.fromJSON(object.InputIOXRayGP)
        : undefined,
      InputDentalPhotoGP: isSet(object.InputDentalPhotoGP)
        ? ReportRequest_InputTypeDentalPhotoGP.fromJSON(
            object.InputDentalPhotoGP,
          )
        : undefined,
      InputCBCTSegmentation: isSet(object.InputCBCTSegmentation)
        ? ReportRequest_InputTypeCBCTSegmentation.fromJSON(
            object.InputCBCTSegmentation,
          )
        : undefined,
      InputCBCTIOSSuperimposition: isSet(object.InputCBCTIOSSuperimposition)
        ? ReportRequest_InputTypeCBCTIOSSuperimposition.fromJSON(
            object.InputCBCTIOSSuperimposition,
          )
        : undefined,
      InputStudioImplant: isSet(object.InputStudioImplant)
        ? ReportRequest_InputTypeStudioImplant.fromJSON(
            object.InputStudioImplant,
          )
        : undefined,
      InputStudioOrtho: isSet(object.InputStudioOrtho)
        ? ReportRequest_InputTypeStudioOrtho.fromJSON(object.InputStudioOrtho)
        : undefined,
    };
  },

  toJSON(message: ReportRequest): unknown {
    const obj: any = {};
    message.InputCBCTGP !== undefined &&
      (obj.InputCBCTGP = message.InputCBCTGP
        ? ReportRequest_InputTypeCBCTGP.toJSON(message.InputCBCTGP)
        : undefined);
    message.InputCBCTEndo !== undefined &&
      (obj.InputCBCTEndo = message.InputCBCTEndo
        ? ReportRequest_InputTypeCBCTEndo.toJSON(message.InputCBCTEndo)
        : undefined);
    message.InputCBCTImplant !== undefined &&
      (obj.InputCBCTImplant = message.InputCBCTImplant
        ? ReportRequest_InputTypeCBCTImplant.toJSON(message.InputCBCTImplant)
        : undefined);
    message.InputCBCTMolar !== undefined &&
      (obj.InputCBCTMolar = message.InputCBCTMolar
        ? ReportRequest_InputTypeCBCTMolar.toJSON(message.InputCBCTMolar)
        : undefined);
    message.InputCBCTOrtho !== undefined &&
      (obj.InputCBCTOrtho = message.InputCBCTOrtho
        ? ReportRequest_InputTypeCBCTOrtho.toJSON(message.InputCBCTOrtho)
        : undefined);
    message.InputPanoGP !== undefined &&
      (obj.InputPanoGP = message.InputPanoGP
        ? ReportRequest_InputTypePanoGP.toJSON(message.InputPanoGP)
        : undefined);
    message.InputIOXRayGP !== undefined &&
      (obj.InputIOXRayGP = message.InputIOXRayGP
        ? ReportRequest_InputTypeIOXRayGP.toJSON(message.InputIOXRayGP)
        : undefined);
    message.InputDentalPhotoGP !== undefined &&
      (obj.InputDentalPhotoGP = message.InputDentalPhotoGP
        ? ReportRequest_InputTypeDentalPhotoGP.toJSON(
            message.InputDentalPhotoGP,
          )
        : undefined);
    message.InputCBCTSegmentation !== undefined &&
      (obj.InputCBCTSegmentation = message.InputCBCTSegmentation
        ? ReportRequest_InputTypeCBCTSegmentation.toJSON(
            message.InputCBCTSegmentation,
          )
        : undefined);
    message.InputCBCTIOSSuperimposition !== undefined &&
      (obj.InputCBCTIOSSuperimposition = message.InputCBCTIOSSuperimposition
        ? ReportRequest_InputTypeCBCTIOSSuperimposition.toJSON(
            message.InputCBCTIOSSuperimposition,
          )
        : undefined);
    message.InputStudioImplant !== undefined &&
      (obj.InputStudioImplant = message.InputStudioImplant
        ? ReportRequest_InputTypeStudioImplant.toJSON(
            message.InputStudioImplant,
          )
        : undefined);
    message.InputStudioOrtho !== undefined &&
      (obj.InputStudioOrtho = message.InputStudioOrtho
        ? ReportRequest_InputTypeStudioOrtho.toJSON(message.InputStudioOrtho)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportRequest>, I>>(
    object: I,
  ): ReportRequest {
    const message = createBaseReportRequest();
    message.InputCBCTGP =
      object.InputCBCTGP !== undefined && object.InputCBCTGP !== null
        ? ReportRequest_InputTypeCBCTGP.fromPartial(object.InputCBCTGP)
        : undefined;
    message.InputCBCTEndo =
      object.InputCBCTEndo !== undefined && object.InputCBCTEndo !== null
        ? ReportRequest_InputTypeCBCTEndo.fromPartial(object.InputCBCTEndo)
        : undefined;
    message.InputCBCTImplant =
      object.InputCBCTImplant !== undefined && object.InputCBCTImplant !== null
        ? ReportRequest_InputTypeCBCTImplant.fromPartial(
            object.InputCBCTImplant,
          )
        : undefined;
    message.InputCBCTMolar =
      object.InputCBCTMolar !== undefined && object.InputCBCTMolar !== null
        ? ReportRequest_InputTypeCBCTMolar.fromPartial(object.InputCBCTMolar)
        : undefined;
    message.InputCBCTOrtho =
      object.InputCBCTOrtho !== undefined && object.InputCBCTOrtho !== null
        ? ReportRequest_InputTypeCBCTOrtho.fromPartial(object.InputCBCTOrtho)
        : undefined;
    message.InputPanoGP =
      object.InputPanoGP !== undefined && object.InputPanoGP !== null
        ? ReportRequest_InputTypePanoGP.fromPartial(object.InputPanoGP)
        : undefined;
    message.InputIOXRayGP =
      object.InputIOXRayGP !== undefined && object.InputIOXRayGP !== null
        ? ReportRequest_InputTypeIOXRayGP.fromPartial(object.InputIOXRayGP)
        : undefined;
    message.InputDentalPhotoGP =
      object.InputDentalPhotoGP !== undefined &&
      object.InputDentalPhotoGP !== null
        ? ReportRequest_InputTypeDentalPhotoGP.fromPartial(
            object.InputDentalPhotoGP,
          )
        : undefined;
    message.InputCBCTSegmentation =
      object.InputCBCTSegmentation !== undefined &&
      object.InputCBCTSegmentation !== null
        ? ReportRequest_InputTypeCBCTSegmentation.fromPartial(
            object.InputCBCTSegmentation,
          )
        : undefined;
    message.InputCBCTIOSSuperimposition =
      object.InputCBCTIOSSuperimposition !== undefined &&
      object.InputCBCTIOSSuperimposition !== null
        ? ReportRequest_InputTypeCBCTIOSSuperimposition.fromPartial(
            object.InputCBCTIOSSuperimposition,
          )
        : undefined;
    message.InputStudioImplant =
      object.InputStudioImplant !== undefined &&
      object.InputStudioImplant !== null
        ? ReportRequest_InputTypeStudioImplant.fromPartial(
            object.InputStudioImplant,
          )
        : undefined;
    message.InputStudioOrtho =
      object.InputStudioOrtho !== undefined && object.InputStudioOrtho !== null
        ? ReportRequest_InputTypeStudioOrtho.fromPartial(
            object.InputStudioOrtho,
          )
        : undefined;
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTGP(): ReportRequest_InputTypeCBCTGP {
  return { CBCTStudyID: '' };
}

export const ReportRequest_InputTypeCBCTGP = {
  encode(
    message: ReportRequest_InputTypeCBCTGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTGP {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTGP): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportRequest_InputTypeCBCTGP>, I>>(
    object: I,
  ): ReportRequest_InputTypeCBCTGP {
    const message = createBaseReportRequest_InputTypeCBCTGP();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTEndo(): ReportRequest_InputTypeCBCTEndo {
  return { CBCTStudyID: '', ToothNumberISO: 0, StepMM: 0, ThicknessMM: 0 };
}

export const ReportRequest_InputTypeCBCTEndo = {
  encode(
    message: ReportRequest_InputTypeCBCTEndo,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.ToothNumberISO !== 0) {
      writer.uint32(16).uint32(message.ToothNumberISO);
    }
    if (message.StepMM !== 0) {
      writer.uint32(29).float(message.StepMM);
    }
    if (message.ThicknessMM !== 0) {
      writer.uint32(37).float(message.ThicknessMM);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTEndo {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTEndo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.ToothNumberISO = reader.uint32();
          break;
        case 3:
          message.StepMM = reader.float();
          break;
        case 4:
          message.ThicknessMM = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTEndo {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      ToothNumberISO: isSet(object.ToothNumberISO)
        ? Number(object.ToothNumberISO)
        : 0,
      StepMM: isSet(object.StepMM) ? Number(object.StepMM) : 0,
      ThicknessMM: isSet(object.ThicknessMM) ? Number(object.ThicknessMM) : 0,
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTEndo): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.ToothNumberISO !== undefined &&
      (obj.ToothNumberISO = Math.round(message.ToothNumberISO));
    message.StepMM !== undefined && (obj.StepMM = message.StepMM);
    message.ThicknessMM !== undefined &&
      (obj.ThicknessMM = message.ThicknessMM);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportRequest_InputTypeCBCTEndo>, I>>(
    object: I,
  ): ReportRequest_InputTypeCBCTEndo {
    const message = createBaseReportRequest_InputTypeCBCTEndo();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.ToothNumberISO = object.ToothNumberISO ?? 0;
    message.StepMM = object.StepMM ?? 0;
    message.ThicknessMM = object.ThicknessMM ?? 0;
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTImplant(): ReportRequest_InputTypeCBCTImplant {
  return {
    CBCTStudyID: '',
    StartToothNumberISO: 0,
    EndToothNumberISO: 0,
    StepMM: 0,
    ThicknessMM: 0,
  };
}

export const ReportRequest_InputTypeCBCTImplant = {
  encode(
    message: ReportRequest_InputTypeCBCTImplant,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.StartToothNumberISO !== 0) {
      writer.uint32(16).uint32(message.StartToothNumberISO);
    }
    if (message.EndToothNumberISO !== 0) {
      writer.uint32(24).uint32(message.EndToothNumberISO);
    }
    if (message.StepMM !== 0) {
      writer.uint32(37).float(message.StepMM);
    }
    if (message.ThicknessMM !== 0) {
      writer.uint32(45).float(message.ThicknessMM);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTImplant {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTImplant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.StartToothNumberISO = reader.uint32();
          break;
        case 3:
          message.EndToothNumberISO = reader.uint32();
          break;
        case 4:
          message.StepMM = reader.float();
          break;
        case 5:
          message.ThicknessMM = reader.float();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTImplant {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      StartToothNumberISO: isSet(object.StartToothNumberISO)
        ? Number(object.StartToothNumberISO)
        : 0,
      EndToothNumberISO: isSet(object.EndToothNumberISO)
        ? Number(object.EndToothNumberISO)
        : 0,
      StepMM: isSet(object.StepMM) ? Number(object.StepMM) : 0,
      ThicknessMM: isSet(object.ThicknessMM) ? Number(object.ThicknessMM) : 0,
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTImplant): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.StartToothNumberISO !== undefined &&
      (obj.StartToothNumberISO = Math.round(message.StartToothNumberISO));
    message.EndToothNumberISO !== undefined &&
      (obj.EndToothNumberISO = Math.round(message.EndToothNumberISO));
    message.StepMM !== undefined && (obj.StepMM = message.StepMM);
    message.ThicknessMM !== undefined &&
      (obj.ThicknessMM = message.ThicknessMM);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeCBCTImplant>, I>,
  >(object: I): ReportRequest_InputTypeCBCTImplant {
    const message = createBaseReportRequest_InputTypeCBCTImplant();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.StartToothNumberISO = object.StartToothNumberISO ?? 0;
    message.EndToothNumberISO = object.EndToothNumberISO ?? 0;
    message.StepMM = object.StepMM ?? 0;
    message.ThicknessMM = object.ThicknessMM ?? 0;
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTMolar(): ReportRequest_InputTypeCBCTMolar {
  return { CBCTStudyID: '', ToothNumberISO: 0 };
}

export const ReportRequest_InputTypeCBCTMolar = {
  encode(
    message: ReportRequest_InputTypeCBCTMolar,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.ToothNumberISO !== 0) {
      writer.uint32(16).uint32(message.ToothNumberISO);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTMolar {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTMolar();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.ToothNumberISO = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTMolar {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      ToothNumberISO: isSet(object.ToothNumberISO)
        ? Number(object.ToothNumberISO)
        : 0,
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTMolar): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.ToothNumberISO !== undefined &&
      (obj.ToothNumberISO = Math.round(message.ToothNumberISO));
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeCBCTMolar>, I>,
  >(object: I): ReportRequest_InputTypeCBCTMolar {
    const message = createBaseReportRequest_InputTypeCBCTMolar();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.ToothNumberISO = object.ToothNumberISO ?? 0;
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTOrtho(): ReportRequest_InputTypeCBCTOrtho {
  return { CBCTStudyID: '', Language: '', EnableMeasures: false };
}

export const ReportRequest_InputTypeCBCTOrtho = {
  encode(
    message: ReportRequest_InputTypeCBCTOrtho,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.Language !== '') {
      writer.uint32(18).string(message.Language);
    }
    if (message.EnableMeasures === true) {
      writer.uint32(80).bool(message.EnableMeasures);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTOrtho {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTOrtho();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.Language = reader.string();
          break;
        case 10:
          message.EnableMeasures = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTOrtho {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      Language: isSet(object.Language) ? String(object.Language) : '',
      EnableMeasures: isSet(object.EnableMeasures)
        ? Boolean(object.EnableMeasures)
        : false,
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTOrtho): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.Language !== undefined && (obj.Language = message.Language);
    message.EnableMeasures !== undefined &&
      (obj.EnableMeasures = message.EnableMeasures);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeCBCTOrtho>, I>,
  >(object: I): ReportRequest_InputTypeCBCTOrtho {
    const message = createBaseReportRequest_InputTypeCBCTOrtho();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.Language = object.Language ?? '';
    message.EnableMeasures = object.EnableMeasures ?? false;
    return message;
  },
};

function createBaseReportRequest_InputTypePanoGP(): ReportRequest_InputTypePanoGP {
  return { PanoramicXRayStudyID: '' };
}

export const ReportRequest_InputTypePanoGP = {
  encode(
    message: ReportRequest_InputTypePanoGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.PanoramicXRayStudyID !== '') {
      writer.uint32(10).string(message.PanoramicXRayStudyID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypePanoGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypePanoGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.PanoramicXRayStudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypePanoGP {
    return {
      PanoramicXRayStudyID: isSet(object.PanoramicXRayStudyID)
        ? String(object.PanoramicXRayStudyID)
        : '',
    };
  },

  toJSON(message: ReportRequest_InputTypePanoGP): unknown {
    const obj: any = {};
    message.PanoramicXRayStudyID !== undefined &&
      (obj.PanoramicXRayStudyID = message.PanoramicXRayStudyID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportRequest_InputTypePanoGP>, I>>(
    object: I,
  ): ReportRequest_InputTypePanoGP {
    const message = createBaseReportRequest_InputTypePanoGP();
    message.PanoramicXRayStudyID = object.PanoramicXRayStudyID ?? '';
    return message;
  },
};

function createBaseReportRequest_InputTypeIOXRayGP(): ReportRequest_InputTypeIOXRayGP {
  return { IntraoralXRayStudyID: '' };
}

export const ReportRequest_InputTypeIOXRayGP = {
  encode(
    message: ReportRequest_InputTypeIOXRayGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.IntraoralXRayStudyID !== '') {
      writer.uint32(10).string(message.IntraoralXRayStudyID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeIOXRayGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeIOXRayGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.IntraoralXRayStudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeIOXRayGP {
    return {
      IntraoralXRayStudyID: isSet(object.IntraoralXRayStudyID)
        ? String(object.IntraoralXRayStudyID)
        : '',
    };
  },

  toJSON(message: ReportRequest_InputTypeIOXRayGP): unknown {
    const obj: any = {};
    message.IntraoralXRayStudyID !== undefined &&
      (obj.IntraoralXRayStudyID = message.IntraoralXRayStudyID);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ReportRequest_InputTypeIOXRayGP>, I>>(
    object: I,
  ): ReportRequest_InputTypeIOXRayGP {
    const message = createBaseReportRequest_InputTypeIOXRayGP();
    message.IntraoralXRayStudyID = object.IntraoralXRayStudyID ?? '';
    return message;
  },
};

function createBaseReportRequest_InputTypeDentalPhotoGP(): ReportRequest_InputTypeDentalPhotoGP {
  return { DentalPhotoStudyID: '' };
}

export const ReportRequest_InputTypeDentalPhotoGP = {
  encode(
    message: ReportRequest_InputTypeDentalPhotoGP,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.DentalPhotoStudyID !== '') {
      writer.uint32(10).string(message.DentalPhotoStudyID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeDentalPhotoGP {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeDentalPhotoGP();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.DentalPhotoStudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeDentalPhotoGP {
    return {
      DentalPhotoStudyID: isSet(object.DentalPhotoStudyID)
        ? String(object.DentalPhotoStudyID)
        : '',
    };
  },

  toJSON(message: ReportRequest_InputTypeDentalPhotoGP): unknown {
    const obj: any = {};
    message.DentalPhotoStudyID !== undefined &&
      (obj.DentalPhotoStudyID = message.DentalPhotoStudyID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeDentalPhotoGP>, I>,
  >(object: I): ReportRequest_InputTypeDentalPhotoGP {
    const message = createBaseReportRequest_InputTypeDentalPhotoGP();
    message.DentalPhotoStudyID = object.DentalPhotoStudyID ?? '';
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTSegmentation(): ReportRequest_InputTypeCBCTSegmentation {
  return { CBCTStudyID: '', Segments: [] };
}

export const ReportRequest_InputTypeCBCTSegmentation = {
  encode(
    message: ReportRequest_InputTypeCBCTSegmentation,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    for (const v of message.Segments) {
      ReportCBCTIOSSegment.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTSegmentation {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTSegmentation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.Segments.push(
            ReportCBCTIOSSegment.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTSegmentation {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      Segments: Array.isArray(object?.Segments)
        ? object.Segments.map((e: any) => ReportCBCTIOSSegment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTSegmentation): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    if (message.Segments) {
      obj.Segments = message.Segments.map((e) =>
        e ? ReportCBCTIOSSegment.toJSON(e) : undefined,
      );
    } else {
      obj.Segments = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeCBCTSegmentation>, I>,
  >(object: I): ReportRequest_InputTypeCBCTSegmentation {
    const message = createBaseReportRequest_InputTypeCBCTSegmentation();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.Segments =
      object.Segments?.map((e) => ReportCBCTIOSSegment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseReportRequest_InputTypeCBCTIOSSuperimposition(): ReportRequest_InputTypeCBCTIOSSuperimposition {
  return {
    CBCTStudyID: '',
    STLStudyID: '',
    DentalPhotoStudyID: '',
    Orientation: 0,
    Segments: [],
  };
}

export const ReportRequest_InputTypeCBCTIOSSuperimposition = {
  encode(
    message: ReportRequest_InputTypeCBCTIOSSuperimposition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.STLStudyID !== '') {
      writer.uint32(18).string(message.STLStudyID);
    }
    if (message.DentalPhotoStudyID !== '') {
      writer.uint32(26).string(message.DentalPhotoStudyID);
    }
    if (message.Orientation !== 0) {
      writer.uint32(32).int32(message.Orientation);
    }
    for (const v of message.Segments) {
      ReportCBCTIOSSegment.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeCBCTIOSSuperimposition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeCBCTIOSSuperimposition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.STLStudyID = reader.string();
          break;
        case 3:
          message.DentalPhotoStudyID = reader.string();
          break;
        case 4:
          message.Orientation = reader.int32() as any;
          break;
        case 5:
          message.Segments.push(
            ReportCBCTIOSSegment.decode(reader, reader.uint32()),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeCBCTIOSSuperimposition {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      STLStudyID: isSet(object.STLStudyID) ? String(object.STLStudyID) : '',
      DentalPhotoStudyID: isSet(object.DentalPhotoStudyID)
        ? String(object.DentalPhotoStudyID)
        : '',
      Orientation: isSet(object.Orientation)
        ? reportCBCTIOSSuperimpositionOrientationFromJSON(object.Orientation)
        : 0,
      Segments: Array.isArray(object?.Segments)
        ? object.Segments.map((e: any) => ReportCBCTIOSSegment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ReportRequest_InputTypeCBCTIOSSuperimposition): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.STLStudyID !== undefined && (obj.STLStudyID = message.STLStudyID);
    message.DentalPhotoStudyID !== undefined &&
      (obj.DentalPhotoStudyID = message.DentalPhotoStudyID);
    message.Orientation !== undefined &&
      (obj.Orientation = reportCBCTIOSSuperimpositionOrientationToJSON(
        message.Orientation,
      ));
    if (message.Segments) {
      obj.Segments = message.Segments.map((e) =>
        e ? ReportCBCTIOSSegment.toJSON(e) : undefined,
      );
    } else {
      obj.Segments = [];
    }
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<ReportRequest_InputTypeCBCTIOSSuperimposition>,
      I
    >,
  >(object: I): ReportRequest_InputTypeCBCTIOSSuperimposition {
    const message = createBaseReportRequest_InputTypeCBCTIOSSuperimposition();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.STLStudyID = object.STLStudyID ?? '';
    message.DentalPhotoStudyID = object.DentalPhotoStudyID ?? '';
    message.Orientation = object.Orientation ?? 0;
    message.Segments =
      object.Segments?.map((e) => ReportCBCTIOSSegment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseReportRequest_InputTypeStudioImplant(): ReportRequest_InputTypeStudioImplant {
  return { CBCTStudyID: '', IOSMeshesStudyID: '', DentalPhotoStudyID: '' };
}

export const ReportRequest_InputTypeStudioImplant = {
  encode(
    message: ReportRequest_InputTypeStudioImplant,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.IOSMeshesStudyID !== '') {
      writer.uint32(18).string(message.IOSMeshesStudyID);
    }
    if (message.DentalPhotoStudyID !== '') {
      writer.uint32(26).string(message.DentalPhotoStudyID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeStudioImplant {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeStudioImplant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.IOSMeshesStudyID = reader.string();
          break;
        case 3:
          message.DentalPhotoStudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeStudioImplant {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      IOSMeshesStudyID: isSet(object.IOSMeshesStudyID)
        ? String(object.IOSMeshesStudyID)
        : '',
      DentalPhotoStudyID: isSet(object.DentalPhotoStudyID)
        ? String(object.DentalPhotoStudyID)
        : '',
    };
  },

  toJSON(message: ReportRequest_InputTypeStudioImplant): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.IOSMeshesStudyID !== undefined &&
      (obj.IOSMeshesStudyID = message.IOSMeshesStudyID);
    message.DentalPhotoStudyID !== undefined &&
      (obj.DentalPhotoStudyID = message.DentalPhotoStudyID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeStudioImplant>, I>,
  >(object: I): ReportRequest_InputTypeStudioImplant {
    const message = createBaseReportRequest_InputTypeStudioImplant();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.IOSMeshesStudyID = object.IOSMeshesStudyID ?? '';
    message.DentalPhotoStudyID = object.DentalPhotoStudyID ?? '';
    return message;
  },
};

function createBaseReportRequest_InputTypeStudioOrtho(): ReportRequest_InputTypeStudioOrtho {
  return { CBCTStudyID: '', IOSMeshesStudyID: '', DentalPhotoStudyID: '' };
}

export const ReportRequest_InputTypeStudioOrtho = {
  encode(
    message: ReportRequest_InputTypeStudioOrtho,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.CBCTStudyID !== '') {
      writer.uint32(10).string(message.CBCTStudyID);
    }
    if (message.IOSMeshesStudyID !== '') {
      writer.uint32(18).string(message.IOSMeshesStudyID);
    }
    if (message.DentalPhotoStudyID !== '') {
      writer.uint32(26).string(message.DentalPhotoStudyID);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): ReportRequest_InputTypeStudioOrtho {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportRequest_InputTypeStudioOrtho();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.CBCTStudyID = reader.string();
          break;
        case 2:
          message.IOSMeshesStudyID = reader.string();
          break;
        case 3:
          message.DentalPhotoStudyID = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ReportRequest_InputTypeStudioOrtho {
    return {
      CBCTStudyID: isSet(object.CBCTStudyID) ? String(object.CBCTStudyID) : '',
      IOSMeshesStudyID: isSet(object.IOSMeshesStudyID)
        ? String(object.IOSMeshesStudyID)
        : '',
      DentalPhotoStudyID: isSet(object.DentalPhotoStudyID)
        ? String(object.DentalPhotoStudyID)
        : '',
    };
  },

  toJSON(message: ReportRequest_InputTypeStudioOrtho): unknown {
    const obj: any = {};
    message.CBCTStudyID !== undefined &&
      (obj.CBCTStudyID = message.CBCTStudyID);
    message.IOSMeshesStudyID !== undefined &&
      (obj.IOSMeshesStudyID = message.IOSMeshesStudyID);
    message.DentalPhotoStudyID !== undefined &&
      (obj.DentalPhotoStudyID = message.DentalPhotoStudyID);
    return obj;
  },

  fromPartial<
    I extends Exact<DeepPartial<ReportRequest_InputTypeStudioOrtho>, I>,
  >(object: I): ReportRequest_InputTypeStudioOrtho {
    const message = createBaseReportRequest_InputTypeStudioOrtho();
    message.CBCTStudyID = object.CBCTStudyID ?? '';
    message.IOSMeshesStudyID = object.IOSMeshesStudyID ?? '';
    message.DentalPhotoStudyID = object.DentalPhotoStudyID ?? '';
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
