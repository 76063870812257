import { FC, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';

import { CORS_POLICY } from '@/shared/config';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { FMXRender, PBLProps } from '@/shared/graphics/viewer2D/Viewer2D';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { reportsModel } from '@/entities/reports';
import { toothModel } from '@/entities/tooth';
import {
  IOXRayImagesInterfaceModel,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';
import { maskFiltersModel as maskFilterTypesModel } from '@/entities/maskFilters'; // TODO:[4|m] Unite two mask filtel models
import { organizationModel } from '@/entities/organization';

import { maskFiltersModel } from '@/features/maskFilters';
import { Landmark, useTeethLandmarks } from '@/features/toothLandmark';
import { hoveredConditionBBoxesModel } from '@/features/hoveredConditionBBoxes';

import styles from './IOXRayReportRender.module.scss';

type IOXRayReportRenderProps = {
  className?: string;
  children?: ReactNode;
  previewMode?: boolean;
};

export const IOXRayReportRender: FC<IOXRayReportRenderProps> = (props) => {
  const { className, children, previewMode } = props;

  const dispatch = useAppDispatch();

  const reportID = useAppSelector(reportsModel.selectors.selectCurrentReportID);
  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectCurrentReportViewOptions,
  );
  const showPBL = useAppSelector(
    maskFilterTypesModel.selectors.selectIsLandmarksShown,
  );
  const hidePBLFeatureFlag = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_PBLRulesAndMeasurements,
    ),
  );
  const reportReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportReadyForRender,
  );
  const reportPBLReadyForRender = useAppSelector(
    reportsModel.selectors.selectReportPBLReadyForRender,
  );

  const mainViewRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID || '',
    ),
  );

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface,
  );

  const teethIDs = useAppSelector(toothModel.selectors.selectROITeethIDs);

  const { getPBLsForImage } = useTeethLandmarks(teethIDs);

  // Hovered condition BBoxes
  const hoveredBBoxes = useAppSelector(
    hoveredConditionBBoxesModel.selectors.selectToothChartItems,
  );

  const masks2DRenderData = useAppSelector(
    maskFiltersModel.selectors.select2DMasksRenderData,
  );

  const hideMasks = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_ConditionsMasks,
    ),
  );

  useEffect(() => {
    FMXRender.setViewRef(mainViewRef, 'main');
    FMXRender.setCanvasRef(canvasRef);
  }, []);

  const teeth = useAppSelector(toothModel.selectors.selectAll);

  useEffect(() => {
    if (FMXRender.isRunning()) {
      FMXRender.updateLayout(groupedIOXRayImages);
    }
  }, [groupedIOXRayImages]);

  // render initialization
  useEffect(() => {
    if (reportReadyForRender && !!IOXRayImagesInterface.length) {
      performance.mark('InitializeIOXRayRender:start');

      FMXRender.setCredentials(CORS_POLICY);

      const isRenderStartCorrect = FMXRender.run(
        reportID!,
        groupedIOXRayImages,
        reportViewOptions,
      );

      if (!isRenderStartCorrect) {
        return;
      }

      if (previewMode) {
        FMXRender.layoutModes.fullScreenMatrix();
        FMXRender.activateMode('printMode');
      } else {
        FMXRender.setTeeth(teeth);

        FMXRender.addEventListener('layout', (event) => {
          if (event.mode === 'focus') {
            dispatch(reportsModel.actions.setToolbarActiveControl('view'));
            dispatch(reportsModel.actions.setFocusedMetaImageID(event.id));
          }
        });
      }

      performance.mark('InitializeIOXRayRender:end');
      performance.measure(
        'InitializeIOXRayRender',
        'InitializeIOXRayRender:start',
        'InitializeIOXRayRender:end',
      );
    }
  }, [reportReadyForRender, IOXRayImagesInterface, hidePBLFeatureFlag]);

  // NOTE: PBL should be setted up just one time after render is done.
  useEffect(() => {
    if (reportPBLReadyForRender && FMXRender.isRunning()) {
      performance.mark('setPBLs:start');
      if (!previewMode) {
        const PBLsData = IOXRayImagesInterface.map((data) => {
          const initalPBLList = getPBLsForImage(data.asset.ID);

          if (!initalPBLList) {
            return undefined;
          }

          const getPBL = (landmark: Landmark) => ({
            start: {
              x: landmark.lowPoint?.ModelPosition?.X || 0,
              y: landmark.lowPoint?.ModelPosition?.Y || 0,
            },
            end: {
              x: landmark.upPoint?.ModelPosition?.X || 0,
              y: landmark.upPoint?.ModelPosition?.Y || 0,
            },
            color: landmark.color,
            textProps: {
              color: landmark.color === '#D4D4D4' ? 0 : 0xffffff,
            },
          });

          return {
            imageID: data.imageMeta.ID,
            PBLs: initalPBLList.map((pbl) => {
              return getPBL(pbl);
            }),
            scale: data.imageMeta.Scale?.X || 1,
          };
        }).filter((data) => data) as PBLProps[];

        if (PBLsData) {
          FMXRender.setPBLs(PBLsData);
          if (showPBL) {
            FMXRender.showPBLs();
          }
          performance.mark('setPBLs:end');
          performance.measure('setPBLs', 'setPBLs:start', 'setPBLs:end');
        }
      }
    }
  }, [reportPBLReadyForRender]);

  // Toggle PBL
  // TODO: Is it possible to move it on perio toggle function to avoid useEffect?
  useEffect(() => {
    if (reportReadyForRender && FMXRender.isRunning()) {
      if (!hideMasks && !hidePBLFeatureFlag && showPBL) {
        FMXRender.showPBLs();
      } else {
        FMXRender.hidePBLs();
      }
    }
  }, [reportReadyForRender, showPBL, hideMasks, hidePBLFeatureFlag]);

  useEffect(
    () => () => {
      FMXRender.shutdown();
    },
    [],
  );

  // Render masks
  useEffect(() => {
    if (FMXRender.isRunning() && !previewMode && reportReadyForRender) {
      FMXRender.deleteMasks();
      if (!hideMasks && masks2DRenderData.length > 0) {
        FMXRender.addMasks(masks2DRenderData);
        FMXRender.showMasks();
      }
    }
  }, [masks2DRenderData, previewMode, reportReadyForRender, hideMasks]);

  // BBoxes render
  useEffect(() => {
    if (FMXRender.isRunning()) {
      if (hoveredBBoxes) {
        FMXRender.deleteConditionBoxes();
        FMXRender.addConditionBoxes(hoveredBBoxes);
      } else {
        FMXRender.deleteConditionBoxes();
      }
    }
  }, [hoveredBBoxes]);

  return (
    <div className={cn(styles.container, className)} ref={mainViewRef}>
      <canvas ref={canvasRef} className={styles.canvas} />
      {children}
    </div>
  );
};
