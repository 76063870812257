import { useParams } from 'react-router';

import { useAppSelector } from '@/shared/hooks';

import {
  FMXNavigationMode,
  ReportLayout,
  reportsModel,
} from '@/entities/reports';
import { ConfirmModal } from '@/entities/modal';

import { useReportDataStream } from '@/features/reportStream';
import { MaskFilters } from '@/features/maskFilters';
import { ConditionMaskTooltip } from '@/features/conditionMaskTooltip';

import { Header } from '@/widgets/Header';
import { Conclusion } from '@/widgets/Conclusion';
import { AddConditionModal } from '@/widgets/AddConditionModal';
import { PanowingsReportRender } from '@/widgets/PanowingsReportRender';
import { ReportActions } from '@/widgets/ReportActions';
import { Report2DToothChart } from '@/widgets/Report2DToothChart';

import { PanowingsToothCardList } from '../PanowingsToothCardList/PanowingsToothCardList';
import { PanowingsNavigation } from '../PanowingsNavigation/PanowingsNavigation';
import { Toolbar } from '../Toolbar/Toolbar';
import { PanowingImageSlider } from '../PanowingImageSlider/PanowingImageSlider';
import { PanowingChangeTeethNumberModal } from '../PanowingChangeTeethNumberModal/PanowingChangeTeethNumberModal';

import styles from './PanowignsReport.module.scss';

export const PanowingsReport = () => {
  const { patientID, reportID } = useParams();

  // TODO: This should be placed on parent route for the report
  useReportDataStream(reportID as string);

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  return (
    <ReportLayout.Container>
      <Header fullWidth />
      <ReportLayout.Main>
        <ReportLayout.LeftColumn>
          <h1>Panowings AI Report</h1>
          <PanowingsReportRender>
            <MaskFilters className={styles.maskFilters} />
            <ConditionMaskTooltip />
            <Toolbar
              className={styles.toolbar}
              handleScrollToothCardsToTop={() => {}}
            />
            <PanowingImageSlider />
          </PanowingsReportRender>
        </ReportLayout.LeftColumn>
        <ReportLayout.RightColumn>
          {navigationMode === FMXNavigationMode.ToothChart && (
            <Report2DToothChart />
          )}
          {navigationMode === FMXNavigationMode.MatrixView && (
            <PanowingsNavigation />
          )}

          <PanowingsToothCardList
            reportID={reportID as string}
            patientID={patientID as string}
          />

          <Conclusion />

          <ReportActions
            patientID={patientID as string}
            reportID={reportID as string}
          />
        </ReportLayout.RightColumn>
      </ReportLayout.Main>
      {/* Modals */}
      <ConfirmModal />
      <AddConditionModal />
      <PanowingChangeTeethNumberModal />
    </ReportLayout.Container>
  );
};
