import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Skeleton } from '@/shared/ui';
import { scrollToElementByID } from '@/shared/lib';

import { toothModel } from '@/entities/tooth';
import { FMXNavigationMode, reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
} from '@/entities/IOXRayImagesMatrix';

import { ToothCard } from '@/widgets/ToothCard';

import styles from './PanowingsToothCardList.module.scss';

type PanowingsToothCardListProps = {
  reportID: string;
  patientID: string;
};

export const PanowingsToothCardList = (props: PanowingsToothCardListProps) => {
  // TODO: use currentReport and currentPatient data from store to avoid prop drilling
  const { reportID, patientID } = props;

  const dispatch = useAppDispatch();
  const allTeethIDs = useAppSelector(toothModel.selectors.selectAllIds);
  const teethIDs = useAppSelector(
    toothModel.selectors.selectTeethIDsToShow(reportID),
  );
  const teethLoadingState = useAppSelector(toothModel.selectors.selectLoading);

  const navigationMode = useAppSelector(
    reportsModel.selectors.selectNavigationMode,
  );

  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID || '',
    ),
  );

  const focusedImageMetaID = useAppSelector(
    reportsModel.selectors.selectFocusedImageMetaID,
  );

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface;

  const isToothChartMode = navigationMode === FMXNavigationMode.ToothChart;

  const focusedImageInterface = [
    ...IOXRayImagesInterface,
    panoImageInterface,
  ].find((imageItem) => imageItem?.imageMeta.ID === focusedImageMetaID);

  const teethIDsToShow = isToothChartMode ? teethIDs : allTeethIDs;

  // TODO: [2|h] Move logic into selector
  const resultTeethIDs = focusedImageMetaID
    ? teethIDsToShow.filter((id) =>
        focusedImageInterface?.teethIDs.includes(id),
      )
    : teethIDsToShow;

  const handleToothClick = useCallback(
    (id: string) => {
      dispatch(reportsModel.actions.setActiveToothID(id));
      scrollToElementByID(id, 'smooth', 'start');
    },
    [dispatch],
  );

  if (teethLoadingState !== 'succeeded') {
    return <Skeleton />;
  }

  return (
    <>
      {resultTeethIDs.map((toothID) => (
        <ToothCard
          key={toothID}
          id={toothID}
          className={styles.toothCard}
          patientID={patientID}
          reportID={reportID}
          showSlices={false}
          handleCardClick={handleToothClick}
          isToothChartMode={isToothChartMode}
        />
      ))}
    </>
  );
};
