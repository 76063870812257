import { FC } from 'react';
import cn from 'classnames';
import { VirtuosoGrid } from 'react-virtuoso';
import { generatePath, useNavigate } from 'react-router';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';

import { useAppSelector, useMedia } from '@/shared/hooks';
import { TableWithInfiniteScroll } from '@/shared/ui/TableWithInfiniteScroll/TableWithInfiniteScroll';
import { NEW_LINE, PATHS, SortByFunctionType } from '@/shared/config';
import { Skeleton } from '@/shared/ui';
import { Patient } from '@/shared/api/protocol_gen/model/dto_patient';

import { patientModel } from '@/entities/patient';
import { userModel } from '@/entities/user';

import { useGetPatientsColumnsScheme } from '../../hooks/useGetPatientsColumnsScheme';
import { ListLoadingFailed } from '../ListLoadingFailed/ListLoadingFailed';
import { EmptyResult } from '../EmptyResult/EmptyResult';
import { PatientListCard } from '../PatientListCard/PatientListCard';

import styles from './PatientList.module.scss';

type PatientListProps = {
  className?: string;
  reFetchPatients: (id: string) => void;
  handleAddPatient: () => void;
  sortBy: SortByFunctionType;
  onDoctorFilterChange: (doctorsIDs: string[]) => void;
};

export const PatientList: FC<PatientListProps> = (props) => {
  const {
    className,
    reFetchPatients,
    handleAddPatient,
    sortBy,
    onDoctorFilterChange,
  } = props;

  const { totalOrganizationPatientCount } = useAppSelector(
    patientModel.selectors.selectCounters,
  );

  const { formatMessage, formatDate } = useIntl();

  const navigate = useNavigate();

  const navigateToPatientProfile = (patientID: string) => {
    const path = generatePath(PATHS.patientProfile, { patientID });
    navigate(path);
  };

  const columnsScheme = useGetPatientsColumnsScheme();
  const [searchParams] = useSearchParams();

  const search = searchParams.get('search');

  const { isPhone } = useMedia();

  const { view } = useAppSelector(userModel.selectors.selectSettings);

  const patientList = useAppSelector(patientModel.selectors.selectAll);

  const patientLastReportAssetIDs = useAppSelector(
    patientModel.selectors.selectPatientsLastReportAssetIDs,
  );

  const patientListLoading = useAppSelector((state) => state.patient.loading);

  const isRowSkeleton = view === 'row' && !isPhone;

  const isGridView = view === 'grid';

  const showSkeleton =
    (patientListLoading === 'pending' || patientListLoading === 'idle') &&
    patientList.length === 0;

  const showEmpty =
    patientListLoading === 'succeeded' &&
    patientList.length === 0 &&
    isEmpty(search);

  const showNothingFound =
    patientListLoading === 'succeeded' &&
    patientList.length === 0 &&
    Boolean(search);

  const patientListGrid = (index: number) => {
    const patient = patientList[index];

    return (
      <PatientListCard
        key={patient?.ID}
        patientID={patient?.ID}
        patientExternalID={patient.ExternalID}
        patientName={`${patient.PersonalData?.FirstName}\n${patient.PersonalData?.LastName}`}
        patientBirthDate={formatDate(
          new Date(patient.PersonalData?.DateOfBirth ?? 0),
          { dateStyle: 'long' },
        )}
        lastReportAssetIDs={patientLastReportAssetIDs[patient.ID]}
        displayAssetID={patient.DisplayAssetID}
      />
    );
  };

  const loadMore = () => {
    const patient = patientList[patientList.length - 1];
    reFetchPatients(patient.ID);
  };

  return (
    <div
      className={cn(
        styles.container,
        isGridView && styles.transparent,
        className,
      )}
    >
      {showSkeleton && !isRowSkeleton && <Skeleton.Grid />}

      {patientListLoading === 'failed' && <ListLoadingFailed />}

      {/* TODO: [1/l] showNothingFound should be displayed only in grid cases, because showNothingFound also is displayed in the TableWithInfinityScroll component */}
      {showNothingFound && isGridView && (
        <EmptyResult
          emptyText={formatMessage(
            {
              id: 'patientList.nothingFound',
              defaultMessage: `It looks like there are no patients with these search parameters.{newLine}Try to change search or filtering parameters`,
            },
            { newLine: NEW_LINE },
          )}
        />
      )}

      {view === 'row' && !isPhone && patientListLoading !== 'failed' ? (
        <TableWithInfiniteScroll
          listData={patientList}
          reFetchList={reFetchPatients}
          itemCounter={totalOrganizationPatientCount}
          clickOnRow={navigateToPatientProfile}
          columnsScheme={columnsScheme as ColumnDef<Patient>[]}
          sortBy={sortBy}
          loadingState="patient"
          showSkeleton={showSkeleton && isRowSkeleton}
          showNothingFound={showNothingFound}
          className={styles.table}
          serverSideSorting
          onDoctorFilterChange={onDoctorFilterChange}
          showEmpty={showEmpty}
          emptyComponent={
            <EmptyResult
              handleButtonClick={handleAddPatient}
              emptyText={formatMessage({
                id: 'patientList.emptyAll',
                defaultMessage: 'There are no patients yet',
              })}
            />
          }
        />
      ) : (
        patientListLoading !== 'failed' &&
        !showNothingFound &&
        (showEmpty ? (
          <EmptyResult
            handleButtonClick={handleAddPatient}
            emptyText={formatMessage({
              id: 'patientList.emptyAll',
              defaultMessage: 'There are no patients yet',
            })}
          />
        ) : (
          <VirtuosoGrid
            listClassName={styles.grid}
            itemClassName={styles.gridItem}
            className={styles.virtuosoGrid}
            totalCount={patientList.length}
            style={{ flex: 1 }}
            endReached={loadMore}
            overscan={1200}
            itemContent={patientListGrid}
          />
        ))
      )}
    </div>
  );
};
