import { FC, memo } from 'react';
import cn from 'classnames';

import { MedicalImageInterface } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { Group, MedicalImagesGroups, PathologyGroup } from '@/entities/assets';
import { organizationModel } from '@/entities/organization';

import { MedicalImageGroupSection } from '../MedicalImageGroupSection/MedicalImageGroupSection';

import styles from './MedicalImagesGroup.module.scss';

type MedicalImagesGroupProps = {
  selectedMedicalImages?: MedicalImageInterface[];
  groups: (Group | PathologyGroup)[];
  onDragMedicalImage?: (medicalImage: MedicalImageInterface) => void;
  toggleSelected?: (medicalImage: MedicalImageInterface) => void;
  className?: string;
};

export const MedicalImagesGroup: FC<MedicalImagesGroupProps> = memo((props) => {
  const {
    className,
    selectedMedicalImages,
    toggleSelected,
    onDragMedicalImage,
    groups,
  } = props;

  const hidePerioMasksAndSlices = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_PerioMasksAndSlices,
    ),
  );
  const hideCariesSigns = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_CariesSigns,
    ),
  );

  return (
    <div className={cn(styles.container, className)}>
      {groups.length &&
        groups
          .filter((group) => {
            if (group.type === MedicalImagesGroups.Caries) {
              return !hideCariesSigns;
            }

            if (group.type === MedicalImagesGroups.PeriapicalLesion) {
              return !hidePerioMasksAndSlices;
            }

            return true;
          })
          .map((group) => (
            <MedicalImageGroupSection
              key={group.type}
              group={group}
              selectedMedicalImages={selectedMedicalImages}
              onDragMedicalImage={onDragMedicalImage}
              toggleSelected={toggleSelected}
            />
          ))}
    </div>
  );
});
