import { MessageDescriptor } from 'react-intl';

import { Annotation } from '@/shared/graphics/RenderComponents/AnnotationsLayer/Annotation';
import { BBox } from '@/shared/api/protocol_gen/model/dto_common_geometry';

import { ContextRequest } from 'graphics';

import { PatientPermissions } from '../api/protocol_gen/model/dto_patient_permissions';
import { ReportPermissions } from '../api/protocol_gen/model/dto_report_permissions';

export enum LocalStorageKey {
  locale = 'locale',
  theme = 'theme',
  view = 'view',
  organizationID = 'organizationID',
  signUpFormData = 'signUpFormData',
  UTMParameters = 'UTMParameters',
}

export type LoadingStateType = 'idle' | 'pending' | 'failed' | 'succeeded';

export type PrimitiveType = string | number | boolean | null | undefined;

export type DefaultInitialState = {
  loading: LoadingStateType;
};

export enum StreamDataAccumulatorKey {
  study = 'study',
  reports = 'reports',
  teeth = 'teeth',
  conditions = 'conditions',
  newConditions = 'newConditions',
  assets = 'assets',
  access = 'access',
  initialROITeethISONumbers = 'initialROITeethISONumbers',
  toothLandmarks = 'toothLandmarks',
  initialROITeethIDs = 'initialROITeethIDs',
}

export enum AccountInfoStreamDataAccumulatorKeys {
  inventory = 'inventory',
  orders = 'orders',
}

export type Mask = {
  url: string;
  id: string;
};

export type MedicalImageInterface = {
  id: string;
  src: string;
  assetID: string;
  image?: {
    Height: number;
    Width: number;
  };
  annotations?: Annotation[];
  strideMm?: number;
  thicknessMm?: number | string;
  mask?: ContextRequest;
  path?: string;
  localizationID?: string;
  rightSideOrient?: string;
  leftSideOrient?: string;
  TargetAssetID?: string;
  BBox?: BBox;
  viewOptions?: Partial<{
    sharpness: number;
    invert: boolean;
    brightness: number;
    contrast: number;
    wwwc: { ww: number; wc: number };
  }>;
  groupName?: string | number;
  order?: number;
  kind?: 'dicom' | 'raster';
};

export type ToolNames =
  | 'ruler'
  | 'arrow'
  | 'angle'
  | 'eraser'
  | 'view'
  | 'sharpening'
  | 'expand'
  | 'brightness'
  | 'reset'
  | 'invert'
  | 'move'
  | 'zoom'
  | 'split'
  | 'sharpness'
  | 'download'
  | 'editNumbers'
  | 'ios'
  | 'mask';

export type LocationStateType = {
  lastPositionPatientProfile: number;
  lastPositionReport: number;
  recentReportIndex: number;
  toothID: string;
  lastOpenTabStudyID: string;
};

export type SortByType = {
  patientName?: boolean;
  clinicName?: boolean;
  senderName?: boolean;
  senderEmail?: boolean;
  sharingDate?: boolean;
  status?: boolean;
  recipientEmail?: boolean;
  dateOfBirth?: boolean;
  externalID?: boolean;
};

export type SortKeyType = {
  [key in PatientsListColumnName]: SortByType;
};

export type SortByFunctionType = (
  sortKey: keyof SortKeyType,
  leastOrGreatest: boolean,
) => void;

export enum PatientsListColumnName {
  ClinicName = 'Clinic name',
  SharedBy = 'Shared by',
  SenderEmail = 'Sender Email',
  SharedWith = 'Shared with',
  SharingDate = 'Sharing date',
  Status = 'Status',
  PatientName = 'PatientName',
  DateOfBirth = 'DateOfBirth',
  ExternalID = 'ExternalID',
  Doctors = 'Doctors',
  Studies = 'Studies',
  Delete = 'Delete',
}

export type SelectOptionType = {
  value: string;
  label: string;
};

export type BreadcrumbElementType = {
  label: string | JSX.Element;
  path: string;
  state?: LocationStateType;
};

export type FormatMessageFunction = (
  messageDescriptor: MessageDescriptor,
  values?: Record<string, PrimitiveType>,
) => string;

export type PatientPermissionsKeys = keyof PatientPermissions;

export type ReportPermissionsKeys = keyof ReportPermissions;
