import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import cn from 'classnames';

import { Button, Modal } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { ToothNumeration } from '@/shared/api/protocol_gen/model/dto_report_common';
import { FileMetadataImage } from '@/shared/api/protocol_gen/model/dto_asset_common';
import { checkDuplicatesInLocalizations } from '@/shared/lib';

import { ModalID, modalModel } from '@/entities/modal';
import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import {
  LocalizationWithISO,
  TeethNumbersPopup,
  toothModel,
} from '@/entities/tooth';
import { organizationModel } from '@/entities/organization';
import { assetsModel, getImageSrc } from '@/entities/assets';

import { ImageWithTeethNumbers } from '@/features/ImageWithTeethNumbers';

import styles from './PanoChangeTeethNumberModal.module.scss';

type PanoChangeTeethNumberModalProps = {
  className?: string;
};

// TODO: [m|8] write universal HOC component for modals to prevent hidden modals rendering
export const PanoChangeTeethNumberModal = () => {
  const { visible } = useAppSelector(
    modalModel.selectors.selectTeethNumberingModal,
  );

  return visible ? <PanoChangeTeethNumberModalComponent /> : null;
};

export const PanoChangeTeethNumberModalComponent: FC<
  PanoChangeTeethNumberModalProps
> = (props) => {
  const { className } = props;

  const { reportID } = useParams();

  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const { visible } = useAppSelector(
    modalModel.selectors.selectTeethNumberingModal,
  );
  const reportTeeth = useAppSelector(
    toothModel.selectors.selectByReportID(reportID as string),
  );
  const dentalNotationFormat = useAppSelector(
    organizationModel.selectors.selectDentalNotationFormat,
  );
  const panoAssetID = useAppSelector(
    reportsModel.selectors.selectCurrentReportPanoAssetID,
  );
  const panoAsset = useAppSelector(
    assetsModel.selectors.selectAssetByID(panoAssetID as string),
  );
  const deletedTooth404 = useAppSelector(
    toothModel.selectors.select404ByReportID(reportID as string),
  );

  const [selectedLocalization, setSelectedToothLocalization] = useState<
    LocalizationWithISO | undefined
  >(undefined);
  const [selectedAssetISONumbers, setSelectedAssetISONumbers] = useState<
    number[] | undefined
  >(undefined);
  const [isToothRemoving, setIsToothRemoving] = useState(false);
  const [animatedToothNumber, setAnimatedToothNumber] = useState<number>(0);
  const [panoScaledSize, setPanoScaledSize] = useState({
    width: 0,
    height: 0,
    scale: 0,
  });

  const panoContainerRef = useRef<HTMLDivElement>(null);

  const { checkReportSignature } = useCheckReportSignature();

  const allReportTeeth = deletedTooth404
    ? [...reportTeeth, deletedTooth404]
    : reportTeeth;

  const localizationItems = allReportTeeth.flatMap((tooth) =>
    tooth.Localizations.map((localization) => ({
      ISONumber: tooth.Numeration?.ISO,
      ...localization,
    })),
  ) as LocalizationWithISO[];

  const title = formatMessage({
    id: 'report.EditTeethNumbers',
    defaultMessage: 'Edit teeth numbers',
  });

  // TODO: [2|m] write selector for pano localizations
  const panoLocalizations = localizationItems.filter(
    (localizationItem) => localizationItem.TargetAssetID === panoAsset?.ID,
  ) as LocalizationWithISO[];

  const disabledByDuplicates =
    checkDuplicatesInLocalizations(panoLocalizations);

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.TeethNumberingModal));
  }, [dispatch]);

  const handleToothClick = (localization: LocalizationWithISO | undefined) => {
    setSelectedToothLocalization(localization);
  };

  // TODO?: mb move to separated hook
  const handleChangeToothNumber = (
    toothLocalizationID: string,
    newToothISONumber: number,
  ) => {
    checkReportSignature({
      onSignatureChecked: async () => {
        // TODO: [2/m] add SupernumeraryIndex in ToothNumeration, otherwise it will be equal to 0
        try {
          setAnimatedToothNumber(newToothISONumber);

          const { Teeth } = await dispatch(
            reportsModel.thunks.changeToothLocalizationNumeration({
              ToothLocalizationID: toothLocalizationID,
              Numeration: { ISO: newToothISONumber, SupernumeraryIndex: 0 },
            }),
          ).unwrap();

          dispatch(toothModel.actions.addMany(Teeth));
          setAnimatedToothNumber(0);
        } finally {
          setSelectedToothLocalization(undefined);
        }
      },
    });
  };

  const handleRemoveTooth = (toothLocalizationID: string) => {
    checkReportSignature({
      onSignatureChecked: async () => {
        setIsToothRemoving(true);
        try {
          const { Teeth } = await dispatch(
            reportsModel.thunks.changeToothLocalizationNumeration({
              ToothLocalizationID: toothLocalizationID,
              Numeration: { ISO: 404 } as unknown as ToothNumeration,
            }),
          ).unwrap();

          dispatch(toothModel.actions.addMany(Teeth));
        } finally {
          setSelectedToothLocalization(undefined);
          setIsToothRemoving(false);
        }
      },
    });
  };

  const toothClickHandle = (
    viewedISONumbers: number[],
    localization: LocalizationWithISO,
  ) => {
    setSelectedAssetISONumbers(viewedISONumbers);
    handleToothClick(localization);
  };

  // TODO: move observer to hook
  useEffect(() => {
    const panoResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { height } = entry.contentRect;

        const panoOriginalSize = panoAsset?.Study?.PanoramicXRay?.PanoramaImage
          ?.Image as FileMetadataImage;

        const PANO_CONTAINER_HEIGHT_STYLE = 1; // use for adopt scale to container flex: 0 0 70%

        const scalePanoByOriginalHeight =
          (height / panoOriginalSize?.Height) * PANO_CONTAINER_HEIGHT_STYLE;

        setPanoScaledSize({
          width: panoOriginalSize?.Width * scalePanoByOriginalHeight,
          height,
          scale: scalePanoByOriginalHeight,
        });
      }
    });

    if (panoContainerRef.current) {
      panoResizeObserver.observe(panoContainerRef.current);
    }

    return () => {
      panoResizeObserver.disconnect();
    };
  }, []);

  return (
    <Modal
      title={title}
      isOpen={visible}
      onCancel={handleClose}
      className={styles.modal}
      bodyClassName={styles.modalBody}
      containerClassName={styles.modalContainer}
      hideFooter
      borderless
      shouldRenderCloseIconButton={false}
      shouldCloseOnOverlayClick={!disabledByDuplicates}
      shouldRenderCancelButton={false}
    >
      <div className={cn(styles.container, className)}>
        <div className={styles.matrixContainer} ref={panoContainerRef}>
          <ImageWithTeethNumbers
            className={styles.panoWrap}
            localizations={panoLocalizations}
            src={getImageSrc(panoAsset?.ID as string, 'original')}
            onToothClick={toothClickHandle}
            dentalNotation={dentalNotationFormat}
            scale={panoScaledSize.scale}
            style={{
              width: panoScaledSize.width,
              height: panoScaledSize.height,
            }}
          />
        </div>
        <TeethNumbersPopup
          isOpen={!!selectedLocalization}
          onChangeToothNumber={handleChangeToothNumber}
          onChangeSelectedLocalization={handleToothClick}
          title={title}
          selectedToothLocalization={selectedLocalization}
          selectedAssetISONumbers={selectedAssetISONumbers}
          isToothRemoving={isToothRemoving}
          dentalNotationFormat={dentalNotationFormat}
          onRemoveTooth={handleRemoveTooth}
          animatedToothNumber={animatedToothNumber}
        />
      </div>
      <div className={styles.footer}>
        <Button
          size="medium"
          onClick={handleClose}
          disabled={disabledByDuplicates}
          className={styles.approveButton}
        >
          <FormattedMessage id="patient.confirm" defaultMessage="Confirm" />
        </Button>
      </div>
    </Modal>
  );
};
