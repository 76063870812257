import { useCallback } from 'react';
import { isEmpty } from 'ramda';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { scrollToElementByID } from '@/shared/lib';
import { Skeleton } from '@/shared/ui';

import { toothModel } from '@/entities/tooth';
import { reportsModel } from '@/entities/reports';

import {
  ToothChart,
  toothChartModel,
  ToothChartItemClickHandler,
} from '@/features/toothChart';

import styles from './GpToothChart.module.scss';

export const GpToothChart = () => {
  const dispatch = useAppDispatch();
  const { toothItems, toothTypesSet } = useAppSelector(
    toothChartModel.selectors.selectToothChartItems,
  );
  const customMode = useAppSelector(
    toothModel.selectors.selectToothChartCustomMode,
  );

  const isToothChartReady = !isEmpty(toothTypesSet);

  const handleToothClick = useCallback<ToothChartItemClickHandler>(
    ({ id, active }) => {
      if (customMode) {
        dispatch(toothModel.actions.setLocalROITeethIDs([id]));
      } else if (active) {
        scrollToElementByID(id);
        dispatch(reportsModel.actions.setActiveToothID(id));
      }
    },
    [customMode, dispatch],
  );

  return (
    <ToothChart.Root size="default" className={styles.container}>
      <ToothChart.Header>
        <ToothChart.CustomModeToggle />
      </ToothChart.Header>

      {isToothChartReady ? (
        <>
          <ToothChart.Tags toothTypesSet={toothTypesSet} />

          <ToothChart.Grid>
            {toothItems.map((toothItem) => (
              <ToothChart.MetaItem
                key={toothItem.id}
                toothItem={toothItem}
                handleToothClick={handleToothClick}
              />
            ))}
          </ToothChart.Grid>
        </>
      ) : (
        <>
          <Skeleton.Filter
            filtersQuantity={4}
            height={20}
            gap={4}
            borderRadius={10}
          />

          <Skeleton width={582} height={184} borderRadius={8} />
        </>
      )}
    </ToothChart.Root>
  );
};
