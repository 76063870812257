import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';
import { MedicalImageInterface } from '@/shared/config';

import {
  GroupConfigsType,
  PathologyGroup,
} from '../config/medicalImages.types';

import { getFileSrc } from './getFileSrc';

export const makePathologyGroup = (
  groupConfigs: GroupConfigsType,
  medicalImagesList: Asset[],
): PathologyGroup => {
  const { groupName, groupCode } = groupConfigs;

  const medicalImages = medicalImagesList.reduce((acc, item) => {
    if (
      item?.GeneratedReport?.CBCTToothPathologySlice?.Pathology === groupCode
    ) {
      acc.push({
        id: item?.GeneratedReport?.CBCTToothPathologySlice?.Slice?.ID || '',
        src: getFileSrc(
          item?.GeneratedReport?.CBCTToothPathologySlice?.Slice?.ID || '',
        ),
        path: item?.GeneratedReport?.CBCTToothPathologySlice?.Slice?.Path,
        assetID: item.ID,
        mask: undefined,
        groupName,
      });
    }

    return acc;
  }, [] as MedicalImageInterface[]);

  return {
    type: groupName,
    medicalImages,
  };
};
