import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { Icon, IconNames, Tooltip } from '@/shared/ui';
import { ToolNames } from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';

import { ModalID, modalModel } from '@/entities/modal';
import { ActiveControlType } from '@/entities/reports';

import { toolsMessages } from '../../config/i18n';

import styles from './ImageToolbar.module.scss';

export const CONTROL_TOOLS: Record<ToolNames, string> = {
  ruler: 'ruler',
  arrow: 'arrow',
  angle: 'angle',
  eraser: 'erase',
  view: 'eyeFilled',
  brightness: 'brightness',
  sharpening: 'shaprness',
  expand: 'expand',
  invert: 'invert',
  reset: 'back',
  move: 'move',
  zoom: 'zoom',
  split: 'split',
  download: 'download',
  sharpness: 'shaprness',
  editNumbers: 'toothnum',
  ios: 'ios',
  mask: 'mask',
};

type SliceToolbarProps = {
  controls: ToolNames[];
  activeControl: string;
  setActiveControl: (newControl: ActiveControlType) => void;
  isInverted?: boolean;
  isSplit?: boolean;
  toggleIsInverted?: () => void;
  tooltipPosition?: 'top' | 'right' | 'left' | 'bottom';
  className?: string;
  toggleIsSplit?: () => void;
};

// TODO need global refactoring including reports where its used
export const ImageToolbar: FC<SliceToolbarProps> = (props) => {
  const {
    controls,
    activeControl,
    setActiveControl,
    isInverted,
    isSplit,
    toggleIsInverted,
    tooltipPosition,
    toggleIsSplit,
    className,
  } = props;

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const handleOpenEditModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.TeethNumberingModal,
        data: {},
      }),
    );
  }, [dispatch]);

  const handleControlClick = (control: ToolNames) => {
    if (activeControl === 'eyeFilled' && control === 'view') {
      return setActiveControl('');
    }

    if (control === 'editNumbers') {
      handleOpenEditModal();
      return setActiveControl('');
    }

    if (control === 'invert' && toggleIsInverted) {
      toggleIsInverted();
    }

    if (control === 'split') {
      if (toggleIsSplit) {
        toggleIsSplit();
      }
    }

    return control === activeControl && control !== 'invert'
      ? setActiveControl('')
      : control !== 'invert' && setActiveControl(control);
  };

  const isIconActive = (control: ToolNames) => {
    if (
      activeControl === control &&
      activeControl !== 'invert' &&
      activeControl !== 'split'
    ) {
      return true;
    }

    if (
      (control === 'invert' && isInverted) ||
      (control === 'split' && isSplit)
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className={cn(styles.container, className)}>
      {controls.map((control) => {
        const toolIcon = (
          <Icon
            key={control}
            name={CONTROL_TOOLS[control] as IconNames}
            className={cn(styles.icon, isIconActive(control) && styles.active)}
            size={32}
            onClick={() => handleControlClick(control)}
          />
        );
        if (tooltipPosition) {
          return (
            <Tooltip.Primary
              key={control}
              side={tooltipPosition}
              content={
                <span className="p3">
                  {formatMessage(toolsMessages[control])}
                </span>
              }
            >
              {toolIcon}
            </Tooltip.Primary>
          );
        }
        return toolIcon;
      })}
    </div>
  );
};
