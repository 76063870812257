import { FC } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { FMXRender } from '@/shared/graphics/viewer2D/Viewer2D';

import { reportsModel } from '@/entities/reports';

import { IOXRayMatrixPreview } from '@/features/IOXRayMatrixPreview';

import styles from './IOXRayNavigation.module.scss';

type IOXRayNavigationProps = {
  className?: string;
};

export const IOXRayNavigation: FC<IOXRayNavigationProps> = (props) => {
  const { className } = props;

  const { reportID } = useParams();
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const focusedAssetId = useAppSelector(
    reportsModel.selectors.selectFocusedImageMetaID,
  );

  const onImageClickHandle = (assetID: string) => {
    FMXRender.activateMode('mainMode');
    dispatch(reportsModel.actions.setToolbarActiveControl('view'));
    dispatch(reportsModel.actions.setFocusedMetaImageID(assetID));
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h2 className="h5">
          {formatMessage({
            id: 'IOXRayNavigation.title',
            defaultMessage: 'Teeth in the report',
          })}
        </h2>
      </header>
      <IOXRayMatrixPreview
        className={cn(styles.matrixContainer, className)}
        onImageClick={onImageClickHandle}
        focusedImageMetaID={focusedAssetId}
        reportID={reportID as string}
      />
    </div>
  );
};
