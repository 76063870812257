import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Back, Breadcrumbs } from '@/shared/ui';

import { useBreadcrumbs } from '../hooks/useBreadcrumbs';

import { Logo } from './Logo/Logo';
import { ControlPanel } from './ControlPanel/ControlPanel';
import styles from './Header.module.scss';
import { LanguageSelector } from './LanguageSelector/LanguageSelector';

type HeaderProps = {
  fullWidth?: boolean;
  className?: string;
  back?: boolean;
};

export const Header: FC<HeaderProps> = (props) => {
  const { fullWidth = false, className, back } = props;

  // TODO: [8/l] add when the design is ready
  // const changeTheme = () => {
  //   document.body.classList.toggle('dark');
  // };

  const breadCrumbs = useBreadcrumbs();

  return (
    <header
      className={cn(styles.container, fullWidth && styles.fullWidth, className)}
    >
      <Logo className={styles.logo} />

      <div className={styles.navigation}>
        {back && <Back />}

        <Breadcrumbs breadCrumbs={breadCrumbs} />
      </div>

      {!window?.location.pathname?.includes('reports') && (
        <div className={styles.warning}>
          <FormattedMessage
            id="header.betaWarning"
            defaultMessage="WARNING: Not for commercial use - Beta Testing"
          />
        </div>
      )}
      <div className={styles.settingsWrapper}>
        {/* TODO: [8/l] add when the design is ready */}
        {/* <Icon
          className={styles.icon}
          name="moon"
          // name="sun"
          onClick={changeTheme}
        /> */}

        <LanguageSelector />
        <ControlPanel />
      </div>
    </header>
  );
};
