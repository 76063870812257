/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { File } from '../model/dto_asset_common';
import {
  ToothNumeration,
  IOSAnatomy,
  CBCTAnatomy,
  iOSAnatomyFromJSON,
  cBCTAnatomyFromJSON,
  iOSAnatomyToJSON,
  cBCTAnatomyToJSON,
} from '../model/dto_report_common';

export const protobufPackage = 'com.diagnocat.model';

export enum CBCTToothAnatomy {
  CBCT_Tooth_Anatomy_InvalidValue = 0,
  CBCT_Tooth_Anatomy_Pulp = 1,
  CBCT_Tooth_Anatomy_Dentin = 2,
  CBCT_Tooth_Anatomy_Enamel = 3,
  UNRECOGNIZED = -1,
}

export function cBCTToothAnatomyFromJSON(object: any): CBCTToothAnatomy {
  switch (object) {
    case 0:
    case 'CBCT_Tooth_Anatomy_InvalidValue':
      return CBCTToothAnatomy.CBCT_Tooth_Anatomy_InvalidValue;
    case 1:
    case 'CBCT_Tooth_Anatomy_Pulp':
      return CBCTToothAnatomy.CBCT_Tooth_Anatomy_Pulp;
    case 2:
    case 'CBCT_Tooth_Anatomy_Dentin':
      return CBCTToothAnatomy.CBCT_Tooth_Anatomy_Dentin;
    case 3:
    case 'CBCT_Tooth_Anatomy_Enamel':
      return CBCTToothAnatomy.CBCT_Tooth_Anatomy_Enamel;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return CBCTToothAnatomy.UNRECOGNIZED;
  }
}

export function cBCTToothAnatomyToJSON(object: CBCTToothAnatomy): string {
  switch (object) {
    case CBCTToothAnatomy.CBCT_Tooth_Anatomy_InvalidValue:
      return 'CBCT_Tooth_Anatomy_InvalidValue';
    case CBCTToothAnatomy.CBCT_Tooth_Anatomy_Pulp:
      return 'CBCT_Tooth_Anatomy_Pulp';
    case CBCTToothAnatomy.CBCT_Tooth_Anatomy_Dentin:
      return 'CBCT_Tooth_Anatomy_Dentin';
    case CBCTToothAnatomy.CBCT_Tooth_Anatomy_Enamel:
      return 'CBCT_Tooth_Anatomy_Enamel';
    case CBCTToothAnatomy.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface AssetContentGeneratedMeshModel {
  /** optional, used only in segmentation */
  SegmentID: string;
  /** and superimposition */
  Mesh: File | undefined;
  CBCTTooth: ToothNumeration | undefined;
  IOSTooth: ToothNumeration | undefined;
  FusionTooth: ToothNumeration | undefined;
  IOSAnatomy: IOSAnatomy | undefined;
  CBCTAnatomy: CBCTAnatomy | undefined;
  CBCTToothPeriapicalRadiolucency:
    | AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency
    | undefined;
  ArtificialCrown: ToothNumeration | undefined;
  CBCTToothAnatomy: AssetContentGeneratedMeshModel_CBCTToothAnatomy | undefined;
  CBCTToothCondition:
    | AssetContentGeneratedMeshModel_CBCTToothCondition
    | undefined;
  /**
   * the following files represent gzipped JSONs with additional face
   * attributes that currently cannot be plugged into draco files
   */
  ToothNumerationWithSurfaceAttributes: File | undefined;
  /** only for fused teeth; contained keys: */
  FusedModalityAttributes: File | undefined;
}

export interface AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency {
  Numeration: ToothNumeration | undefined;
  /** there may be several lesions for one tooth */
  LesionIndex: number;
}

export interface AssetContentGeneratedMeshModel_CBCTToothAnatomy {
  Numeration: ToothNumeration | undefined;
  Anatomy: CBCTToothAnatomy;
}

export interface AssetContentGeneratedMeshModel_CBCTToothCondition {
  Numeration: ToothNumeration | undefined;
  Condition: AssetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothCondition;
}

export enum AssetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothCondition {
  InvalidValue = 0,
  CanalFilling = 1,
  UNRECOGNIZED = -1,
}

export function assetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothConditionFromJSON(
  object: any,
): AssetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothCondition {
  switch (object) {
    case 0:
    case 'InvalidValue':
      return AssetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothCondition.InvalidValue;
    case 1:
    case 'CanalFilling':
      return AssetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothCondition.CanalFilling;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return AssetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothCondition.UNRECOGNIZED;
  }
}

export function assetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothConditionToJSON(
  object: AssetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothCondition,
): string {
  switch (object) {
    case AssetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothCondition.InvalidValue:
      return 'InvalidValue';
    case AssetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothCondition.CanalFilling:
      return 'CanalFilling';
    case AssetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothCondition.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

function createBaseAssetContentGeneratedMeshModel(): AssetContentGeneratedMeshModel {
  return {
    SegmentID: '',
    Mesh: undefined,
    CBCTTooth: undefined,
    IOSTooth: undefined,
    FusionTooth: undefined,
    IOSAnatomy: undefined,
    CBCTAnatomy: undefined,
    CBCTToothPeriapicalRadiolucency: undefined,
    ArtificialCrown: undefined,
    CBCTToothAnatomy: undefined,
    CBCTToothCondition: undefined,
    ToothNumerationWithSurfaceAttributes: undefined,
    FusedModalityAttributes: undefined,
  };
}

export const AssetContentGeneratedMeshModel = {
  encode(
    message: AssetContentGeneratedMeshModel,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.SegmentID !== '') {
      writer.uint32(10).string(message.SegmentID);
    }
    if (message.Mesh !== undefined) {
      File.encode(message.Mesh, writer.uint32(18).fork()).ldelim();
    }
    if (message.CBCTTooth !== undefined) {
      ToothNumeration.encode(
        message.CBCTTooth,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.IOSTooth !== undefined) {
      ToothNumeration.encode(
        message.IOSTooth,
        writer.uint32(98).fork(),
      ).ldelim();
    }
    if (message.FusionTooth !== undefined) {
      ToothNumeration.encode(
        message.FusionTooth,
        writer.uint32(106).fork(),
      ).ldelim();
    }
    if (message.IOSAnatomy !== undefined) {
      writer.uint32(112).int32(message.IOSAnatomy);
    }
    if (message.CBCTAnatomy !== undefined) {
      writer.uint32(120).int32(message.CBCTAnatomy);
    }
    if (message.CBCTToothPeriapicalRadiolucency !== undefined) {
      AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency.encode(
        message.CBCTToothPeriapicalRadiolucency,
        writer.uint32(130).fork(),
      ).ldelim();
    }
    if (message.ArtificialCrown !== undefined) {
      ToothNumeration.encode(
        message.ArtificialCrown,
        writer.uint32(138).fork(),
      ).ldelim();
    }
    if (message.CBCTToothAnatomy !== undefined) {
      AssetContentGeneratedMeshModel_CBCTToothAnatomy.encode(
        message.CBCTToothAnatomy,
        writer.uint32(170).fork(),
      ).ldelim();
    }
    if (message.CBCTToothCondition !== undefined) {
      AssetContentGeneratedMeshModel_CBCTToothCondition.encode(
        message.CBCTToothCondition,
        writer.uint32(178).fork(),
      ).ldelim();
    }
    if (message.ToothNumerationWithSurfaceAttributes !== undefined) {
      File.encode(
        message.ToothNumerationWithSurfaceAttributes,
        writer.uint32(80002).fork(),
      ).ldelim();
    }
    if (message.FusedModalityAttributes !== undefined) {
      File.encode(
        message.FusedModalityAttributes,
        writer.uint32(80010).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedMeshModel {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedMeshModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.SegmentID = reader.string();
          break;
        case 2:
          message.Mesh = File.decode(reader, reader.uint32());
          break;
        case 11:
          message.CBCTTooth = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 12:
          message.IOSTooth = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 13:
          message.FusionTooth = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 14:
          message.IOSAnatomy = reader.int32() as any;
          break;
        case 15:
          message.CBCTAnatomy = reader.int32() as any;
          break;
        case 16:
          message.CBCTToothPeriapicalRadiolucency =
            AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 17:
          message.ArtificialCrown = ToothNumeration.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 21:
          message.CBCTToothAnatomy =
            AssetContentGeneratedMeshModel_CBCTToothAnatomy.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 22:
          message.CBCTToothCondition =
            AssetContentGeneratedMeshModel_CBCTToothCondition.decode(
              reader,
              reader.uint32(),
            );
          break;
        case 10000:
          message.ToothNumerationWithSurfaceAttributes = File.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10001:
          message.FusedModalityAttributes = File.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedMeshModel {
    return {
      SegmentID: isSet(object.SegmentID) ? String(object.SegmentID) : '',
      Mesh: isSet(object.Mesh) ? File.fromJSON(object.Mesh) : undefined,
      CBCTTooth: isSet(object.CBCTTooth)
        ? ToothNumeration.fromJSON(object.CBCTTooth)
        : undefined,
      IOSTooth: isSet(object.IOSTooth)
        ? ToothNumeration.fromJSON(object.IOSTooth)
        : undefined,
      FusionTooth: isSet(object.FusionTooth)
        ? ToothNumeration.fromJSON(object.FusionTooth)
        : undefined,
      IOSAnatomy: isSet(object.IOSAnatomy)
        ? iOSAnatomyFromJSON(object.IOSAnatomy)
        : undefined,
      CBCTAnatomy: isSet(object.CBCTAnatomy)
        ? cBCTAnatomyFromJSON(object.CBCTAnatomy)
        : undefined,
      CBCTToothPeriapicalRadiolucency: isSet(
        object.CBCTToothPeriapicalRadiolucency,
      )
        ? AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency.fromJSON(
            object.CBCTToothPeriapicalRadiolucency,
          )
        : undefined,
      ArtificialCrown: isSet(object.ArtificialCrown)
        ? ToothNumeration.fromJSON(object.ArtificialCrown)
        : undefined,
      CBCTToothAnatomy: isSet(object.CBCTToothAnatomy)
        ? AssetContentGeneratedMeshModel_CBCTToothAnatomy.fromJSON(
            object.CBCTToothAnatomy,
          )
        : undefined,
      CBCTToothCondition: isSet(object.CBCTToothCondition)
        ? AssetContentGeneratedMeshModel_CBCTToothCondition.fromJSON(
            object.CBCTToothCondition,
          )
        : undefined,
      ToothNumerationWithSurfaceAttributes: isSet(
        object.ToothNumerationWithSurfaceAttributes,
      )
        ? File.fromJSON(object.ToothNumerationWithSurfaceAttributes)
        : undefined,
      FusedModalityAttributes: isSet(object.FusedModalityAttributes)
        ? File.fromJSON(object.FusedModalityAttributes)
        : undefined,
    };
  },

  toJSON(message: AssetContentGeneratedMeshModel): unknown {
    const obj: any = {};
    message.SegmentID !== undefined && (obj.SegmentID = message.SegmentID);
    message.Mesh !== undefined &&
      (obj.Mesh = message.Mesh ? File.toJSON(message.Mesh) : undefined);
    message.CBCTTooth !== undefined &&
      (obj.CBCTTooth = message.CBCTTooth
        ? ToothNumeration.toJSON(message.CBCTTooth)
        : undefined);
    message.IOSTooth !== undefined &&
      (obj.IOSTooth = message.IOSTooth
        ? ToothNumeration.toJSON(message.IOSTooth)
        : undefined);
    message.FusionTooth !== undefined &&
      (obj.FusionTooth = message.FusionTooth
        ? ToothNumeration.toJSON(message.FusionTooth)
        : undefined);
    message.IOSAnatomy !== undefined &&
      (obj.IOSAnatomy =
        message.IOSAnatomy !== undefined
          ? iOSAnatomyToJSON(message.IOSAnatomy)
          : undefined);
    message.CBCTAnatomy !== undefined &&
      (obj.CBCTAnatomy =
        message.CBCTAnatomy !== undefined
          ? cBCTAnatomyToJSON(message.CBCTAnatomy)
          : undefined);
    message.CBCTToothPeriapicalRadiolucency !== undefined &&
      (obj.CBCTToothPeriapicalRadiolucency =
        message.CBCTToothPeriapicalRadiolucency
          ? AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency.toJSON(
              message.CBCTToothPeriapicalRadiolucency,
            )
          : undefined);
    message.ArtificialCrown !== undefined &&
      (obj.ArtificialCrown = message.ArtificialCrown
        ? ToothNumeration.toJSON(message.ArtificialCrown)
        : undefined);
    message.CBCTToothAnatomy !== undefined &&
      (obj.CBCTToothAnatomy = message.CBCTToothAnatomy
        ? AssetContentGeneratedMeshModel_CBCTToothAnatomy.toJSON(
            message.CBCTToothAnatomy,
          )
        : undefined);
    message.CBCTToothCondition !== undefined &&
      (obj.CBCTToothCondition = message.CBCTToothCondition
        ? AssetContentGeneratedMeshModel_CBCTToothCondition.toJSON(
            message.CBCTToothCondition,
          )
        : undefined);
    message.ToothNumerationWithSurfaceAttributes !== undefined &&
      (obj.ToothNumerationWithSurfaceAttributes =
        message.ToothNumerationWithSurfaceAttributes
          ? File.toJSON(message.ToothNumerationWithSurfaceAttributes)
          : undefined);
    message.FusedModalityAttributes !== undefined &&
      (obj.FusedModalityAttributes = message.FusedModalityAttributes
        ? File.toJSON(message.FusedModalityAttributes)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<AssetContentGeneratedMeshModel>, I>>(
    object: I,
  ): AssetContentGeneratedMeshModel {
    const message = createBaseAssetContentGeneratedMeshModel();
    message.SegmentID = object.SegmentID ?? '';
    message.Mesh =
      object.Mesh !== undefined && object.Mesh !== null
        ? File.fromPartial(object.Mesh)
        : undefined;
    message.CBCTTooth =
      object.CBCTTooth !== undefined && object.CBCTTooth !== null
        ? ToothNumeration.fromPartial(object.CBCTTooth)
        : undefined;
    message.IOSTooth =
      object.IOSTooth !== undefined && object.IOSTooth !== null
        ? ToothNumeration.fromPartial(object.IOSTooth)
        : undefined;
    message.FusionTooth =
      object.FusionTooth !== undefined && object.FusionTooth !== null
        ? ToothNumeration.fromPartial(object.FusionTooth)
        : undefined;
    message.IOSAnatomy = object.IOSAnatomy ?? undefined;
    message.CBCTAnatomy = object.CBCTAnatomy ?? undefined;
    message.CBCTToothPeriapicalRadiolucency =
      object.CBCTToothPeriapicalRadiolucency !== undefined &&
      object.CBCTToothPeriapicalRadiolucency !== null
        ? AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency.fromPartial(
            object.CBCTToothPeriapicalRadiolucency,
          )
        : undefined;
    message.ArtificialCrown =
      object.ArtificialCrown !== undefined && object.ArtificialCrown !== null
        ? ToothNumeration.fromPartial(object.ArtificialCrown)
        : undefined;
    message.CBCTToothAnatomy =
      object.CBCTToothAnatomy !== undefined && object.CBCTToothAnatomy !== null
        ? AssetContentGeneratedMeshModel_CBCTToothAnatomy.fromPartial(
            object.CBCTToothAnatomy,
          )
        : undefined;
    message.CBCTToothCondition =
      object.CBCTToothCondition !== undefined &&
      object.CBCTToothCondition !== null
        ? AssetContentGeneratedMeshModel_CBCTToothCondition.fromPartial(
            object.CBCTToothCondition,
          )
        : undefined;
    message.ToothNumerationWithSurfaceAttributes =
      object.ToothNumerationWithSurfaceAttributes !== undefined &&
      object.ToothNumerationWithSurfaceAttributes !== null
        ? File.fromPartial(object.ToothNumerationWithSurfaceAttributes)
        : undefined;
    message.FusedModalityAttributes =
      object.FusedModalityAttributes !== undefined &&
      object.FusedModalityAttributes !== null
        ? File.fromPartial(object.FusedModalityAttributes)
        : undefined;
    return message;
  },
};

function createBaseAssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency(): AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency {
  return { Numeration: undefined, LesionIndex: 0 };
}

export const AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency = {
  encode(
    message: AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Numeration !== undefined) {
      ToothNumeration.encode(
        message.Numeration,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.LesionIndex !== 0) {
      writer.uint32(16).uint32(message.LesionIndex);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseAssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Numeration = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 2:
          message.LesionIndex = reader.uint32();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(
    object: any,
  ): AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency {
    return {
      Numeration: isSet(object.Numeration)
        ? ToothNumeration.fromJSON(object.Numeration)
        : undefined,
      LesionIndex: isSet(object.LesionIndex) ? Number(object.LesionIndex) : 0,
    };
  },

  toJSON(
    message: AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency,
  ): unknown {
    const obj: any = {};
    message.Numeration !== undefined &&
      (obj.Numeration = message.Numeration
        ? ToothNumeration.toJSON(message.Numeration)
        : undefined);
    message.LesionIndex !== undefined &&
      (obj.LesionIndex = Math.round(message.LesionIndex));
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency>,
      I
    >,
  >(object: I): AssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency {
    const message =
      createBaseAssetContentGeneratedMeshModel_CBCTToothPeriapicalRadiolucency();
    message.Numeration =
      object.Numeration !== undefined && object.Numeration !== null
        ? ToothNumeration.fromPartial(object.Numeration)
        : undefined;
    message.LesionIndex = object.LesionIndex ?? 0;
    return message;
  },
};

function createBaseAssetContentGeneratedMeshModel_CBCTToothAnatomy(): AssetContentGeneratedMeshModel_CBCTToothAnatomy {
  return { Numeration: undefined, Anatomy: 0 };
}

export const AssetContentGeneratedMeshModel_CBCTToothAnatomy = {
  encode(
    message: AssetContentGeneratedMeshModel_CBCTToothAnatomy,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Numeration !== undefined) {
      ToothNumeration.encode(
        message.Numeration,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Anatomy !== 0) {
      writer.uint32(16).int32(message.Anatomy);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedMeshModel_CBCTToothAnatomy {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetContentGeneratedMeshModel_CBCTToothAnatomy();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Numeration = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 2:
          message.Anatomy = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedMeshModel_CBCTToothAnatomy {
    return {
      Numeration: isSet(object.Numeration)
        ? ToothNumeration.fromJSON(object.Numeration)
        : undefined,
      Anatomy: isSet(object.Anatomy)
        ? cBCTToothAnatomyFromJSON(object.Anatomy)
        : 0,
    };
  },

  toJSON(message: AssetContentGeneratedMeshModel_CBCTToothAnatomy): unknown {
    const obj: any = {};
    message.Numeration !== undefined &&
      (obj.Numeration = message.Numeration
        ? ToothNumeration.toJSON(message.Numeration)
        : undefined);
    message.Anatomy !== undefined &&
      (obj.Anatomy = cBCTToothAnatomyToJSON(message.Anatomy));
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedMeshModel_CBCTToothAnatomy>,
      I
    >,
  >(object: I): AssetContentGeneratedMeshModel_CBCTToothAnatomy {
    const message = createBaseAssetContentGeneratedMeshModel_CBCTToothAnatomy();
    message.Numeration =
      object.Numeration !== undefined && object.Numeration !== null
        ? ToothNumeration.fromPartial(object.Numeration)
        : undefined;
    message.Anatomy = object.Anatomy ?? 0;
    return message;
  },
};

function createBaseAssetContentGeneratedMeshModel_CBCTToothCondition(): AssetContentGeneratedMeshModel_CBCTToothCondition {
  return { Numeration: undefined, Condition: 0 };
}

export const AssetContentGeneratedMeshModel_CBCTToothCondition = {
  encode(
    message: AssetContentGeneratedMeshModel_CBCTToothCondition,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Numeration !== undefined) {
      ToothNumeration.encode(
        message.Numeration,
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Condition !== 0) {
      writer.uint32(16).int32(message.Condition);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): AssetContentGeneratedMeshModel_CBCTToothCondition {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message =
      createBaseAssetContentGeneratedMeshModel_CBCTToothCondition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Numeration = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 2:
          message.Condition = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AssetContentGeneratedMeshModel_CBCTToothCondition {
    return {
      Numeration: isSet(object.Numeration)
        ? ToothNumeration.fromJSON(object.Numeration)
        : undefined,
      Condition: isSet(object.Condition)
        ? assetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothConditionFromJSON(
            object.Condition,
          )
        : 0,
    };
  },

  toJSON(message: AssetContentGeneratedMeshModel_CBCTToothCondition): unknown {
    const obj: any = {};
    message.Numeration !== undefined &&
      (obj.Numeration = message.Numeration
        ? ToothNumeration.toJSON(message.Numeration)
        : undefined);
    message.Condition !== undefined &&
      (obj.Condition =
        assetContentGeneratedMeshModel_CBCTToothCondition_CBCTToothConditionToJSON(
          message.Condition,
        ));
    return obj;
  },

  fromPartial<
    I extends Exact<
      DeepPartial<AssetContentGeneratedMeshModel_CBCTToothCondition>,
      I
    >,
  >(object: I): AssetContentGeneratedMeshModel_CBCTToothCondition {
    const message =
      createBaseAssetContentGeneratedMeshModel_CBCTToothCondition();
    message.Numeration =
      object.Numeration !== undefined && object.Numeration !== null
        ? ToothNumeration.fromPartial(object.Numeration)
        : undefined;
    message.Condition = object.Condition ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
