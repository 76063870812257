import { useIntl } from 'react-intl';
import cn from 'classnames';

import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { FMXRender } from '@/shared/graphics/viewer2D/Viewer2D';

import { ImageSizeType, getImageSrc } from '@/entities/assets';
import { i18n } from '@/entities/study';
import { reportsModel } from '@/entities/reports';
import {
  IOXRayImagesInterfaceModel,
  IOXrayImageInterface,
  groupIOXRayImagesByPartition,
} from '@/entities/IOXRayImagesMatrix';

import { getImageSizeDataByType } from '@/features/IOXRayMatrixPreview';

import styles from './PanowingsNavigation.module.scss';

type PanowingsNavigationProps = {
  className?: string;
};

const PREVIEW_IMAGE_SIZE: ImageSizeType = 'thumbnail';

export const PanowingsNavigation = (props: PanowingsNavigationProps) => {
  const { className } = props;

  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const currentReport = useAppSelector(
    reportsModel.selectors.selectCurrentReport,
  );

  const focusedImageMetaID = useAppSelector(
    reportsModel.selectors.selectFocusedImageMetaID,
  );

  const reportID = currentReport?.ID as string;

  const panoImageInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectPanoImageInterfaceByReportID(
      reportID,
    ),
  ) as IOXrayImageInterface;

  // TODO: All you need to render peview is image src. Need to refactor
  const IOXRayImagesInterface = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayImagesInterfaceByReportID(
      reportID,
    ),
  );

  const groupedIOXRayImages = groupIOXRayImagesByPartition(
    IOXRayImagesInterface,
  );

  const bitewingsImagesInterface = [
    ...groupedIOXRayImages['MiddleLeft'],
    ...groupedIOXRayImages['MiddleRight'],
  ];

  const onImageClickHandle = (imageMetaID: string) => {
    dispatch(reportsModel.actions.setFocusedMetaImageID(imageMetaID));
    FMXRender.layoutModes.focus(imageMetaID);
  };

  const previewImageSrc = panoImageInterface
    ? getImageSrc(panoImageInterface.asset.ID, PREVIEW_IMAGE_SIZE)
    : '';

  const BITEWINGS_CONTAINER_HEIGHT = 80;

  return (
    <div className={cn(styles.container, className)}>
      <header className={styles.header}>
        <h2 className="h5">
          {formatMessage({
            id: 'panowingsNavigation.title',
            defaultMessage: 'Teeth in the report',
          })}
        </h2>
      </header>
      <div className={cn(styles.navigatinContainer, className)}>
        <div
          className={cn(styles.panoContainer)}
          onClick={() => {
            onImageClickHandle(panoImageInterface.imageMeta?.ID || '');
          }}
        >
          <img
            className={cn(
              styles.panoImage,
              styles.image,
              focusedImageMetaID === panoImageInterface?.imageMeta?.ID
                ? styles.focused
                : '',
            )}
            src={previewImageSrc}
            alt={formatMessage(
              i18n.studyTypes[StudyType.StudyType_PanoramicXRay],
            )}
          />
        </div>

        <div
          className={styles.bitewings}
          style={{ height: BITEWINGS_CONTAINER_HEIGHT }}
        >
          {bitewingsImagesInterface.map((imageItem) => {
            const isRotated =
              imageItem.imageMeta.OrientationAngle === 90 ||
              imageItem.imageMeta.OrientationAngle === 270;

            const imageSize = getImageSizeDataByType(
              imageItem,
              PREVIEW_IMAGE_SIZE,
            );

            const scaleByHeight = BITEWINGS_CONTAINER_HEIGHT / imageSize.height;
            const scaleByWidth = BITEWINGS_CONTAINER_HEIGHT / imageSize.width;

            const initialScaledWidth = scaleByHeight * imageSize.width;
            const initialScaledHeight = scaleByHeight * imageSize.height;

            const rotatedScaledWidth = scaleByWidth * imageSize.width;
            const rotatedScaledHeight = scaleByWidth * imageSize.height;

            const imageWidth = isRotated
              ? rotatedScaledWidth
              : initialScaledWidth;
            const imageHeight = isRotated
              ? rotatedScaledHeight
              : initialScaledHeight;

            const containerHeight = BITEWINGS_CONTAINER_HEIGHT;
            const containerWidth = isRotated
              ? rotatedScaledHeight
              : initialScaledWidth;

            return (
              <div
                style={{
                  width: containerWidth,
                  height: containerHeight,
                }}
                className={cn(
                  styles.bitewingImage,
                  styles.image,
                  focusedImageMetaID === imageItem.imageMeta.ID
                    ? styles.focused
                    : '',
                )}
                onClick={() => {
                  onImageClickHandle(imageItem.imageMeta.ID);
                }}
              >
                <img
                  style={{
                    objectFit: 'contain',
                    width: imageWidth,
                    height: imageHeight,
                    transformOrigin: 'center center',
                    transform: `rotate(${imageItem.imageMeta.OrientationAngle * (Math.PI / 180)}rad)`,
                  }}
                  src={getImageSrc(imageItem.asset.ID, PREVIEW_IMAGE_SIZE)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
