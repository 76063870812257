import {
  LABELING_CANADA_COUNTRY,
  LABELING_CIS_COUNTRIES,
  LABELING_EU_COUNTRIES,
  LABELING_ISRAEL_COUNTRY,
  LABELING_MEXICO_COUNTRY,
  LABELING_SWITZERLAND_COUNTRY,
  LABELING_UK_COUNTRY,
  LABELING_US_COUNTRY,
  LabelingCountryGroupTypes,
} from '../config';

export const getLabelingCountryGroup = (
  country: string,
): LabelingCountryGroupTypes | undefined => {
  if (LABELING_EU_COUNTRIES.includes(country)) {
    return 'EU';
  }

  if (LABELING_UK_COUNTRY.includes(country)) {
    return 'UK';
  }

  if (LABELING_SWITZERLAND_COUNTRY.includes(country)) {
    return 'Switzerland';
  }

  if (LABELING_US_COUNTRY.includes(country)) {
    return 'US';
  }

  if (LABELING_MEXICO_COUNTRY.includes(country)) {
    return 'Mexico';
  }

  if (LABELING_CANADA_COUNTRY.includes(country)) {
    return 'Canada';
  }

  if (LABELING_ISRAEL_COUNTRY.includes(country)) {
    return 'Israel';
  }

  if (LABELING_CIS_COUNTRIES.includes(country)) {
    return 'CIS';
  }

  return undefined;
};
