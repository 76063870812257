import * as process from 'process';

// import packageJSON from 'package.json';

console.log(import.meta.env);
console.log(process.env);
console.log(__REACT_APP_AVAILABLE_LANGS__);
console.log(__REACT_APP_BASE_URI__);
console.log(__REACT_APP_ROOT_URI__);
console.log(__REACT_APP_CORS_POLICY__);
console.log(__APP_VERSION__);

export const BASE_URI =
  __REACT_APP_ROOT_URI__ || __REACT_APP_BASE_URI__ || window.location.origin;
export const ENVIRONMENT = import.meta.env.MODE || 'development';
export const APP_VERSION = __APP_VERSION__;
export const IS_DEVELOP_FEATURE = ENVIRONMENT === 'development';

// sentry variables
export const SENTRY_DSN = import.meta.env.REACT_APP_SENTRY_DSN || '';
export const SENTRY_TRACES_SAMPLE_RATE =
  parseFloat(import.meta.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || 0.2;
export const ERROR_TRACKING_ENABLED_ENVIRONMENTS = ['production', 'staging'];
export const IS_ERROR_TRACKING_ENABLED =
  ERROR_TRACKING_ENABLED_ENVIRONMENTS.includes(ENVIRONMENT);

// debug mode
export const DEBUG_MODE = true;

export const CORS_POLICY =
  (__REACT_APP_CORS_POLICY__ as RequestCredentials) || 'same-origin';
