import { FC } from 'react';
import cn from 'classnames';

import { Layout } from '@/shared/ui/';

import { Header } from '@/widgets/Header';

import styles from './ConfirmSharing.module.scss';

type ConfirmSharingProps = {
  className?: string;
  testID?: string;
};

export const ConfirmSharing: FC<ConfirmSharingProps> = (props) => {
  const { className, testID } = props;

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout.Content>
        <Layout.Main>
          <div className={cn(styles.container, className)} data-testid={testID}>
            ConfirmSharing page
          </div>
        </Layout.Main>
      </Layout.Content>
    </Layout>
  );
};
