import { FC } from 'react';
import cn from 'classnames';
import { generatePath, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Avatar } from '@/shared/ui';
import { PATHS } from '@/shared/config';
import { useAppSelector } from '@/shared/hooks';

import { StudiesCounters } from '@/entities/patient';
import { getImageSrc } from '@/entities/assets';
import { DoctorsAvatars } from '@/entities/doctors';

import { patientDoctorsModel } from '@/features/patientDoctors';

import styles from './PatientListCard.module.scss';

type PatientListCardProps = {
  patientID: string;
  patientExternalID: string;
  patientName: string;
  patientBirthDate: string;
  displayAssetID: string;
  className?: string;
  testID?: string;
  lastReportAssetIDs?: string[];
};

const MAX_IOXRAY_PREVIEW_IMAGES = 3;

export const PatientListCard: FC<PatientListCardProps> = (props) => {
  const {
    testID,
    className,
    patientID,
    patientName,
    displayAssetID,
    patientBirthDate,
    patientExternalID,
    lastReportAssetIDs,
  } = props;

  const allPatientDoctors = useAppSelector(
    patientDoctorsModel.selectors.selectAllPatientDoctors(patientID),
  );

  const isOnlyOneDoctor = allPatientDoctors?.length === 1;

  const { formatMessage } = useIntl();

  const isEmptyPreview = !displayAssetID && !lastReportAssetIDs?.length;
  const isIOXRayPreview = !displayAssetID && lastReportAssetIDs?.length;

  const previewImages = lastReportAssetIDs?.slice(0, MAX_IOXRAY_PREVIEW_IMAGES);

  const previewImage = isIOXRayPreview ? (
    <div className={styles.ioxrayImagesContainer}>
      {previewImages?.map((assetID) => (
        <img
          key={assetID}
          style={{
            width: `${100 / previewImages.length}%`,
            height: '100%',
            objectFit: previewImages.length === 1 ? 'contain' : 'cover',
          }}
          src={getImageSrc(assetID, 'preview')}
        />
      ))}
    </div>
  ) : (
    <img
      className={styles.preview}
      src={getImageSrc(displayAssetID, 'preview')}
      alt={formatMessage({
        id: 'imgAltText.patientPreview',
        defaultMessage: 'Patient preview',
      })}
    />
  );

  return (
    <div data-testid={testID} className={cn(styles.container, className)}>
      <header className={styles.patientCardHeader}>
        <Link to={generatePath(PATHS.patientProfile, { patientID })}>
          <div
            className={cn(
              styles.previewContainer,
              isEmptyPreview && styles.empty,
            )}
          >
            {isEmptyPreview ? (
              <div className={styles.previewDefaultImage} />
            ) : (
              previewImage
            )}
          </div>
        </Link>
      </header>
      <div className={styles.patientInfo}>
        <Link to={generatePath(PATHS.patientProfile, { patientID })}>
          <h4 className={cn(styles.patientName, 'h4')}>{patientName}</h4>
          <p className={cn(styles.patientID, 'p2')}>
            {formatMessage({
              id: 'patientInfo.externalID',
              defaultMessage: 'ID',
            })}
            {': '}
            {patientExternalID}
          </p>
          <p className={cn(styles.date, 'p2')}>
            {formatMessage({
              id: 'patientInfo.DoB',
              defaultMessage: 'DoB',
            })}
            {': '}
            {patientBirthDate}
          </p>
          <Avatar.Group className={styles.avatars}>
            {allPatientDoctors?.map((doctor) => (
              <DoctorsAvatars
                key={doctor?.UserID}
                doctor={doctor}
                isOnlyOneDoctor={isOnlyOneDoctor}
              />
            ))}
          </Avatar.Group>
          <StudiesCounters patientID={patientID} />
        </Link>
      </div>
    </div>
  );
};
