import { useAppSelector, useMedia, useWindowSize } from '@/shared/hooks';
import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { assetsModel, getFileSrc } from '@/entities/assets';
import { reportsModel } from '@/entities/reports';

import { calculateImageSize } from '../lib/calculateImageSize';

const PANO_IMAGE_HEIGHT_COEFFICIENT = 0.9;
const PANO_IMAGE_HEIGHT_COEFFICIENT_SMALL_WINDOW = 0.75;
const MODAL_PADDING = 220;

export const useGetTeethNumberingModalImage = (report: Report) => {
  const { ID: reportID, Type: reportType } = report ?? {};

  const { width: wWidth, height: wHeight } = useWindowSize();
  const { isSmallDesktop } = useMedia();

  const coefficient = isSmallDesktop
    ? PANO_IMAGE_HEIGHT_COEFFICIENT_SMALL_WINDOW
    : PANO_IMAGE_HEIGHT_COEFFICIENT;

  const assetByStudyID = useAppSelector(
    assetsModel.selectors.selectAssetByStudyID(report?.SourceStudyIDs[0] ?? ''),
  );

  const CBCTPanoramaImage = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageByReportID(reportID),
  );

  const CBCTPanoramaAsset = useAppSelector(
    assetsModel.selectors.selectGPAssetByReportID(reportID),
  );

  const CBCTPanoramaAssetID = CBCTPanoramaAsset?.ID;

  const CBCTPanoramaSplit = useAppSelector(
    assetsModel.selectors.selectCBCTGPPanoImageSplit(reportID),
  );

  const CBCTPanoramaSplitURL = getFileSrc(
    CBCTPanoramaSplit?.CBCTGPPanoramaReformatSplit?.PanoramaSplit?.ID ?? '',
  );

  const reportViewOptions = useAppSelector(
    reportsModel.selectors.selectReportViewOptions(reportID),
  );

  const panoImage = assetByStudyID?.Study?.PanoramicXRay?.PanoramaImage;
  const panoramicImageOriginalWidth = panoImage?.Image?.Width;
  const panoramicImageOriginalHeight = panoImage?.Image?.Height;

  const containerWidth = wWidth * coefficient;
  const containerHeight =
    (wHeight - MODAL_PADDING) * PANO_IMAGE_HEIGHT_COEFFICIENT;

  const panoAssetID = assetByStudyID?.ID;

  const panoImageURL = getFileSrc(panoImage?.ID ?? '');

  const panoramicImageSize = calculateImageSize(
    containerWidth,
    containerHeight,
    panoramicImageOriginalWidth ?? 0,
    panoramicImageOriginalHeight ?? 0,
  );

  const panoramicImage = {
    src: getFileSrc(panoImage?.ID ?? ''),
    width: panoramicImageSize.width,
    height: panoramicImageSize.height,
    path: panoImage?.Path ?? '',
    zoom: panoramicImageSize.zoom,
  };

  const CBCTGpImage =
    CBCTPanoramaImage?.CBCTGPPanoramaReformatGeneral?.PanoramaSingle;

  const CBCTPanoramaURL = getFileSrc(CBCTGpImage?.ID ?? '');

  const CBCTImageSize = calculateImageSize(
    containerWidth,
    containerHeight,
    CBCTGpImage?.Image?.Width ?? 0,
    CBCTGpImage?.Image?.Height ?? 0,
  );

  const CBCTPanoImage = {
    src: CBCTPanoramaURL,
    width: CBCTImageSize.width,
    height: CBCTImageSize.height,
    path: CBCTGpImage?.Path ?? '',
    zoom: CBCTImageSize.zoom,
  };

  const getModalSize = (type: ReportType) => {
    switch (type) {
      case ReportType.ReportType_CBCT_GP:
        return CBCTImageSize;
      default:
        return panoramicImageSize;
    }
  };

  const modalSize = getModalSize(reportType);

  return {
    panoramicImage,
    panoImageURL,
    panoAssetID,
    CBCTPanoImage,
    reportViewOptions,
    CBCTPanoramaSplitURL,
    CBCTPanoramaURL,
    CBCTPanoramaAssetID,
    CBCTPanoramaImage,
    modalWidth: modalSize?.width,
    modalHeight: modalSize?.height,
  };
};
