import { useAppDispatch } from '@/shared/hooks';
import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';
import { RequestReportReq } from '@/shared/api/protocol_gen/api/core/svc_report';

import { reportsModel } from '@/entities/reports';

export const useNewReport = () => {
  const dispatch = useAppDispatch();

  const requestPanoOrFMXReport = async (
    studyID: string,
    studyType: StudyType,
  ) => {
    if (studyType === StudyType.StudyType_IntraoralXRay) {
      const { Report } = await dispatch(
        reportsModel.thunks.requestReport({
          Request: {
            InputIOXRayGP: {
              IntraoralXRayStudyID: studyID,
            },
          },
        } as RequestReportReq),
      ).unwrap();

      if (Report) {
        dispatch(reportsModel.actions.setOne(Report));
      }
    } else {
      const { Report } = await dispatch(
        reportsModel.thunks.requestReport({
          Request: {
            InputPanoGP: {
              PanoramicXRayStudyID: studyID,
            },
          },
        } as RequestReportReq),
      ).unwrap();

      if (Report) {
        dispatch(reportsModel.actions.setOne(Report));
      }
    }
  };

  const requestCBCTNewReport = async (studyID: string) => {
    const { Report } = await dispatch(
      reportsModel.thunks.requestReport({
        Request: {
          InputCBCTGP: {
            CBCTStudyID: studyID,
          },
        },
      } as RequestReportReq),
    ).unwrap();

    if (Report) {
      dispatch(reportsModel.actions.setOne(Report));
    }
  };

  return {
    requestPanoOrFMXReport,
    requestCBCTNewReport,
  };
};
