// import { generatePath } from 'react-router-dom';

export const PATHS = {
  main: '/',
  patients: '/patients',
  patientProfile: '/patients/:patientID',
  organizations: '/organizations',
  personalSettings: '/personal-settings',
  companySettings: '/company-settings',
  gpReport: '/patients/:patientID/reports/gp/:reportID',
  gpReportAllSlices: 'tooth/:toothID',
  panoReport: '/patients/:patientID/reports/pano/:reportID',
  bitewingsReport: '/patients/:patientID/reports/bitewings/:reportID',
  ioxRayReport: '/patients/:patientID/reports/ioxray/:reportID',
  endodonticReport: '/patients/:patientID/reports/endodontic/:reportID',
  implantReport: '/patients/:patientID/reports/implant/:reportID',
  molarReport: '/patients/:patientID/reports/molar/:reportID',
  segmentationReport: '/patients/:patientID/reports/segmentation/:reportID',
  previewReport: '/patients/:patientID/reports/:reportID/preview',
  STLViewer: '/patients/:patientID/:STLViewer',
  implantStudio: '/patients/:patientID/implantStudio',
  error: '*',
  login: '/login',
  resetPassword: '/reset-password',
  newPassword: '/new-password',
  disclaimer: '/disclaimer',
  signUp: '/sign-up',
  signIn: '/sign-in',
  confirmEmail: '/confirm-email',
  confirmSharing: '/confirm-sharing',
  additionalInfo: '/additional-info',
  invite: '/invite',
  finishRegistration: '/finish-registration',
  organization_invite: '/organization_invite/:token',
};
