import { useIntl } from 'react-intl';

export const useSubscriptionLifeTimeOptions = () => {
  const { formatMessage } = useIntl();

  return [
    {
      label: formatMessage({
        id: 'billing.subscriptionLifeTime.month',
        defaultMessage: '1 month',
      }),
      value: 1,
    },
    {
      label: formatMessage({
        id: 'billing.subscriptionLifeTime.month',
        defaultMessage: '3 month',
      }),
      value: 3,
    },
    {
      label: formatMessage({
        id: 'billing.subscriptionLifeTime.annual',
        defaultMessage: '12 months',
      }),
      value: 12,
    },
  ];
};
