import { createSelector } from '@reduxjs/toolkit';

import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { ClosedPolygon } from '@/shared/api/protocol_gen/model/dto_common_geometry';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { conditionModel } from '@/entities/condition';
import {
  MASK_FILTERS_ENDO_CODES,
  MASK_FILTERS_RESTORATIVE_CODES,
  MaskFiltersType,
  getMaskColorConfig,
  maskFiltersModel,
} from '@/entities/maskFilters';
import { getImageMetaIDByAssetID, reportsModel } from '@/entities/reports';

type Mask2DConfig = {
  maskID: string;
  conditionCode: ConditionCode;
  path: { x: number; y: number }[];
  color: number;
  opacity: number;
  outlineColor: number;
  outlineWidth: number;
  renderOrder: 1;
};

type Mask2DRenderData = {
  imageID: string;
  groupID: MaskFiltersType;
  config: Mask2DConfig[];
};

const convertConditionLocalizationsIntoMask = (condition: Condition) => {
  if (!condition.Localizations.length) {
    return [];
  }

  const masks = condition.Localizations.map((localization) => {
    const { color, opacity } = getMaskColorConfig(condition.Code);

    const maskData: Mask2DConfig = {
      conditionCode: condition.Code,
      maskID: localization.ID,
      path: (localization.Mask as ClosedPolygon)?.Points.map((point) => ({
        x: point.X,
        y: point.Y,
      })),
      opacity,
      color,
      outlineColor: color,
      outlineWidth: 1,
      renderOrder: 1,
    };

    return maskData;
  });

  return masks;
};

export const select2DMasksRenderData = createSelector(
  conditionModel.selectors.selectConditionsWithMasks, // TODO: write selector in logical condition entitiy
  maskFiltersModel.selectors.selectMaskActiveConditionCodes,
  reportsModel.selectors.selectCurrentReportImagesMeta,
  (conditionsWithMasks, activeConditionCodes, imagesMeta) => {
    const maskConfigListByImageMetaID = conditionsWithMasks.reduce(
      (accum, condition) => {
        if (
          condition.Localizations.length &&
          activeConditionCodes.includes(condition.Code)
        ) {
          const masks = convertConditionLocalizationsIntoMask(condition);
          const assetID = condition.Localizations[0].TargetAssetID;
          const imageID = getImageMetaIDByAssetID(imagesMeta, assetID);

          if (imageID in accum) {
            accum[imageID].config.push(...masks);
          } else {
            accum[imageID] = {
              imageID,
              groupID: 'perio', // WARN: I don't understant what puprose of this field
              config: masks,
            };
          }
        }

        return accum;
      },
      {} as Record<string, Mask2DRenderData>,
    );

    return Object.values(maskConfigListByImageMetaID);
  },
);

export const selectMaskFilterDisabled = (maskFilterType: MaskFiltersType) =>
  createSelector(
    conditionModel.selectors.selectConditionCodesWithMasks,
    (conditionCodesWithMasks) => {
      switch (maskFilterType) {
        case 'restorative': {
          return !conditionCodesWithMasks.some((code) =>
            MASK_FILTERS_RESTORATIVE_CODES.includes(code),
          );
        }
        case 'endo': {
          return !conditionCodesWithMasks.some((code) =>
            MASK_FILTERS_ENDO_CODES.includes(code),
          );
        }
        case 'perio': // Perio should never be disabled
        default: {
          return false;
        }
      }
    },
  );
