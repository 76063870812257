import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { ToothStatus } from '@/shared/config';

import { LOW_PROBABILITY_CONDITION_CODES } from '@/entities/condition';

import {
  getCondtionStatus,
  isConditionUncertain,
  shouldConditionItemBeShown,
} from '../condition';

import { ToothCondition, ToothConditions } from './model/logicalConditionSlice';

const conditionEngineDecisionIsPositive = (condition: Condition) =>
  condition.Certainty?.EngineDecision === Decision.PositiveDecision;

// Use it in the selector to iterate over each tooth conditions
export const getToothStatus = (
  toothConditions: ToothCondition,
  showLowProbability: boolean = false,
) => {
  // TODO [4|h] Find out why there is no conditions for supernumerary teeth
  if (!toothConditions) {
    return ToothStatus.healthy;
  }

  const missingConditions = toothConditions.conditions[ConditionCode.Missing];
  const missingConditionsList = Object.values(missingConditions || {});

  if (missingConditionsList.some(conditionEngineDecisionIsPositive)) {
    return ToothStatus.missing;
  }

  const conditionStatuses = new Map<ToothStatus, boolean>();

  Object.values(toothConditions.conditions).forEach((conditions) => {
    const conditionsWithoutPBL = Object.values(conditions).filter(
      (condition) => condition.Code !== ConditionCode.PeriodontalBoneLoss,
    );

    // Can we ignore child conditions to find correct status?
    const condition = conditionsWithoutPBL.sort(
      (a, b) => (b.Certainty?.ModelScore ?? 0) - (a.Certainty?.ModelScore ?? 0),
    )?.[0];

    if (shouldConditionItemBeShown(showLowProbability)(condition)) {
      const conditionStatus = getCondtionStatus(condition);

      conditionStatuses.set(conditionStatus, true);
    }
  });

  if (conditionStatuses.get(ToothStatus.lowProbability)) {
    return ToothStatus.lowProbability;
  }

  if (conditionStatuses.get(ToothStatus.unhealthy)) {
    return ToothStatus.unhealthy;
  }

  if (conditionStatuses.get(ToothStatus.treated)) {
    return ToothStatus.treated;
  }

  return ToothStatus.healthy;
};

export const findLowProbabilityConditionIDs = (
  toothConditions: ToothConditions,
) => {
  const toothConditionList = Object.values(toothConditions);

  return toothConditionList.reduce((conditionIDs, toothCondition) => {
    LOW_PROBABILITY_CONDITION_CODES.forEach((lowProbabilityCode) => {
      const conditions = toothCondition.conditions[lowProbabilityCode];

      if (conditions) {
        const condition = Object.values(conditions).sort(
          (a, b) =>
            (b.Certainty?.ModelScore ?? 0) - (a.Certainty?.ModelScore ?? 0),
        )?.[0];

        if (isConditionUncertain(condition)) {
          conditionIDs.push(condition.ID);
        }
      }
    });
    return conditionIDs;
  }, [] as string[]);
};
