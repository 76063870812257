// TODO [m|8]: this component overcomlicated, need to refactor usind useToothTransform.ts hook
// with simple UI squares with tooth number, color and active state determined by tooth id from url
// Just make simple grid like in TeethChart.tsx
import { FC, useMemo } from 'react';
import cn from 'classnames';
import { generatePath, useLocation, useNavigate } from 'react-router';

import { useAppSelector } from '@/shared/hooks';
import { LocationStateType, PATHS } from '@/shared/config';

import {
  JawQuartile,
  makeJawObject,
  Quartiles,
  toothModel,
} from '@/entities/tooth';

import styles from './SimplifiedToothChart.module.scss';

type SimplifiedToothChartProps = {
  reportID: string;
  patientID: string;
  className?: string;
  toothOfInterestNumber?: number;
  handleToothClick?: () => void;
};

export const SimplifiedToothChart: FC<SimplifiedToothChartProps> = (props) => {
  const {
    patientID,
    reportID,
    className,
    toothOfInterestNumber,
    handleToothClick,
  } = props;

  const navigate = useNavigate();

  const location = useLocation();

  // type casting is necessary for typification location.state
  const locationState = location?.state as LocationStateType;

  const teeth = useAppSelector(toothModel.selectors.selectByReportID(reportID));

  const showPrimaryTeeth = useMemo(
    () => teeth.some((tooth) => (tooth.Numeration?.ISO ?? 0) > 50),
    [teeth],
  );

  const jawObject = makeJawObject(teeth);

  const mainTeeth = {
    quartile1: jawObject.quartile1,
    quartile2: jawObject.quartile2,
    quartile3: jawObject.quartile3,
    quartile4: jawObject.quartile4,
  };

  const childTeeth = {
    primaryQuartile1: jawObject?.primaryQuartile1 ?? [],
    primaryQuartile2: jawObject?.primaryQuartile2 ?? [],
    primaryQuartile3: jawObject?.primaryQuartile3 ?? [],
    primaryQuartile4: jawObject?.primaryQuartile4 ?? [],
  };

  const onToothClick = (toothID: string) => {
    if (handleToothClick) {
      handleToothClick();
    }

    navigate(
      {
        pathname: generatePath(PATHS.gpReport + '/' + PATHS.gpReportAllSlices, {
          patientID,
          reportID,
          toothID,
        }),
      },
      {
        state: {
          ...locationState,
          toothID,
        },
      },
    );
  };

  return (
    <>
      <div className={cn(styles.container, className)}>
        {Object.entries(mainTeeth).map(([quartile, quartileTeeth]) => (
          <JawQuartile
            key={quartile}
            className={cn(styles[quartile])}
            reportID={reportID}
            quartile={quartile as Quartiles}
            teeth={quartileTeeth}
            onToothClick={onToothClick}
            toothOfInterestNumber={toothOfInterestNumber}
            isSimplify
          />
        ))}
      </div>

      {showPrimaryTeeth && (
        <div className={cn(styles.container, styles.primary, className)}>
          {Object.entries(childTeeth).map(([quartile, quartileTeeth]) => (
            <JawQuartile
              key={quartile}
              className={cn(styles[quartile])}
              reportID={reportID}
              quartile={quartile as Quartiles}
              teeth={quartileTeeth}
              onToothClick={onToothClick}
              toothOfInterestNumber={toothOfInterestNumber}
              isSimplify
            />
          ))}
        </div>
      )}
    </>
  );
};
