import { FC, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { ErrorText, Modal, ModalProps } from '@/shared/ui';
import { ApiError } from '@/shared/api/api';

import { useRemoveReport } from '../../hooks/useRemoveReport';

import styles from './RemoveReportModal.module.scss';

type RemoveReportModalProps = {
  reportID?: string;
  className?: string;
  testID?: string;
} & Pick<ModalProps, 'isOpen' | 'onCancel'>;

export const RemoveReportModal: FC<RemoveReportModalProps> = (props) => {
  const { className, testID, isOpen, onCancel: onClose, reportID } = props;

  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const { formatMessage } = useIntl();

  const removeReport = useRemoveReport();

  const handleClose = () => {
    setErrorMessage(undefined);
    if (onClose) {
      onClose();
    }
  };

  const handleRemoveReport = async () => {
    setLoading(true);

    try {
      if (reportID) {
        await removeReport(reportID);
      }
    } catch (error) {
      setErrorMessage((error as ApiError)?.message);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      className={cn(styles.container, className)}
      data-testid={testID}
      bodyClassName={styles.modalBody}
      title={formatMessage({
        id: 'removeReportModal.title',
        defaultMessage: 'Remove report',
      })}
      isOpen={isOpen}
      onCancel={handleClose}
      onOk={handleRemoveReport}
      okButtonText={formatMessage({
        id: 'global.applyRemove',
        defaultMessage: 'Yes, remove',
      })}
      okButtonProps={{
        danger: true,
        loading: isLoading,
      }}
    >
      <FormattedMessage
        id="removeReportModal.message"
        defaultMessage="After removing, all report data will be lost. Are you sure?"
      />
      <ErrorText className={styles.errorText} error={errorMessage} />
    </Modal>
  );
};
