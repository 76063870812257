import { useCallback } from 'react';
import { isEmpty } from 'ramda';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { scrollToElementByID } from '@/shared/lib';
import { Skeleton } from '@/shared/ui';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { toothModel } from '@/entities/tooth';
import { reportsModel } from '@/entities/reports';
import { organizationModel } from '@/entities/organization';

import {
  ToothChart,
  toothChartModel,
  ToothChartItemClickHandler,
} from '@/features/toothChart';
import { ToggleLowProbability } from '@/features/toggleLowProbability';

import styles from './Report2DToothChart.module.scss';

export const Report2DToothChart = () => {
  const dispatch = useAppDispatch();

  const hideProbability = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_Probabilities,
    ),
  );

  const { toothItems, toothTypesSet } = useAppSelector(
    toothChartModel.selectors.selectToothChartItems,
  );
  const customMode = useAppSelector(
    toothModel.selectors.selectToothChartCustomMode,
  );

  const isToothChartReady = !isEmpty(toothTypesSet);

  const handleToothClick = useCallback<ToothChartItemClickHandler>(
    ({ id, active }) => {
      if (customMode) {
        dispatch(toothModel.actions.setLocalROITeethIDs([id]));
      } else if (active) {
        scrollToElementByID(id, 'smooth', 'start');
        dispatch(reportsModel.actions.setActiveToothID(id));
      }
    },
    [customMode, dispatch],
  );

  return (
    <ToothChart.Root size="small" className={styles.container}>
      <ToothChart.Header>
        <ToothChart.CustomModeToggle />
      </ToothChart.Header>

      {isToothChartReady ? (
        <>
          <ToothChart.Tags toothTypesSet={toothTypesSet} />

          <ToothChart.Grid>
            {toothItems.map((toothItem) => (
              <ToothChart.MetaItem
                key={toothItem.id}
                toothItem={toothItem}
                handleToothClick={handleToothClick}
              />
            ))}
          </ToothChart.Grid>
        </>
      ) : (
        <>
          <Skeleton.Filter
            filtersQuantity={4}
            height={20}
            gap={4}
            borderRadius={10}
          />

          <Skeleton width={484} height={126} borderRadius={4} />
        </>
      )}

      {!hideProbability && <ToggleLowProbability />}
    </ToothChart.Root>
  );
};
