import { FC } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';

// I think we should unite maskFilters entitiy with maskFilters feature
import { MaskFiltersType } from '../../../../entities/maskFilters/config/maskFilters.type';
import { maskFiltersDefaultMessages } from '../../config/i18n';
import * as maskFilterModel from '../../model';

import styles from './MaskFilterButton.module.scss';

type MaskFilterButtonProps = {
  active: boolean;
  filterGroup: MaskFiltersType;
  onClick: (filterGroup: MaskFiltersType) => void;
  className?: string;
  testID?: string;
};

const buttonColors = {
  perio: styles.maskFilterPerio,
  restorative: styles.maskFilterRestorative,
  endo: styles.maskFilterEndo,
  anatomy: styles.maskFilterAnatomy,
};

export const MaskFilterButton: FC<MaskFilterButtonProps> = (props) => {
  const { className, testID, active, filterGroup, onClick } = props;

  const { formatMessage } = useIntl();
  const disabled = useAppSelector(
    maskFilterModel.selectors.selectMaskFilterDisabled(filterGroup),
  );

  return (
    <button
      data-testid={testID}
      className={cn(
        className,
        styles.container,
        'p4',
        active && buttonColors[filterGroup],
      )}
      type="button"
      disabled={disabled}
      onClick={() => onClick(filterGroup)}
    >
      {formatMessage(maskFiltersDefaultMessages[filterGroup])}
    </button>
  );
};
