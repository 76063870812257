import { createSelector } from '@reduxjs/toolkit';
import { compose, head, keys, values } from 'ramda';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import {
  getToothStatus,
  logicalConditionModel,
} from '@/entities/logicalCondition';
import { shouldConditionItemBeShown } from '@/entities/condition';
import {
  LOWER_JAW_TEETH_ISO_NUMBERS,
  toothModel,
  ToothTypesSet,
} from '@/entities/tooth';
import { reportsModel } from '@/entities/reports';
import { ToothConditionCollectionByID } from '@/entities/logicalCondition/model/logicalConditionSlice';

import { ToothItem } from '../config/types';
import { DEFAULT_TOOTH_CHART_ITEMS, PBL_SEVERITIES } from '../config/constants';

const LOWER_JAW_TEETH_ISO_NUMBERS_SET = LOWER_JAW_TEETH_ISO_NUMBERS.reduce(
  (acc, number) => {
    acc[number] = number;
    return acc;
  },
  {} as Record<number, number>,
);

// Handle supernumerary and primary teeth
const groupTeethByISONumber = (teeth: Tooth[]) =>
  teeth.reduce(
    (acc, tooth) => {
      const ISO = tooth.Numeration?.ISO as number;

      if (!acc[ISO]) {
        acc[ISO] = [];
      }
      acc[ISO].push(tooth);

      return acc;
    },
    {} as Record<number, typeof teeth>,
  );

export const selectPBLSeverities = (toothID: string) =>
  createSelector(
    logicalConditionModel.selectors.selectToothConditions(toothID),
    reportsModel.selectors.selectLowProbabilityMode,
    (toothConditions, showLowProbability) => {
      const PBLConditionID =
        compose<ToothConditionCollectionByID[], string[], Condition['ID']>(
          head,
          keys,
        )(
          toothConditions.conditions[ConditionCode.PeriodontalBoneLoss] ?? {},
        ) ?? '';

      const PBLChildConditions =
        toothConditions.childConditions[PBLConditionID];

      return PBL_SEVERITIES.map((severity) => {
        if (PBLChildConditions) {
          const currentCondition = compose<
            ToothConditionCollectionByID[],
            Condition[],
            Condition
          >(
            head,
            values,
          )(PBLChildConditions[severity]);

          return (
            Boolean(PBLChildConditions[severity]) &&
            shouldConditionItemBeShown(showLowProbability)(currentCondition)
          );
        }

        return false;
      });
    },
  );

const getGridArea = (ISONumber: number) => {
  if (ISONumber > 100 || ISONumber < 50) {
    return ISONumber;
  } else {
    return ISONumber - 40;
  }
};

export const selectToothChartItems = createSelector(
  toothModel.selectors.selectAll,
  logicalConditionModel.selectors.selectTeethConditions,
  toothModel.selectors.selectLocalROITeethIDs,
  reportsModel.selectors.selectLowProbabilityMode,
  (teeth, toothConditions, localROITeethIDs, showLowProbability) => {
    const toothTypesSet = {} as ToothTypesSet;

    const toothGroupedByISONumber = groupTeethByISONumber(teeth);

    const toothItems = Object.values(DEFAULT_TOOTH_CHART_ITEMS).reduce(
      (acc, toothItem) => {
        const { ISONumber } = toothItem;
        const [firstTooth, ...restTeeth] =
          toothGroupedByISONumber[ISONumber]?.sort(
            (a, b) =>
              (a?.Numeration?.SupernumeraryIndex ?? 0) -
              (b?.Numeration?.SupernumeraryIndex ?? 0),
          ) ?? [];

        // Collect supernumeric or baby teeth
        const extraTeeth = (
          toothGroupedByISONumber[ISONumber + 40] || restTeeth
        ).map((tooth) => {
          const toothStatus = getToothStatus(
            toothConditions[tooth.ID],
            showLowProbability,
          );
          toothTypesSet[tooth.ID] = toothStatus;

          return {
            id: tooth.ID,
            status: toothStatus,
            ISONumber: tooth?.Numeration?.ISO as number,
            supernumeraryIndex: tooth.Numeration?.SupernumeraryIndex,
            active: localROITeethIDs.includes(tooth.ID),
            isLowerJaw: ISONumber in LOWER_JAW_TEETH_ISO_NUMBERS_SET,
            gridArea: getGridArea(tooth?.Numeration?.ISO as number),
          };
        });

        if (firstTooth) {
          const toothStatus = getToothStatus(
            toothConditions[firstTooth.ID],
            showLowProbability,
          );
          toothTypesSet[firstTooth.ID] = toothStatus;

          acc.push({
            id: firstTooth.ID,
            status: toothStatus,
            ISONumber,
            isLowerJaw: toothItem.isLowerJaw,
            active: localROITeethIDs.includes(firstTooth.ID),
            childItems: extraTeeth,
            gridArea: getGridArea(ISONumber),
          });
        } else {
          acc.push(toothItem);
        }

        return acc;
      },
      [] as ToothItem[],
    );

    return {
      toothItems,
      toothTypesSet,
    };
  },
);
