// TODO: Resolve tyescript errors for return type
import { useIntl } from 'react-intl';

// import { Invitation_InvitationStatus } from '@/shared/api/protocol_gen/model/dto_access';
import { useAppSelector } from '@/shared/hooks';
import {
  // getInitials,
  getUserFullName,
  getUserInitials,
} from '@/shared/lib';

import { getAvatarSrcByUserID } from '../../assets';
// import { accessModel } from '../../access';
import { getOrganizationDoctorsRoles } from '../lib/getOrganizationDoctorsRoles';
import { getOrganizationDoctorSpecializations } from '../lib/getOrganizationDoctorSpecializations';
import { DoctorInterface } from '../config';
import * as organizationModel from '../model';

// TODO: Need to discuss invite entity with the backend team.
// useOrganizationSettingsStream: HistoricalInvitation and UpdatedInvitation
// There is a lot of commented code because of that.
export const useGetOrganizationDoctorsInterfaces = (): DoctorInterface[] => {
  const allOrganizationDoctors = useAppSelector(
    organizationModel.selectors.selectAllUsers,
  );

  // const organizationDoctorInvitations = useAppSelector(
  //   accessModel.selectors.selectOrganizationDoctorInvitations,
  // );

  const { formatMessage } = useIntl();

  // const invitedDoctors: DoctorInterface[] =
  //   organizationDoctorInvitations.reduce((doctors, invite) => {
  //     if (
  //       invite.Status !== Invitation_InvitationStatus.StatusCancelled &&
  //       invite.Status !== Invitation_InvitationStatus.StatusDeleted
  //     ) {
  //       const doctor = invite.Target?.Organization;
  //
  //       const invitedDoctor = {
  //         id: invite.ID,
  //         avatarSrc: '',
  //         fullName: `${doctor?.FirstName} ${doctor?.LastName}`,
  //         specializations: doctor?.JobPositions
  //           ? getOrganizationDoctorSpecializations(
  //               doctor?.JobPositions,
  //               formatMessage,
  //             )
  //           : [],
  //         roles: doctor?.Roles
  //           ? getOrganizationDoctorsRoles(doctor?.Roles, formatMessage)
  //           : [],
  //         initials: getInitials(`${doctor?.FirstName} ${doctor?.LastName}`),
  //         email: invite.Recipient?.Email ?? '',
  //         doctorInfo: {
  //           ...doctor,
  //           InvitationID: invite.ID,
  //           email: invite.Recipient?.Email,
  //         },
  //       };
  //
  //       doctors.push(invitedDoctor);
  //     }
  //
  //     return doctors;
  //   }, [] as DoctorInterface[]);

  const orgDoctors: DoctorInterface[] = allOrganizationDoctors?.length
    ? allOrganizationDoctors?.map((doctor) => {
        const avatarSrc = getAvatarSrcByUserID(doctor.UserID, 'thumbnail');

        return {
          id: doctor.UserID,
          avatarSrc,
          fullName: getUserFullName(doctor),
          email: doctor?.PersonalData?.Email ?? '',
          specializations: getOrganizationDoctorSpecializations(
            doctor.JobPositions,
            formatMessage,
          ),
          roles: getOrganizationDoctorsRoles(doctor.Roles, formatMessage),
          doctorInfo: doctor,
          initials: getUserInitials(doctor),
        };
      })
    : [];

  return orgDoctors;
  // return [...invitedDoctors, ...orgDoctors];
};
