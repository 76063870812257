/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { ToothNumeration } from '../model/dto_report_common';
import { Localization } from '../model/dto_report_localization';
import { Created, Revision, Deleted } from '../model/dto_base';

export const protobufPackage = 'com.diagnocat.model';

/** These teeth are "real" teeth in a patient's mouth. They have ISO numbers, and everyone knows they're real. */
export interface Tooth {
  ID: string;
  ReportID: string;
  PatientID: string;
  OrganizationID: string;
  Numeration: ToothNumeration | undefined;
  Comment: string;
  IsInROI: boolean;
  IsApproved: boolean;
  IsControl: boolean;
  IsLocalized: boolean;
  Localizations: Localization[];
  /** selected Assets */
  DisplaySlices: string[];
  Completed: boolean | undefined;
  InProgress: ToothProgress | undefined;
  /** creation information */
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
  /** removal information */
  Deleted: Deleted | undefined;
}

export interface ToothProgress {
  ConditionsCompleted: boolean;
  SlicesUploaded: boolean;
}

function createBaseTooth(): Tooth {
  return {
    ID: '',
    ReportID: '',
    PatientID: '',
    OrganizationID: '',
    Numeration: undefined,
    Comment: '',
    IsInROI: false,
    IsApproved: false,
    IsControl: false,
    IsLocalized: false,
    Localizations: [],
    DisplaySlices: [],
    Completed: undefined,
    InProgress: undefined,
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
  };
}

export const Tooth = {
  encode(message: Tooth, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.ReportID !== '') {
      writer.uint32(18).string(message.ReportID);
    }
    if (message.PatientID !== '') {
      writer.uint32(26).string(message.PatientID);
    }
    if (message.OrganizationID !== '') {
      writer.uint32(34).string(message.OrganizationID);
    }
    if (message.Numeration !== undefined) {
      ToothNumeration.encode(
        message.Numeration,
        writer.uint32(82).fork(),
      ).ldelim();
    }
    if (message.Comment !== '') {
      writer.uint32(90).string(message.Comment);
    }
    if (message.IsInROI === true) {
      writer.uint32(160).bool(message.IsInROI);
    }
    if (message.IsApproved === true) {
      writer.uint32(168).bool(message.IsApproved);
    }
    if (message.IsControl === true) {
      writer.uint32(176).bool(message.IsControl);
    }
    if (message.IsLocalized === true) {
      writer.uint32(184).bool(message.IsLocalized);
    }
    for (const v of message.Localizations) {
      Localization.encode(v!, writer.uint32(242).fork()).ldelim();
    }
    for (const v of message.DisplaySlices) {
      writer.uint32(250).string(v!);
    }
    if (message.Completed !== undefined) {
      writer.uint32(808).bool(message.Completed);
    }
    if (message.InProgress !== undefined) {
      ToothProgress.encode(
        message.InProgress,
        writer.uint32(818).fork(),
      ).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Deleted.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tooth {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTooth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.ReportID = reader.string();
          break;
        case 3:
          message.PatientID = reader.string();
          break;
        case 4:
          message.OrganizationID = reader.string();
          break;
        case 10:
          message.Numeration = ToothNumeration.decode(reader, reader.uint32());
          break;
        case 11:
          message.Comment = reader.string();
          break;
        case 20:
          message.IsInROI = reader.bool();
          break;
        case 21:
          message.IsApproved = reader.bool();
          break;
        case 22:
          message.IsControl = reader.bool();
          break;
        case 23:
          message.IsLocalized = reader.bool();
          break;
        case 30:
          message.Localizations.push(
            Localization.decode(reader, reader.uint32()),
          );
          break;
        case 31:
          message.DisplaySlices.push(reader.string());
          break;
        case 101:
          message.Completed = reader.bool();
          break;
        case 102:
          message.InProgress = ToothProgress.decode(reader, reader.uint32());
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = Deleted.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Tooth {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      ReportID: isSet(object.ReportID) ? String(object.ReportID) : '',
      PatientID: isSet(object.PatientID) ? String(object.PatientID) : '',
      OrganizationID: isSet(object.OrganizationID)
        ? String(object.OrganizationID)
        : '',
      Numeration: isSet(object.Numeration)
        ? ToothNumeration.fromJSON(object.Numeration)
        : undefined,
      Comment: isSet(object.Comment) ? String(object.Comment) : '',
      IsInROI: isSet(object.IsInROI) ? Boolean(object.IsInROI) : false,
      IsApproved: isSet(object.IsApproved) ? Boolean(object.IsApproved) : false,
      IsControl: isSet(object.IsControl) ? Boolean(object.IsControl) : false,
      IsLocalized: isSet(object.IsLocalized)
        ? Boolean(object.IsLocalized)
        : false,
      Localizations: Array.isArray(object?.Localizations)
        ? object.Localizations.map((e: any) => Localization.fromJSON(e))
        : [],
      DisplaySlices: Array.isArray(object?.DisplaySlices)
        ? object.DisplaySlices.map((e: any) => String(e))
        : [],
      Completed: isSet(object.Completed)
        ? Boolean(object.Completed)
        : undefined,
      InProgress: isSet(object.InProgress)
        ? ToothProgress.fromJSON(object.InProgress)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Deleted.fromJSON(object.Deleted)
        : undefined,
    };
  },

  toJSON(message: Tooth): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.ReportID !== undefined && (obj.ReportID = message.ReportID);
    message.PatientID !== undefined && (obj.PatientID = message.PatientID);
    message.OrganizationID !== undefined &&
      (obj.OrganizationID = message.OrganizationID);
    message.Numeration !== undefined &&
      (obj.Numeration = message.Numeration
        ? ToothNumeration.toJSON(message.Numeration)
        : undefined);
    message.Comment !== undefined && (obj.Comment = message.Comment);
    message.IsInROI !== undefined && (obj.IsInROI = message.IsInROI);
    message.IsApproved !== undefined && (obj.IsApproved = message.IsApproved);
    message.IsControl !== undefined && (obj.IsControl = message.IsControl);
    message.IsLocalized !== undefined &&
      (obj.IsLocalized = message.IsLocalized);
    if (message.Localizations) {
      obj.Localizations = message.Localizations.map((e) =>
        e ? Localization.toJSON(e) : undefined,
      );
    } else {
      obj.Localizations = [];
    }
    if (message.DisplaySlices) {
      obj.DisplaySlices = message.DisplaySlices.map((e) => e);
    } else {
      obj.DisplaySlices = [];
    }
    message.Completed !== undefined && (obj.Completed = message.Completed);
    message.InProgress !== undefined &&
      (obj.InProgress = message.InProgress
        ? ToothProgress.toJSON(message.InProgress)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Deleted.toJSON(message.Deleted)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Tooth>, I>>(object: I): Tooth {
    const message = createBaseTooth();
    message.ID = object.ID ?? '';
    message.ReportID = object.ReportID ?? '';
    message.PatientID = object.PatientID ?? '';
    message.OrganizationID = object.OrganizationID ?? '';
    message.Numeration =
      object.Numeration !== undefined && object.Numeration !== null
        ? ToothNumeration.fromPartial(object.Numeration)
        : undefined;
    message.Comment = object.Comment ?? '';
    message.IsInROI = object.IsInROI ?? false;
    message.IsApproved = object.IsApproved ?? false;
    message.IsControl = object.IsControl ?? false;
    message.IsLocalized = object.IsLocalized ?? false;
    message.Localizations =
      object.Localizations?.map((e) => Localization.fromPartial(e)) || [];
    message.DisplaySlices = object.DisplaySlices?.map((e) => e) || [];
    message.Completed = object.Completed ?? undefined;
    message.InProgress =
      object.InProgress !== undefined && object.InProgress !== null
        ? ToothProgress.fromPartial(object.InProgress)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Deleted.fromPartial(object.Deleted)
        : undefined;
    return message;
  },
};

function createBaseToothProgress(): ToothProgress {
  return { ConditionsCompleted: false, SlicesUploaded: false };
}

export const ToothProgress = {
  encode(
    message: ToothProgress,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ConditionsCompleted === true) {
      writer.uint32(8).bool(message.ConditionsCompleted);
    }
    if (message.SlicesUploaded === true) {
      writer.uint32(16).bool(message.SlicesUploaded);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ToothProgress {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToothProgress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ConditionsCompleted = reader.bool();
          break;
        case 2:
          message.SlicesUploaded = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): ToothProgress {
    return {
      ConditionsCompleted: isSet(object.ConditionsCompleted)
        ? Boolean(object.ConditionsCompleted)
        : false,
      SlicesUploaded: isSet(object.SlicesUploaded)
        ? Boolean(object.SlicesUploaded)
        : false,
    };
  },

  toJSON(message: ToothProgress): unknown {
    const obj: any = {};
    message.ConditionsCompleted !== undefined &&
      (obj.ConditionsCompleted = message.ConditionsCompleted);
    message.SlicesUploaded !== undefined &&
      (obj.SlicesUploaded = message.SlicesUploaded);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<ToothProgress>, I>>(
    object: I,
  ): ToothProgress {
    const message = createBaseToothProgress();
    message.ConditionsCompleted = object.ConditionsCompleted ?? false;
    message.SlicesUploaded = object.SlicesUploaded ?? false;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
