/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { DoubleValue, BoolValue } from '../google/protobuf/wrappers';

export const protobufPackage = 'com.diagnocat.model';

export interface MedicalImageViewOptions {
  WindowWidth: number | undefined;
  WindowLevel: number | undefined;
  Sharpness: number | undefined;
  Invert: boolean | undefined;
  ColorMap: MedicalImageColorMap | undefined;
  ViewportCoords: MedicalImageViewportCoords | undefined;
  Brightness: number | undefined;
  Contrast: number | undefined;
}

/** TODO: @kirill */
export interface MedicalImageColorMap {}

export interface MedicalImageViewportCoords {
  Left: number | undefined;
  Top: number | undefined;
  Right: number | undefined;
  Bottom: number | undefined;
}

function createBaseMedicalImageViewOptions(): MedicalImageViewOptions {
  return {
    WindowWidth: undefined,
    WindowLevel: undefined,
    Sharpness: undefined,
    Invert: undefined,
    ColorMap: undefined,
    ViewportCoords: undefined,
    Brightness: undefined,
    Contrast: undefined,
  };
}

export const MedicalImageViewOptions = {
  encode(
    message: MedicalImageViewOptions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.WindowWidth !== undefined) {
      DoubleValue.encode(
        { value: message.WindowWidth! },
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.WindowLevel !== undefined) {
      DoubleValue.encode(
        { value: message.WindowLevel! },
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Sharpness !== undefined) {
      DoubleValue.encode(
        { value: message.Sharpness! },
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Invert !== undefined) {
      BoolValue.encode(
        { value: message.Invert! },
        writer.uint32(34).fork(),
      ).ldelim();
    }
    if (message.ColorMap !== undefined) {
      MedicalImageColorMap.encode(
        message.ColorMap,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.ViewportCoords !== undefined) {
      MedicalImageViewportCoords.encode(
        message.ViewportCoords,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    if (message.Brightness !== undefined) {
      DoubleValue.encode(
        { value: message.Brightness! },
        writer.uint32(90).fork(),
      ).ldelim();
    }
    if (message.Contrast !== undefined) {
      DoubleValue.encode(
        { value: message.Contrast! },
        writer.uint32(98).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MedicalImageViewOptions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMedicalImageViewOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.WindowWidth = DoubleValue.decode(
            reader,
            reader.uint32(),
          ).value;
          break;
        case 2:
          message.WindowLevel = DoubleValue.decode(
            reader,
            reader.uint32(),
          ).value;
          break;
        case 3:
          message.Sharpness = DoubleValue.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.Invert = BoolValue.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.ColorMap = MedicalImageColorMap.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 6:
          message.ViewportCoords = MedicalImageViewportCoords.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 11:
          message.Brightness = DoubleValue.decode(
            reader,
            reader.uint32(),
          ).value;
          break;
        case 12:
          message.Contrast = DoubleValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MedicalImageViewOptions {
    return {
      WindowWidth: isSet(object.WindowWidth)
        ? Number(object.WindowWidth)
        : undefined,
      WindowLevel: isSet(object.WindowLevel)
        ? Number(object.WindowLevel)
        : undefined,
      Sharpness: isSet(object.Sharpness) ? Number(object.Sharpness) : undefined,
      Invert: isSet(object.Invert) ? Boolean(object.Invert) : undefined,
      ColorMap: isSet(object.ColorMap)
        ? MedicalImageColorMap.fromJSON(object.ColorMap)
        : undefined,
      ViewportCoords: isSet(object.ViewportCoords)
        ? MedicalImageViewportCoords.fromJSON(object.ViewportCoords)
        : undefined,
      Brightness: isSet(object.Brightness)
        ? Number(object.Brightness)
        : undefined,
      Contrast: isSet(object.Contrast) ? Number(object.Contrast) : undefined,
    };
  },

  toJSON(message: MedicalImageViewOptions): unknown {
    const obj: any = {};
    message.WindowWidth !== undefined &&
      (obj.WindowWidth = message.WindowWidth);
    message.WindowLevel !== undefined &&
      (obj.WindowLevel = message.WindowLevel);
    message.Sharpness !== undefined && (obj.Sharpness = message.Sharpness);
    message.Invert !== undefined && (obj.Invert = message.Invert);
    message.ColorMap !== undefined &&
      (obj.ColorMap = message.ColorMap
        ? MedicalImageColorMap.toJSON(message.ColorMap)
        : undefined);
    message.ViewportCoords !== undefined &&
      (obj.ViewportCoords = message.ViewportCoords
        ? MedicalImageViewportCoords.toJSON(message.ViewportCoords)
        : undefined);
    message.Brightness !== undefined && (obj.Brightness = message.Brightness);
    message.Contrast !== undefined && (obj.Contrast = message.Contrast);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MedicalImageViewOptions>, I>>(
    object: I,
  ): MedicalImageViewOptions {
    const message = createBaseMedicalImageViewOptions();
    message.WindowWidth = object.WindowWidth ?? undefined;
    message.WindowLevel = object.WindowLevel ?? undefined;
    message.Sharpness = object.Sharpness ?? undefined;
    message.Invert = object.Invert ?? undefined;
    message.ColorMap =
      object.ColorMap !== undefined && object.ColorMap !== null
        ? MedicalImageColorMap.fromPartial(object.ColorMap)
        : undefined;
    message.ViewportCoords =
      object.ViewportCoords !== undefined && object.ViewportCoords !== null
        ? MedicalImageViewportCoords.fromPartial(object.ViewportCoords)
        : undefined;
    message.Brightness = object.Brightness ?? undefined;
    message.Contrast = object.Contrast ?? undefined;
    return message;
  },
};

function createBaseMedicalImageColorMap(): MedicalImageColorMap {
  return {};
}

export const MedicalImageColorMap = {
  encode(
    _: MedicalImageColorMap,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MedicalImageColorMap {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMedicalImageColorMap();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): MedicalImageColorMap {
    return {};
  },

  toJSON(_: MedicalImageColorMap): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MedicalImageColorMap>, I>>(
    _: I,
  ): MedicalImageColorMap {
    const message = createBaseMedicalImageColorMap();
    return message;
  },
};

function createBaseMedicalImageViewportCoords(): MedicalImageViewportCoords {
  return {
    Left: undefined,
    Top: undefined,
    Right: undefined,
    Bottom: undefined,
  };
}

export const MedicalImageViewportCoords = {
  encode(
    message: MedicalImageViewportCoords,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Left !== undefined) {
      DoubleValue.encode(
        { value: message.Left! },
        writer.uint32(10).fork(),
      ).ldelim();
    }
    if (message.Top !== undefined) {
      DoubleValue.encode(
        { value: message.Top! },
        writer.uint32(18).fork(),
      ).ldelim();
    }
    if (message.Right !== undefined) {
      DoubleValue.encode(
        { value: message.Right! },
        writer.uint32(26).fork(),
      ).ldelim();
    }
    if (message.Bottom !== undefined) {
      DoubleValue.encode(
        { value: message.Bottom! },
        writer.uint32(34).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): MedicalImageViewportCoords {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMedicalImageViewportCoords();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Left = DoubleValue.decode(reader, reader.uint32()).value;
          break;
        case 2:
          message.Top = DoubleValue.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.Right = DoubleValue.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.Bottom = DoubleValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): MedicalImageViewportCoords {
    return {
      Left: isSet(object.Left) ? Number(object.Left) : undefined,
      Top: isSet(object.Top) ? Number(object.Top) : undefined,
      Right: isSet(object.Right) ? Number(object.Right) : undefined,
      Bottom: isSet(object.Bottom) ? Number(object.Bottom) : undefined,
    };
  },

  toJSON(message: MedicalImageViewportCoords): unknown {
    const obj: any = {};
    message.Left !== undefined && (obj.Left = message.Left);
    message.Top !== undefined && (obj.Top = message.Top);
    message.Right !== undefined && (obj.Right = message.Right);
    message.Bottom !== undefined && (obj.Bottom = message.Bottom);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<MedicalImageViewportCoords>, I>>(
    object: I,
  ): MedicalImageViewportCoords {
    const message = createBaseMedicalImageViewportCoords();
    message.Left = object.Left ?? undefined;
    message.Top = object.Top ?? undefined;
    message.Right = object.Right ?? undefined;
    message.Bottom = object.Bottom ?? undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
