import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch } from '@/shared/hooks';
import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { SetReportToothConditionsUserDecisionReq } from '@/shared/api/protocol_gen/api/core/svc_report';
import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import { reportsModel, useCheckReportSignature } from '@/entities/reports';
import { ConditionDecisionChange } from '@/entities/condition';
import { toothModel } from '@/entities/tooth';
import { logicalConditionModel } from '@/entities/logicalCondition';

const PESSIMISTIC_CONDITIONS_CODES = [
  ConditionCode.CodeTooth,
  ConditionCode.Implant,
  ConditionCode.Pontic,
  ConditionCode.RootFragment,
  ConditionCode.Missing,
  ConditionCode.ToothGerm,
];

export const useUpdateConditionDecision = (toothID: string) => {
  const [isLoading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  const { checkReportSignature } = useCheckReportSignature();

  const dispatch = useAppDispatch();

  const updateConditionDecision: ConditionDecisionChange = useCallback(
    async ({
      conditionIDs,
      parentConditionId,
      conditionCode,
      userDecision,
    }) => {
      checkReportSignature({
        toothID,
        onSignatureChecked: async () => {
          if (PESSIMISTIC_CONDITIONS_CODES.includes(conditionCode)) {
            setLoading(true);
          }

          const requestData: SetReportToothConditionsUserDecisionReq = {
            PositiveDecisionConditionIDs: [],
            NegativeDecisionConditionIDs: [],
          };

          if (userDecision === Decision.PositiveDecision) {
            requestData.PositiveDecisionConditionIDs = conditionIDs;
          } else {
            requestData.NegativeDecisionConditionIDs = conditionIDs;
          }

          try {
            // Update condition
            if (conditionIDs && conditionIDs.length) {
              // Because of diagnosis engine changing desicion in one condition may lead to changing in several conditions
              const { Conditions, Tooth } = await dispatch(
                reportsModel.thunks.setReportToothConditionsUserDecision(
                  requestData,
                ),
              ).unwrap();

              Conditions.forEach((condition) => {
                dispatch(logicalConditionModel.actions.updateOne(condition));
              });

              if (Tooth) {
                dispatch(toothModel.actions.setNewestOne(Tooth));
              }
              // Add condition
            } else {
              const { Conditions } = await dispatch(
                reportsModel.thunks.createReportToothConditions({
                  ToothID: toothID,
                  Conditions: [
                    {
                      Code: conditionCode,
                      ParentConditionID: parentConditionId ?? '',
                    },
                  ],
                }),
              ).unwrap();

              Conditions.forEach((condition) => {
                dispatch(logicalConditionModel.actions.addOne(condition));
              });
            }
          } finally {
            setLoading(false);
          }
        },
      });
    },
    [dispatch, formatMessage, toothID],
  );

  return {
    updateConditionDecision,
    isLoading,
  };
};
