import {
  createSlice,
  createEntityAdapter,
  PayloadAction,
  // Don't remove, need to debug dispatch spam situation from streams
  // prepareAutoBatched,
} from '@reduxjs/toolkit';
import { difference } from 'lodash';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import {
  DefaultInitialState,
  LoadingStateType,
  SliceName,
} from '@/shared/config';

import { sortByISONumberOrder } from '../lib/sortByISONumbersOrder';

import { updateCurrentReportROI } from './toothSlice.thunks';

export const toothAdapter = createEntityAdapter<Tooth>({
  selectId: (tooth: Tooth) => tooth.ID,
  sortComparer: (a, b) =>
    sortByISONumberOrder(a.Numeration?.ISO ?? 0, b.Numeration?.ISO ?? 0),
});

type ToothState = DefaultInitialState & {
  localROITeethISONumbers: number[];
  localROITeethIDs: string[];
  toothChartCustomMode: boolean;
  updatingROI: boolean;
};

const initialState: ToothState = {
  localROITeethISONumbers: [],
  localROITeethIDs: [],
  toothChartCustomMode: false,
  updatingROI: false, // TODO: use object with loading statuses?
  loading: 'idle',
};

const toothState = toothAdapter.getInitialState(initialState);

const setNewestOne = (
  state: typeof toothState,
  action: PayloadAction<Tooth>,
) => {
  const currentRevisionNumber =
    state.entities[action.payload.ID]?.Revision?.Number ?? 0;
  const payloadRevisionNumber = action.payload.Revision?.Number ?? 0;

  if (payloadRevisionNumber > currentRevisionNumber) {
    toothAdapter.setOne(state, action.payload);
  }
};

const toothSlice = createSlice({
  name: SliceName.tooth,
  initialState: toothState,
  reducers: {
    addOne: toothAdapter.addOne,
    addMany: toothAdapter.addMany,
    setOne: toothAdapter.setOne,
    setNewestOne,
    // Don't remove, need to debug dispatch spam situation from streams
    // setNewestOne: {
    //   reducer: setNewestOne,
    //   // Use the `prepareAutoBatched` utility to automatically
    //   // add the `action.meta[SHOULD_AUTOBATCH]` field the enhancer needs
    //   prepare: prepareAutoBatched<Tooth>(),
    // },
    setMany: toothAdapter.setMany,
    removeOne: toothAdapter.removeOne,
    removeAll: toothAdapter.removeAll,
    setLocalROITeethISONumbers: (state, action: PayloadAction<number[]>) => {
      state.localROITeethISONumbers = action.payload;
    },
    setLocalROITeethIDs: (state, action: PayloadAction<string[]>) => {
      const { localROITeethIDs } = state;
      const teethIDs = action.payload;

      const isAllReceivedIDsInROI = teethIDs.every((ISONumber) =>
        localROITeethIDs.includes(ISONumber),
      );

      const newROITeethIDs = isAllReceivedIDsInROI
        ? difference(localROITeethIDs, teethIDs)
        : [...localROITeethIDs, ...teethIDs];

      state.localROITeethIDs = newROITeethIDs;
    },
    initLocalROITeethIDs: (state, action: PayloadAction<string[]>) => {
      state.localROITeethIDs = action.payload;
    },
    setToothChartCustomMode: (state, action: PayloadAction<boolean>) => {
      state.toothChartCustomMode = action.payload;
    },
    setUpdatingROI: (state, action: PayloadAction<boolean>) => {
      state.updatingROI = action.payload;
    },
    setLoading: (state, action: PayloadAction<LoadingStateType>) => {
      state.loading = action.payload;
    },
    reset: () => toothAdapter.getInitialState(initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(updateCurrentReportROI.pending, (state) => {
      state.updatingROI = true;
    });

    builder.addCase(updateCurrentReportROI.fulfilled, (state, action) => {
      state.updatingROI = false;
      toothAdapter.setMany(state, action.payload.AllReportTeeth);
    });

    builder.addCase(updateCurrentReportROI.rejected, (state) => {
      state.updatingROI = false;
    });
  },
});

export const { actions } = toothSlice;

export default toothSlice.reducer;
