/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { Timestamp } from '../google/protobuf/timestamp';
import {
  OrganizationJobPosition,
  organizationJobPositionFromJSON,
  organizationJobPositionToJSON,
} from '../model/dto_organization_job_position';
import { OrganizationPermissions } from '../model/dto_organization_permissions';
import { Created, Revision, Deleted, Attribute } from '../model/dto_base';

export const protobufPackage = 'com.diagnocat.model';

export enum DentalNotationFormat {
  DentalNotationFormatISO = 0,
  DentalNotationFormatUniversal = 1,
  UNRECOGNIZED = -1,
}

export function dentalNotationFormatFromJSON(
  object: any,
): DentalNotationFormat {
  switch (object) {
    case 0:
    case 'DentalNotationFormatISO':
      return DentalNotationFormat.DentalNotationFormatISO;
    case 1:
    case 'DentalNotationFormatUniversal':
      return DentalNotationFormat.DentalNotationFormatUniversal;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return DentalNotationFormat.UNRECOGNIZED;
  }
}

export function dentalNotationFormatToJSON(
  object: DentalNotationFormat,
): string {
  switch (object) {
    case DentalNotationFormat.DentalNotationFormatISO:
      return 'DentalNotationFormatISO';
    case DentalNotationFormat.DentalNotationFormatUniversal:
      return 'DentalNotationFormatUniversal';
    case DentalNotationFormat.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum OrganizationType {
  OrganizationTypeInvalidValue = 0,
  OrganizationTypeClinic = 1,
  OrganizationTypeDiagnosticCenter = 2,
  OrganizationTypeSoloPractitioner = 3,
  UNRECOGNIZED = -1,
}

export function organizationTypeFromJSON(object: any): OrganizationType {
  switch (object) {
    case 0:
    case 'OrganizationTypeInvalidValue':
      return OrganizationType.OrganizationTypeInvalidValue;
    case 1:
    case 'OrganizationTypeClinic':
      return OrganizationType.OrganizationTypeClinic;
    case 2:
    case 'OrganizationTypeDiagnosticCenter':
      return OrganizationType.OrganizationTypeDiagnosticCenter;
    case 3:
    case 'OrganizationTypeSoloPractitioner':
      return OrganizationType.OrganizationTypeSoloPractitioner;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OrganizationType.UNRECOGNIZED;
  }
}

export function organizationTypeToJSON(object: OrganizationType): string {
  switch (object) {
    case OrganizationType.OrganizationTypeInvalidValue:
      return 'OrganizationTypeInvalidValue';
    case OrganizationType.OrganizationTypeClinic:
      return 'OrganizationTypeClinic';
    case OrganizationType.OrganizationTypeDiagnosticCenter:
      return 'OrganizationTypeDiagnosticCenter';
    case OrganizationType.OrganizationTypeSoloPractitioner:
      return 'OrganizationTypeSoloPractitioner';
    case OrganizationType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum OrganizationUserRoleType {
  /** OrganizationUserRoleTypeInvalidValue - default value */
  OrganizationUserRoleTypeInvalidValue = 0,
  /** OrganizationUserRoleTypeOwner - organization owner */
  OrganizationUserRoleTypeOwner = 1,
  /** OrganizationUserRoleTypeClinicalLeader - organization administrator */
  OrganizationUserRoleTypeClinicalLeader = 2,
  /** OrganizationUserRoleTypeTreatingDoctor - doctor */
  OrganizationUserRoleTypeTreatingDoctor = 3,
  /** OrganizationUserRoleTypeNonClinicalMember - External doctor participating in clinic */
  OrganizationUserRoleTypeNonClinicalMember = 4,
  UNRECOGNIZED = -1,
}

export function organizationUserRoleTypeFromJSON(
  object: any,
): OrganizationUserRoleType {
  switch (object) {
    case 0:
    case 'OrganizationUserRoleTypeInvalidValue':
      return OrganizationUserRoleType.OrganizationUserRoleTypeInvalidValue;
    case 1:
    case 'OrganizationUserRoleTypeOwner':
      return OrganizationUserRoleType.OrganizationUserRoleTypeOwner;
    case 2:
    case 'OrganizationUserRoleTypeClinicalLeader':
      return OrganizationUserRoleType.OrganizationUserRoleTypeClinicalLeader;
    case 3:
    case 'OrganizationUserRoleTypeTreatingDoctor':
      return OrganizationUserRoleType.OrganizationUserRoleTypeTreatingDoctor;
    case 4:
    case 'OrganizationUserRoleTypeNonClinicalMember':
      return OrganizationUserRoleType.OrganizationUserRoleTypeNonClinicalMember;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return OrganizationUserRoleType.UNRECOGNIZED;
  }
}

export function organizationUserRoleTypeToJSON(
  object: OrganizationUserRoleType,
): string {
  switch (object) {
    case OrganizationUserRoleType.OrganizationUserRoleTypeInvalidValue:
      return 'OrganizationUserRoleTypeInvalidValue';
    case OrganizationUserRoleType.OrganizationUserRoleTypeOwner:
      return 'OrganizationUserRoleTypeOwner';
    case OrganizationUserRoleType.OrganizationUserRoleTypeClinicalLeader:
      return 'OrganizationUserRoleTypeClinicalLeader';
    case OrganizationUserRoleType.OrganizationUserRoleTypeTreatingDoctor:
      return 'OrganizationUserRoleTypeTreatingDoctor';
    case OrganizationUserRoleType.OrganizationUserRoleTypeNonClinicalMember:
      return 'OrganizationUserRoleTypeNonClinicalMember';
    case OrganizationUserRoleType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum FeatureFlag {
  FeatureFlag_InvalidValue = 0,
  FeatureFlag_Show_Order3DReports = 1,
  FeatureFlag_Show_BillingShop = 2,
  FeatureFlag_Show_NonDentalFindings = 3,
  FeatureFlag_Show_ImplantPlanningButton = 4,
  FeatureFlag_Show_OrthoPlanningButton = 5,
  FeatureFlag_Hide_Probabilities = 101,
  FeatureFlag_Hide_ConditionsMasks = 102,
  FeatureFlag_Hide_PerioMasksAndSlices = 103,
  FeatureFlag_Hide_CariesSigns = 104,
  FeatureFlag_Hide_PBLRulesAndMeasurements = 105,
  UNRECOGNIZED = -1,
}

export function featureFlagFromJSON(object: any): FeatureFlag {
  switch (object) {
    case 0:
    case 'FeatureFlag_InvalidValue':
      return FeatureFlag.FeatureFlag_InvalidValue;
    case 1:
    case 'FeatureFlag_Show_Order3DReports':
      return FeatureFlag.FeatureFlag_Show_Order3DReports;
    case 2:
    case 'FeatureFlag_Show_BillingShop':
      return FeatureFlag.FeatureFlag_Show_BillingShop;
    case 3:
    case 'FeatureFlag_Show_NonDentalFindings':
      return FeatureFlag.FeatureFlag_Show_NonDentalFindings;
    case 4:
    case 'FeatureFlag_Show_ImplantPlanningButton':
      return FeatureFlag.FeatureFlag_Show_ImplantPlanningButton;
    case 5:
    case 'FeatureFlag_Show_OrthoPlanningButton':
      return FeatureFlag.FeatureFlag_Show_OrthoPlanningButton;
    case 101:
    case 'FeatureFlag_Hide_Probabilities':
      return FeatureFlag.FeatureFlag_Hide_Probabilities;
    case 102:
    case 'FeatureFlag_Hide_ConditionsMasks':
      return FeatureFlag.FeatureFlag_Hide_ConditionsMasks;
    case 103:
    case 'FeatureFlag_Hide_PerioMasksAndSlices':
      return FeatureFlag.FeatureFlag_Hide_PerioMasksAndSlices;
    case 104:
    case 'FeatureFlag_Hide_CariesSigns':
      return FeatureFlag.FeatureFlag_Hide_CariesSigns;
    case 105:
    case 'FeatureFlag_Hide_PBLRulesAndMeasurements':
      return FeatureFlag.FeatureFlag_Hide_PBLRulesAndMeasurements;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FeatureFlag.UNRECOGNIZED;
  }
}

export function featureFlagToJSON(object: FeatureFlag): string {
  switch (object) {
    case FeatureFlag.FeatureFlag_InvalidValue:
      return 'FeatureFlag_InvalidValue';
    case FeatureFlag.FeatureFlag_Show_Order3DReports:
      return 'FeatureFlag_Show_Order3DReports';
    case FeatureFlag.FeatureFlag_Show_BillingShop:
      return 'FeatureFlag_Show_BillingShop';
    case FeatureFlag.FeatureFlag_Show_NonDentalFindings:
      return 'FeatureFlag_Show_NonDentalFindings';
    case FeatureFlag.FeatureFlag_Show_ImplantPlanningButton:
      return 'FeatureFlag_Show_ImplantPlanningButton';
    case FeatureFlag.FeatureFlag_Show_OrthoPlanningButton:
      return 'FeatureFlag_Show_OrthoPlanningButton';
    case FeatureFlag.FeatureFlag_Hide_Probabilities:
      return 'FeatureFlag_Hide_Probabilities';
    case FeatureFlag.FeatureFlag_Hide_ConditionsMasks:
      return 'FeatureFlag_Hide_ConditionsMasks';
    case FeatureFlag.FeatureFlag_Hide_PerioMasksAndSlices:
      return 'FeatureFlag_Hide_PerioMasksAndSlices';
    case FeatureFlag.FeatureFlag_Hide_CariesSigns:
      return 'FeatureFlag_Hide_CariesSigns';
    case FeatureFlag.FeatureFlag_Hide_PBLRulesAndMeasurements:
      return 'FeatureFlag_Hide_PBLRulesAndMeasurements';
    case FeatureFlag.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum FeatureFlagType {
  FeatureFlagType_InvalidValue = 0,
  FeatureFlagType_Development = 1,
  FeatureFlagType_Regulatory = 2,
  UNRECOGNIZED = -1,
}

export function featureFlagTypeFromJSON(object: any): FeatureFlagType {
  switch (object) {
    case 0:
    case 'FeatureFlagType_InvalidValue':
      return FeatureFlagType.FeatureFlagType_InvalidValue;
    case 1:
    case 'FeatureFlagType_Development':
      return FeatureFlagType.FeatureFlagType_Development;
    case 2:
    case 'FeatureFlagType_Regulatory':
      return FeatureFlagType.FeatureFlagType_Regulatory;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return FeatureFlagType.UNRECOGNIZED;
  }
}

export function featureFlagTypeToJSON(object: FeatureFlagType): string {
  switch (object) {
    case FeatureFlagType.FeatureFlagType_InvalidValue:
      return 'FeatureFlagType_InvalidValue';
    case FeatureFlagType.FeatureFlagType_Development:
      return 'FeatureFlagType_Development';
    case FeatureFlagType.FeatureFlagType_Regulatory:
      return 'FeatureFlagType_Regulatory';
    case FeatureFlagType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Organization {
  ID: string;
  Name: string;
  Type: OrganizationType;
  ParentID: string;
  /** >> Asset.ID with AssetType_Organization_Logo */
  LogoAssetID: string;
  /** >> Asset.ID with AssetType_Organization_Stamp */
  StampAssetID: string;
  Contacts: OrganizationContacts | undefined;
  /** // PERM: OrganizationPermissions.CanViewSettings == true */
  Settings: OrganizationSettings | undefined;
  AvailableJobPositions: OrganizationJobPosition[];
  /** // PERM: com.diagnocat.model.OrganizationPermissions.CanViewStaff == true */
  UserRoles: OrganizationUserRole[];
  FeatureFlags: OrganizationFeatureFlag[];
  YourPermissions: OrganizationPermissions | undefined;
  /** creation information */
  Created: Created | undefined;
  /** information about the latest update */
  Revision: Revision | undefined;
  /** removal information */
  Deleted: Deleted | undefined;
}

/** TODO - needs product investigation */
export interface OrganizationSettings {
  HackEditUsersContacts: boolean;
  OnlyTeamMembersCanViewPatient: boolean;
  OnlyTeamMembersCanViewPersonalData: boolean;
  OnlyTeamMembersCanRequestReport: boolean;
  OnlyTeamMembersCanEditPatient: boolean;
  OnlyTeamMembersCanEditReport: boolean;
  /** default == DentalNotationFormatISO */
  DentalNotationFormat: DentalNotationFormat;
}

export interface OrganizationContacts {
  /** immutable field */
  Country: string;
  Region: string;
  City: string;
  Address: string;
  Phones: string[];
  Emails: string[];
  WebSite: string;
  ZipCode: string;
}

export interface OrganizationUserRole {
  UserID: string;
  PersonalData: OrganizationDefinedPersonalData | undefined;
  Roles: OrganizationUserRoleType[];
  /** user job positions */
  JobPositions: OrganizationJobPosition[];
  /** >> Asset.ID with AssetType_Organization_EmployeeStamp */
  StampAssetID: string;
  CreatedAt: Date | undefined;
  Attributes: Attribute[];
}

export interface OrganizationDefinedPersonalData {
  FirstName: string;
  LastName: string;
  Email: string;
}

export interface OrganizationFeatureFlag {
  Flag: FeatureFlag;
  Value: boolean;
  ReadOnly: boolean;
  Type: FeatureFlagType;
}

function createBaseOrganization(): Organization {
  return {
    ID: '',
    Name: '',
    Type: 0,
    ParentID: '',
    LogoAssetID: '',
    StampAssetID: '',
    Contacts: undefined,
    Settings: undefined,
    AvailableJobPositions: [],
    UserRoles: [],
    FeatureFlags: [],
    YourPermissions: undefined,
    Created: undefined,
    Revision: undefined,
    Deleted: undefined,
  };
}

export const Organization = {
  encode(
    message: Organization,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== '') {
      writer.uint32(18).string(message.Name);
    }
    if (message.Type !== 0) {
      writer.uint32(24).int32(message.Type);
    }
    if (message.ParentID !== '') {
      writer.uint32(34).string(message.ParentID);
    }
    if (message.LogoAssetID !== '') {
      writer.uint32(42).string(message.LogoAssetID);
    }
    if (message.StampAssetID !== '') {
      writer.uint32(50).string(message.StampAssetID);
    }
    if (message.Contacts !== undefined) {
      OrganizationContacts.encode(
        message.Contacts,
        writer.uint32(58).fork(),
      ).ldelim();
    }
    if (message.Settings !== undefined) {
      OrganizationSettings.encode(
        message.Settings,
        writer.uint32(66).fork(),
      ).ldelim();
    }
    writer.uint32(74).fork();
    for (const v of message.AvailableJobPositions) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.UserRoles) {
      OrganizationUserRole.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.FeatureFlags) {
      OrganizationFeatureFlag.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.YourPermissions !== undefined) {
      OrganizationPermissions.encode(
        message.YourPermissions,
        writer.uint32(80162).fork(),
      ).ldelim();
    }
    if (message.Created !== undefined) {
      Created.encode(message.Created, writer.uint32(80242).fork()).ldelim();
    }
    if (message.Revision !== undefined) {
      Revision.encode(message.Revision, writer.uint32(80322).fork()).ldelim();
    }
    if (message.Deleted !== undefined) {
      Deleted.encode(message.Deleted, writer.uint32(80402).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Organization {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Name = reader.string();
          break;
        case 3:
          message.Type = reader.int32() as any;
          break;
        case 4:
          message.ParentID = reader.string();
          break;
        case 5:
          message.LogoAssetID = reader.string();
          break;
        case 6:
          message.StampAssetID = reader.string();
          break;
        case 7:
          message.Contacts = OrganizationContacts.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 8:
          message.Settings = OrganizationSettings.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 9:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.AvailableJobPositions.push(reader.int32() as any);
            }
          } else {
            message.AvailableJobPositions.push(reader.int32() as any);
          }
          break;
        case 10:
          message.UserRoles.push(
            OrganizationUserRole.decode(reader, reader.uint32()),
          );
          break;
        case 11:
          message.FeatureFlags.push(
            OrganizationFeatureFlag.decode(reader, reader.uint32()),
          );
          break;
        case 10020:
          message.YourPermissions = OrganizationPermissions.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 10030:
          message.Created = Created.decode(reader, reader.uint32());
          break;
        case 10040:
          message.Revision = Revision.decode(reader, reader.uint32());
          break;
        case 10050:
          message.Deleted = Deleted.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Organization {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Name: isSet(object.Name) ? String(object.Name) : '',
      Type: isSet(object.Type) ? organizationTypeFromJSON(object.Type) : 0,
      ParentID: isSet(object.ParentID) ? String(object.ParentID) : '',
      LogoAssetID: isSet(object.LogoAssetID) ? String(object.LogoAssetID) : '',
      StampAssetID: isSet(object.StampAssetID)
        ? String(object.StampAssetID)
        : '',
      Contacts: isSet(object.Contacts)
        ? OrganizationContacts.fromJSON(object.Contacts)
        : undefined,
      Settings: isSet(object.Settings)
        ? OrganizationSettings.fromJSON(object.Settings)
        : undefined,
      AvailableJobPositions: Array.isArray(object?.AvailableJobPositions)
        ? object.AvailableJobPositions.map((e: any) =>
            organizationJobPositionFromJSON(e),
          )
        : [],
      UserRoles: Array.isArray(object?.UserRoles)
        ? object.UserRoles.map((e: any) => OrganizationUserRole.fromJSON(e))
        : [],
      FeatureFlags: Array.isArray(object?.FeatureFlags)
        ? object.FeatureFlags.map((e: any) =>
            OrganizationFeatureFlag.fromJSON(e),
          )
        : [],
      YourPermissions: isSet(object.YourPermissions)
        ? OrganizationPermissions.fromJSON(object.YourPermissions)
        : undefined,
      Created: isSet(object.Created)
        ? Created.fromJSON(object.Created)
        : undefined,
      Revision: isSet(object.Revision)
        ? Revision.fromJSON(object.Revision)
        : undefined,
      Deleted: isSet(object.Deleted)
        ? Deleted.fromJSON(object.Deleted)
        : undefined,
    };
  },

  toJSON(message: Organization): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Name !== undefined && (obj.Name = message.Name);
    message.Type !== undefined &&
      (obj.Type = organizationTypeToJSON(message.Type));
    message.ParentID !== undefined && (obj.ParentID = message.ParentID);
    message.LogoAssetID !== undefined &&
      (obj.LogoAssetID = message.LogoAssetID);
    message.StampAssetID !== undefined &&
      (obj.StampAssetID = message.StampAssetID);
    message.Contacts !== undefined &&
      (obj.Contacts = message.Contacts
        ? OrganizationContacts.toJSON(message.Contacts)
        : undefined);
    message.Settings !== undefined &&
      (obj.Settings = message.Settings
        ? OrganizationSettings.toJSON(message.Settings)
        : undefined);
    if (message.AvailableJobPositions) {
      obj.AvailableJobPositions = message.AvailableJobPositions.map((e) =>
        organizationJobPositionToJSON(e),
      );
    } else {
      obj.AvailableJobPositions = [];
    }
    if (message.UserRoles) {
      obj.UserRoles = message.UserRoles.map((e) =>
        e ? OrganizationUserRole.toJSON(e) : undefined,
      );
    } else {
      obj.UserRoles = [];
    }
    if (message.FeatureFlags) {
      obj.FeatureFlags = message.FeatureFlags.map((e) =>
        e ? OrganizationFeatureFlag.toJSON(e) : undefined,
      );
    } else {
      obj.FeatureFlags = [];
    }
    message.YourPermissions !== undefined &&
      (obj.YourPermissions = message.YourPermissions
        ? OrganizationPermissions.toJSON(message.YourPermissions)
        : undefined);
    message.Created !== undefined &&
      (obj.Created = message.Created
        ? Created.toJSON(message.Created)
        : undefined);
    message.Revision !== undefined &&
      (obj.Revision = message.Revision
        ? Revision.toJSON(message.Revision)
        : undefined);
    message.Deleted !== undefined &&
      (obj.Deleted = message.Deleted
        ? Deleted.toJSON(message.Deleted)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Organization>, I>>(
    object: I,
  ): Organization {
    const message = createBaseOrganization();
    message.ID = object.ID ?? '';
    message.Name = object.Name ?? '';
    message.Type = object.Type ?? 0;
    message.ParentID = object.ParentID ?? '';
    message.LogoAssetID = object.LogoAssetID ?? '';
    message.StampAssetID = object.StampAssetID ?? '';
    message.Contacts =
      object.Contacts !== undefined && object.Contacts !== null
        ? OrganizationContacts.fromPartial(object.Contacts)
        : undefined;
    message.Settings =
      object.Settings !== undefined && object.Settings !== null
        ? OrganizationSettings.fromPartial(object.Settings)
        : undefined;
    message.AvailableJobPositions =
      object.AvailableJobPositions?.map((e) => e) || [];
    message.UserRoles =
      object.UserRoles?.map((e) => OrganizationUserRole.fromPartial(e)) || [];
    message.FeatureFlags =
      object.FeatureFlags?.map((e) => OrganizationFeatureFlag.fromPartial(e)) ||
      [];
    message.YourPermissions =
      object.YourPermissions !== undefined && object.YourPermissions !== null
        ? OrganizationPermissions.fromPartial(object.YourPermissions)
        : undefined;
    message.Created =
      object.Created !== undefined && object.Created !== null
        ? Created.fromPartial(object.Created)
        : undefined;
    message.Revision =
      object.Revision !== undefined && object.Revision !== null
        ? Revision.fromPartial(object.Revision)
        : undefined;
    message.Deleted =
      object.Deleted !== undefined && object.Deleted !== null
        ? Deleted.fromPartial(object.Deleted)
        : undefined;
    return message;
  },
};

function createBaseOrganizationSettings(): OrganizationSettings {
  return {
    HackEditUsersContacts: false,
    OnlyTeamMembersCanViewPatient: false,
    OnlyTeamMembersCanViewPersonalData: false,
    OnlyTeamMembersCanRequestReport: false,
    OnlyTeamMembersCanEditPatient: false,
    OnlyTeamMembersCanEditReport: false,
    DentalNotationFormat: 0,
  };
}

export const OrganizationSettings = {
  encode(
    message: OrganizationSettings,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.HackEditUsersContacts === true) {
      writer.uint32(8).bool(message.HackEditUsersContacts);
    }
    if (message.OnlyTeamMembersCanViewPatient === true) {
      writer.uint32(16).bool(message.OnlyTeamMembersCanViewPatient);
    }
    if (message.OnlyTeamMembersCanViewPersonalData === true) {
      writer.uint32(24).bool(message.OnlyTeamMembersCanViewPersonalData);
    }
    if (message.OnlyTeamMembersCanRequestReport === true) {
      writer.uint32(32).bool(message.OnlyTeamMembersCanRequestReport);
    }
    if (message.OnlyTeamMembersCanEditPatient === true) {
      writer.uint32(40).bool(message.OnlyTeamMembersCanEditPatient);
    }
    if (message.OnlyTeamMembersCanEditReport === true) {
      writer.uint32(48).bool(message.OnlyTeamMembersCanEditReport);
    }
    if (message.DentalNotationFormat !== 0) {
      writer.uint32(88).int32(message.DentalNotationFormat);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationSettings {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.HackEditUsersContacts = reader.bool();
          break;
        case 2:
          message.OnlyTeamMembersCanViewPatient = reader.bool();
          break;
        case 3:
          message.OnlyTeamMembersCanViewPersonalData = reader.bool();
          break;
        case 4:
          message.OnlyTeamMembersCanRequestReport = reader.bool();
          break;
        case 5:
          message.OnlyTeamMembersCanEditPatient = reader.bool();
          break;
        case 6:
          message.OnlyTeamMembersCanEditReport = reader.bool();
          break;
        case 11:
          message.DentalNotationFormat = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationSettings {
    return {
      HackEditUsersContacts: isSet(object.HackEditUsersContacts)
        ? Boolean(object.HackEditUsersContacts)
        : false,
      OnlyTeamMembersCanViewPatient: isSet(object.OnlyTeamMembersCanViewPatient)
        ? Boolean(object.OnlyTeamMembersCanViewPatient)
        : false,
      OnlyTeamMembersCanViewPersonalData: isSet(
        object.OnlyTeamMembersCanViewPersonalData,
      )
        ? Boolean(object.OnlyTeamMembersCanViewPersonalData)
        : false,
      OnlyTeamMembersCanRequestReport: isSet(
        object.OnlyTeamMembersCanRequestReport,
      )
        ? Boolean(object.OnlyTeamMembersCanRequestReport)
        : false,
      OnlyTeamMembersCanEditPatient: isSet(object.OnlyTeamMembersCanEditPatient)
        ? Boolean(object.OnlyTeamMembersCanEditPatient)
        : false,
      OnlyTeamMembersCanEditReport: isSet(object.OnlyTeamMembersCanEditReport)
        ? Boolean(object.OnlyTeamMembersCanEditReport)
        : false,
      DentalNotationFormat: isSet(object.DentalNotationFormat)
        ? dentalNotationFormatFromJSON(object.DentalNotationFormat)
        : 0,
    };
  },

  toJSON(message: OrganizationSettings): unknown {
    const obj: any = {};
    message.HackEditUsersContacts !== undefined &&
      (obj.HackEditUsersContacts = message.HackEditUsersContacts);
    message.OnlyTeamMembersCanViewPatient !== undefined &&
      (obj.OnlyTeamMembersCanViewPatient =
        message.OnlyTeamMembersCanViewPatient);
    message.OnlyTeamMembersCanViewPersonalData !== undefined &&
      (obj.OnlyTeamMembersCanViewPersonalData =
        message.OnlyTeamMembersCanViewPersonalData);
    message.OnlyTeamMembersCanRequestReport !== undefined &&
      (obj.OnlyTeamMembersCanRequestReport =
        message.OnlyTeamMembersCanRequestReport);
    message.OnlyTeamMembersCanEditPatient !== undefined &&
      (obj.OnlyTeamMembersCanEditPatient =
        message.OnlyTeamMembersCanEditPatient);
    message.OnlyTeamMembersCanEditReport !== undefined &&
      (obj.OnlyTeamMembersCanEditReport = message.OnlyTeamMembersCanEditReport);
    message.DentalNotationFormat !== undefined &&
      (obj.DentalNotationFormat = dentalNotationFormatToJSON(
        message.DentalNotationFormat,
      ));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationSettings>, I>>(
    object: I,
  ): OrganizationSettings {
    const message = createBaseOrganizationSettings();
    message.HackEditUsersContacts = object.HackEditUsersContacts ?? false;
    message.OnlyTeamMembersCanViewPatient =
      object.OnlyTeamMembersCanViewPatient ?? false;
    message.OnlyTeamMembersCanViewPersonalData =
      object.OnlyTeamMembersCanViewPersonalData ?? false;
    message.OnlyTeamMembersCanRequestReport =
      object.OnlyTeamMembersCanRequestReport ?? false;
    message.OnlyTeamMembersCanEditPatient =
      object.OnlyTeamMembersCanEditPatient ?? false;
    message.OnlyTeamMembersCanEditReport =
      object.OnlyTeamMembersCanEditReport ?? false;
    message.DentalNotationFormat = object.DentalNotationFormat ?? 0;
    return message;
  },
};

function createBaseOrganizationContacts(): OrganizationContacts {
  return {
    Country: '',
    Region: '',
    City: '',
    Address: '',
    Phones: [],
    Emails: [],
    WebSite: '',
    ZipCode: '',
  };
}

export const OrganizationContacts = {
  encode(
    message: OrganizationContacts,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Country !== '') {
      writer.uint32(10).string(message.Country);
    }
    if (message.Region !== '') {
      writer.uint32(18).string(message.Region);
    }
    if (message.City !== '') {
      writer.uint32(26).string(message.City);
    }
    if (message.Address !== '') {
      writer.uint32(34).string(message.Address);
    }
    for (const v of message.Phones) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.Emails) {
      writer.uint32(50).string(v!);
    }
    if (message.WebSite !== '') {
      writer.uint32(58).string(message.WebSite);
    }
    if (message.ZipCode !== '') {
      writer.uint32(66).string(message.ZipCode);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationContacts {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationContacts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Country = reader.string();
          break;
        case 2:
          message.Region = reader.string();
          break;
        case 3:
          message.City = reader.string();
          break;
        case 4:
          message.Address = reader.string();
          break;
        case 5:
          message.Phones.push(reader.string());
          break;
        case 6:
          message.Emails.push(reader.string());
          break;
        case 7:
          message.WebSite = reader.string();
          break;
        case 8:
          message.ZipCode = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationContacts {
    return {
      Country: isSet(object.Country) ? String(object.Country) : '',
      Region: isSet(object.Region) ? String(object.Region) : '',
      City: isSet(object.City) ? String(object.City) : '',
      Address: isSet(object.Address) ? String(object.Address) : '',
      Phones: Array.isArray(object?.Phones)
        ? object.Phones.map((e: any) => String(e))
        : [],
      Emails: Array.isArray(object?.Emails)
        ? object.Emails.map((e: any) => String(e))
        : [],
      WebSite: isSet(object.WebSite) ? String(object.WebSite) : '',
      ZipCode: isSet(object.ZipCode) ? String(object.ZipCode) : '',
    };
  },

  toJSON(message: OrganizationContacts): unknown {
    const obj: any = {};
    message.Country !== undefined && (obj.Country = message.Country);
    message.Region !== undefined && (obj.Region = message.Region);
    message.City !== undefined && (obj.City = message.City);
    message.Address !== undefined && (obj.Address = message.Address);
    if (message.Phones) {
      obj.Phones = message.Phones.map((e) => e);
    } else {
      obj.Phones = [];
    }
    if (message.Emails) {
      obj.Emails = message.Emails.map((e) => e);
    } else {
      obj.Emails = [];
    }
    message.WebSite !== undefined && (obj.WebSite = message.WebSite);
    message.ZipCode !== undefined && (obj.ZipCode = message.ZipCode);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationContacts>, I>>(
    object: I,
  ): OrganizationContacts {
    const message = createBaseOrganizationContacts();
    message.Country = object.Country ?? '';
    message.Region = object.Region ?? '';
    message.City = object.City ?? '';
    message.Address = object.Address ?? '';
    message.Phones = object.Phones?.map((e) => e) || [];
    message.Emails = object.Emails?.map((e) => e) || [];
    message.WebSite = object.WebSite ?? '';
    message.ZipCode = object.ZipCode ?? '';
    return message;
  },
};

function createBaseOrganizationUserRole(): OrganizationUserRole {
  return {
    UserID: '',
    PersonalData: undefined,
    Roles: [],
    JobPositions: [],
    StampAssetID: '',
    CreatedAt: undefined,
    Attributes: [],
  };
}

export const OrganizationUserRole = {
  encode(
    message: OrganizationUserRole,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.UserID !== '') {
      writer.uint32(10).string(message.UserID);
    }
    if (message.PersonalData !== undefined) {
      OrganizationDefinedPersonalData.encode(
        message.PersonalData,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.Roles) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.JobPositions) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.StampAssetID !== '') {
      writer.uint32(42).string(message.StampAssetID);
    }
    if (message.CreatedAt !== undefined) {
      Timestamp.encode(
        toTimestamp(message.CreatedAt),
        writer.uint32(50).fork(),
      ).ldelim();
    }
    for (const v of message.Attributes) {
      Attribute.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationUserRole {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationUserRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.UserID = reader.string();
          break;
        case 2:
          message.PersonalData = OrganizationDefinedPersonalData.decode(
            reader,
            reader.uint32(),
          );
          break;
        case 3:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.Roles.push(reader.int32() as any);
            }
          } else {
            message.Roles.push(reader.int32() as any);
          }
          break;
        case 4:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.JobPositions.push(reader.int32() as any);
            }
          } else {
            message.JobPositions.push(reader.int32() as any);
          }
          break;
        case 5:
          message.StampAssetID = reader.string();
          break;
        case 6:
          message.CreatedAt = fromTimestamp(
            Timestamp.decode(reader, reader.uint32()),
          );
          break;
        case 21:
          message.Attributes.push(Attribute.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationUserRole {
    return {
      UserID: isSet(object.UserID) ? String(object.UserID) : '',
      PersonalData: isSet(object.PersonalData)
        ? OrganizationDefinedPersonalData.fromJSON(object.PersonalData)
        : undefined,
      Roles: Array.isArray(object?.Roles)
        ? object.Roles.map((e: any) => organizationUserRoleTypeFromJSON(e))
        : [],
      JobPositions: Array.isArray(object?.JobPositions)
        ? object.JobPositions.map((e: any) =>
            organizationJobPositionFromJSON(e),
          )
        : [],
      StampAssetID: isSet(object.StampAssetID)
        ? String(object.StampAssetID)
        : '',
      CreatedAt: isSet(object.CreatedAt)
        ? fromJsonTimestamp(object.CreatedAt)
        : undefined,
      Attributes: Array.isArray(object?.Attributes)
        ? object.Attributes.map((e: any) => Attribute.fromJSON(e))
        : [],
    };
  },

  toJSON(message: OrganizationUserRole): unknown {
    const obj: any = {};
    message.UserID !== undefined && (obj.UserID = message.UserID);
    message.PersonalData !== undefined &&
      (obj.PersonalData = message.PersonalData
        ? OrganizationDefinedPersonalData.toJSON(message.PersonalData)
        : undefined);
    if (message.Roles) {
      obj.Roles = message.Roles.map((e) => organizationUserRoleTypeToJSON(e));
    } else {
      obj.Roles = [];
    }
    if (message.JobPositions) {
      obj.JobPositions = message.JobPositions.map((e) =>
        organizationJobPositionToJSON(e),
      );
    } else {
      obj.JobPositions = [];
    }
    message.StampAssetID !== undefined &&
      (obj.StampAssetID = message.StampAssetID);
    message.CreatedAt !== undefined &&
      (obj.CreatedAt = message.CreatedAt.toISOString());
    if (message.Attributes) {
      obj.Attributes = message.Attributes.map((e) =>
        e ? Attribute.toJSON(e) : undefined,
      );
    } else {
      obj.Attributes = [];
    }
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationUserRole>, I>>(
    object: I,
  ): OrganizationUserRole {
    const message = createBaseOrganizationUserRole();
    message.UserID = object.UserID ?? '';
    message.PersonalData =
      object.PersonalData !== undefined && object.PersonalData !== null
        ? OrganizationDefinedPersonalData.fromPartial(object.PersonalData)
        : undefined;
    message.Roles = object.Roles?.map((e) => e) || [];
    message.JobPositions = object.JobPositions?.map((e) => e) || [];
    message.StampAssetID = object.StampAssetID ?? '';
    message.CreatedAt = object.CreatedAt ?? undefined;
    message.Attributes =
      object.Attributes?.map((e) => Attribute.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOrganizationDefinedPersonalData(): OrganizationDefinedPersonalData {
  return { FirstName: '', LastName: '', Email: '' };
}

export const OrganizationDefinedPersonalData = {
  encode(
    message: OrganizationDefinedPersonalData,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.FirstName !== '') {
      writer.uint32(10).string(message.FirstName);
    }
    if (message.LastName !== '') {
      writer.uint32(18).string(message.LastName);
    }
    if (message.Email !== '') {
      writer.uint32(26).string(message.Email);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationDefinedPersonalData {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationDefinedPersonalData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.FirstName = reader.string();
          break;
        case 2:
          message.LastName = reader.string();
          break;
        case 3:
          message.Email = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationDefinedPersonalData {
    return {
      FirstName: isSet(object.FirstName) ? String(object.FirstName) : '',
      LastName: isSet(object.LastName) ? String(object.LastName) : '',
      Email: isSet(object.Email) ? String(object.Email) : '',
    };
  },

  toJSON(message: OrganizationDefinedPersonalData): unknown {
    const obj: any = {};
    message.FirstName !== undefined && (obj.FirstName = message.FirstName);
    message.LastName !== undefined && (obj.LastName = message.LastName);
    message.Email !== undefined && (obj.Email = message.Email);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationDefinedPersonalData>, I>>(
    object: I,
  ): OrganizationDefinedPersonalData {
    const message = createBaseOrganizationDefinedPersonalData();
    message.FirstName = object.FirstName ?? '';
    message.LastName = object.LastName ?? '';
    message.Email = object.Email ?? '';
    return message;
  },
};

function createBaseOrganizationFeatureFlag(): OrganizationFeatureFlag {
  return { Flag: 0, Value: false, ReadOnly: false, Type: 0 };
}

export const OrganizationFeatureFlag = {
  encode(
    message: OrganizationFeatureFlag,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Flag !== 0) {
      writer.uint32(8).int32(message.Flag);
    }
    if (message.Value === true) {
      writer.uint32(16).bool(message.Value);
    }
    if (message.ReadOnly === true) {
      writer.uint32(24).bool(message.ReadOnly);
    }
    if (message.Type !== 0) {
      writer.uint32(32).int32(message.Type);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): OrganizationFeatureFlag {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganizationFeatureFlag();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Flag = reader.int32() as any;
          break;
        case 2:
          message.Value = reader.bool();
          break;
        case 3:
          message.ReadOnly = reader.bool();
          break;
        case 4:
          message.Type = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OrganizationFeatureFlag {
    return {
      Flag: isSet(object.Flag) ? featureFlagFromJSON(object.Flag) : 0,
      Value: isSet(object.Value) ? Boolean(object.Value) : false,
      ReadOnly: isSet(object.ReadOnly) ? Boolean(object.ReadOnly) : false,
      Type: isSet(object.Type) ? featureFlagTypeFromJSON(object.Type) : 0,
    };
  },

  toJSON(message: OrganizationFeatureFlag): unknown {
    const obj: any = {};
    message.Flag !== undefined && (obj.Flag = featureFlagToJSON(message.Flag));
    message.Value !== undefined && (obj.Value = message.Value);
    message.ReadOnly !== undefined && (obj.ReadOnly = message.ReadOnly);
    message.Type !== undefined &&
      (obj.Type = featureFlagTypeToJSON(message.Type));
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<OrganizationFeatureFlag>, I>>(
    object: I,
  ): OrganizationFeatureFlag {
    const message = createBaseOrganizationFeatureFlag();
    message.Flag = object.Flag ?? 0;
    message.Value = object.Value ?? false;
    message.ReadOnly = object.ReadOnly ?? false;
    message.Type = object.Type ?? 0;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
