import { BASE_URI } from '../../../shared/config/config';
import { ImageSizeType } from '../config/types';

export const getImageSrc = (
  imageID: string,
  imageSize: ImageSizeType,
): string =>
  imageID
    ? `${BASE_URI}/api/storage/download/image/${imageSize}/${imageID}`
    : '';
