import { createSelector } from '@reduxjs/toolkit';

import { Account } from '@/shared/api/protocol_gen/api/billing_new/dto_account_new';
import {
  ProductState,
  ProductStateStatus,
} from '@/shared/api/protocol_gen/api/billing_new/dto_product_new';
import { Order } from '@/shared/api/protocol_gen/api/billing_new/dto_order_new';

import { RootState } from '@/app/model/store';

import { isProductActive } from '../lib/isProductActive';

export const selectAccount = (state: RootState): Account =>
  state.billing.account;

export const selectInventory = (state: RootState): ProductState[] =>
  state.billing.inventory;

export const selectCurrentSubscription = createSelector(
  selectInventory,
  (inventory) =>
    inventory?.find(
      (inventoryItem) =>
        inventoryItem.Model?.Subscription &&
        inventoryItem.Status === ProductStateStatus.ProductStateStatusActive,
    ),
);

export const selectSubscriptionLifeTime = (state: RootState): number =>
  state.billing.subscriptionLifeTime;

export const selectBillingZoneAndCountry = (
  state: RootState,
): { billingZone: string; country: string } => ({
  billingZone: state.billing.account?.BillingZones?.at(0) as string,
  country: state.billing.account?.Country,
});

export const selectCurrentAddons = createSelector(
  selectInventory,
  (inventory) =>
    inventory.filter((inventoryItem) => inventoryItem?.Model?.Addon),
);

export const selectInvoices = (state: RootState): Order[] =>
  state.billing.invoices;

export const selectActiveProducts = createSelector(
  selectInventory,
  (inventory) => {
    if (inventory.length === 0) return [] as ProductState[];

    return inventory.filter(isProductActive);
  },
);

export const selectAvailableProducts = (state: RootState) =>
  state.billing.availableProducts;

export const selectAvailableSubscriptions = createSelector(
  selectAvailableProducts,
  (availableProducts) =>
    availableProducts.filter(
      (product) => product?.Subscription && !product.AvailableForAdminsOnly,
    ),
);
export const selectAvailableAddons = createSelector(
  selectAvailableProducts,
  (availableProducts) =>
    availableProducts.filter(
      (product) => product?.Addon && !product.AvailableForAdminsOnly,
    ),
);

export const selectSelectedTopUpPackageID = (state: RootState) =>
  state.billing.selectedTopUpPackageID;

export const selectLoading = (state: RootState) => state.billing.loading;
