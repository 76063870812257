import { useState } from 'react';

import { useModal } from '@/shared/hooks';

export const useRemoveReportModal = () => {
  const [currentReportID, setCurrentReportID] = useState<string>();

  const { isOpen, openModal, closeModal } = useModal();

  const openRemoveReportModal = (reportID: string) => {
    setCurrentReportID(reportID);
    openModal();
  };

  const closeRemoveReportModal = () => {
    setCurrentReportID(undefined);
    closeModal();
  };

  return {
    currentReportID,
    isRemoveReportModalOpen: isOpen,
    openRemoveReportModal,
    closeRemoveReportModal,
  };
};
