import { defineMessages } from 'react-intl';

export const maskFiltersDefaultMessages = defineMessages({
  perio: {
    id: 'ioxray.mask.filter.perio',
    defaultMessage: 'Perio',
  },
  restorative: {
    id: 'ioxray.mask.filter.restorative',
    defaultMessage: 'Restorative',
  },
  endo: {
    id: 'ioxray.mask.filter.endo',
    defaultMessage: 'Endo',
  },
  anatomy: {
    id: 'ioxray.mask.filter.anatomy',
    defaultMessage: 'Anatomy',
  },
});
