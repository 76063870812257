import { ConditionCode } from '@/shared/api/protocol_gen/model/dto_report_condition_codes';

import {
  MASK_COLORS,
  MASK_FILTERS_PERIO_CODES,
  MASK_FILTERS_RESTORATIVE_CODES,
} from './config';

export const getMaskColorConfig = (
  code: ConditionCode,
): { color: number; opacity: number } => {
  if (MASK_FILTERS_PERIO_CODES.includes(code)) {
    return MASK_COLORS.perio;
  }
  if (MASK_FILTERS_RESTORATIVE_CODES.includes(code)) {
    return MASK_COLORS.restorative;
  }

  return MASK_COLORS.endo;
};
