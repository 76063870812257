import { createSelector } from '@reduxjs/toolkit';
import { compose, filter, map, prop } from 'ramda';

import {
  Asset,
  AssetType,
  GeneratedReportAsset,
} from '@/shared/api/protocol_gen/model/dto_asset';

import { RootState } from '@/app/model/store';

import { assetsAdapter } from './assetsSlice';

const assetsSelectors = assetsAdapter.getSelectors(
  (state: RootState) => state.assets,
);

export const { selectAll, selectById, selectEntities } = assetsSelectors;

export const selectMedicalImages = createSelector(
  [assetsSelectors.selectAll],
  (assets) =>
    assets.reduce((accumulator, asset) => {
      if (asset.GeneratedReport) {
        return [...accumulator, asset.GeneratedReport];
      }

      return accumulator;
    }, [] as GeneratedReportAsset[]),
);

export const selectMedicalImagesByToothID = (toothID: string) =>
  createSelector(selectMedicalImages, (medicalImages) =>
    medicalImages.filter(
      (medicalImage) => medicalImage?.Belonging?.ToothID === toothID,
    ),
  );

export const selectByToothID = (toothID: string) =>
  createSelector(assetsSelectors.selectAll, (medicalImages) =>
    medicalImages.filter(
      (medicalImage) =>
        medicalImage?.GeneratedReport?.Belonging?.ToothID === toothID ||
        medicalImage?.Report?.Belonging?.ToothID === toothID,
    ),
  );

export const selectByReportID = (reportID: string) =>
  createSelector(assetsSelectors.selectAll, (medicalImages) =>
    medicalImages.filter(
      (medicalImage) =>
        medicalImage?.GeneratedReport?.Belonging?.ReportID === reportID,
    ),
  );

export const selectCBCTGPPanoImageByReportID = (reportID: string) =>
  createSelector(selectMedicalImages, (medicalImages) =>
    medicalImages.find(
      (medicalImage) =>
        medicalImage?.Belonging?.ReportID === reportID &&
        medicalImage?.CBCTGPPanoramaReformatGeneral,
    ),
  );

export const selectEndodonticPanoImageByReportID = (reportID: string) =>
  createSelector(selectMedicalImages, (medicalImages) =>
    medicalImages.find(
      (medicalImage) =>
        medicalImage?.Belonging?.ReportID === reportID &&
        medicalImage?.CBCTEndoPanoramaSplit,
    ),
  );

export const selectCBCTGPPanoImageSplit = (reportID: string) =>
  createSelector(selectMedicalImages, (medicalImages) =>
    medicalImages.find(
      (medicalImage) =>
        medicalImage?.Belonging?.ReportID === reportID &&
        medicalImage?.CBCTGPPanoramaReformatSplit,
    ),
  );

export const selectGPAssetByReportID = (reportID: string) =>
  createSelector([assetsSelectors.selectAll], (assets) =>
    assets.find(
      (asset) =>
        asset?.GeneratedReport?.Belonging?.ReportID === reportID &&
        asset?.GeneratedReport?.CBCTGPPanoramaReformatGeneral,
    ),
  );

export const selectEndodonticAssetByReportID = (reportID: string) =>
  createSelector([assetsSelectors.selectAll], (assets) =>
    assets.find(
      (asset) =>
        asset?.GeneratedReport?.Belonging?.ReportID === reportID &&
        asset?.GeneratedReport?.CBCTEndoPanoramaSplit,
    ),
  );

export const selectCBCTMedicalImagesByToothID = (toothID: string) =>
  createSelector(selectMedicalImagesByToothID(toothID), (medicalImages) =>
    compose(
      map(prop('CBCTGPToothSlice')),
      filter(prop('CBCTGPToothSlice')),
    )(medicalImages),
  );

export const selectCBCTToothPathologyMedicalImage = (toothID: string) =>
  createSelector(selectMedicalImagesByToothID(toothID), (medicalImages) =>
    compose(
      map(prop('CBCTToothPathologySlice')),
      filter(prop('CBCTToothPathologySlice')),
    )(medicalImages),
  );

export const selectIOXRayImagesByStudyID = (studyID: string) =>
  createSelector(assetsSelectors.selectAll, (assets) =>
    assets.filter((asset) => asset.Study?.StudyID === studyID),
  );

// TODO: There are 1500+ assets in CBCT report, so find is not the best way.
// Study has asset id, so is this the same asset that we find here by study id?
export const selectCBCTMetaDataByStudyID = (studyID: string) =>
  createSelector([assetsSelectors.selectAll], (assets) => {
    const targetAsset = assets.find(
      (asset) => asset.Study?.StudyID === studyID,
    );

    return targetAsset ? targetAsset?.Study?.CBCT?.Metadata : undefined;
  });

export const selectMPRByToothID = (toothID: string) =>
  createSelector([assetsSelectors.selectAll], (assets) => {
    const targetAsset = assets.find(
      (asset) =>
        asset?.GeneratedReport?.Belonging?.ToothID === toothID &&
        asset?.GeneratedReport?.CBCTGPMPRSubVolume,
    );

    if (targetAsset) {
      return targetAsset.GeneratedReport?.CBCTGPMPRSubVolume;
    }

    return undefined;
  });

export const selectAssetByStudyID = (studyID: string) =>
  createSelector([assetsSelectors.selectAll], (assets) =>
    assets.find((asset) => asset?.Study?.StudyID === studyID),
  );

export const selectAssetByID = (assetID: string) => (state: RootState) =>
  selectById(state, assetID);

export const selectAssetsByIDs = (assetIDs: string[]) =>
  createSelector(assetsSelectors.selectEntities, (assetsEntities) =>
    assetIDs.reduce((assets, assetID) => {
      if (assetsEntities[assetID]) {
        return assets.concat(assetsEntities[assetID] as Asset);
      }

      return assets;
    }, [] as Asset[]),
  );

export const selectLoading = (state: RootState) => state.assets.loading;

export const makeSelectAssetsByIDs = (assetIDs: string[]) =>
  createSelector(assetsSelectors.selectEntities, (assets) =>
    assetIDs.map((assetID) => assets[assetID]),
  );

export const makeSelectorUserUploadedAssetsByToothID = (toothID: string) =>
  createSelector(assetsSelectors.selectAll, (assets) =>
    assets
      .filter(
        (asset) =>
          !asset.Deleted?.Deleted &&
          (asset.Type === AssetType.AssetType_Report_Uploaded_ToothSlice ||
            asset.Type === AssetType.AssetType_Report_Uploaded_MPRPlane) &&
          asset?.Report?.Belonging?.ToothID === toothID,
      )
      .sort((a, b) => {
        const dateA = a?.Created?.At?.getUTCDate() ?? 0;
        const dateB = b?.Created?.At?.getUTCDate() ?? 0;

        return dateA - dateB;
      }),
  );

export const selectIsAssetsExistsByToothID = (toothID: string) =>
  createSelector(selectByToothID(toothID), (assets) => assets.length > 0);
