import { createSelector } from '@reduxjs/toolkit';
import { compose, filter, head, isEmpty, propEq } from 'ramda';
import { generatePath } from 'react-router';
import { first } from 'lodash';

import { Report, ReportType } from '@/shared/api/protocol_gen/model/dto_report';

import { makeImageViewOptions } from '@/entities/assets';

import { RootState } from '@/app/model/store';

import {
  filterReportByStudyID,
  filterReportByStudyIDAndType,
  getReportPath,
  getReportSignStatus,
} from '../lib';
import { getPatientRecentReports } from '../lib/getPatientRecentReports';

import { reportsAdapter } from './reportSlice';

const reportsSelectors = reportsAdapter.getSelectors(
  (state: RootState) => state.reports,
);

export const { selectAll, selectById, selectIds, selectEntities, selectTotal } =
  reportsSelectors;

export const selectByID = (reportID: string) => (state: RootState) =>
  selectById(state, reportID);

export const selectFirst = (state: RootState) =>
  <Report>compose(head, reportsSelectors.selectAll)(state);

export const selectByStudyID = (studyID: string) =>
  createSelector(selectAll, (reports) =>
    filterReportByStudyID(reports, studyID),
  );

export const selectFirstByStudyID = (studyID: string) =>
  createSelector(selectByStudyID(studyID), (report) => first(report));

export const selectPanowingDisplayAssetIDByReportID = (reportID: string) =>
  createSelector(
    selectByID(reportID),
    (report) => report?.DataPanoBitewings?.PanoStudyAssetID,
  );

const isReportNotDeleted = (report: Report) => !report.Deleted?.Deleted;
const isReportHasStudyID = (studyID: string) => (report: Report) =>
  report.SourceStudyIDs.includes(studyID);

export const selectActiveReportsByStudyID = (studyID: string) =>
  createSelector(selectAll, (reports) =>
    reports.filter(
      (report) =>
        isReportNotDeleted(report) && isReportHasStudyID(studyID)(report),
    ),
  );

export const selectMaskStyle = (state: RootState) =>
  state.reports.maskStyleType;

export const selectShowMasks = (state: RootState) => state.reports.showMasks;

export const selectByStudyIDAndType = (
  studyID: string,
  reportType: ReportType,
) =>
  createSelector(selectAll, (reports) =>
    filterReportByStudyIDAndType(reports, studyID, reportType),
  );

export const selectByPatientID = (patientID: string) =>
  createSelector(selectAll, (reports) =>
    filter<Report>(propEq<keyof Report>('PatientID')(patientID))(reports),
  );

export const selectPatientRecentReports = (
  patientID: string,
  enabledAsRecentTypes: ReportType[],
) =>
  createSelector(selectAll, (reports) =>
    getPatientRecentReports(reports, patientID, enabledAsRecentTypes),
  );

export const selectToothChartHeight = (state: RootState) =>
  state.reports.toothChartHeight;

export const selectNavigationMode = (state: RootState) =>
  state.reports.navigationMode;

export const selectActivePageNumber = (state: RootState) =>
  state.reports.activePageNumber;

export const selectActiveToothID = (state: RootState) =>
  state.reports.activeToothID;

export const selectActiveSubImagesIDs = (state: RootState) =>
  state.reports.activeSubImagesIDs;

export const selectDisabledSubImagesIDs = (state: RootState) =>
  state.reports.disabledSubImagesIDs;

export const selectFocusedImageMetaID = (state: RootState) =>
  state.reports.focusedMetaImageID;

export const selectHoveredMaskID = (state: RootState) =>
  state.reports.hoveredMaskID;

export const selectSubImagesIDs = (state: RootState) =>
  state.reports.subImagesIDs;

export const selectToolbarMultipleMode = (state: RootState) =>
  isEmpty(state.reports.activeToothID) &&
  isEmpty(state.reports.focusedMetaImageID);

export const selectReportViewOptions = (reportID: string) =>
  createSelector(
    (state: RootState) => reportsSelectors.selectById(state, reportID),
    (report) => makeImageViewOptions(report?.MedicalImageFeatures?.ViewOptions),
  );

export const selectToolbarActiveControl = (state: RootState) =>
  state.reports.toolbarActiveControl;

export const selectToolsMode = (state: RootState) => state.reports.toolsMode;

export const selectLastUpdatedCropID = (state: RootState) =>
  state.reports.lastUpdatedCropID;

export const selectCurrentReportType = (state: RootState) =>
  state.reports.currentReport?.Type;

export const selectShownSubImagesIDs = createSelector(
  [selectSubImagesIDs, selectDisabledSubImagesIDs],
  (subImagesIDs, disabledSubImagesIDs) =>
    subImagesIDs.filter(
      (subImageID) => !disabledSubImagesIDs.includes(subImageID),
    ),
);

export const selectReportBreadcrumbData = ({
  reportID,
  patientID,
}: {
  reportID: string;
  patientID: string;
}) =>
  createSelector(
    (state: RootState) => reportsSelectors.selectById(state, reportID),
    (report) => ({
      reportType: report?.Type,
      reportCreatedDate: report?.Created?.At,
      reportPath: generatePath(
        getReportPath(report?.Type ?? ReportType.UNRECOGNIZED),
        {
          reportID,
          patientID,
        },
      ),
    }),
  );

export const selectCurrentReport = (state: RootState) =>
  state.reports.currentReport;

export const selectCurrentReportID = (state: RootState) =>
  state.reports.currentReport?.ID;

export const selectLowProbabilityMode = (state: RootState) =>
  state.reports?.currentReport?.Settings?.LowProbabilityMode;

export const selectPreviewSettingsShowTopJaw = (state: RootState) =>
  state.reports?.currentReport?.PrintSettings?.ShowTopJaw;

export const selectPreviewSettingsShowBottomJaw = (state: RootState) =>
  state.reports?.currentReport?.PrintSettings?.ShowBottomJaw;

export const selectPreviewSettingsBlackAndWhiteMode = (state: RootState) =>
  state.reports?.currentReport?.PrintSettings?.BlackAndWhiteMode;

export const selectCurrentReportPanoAssetID = (state: RootState) =>
  state.reports.currentReport?.DataPanoGP?.PanoStudyAssetID;

export const selectCurrentReportSigned = (state: RootState) =>
  Boolean(state.reports.currentReport?.Signature?.UserSignatures.length);

export const selectCurrentReportViewOptions = (state: RootState) =>
  state.reports.currentReport?.MedicalImageFeatures?.ViewOptions;

export const selectCurrentReportCanChangeToothApproved = (state: RootState) =>
  state.reports.currentReport?.YourPermissions?.CanChangeToothApproved;

export const selectCurrentReportSignStatus = (state: RootState) => {
  if (state.reports.currentReport) {
    return getReportSignStatus(state.reports.currentReport);
  }
  return '';
};

export const selectCurrentReportImagesMeta = (state: RootState) => {
  const currentReport = state.reports.currentReport;

  if (currentReport?.DataPanoBitewings) {
    return currentReport.DataPanoBitewings.ImagesMeta;
  }

  if (currentReport?.DataIOXRayGP) {
    return currentReport.DataIOXRayGP.ImagesMeta;
  }

  return [];
};

export const selectLoading = (state: RootState) => state.reports.loading;

// NOTE: This are specific selectors which is breaks FSD rules on purpose. It's accessible to do it in entitities models.
export const selectReportReadyForRender = (state: RootState) =>
  state.reports.loading === 'succeeded' && state.assets.loading === 'succeeded';

export const selectReportPBLReadyForRender = (state: RootState) =>
  state.toothLandmarks.loading === 'succeeded' &&
  state.logicalCondition.loading === 'succeeded' &&
  state.reports.loading === 'succeeded' &&
  state.assets.loading === 'succeeded';
