import React, { FC } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

import { BreadcrumbElementType } from '@/shared/config';

import { Icon } from '../Icon/Icon';
import { Skeleton } from '../Skeleton/Skeleton';

import styles from './Breadcrumbs.module.scss';

type BreadcrumbsProps = {
  className?: string;
  testID?: string;
  breadCrumbs: Record<string, BreadcrumbElementType>;
};

export const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const { className, testID, breadCrumbs } = props;

  const { patientID } = useParams();

  const config = Object.values(breadCrumbs);

  const hasEmptyLabels = config.every((item) => !item.label);

  if (hasEmptyLabels && patientID) {
    return <Skeleton.Filter filtersQuantity={config.length} />;
  }

  return (
    <nav className={cn(styles.container, className)} data-testid={testID}>
      {config.map(
        (breadcrumb) =>
          breadcrumb.label && (
            <React.Fragment key={breadcrumb.label.toString()}>
              {breadcrumb.path ? (
                <>
                  <Link
                    to={breadcrumb.path}
                    state={breadcrumb.state}
                    className={cn(styles.link, 'p1')}
                  >
                    {breadcrumb.label}
                  </Link>

                  <Icon name="arrowDown" className={styles.arrow} size={24} />
                </>
              ) : (
                <p className="p1">{breadcrumb.label}</p>
              )}
            </React.Fragment>
          ),
      )}
    </nav>
  );
};
