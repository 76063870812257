import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { stateToHTML } from 'draft-js-export-html';
import { FC, ReactNode, useEffect, useState } from 'react';

import { Button, WidgetCard } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';

import { reportsModel, useCheckReportSignature } from '@/entities/reports';

import { Editor } from '../Editor/Editor';
import { getEditorContentFromValue } from '../../lib/getEditorContentFromValue';
import { getContentWithoutHTML } from '../../lib/getContentWithoutHTML';
import { getDraftJsStyles } from '../../lib/getDraftJsStyles';
import { getHTMLWithStyles } from '../../lib/getHTMLWithStyles';

import styles from './Conclusion.module.scss';

type ConclusionProps = {
  children?: ReactNode;
  className?: string;
};

export const PreviewConclusion: FC<ConclusionProps> = (props) => {
  const { className } = props;

  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);

  const conclusion = report?.LegacyConclusion || '';

  const htmlWithoutAlign = stateToHTML(getEditorContentFromValue(conclusion));
  const html = getHTMLWithStyles(conclusion, htmlWithoutAlign);

  return (
    <div className={cn(styles.preview, className)}>
      <h1>
        <FormattedMessage id="report.conclusion" defaultMessage="Conclusion" />
      </h1>
      <div className={styles.legacyConclusion}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
};

// TODO: Discuss with the team method of choosing FSD slice
export const Conclusion: FC<ConclusionProps> = (props) => {
  const { className, children } = props;

  const report = useAppSelector(reportsModel.selectors.selectCurrentReport);

  const conclusion = report?.LegacyConclusion || '';
  const reportID = report?.ID || '';

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const [isEditMode, setIsEditMode] = useState(false);
  const [editorValue, setEditorValue] = useState('');
  const [loading, setLoading] = useState(false);

  const placeholder = formatMessage({
    id: 'report.conclusion.placeholder',
    defaultMessage: 'Leave a conclusion...',
  });
  const htmlWithoutAlign = stateToHTML(getEditorContentFromValue(conclusion));
  const html = getHTMLWithStyles(conclusion, htmlWithoutAlign);
  const text = getContentWithoutHTML(html);

  const { checkReportSignature } = useCheckReportSignature();

  useEffect(() => {
    setEditorValue(html);
  }, [html, conclusion]);

  const handleSave = () => {
    checkReportSignature({
      onSignatureChecked: async () => {
        setLoading(true);

        try {
          const contentState = JSON.stringify(getDraftJsStyles(editorValue));

          const result = await dispatch(
            reportsModel.thunks.setReportConclusion({
              ReportID: reportID,
              LegacyConclusion: contentState,
            }),
          ).unwrap();

          if (result.Report) {
            dispatch(reportsModel.actions.setNewestOne(result.Report));
          }
        } finally {
          setLoading(false);
          setIsEditMode(false);
        }
      },
    });
  };

  return (
    <WidgetCard className={cn(styles.container, className)}>
      <div className={cn(styles.conclusionContainer, className)}>
        <h1>
          <FormattedMessage
            id="report.conclusion"
            defaultMessage="Conclusion"
          />
        </h1>
        {text && !isEditMode ? (
          <div className={styles.legacyConclusion}>
            {/* TODO: FIX dangerouslySetInnerHTML */}
            <div dangerouslySetInnerHTML={{ __html: html }} />
            <Button
              size="small"
              variant="secondary"
              onClick={() => setIsEditMode(true)}
              className={styles.editButton}
            >
              <FormattedMessage id="global.edit" defaultMessage="Edit" />
            </Button>
          </div>
        ) : (
          <div className={styles.editorContainer}>
            <Editor
              value={editorValue}
              setValue={setEditorValue}
              placeholder={placeholder}
            />
            <div className={styles.buttonsContainer}>
              {text && (
                <Button
                  size="small"
                  variant="tertiary"
                  onClick={() => setIsEditMode(false)}
                >
                  <FormattedMessage
                    id="global.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              )}
              <Button
                size="small"
                variant="secondary"
                onClick={handleSave}
                loading={loading}
                disabled={
                  editorValue === html ||
                  (!text && !getContentWithoutHTML(editorValue).trim())
                }
              >
                <FormattedMessage id="global.save" defaultMessage="Save" />
              </Button>
            </div>
          </div>
        )}
      </div>

      {children}
    </WidgetCard>
  );
};
