import { useIntl } from 'react-intl';

import { StudyType } from '@/shared/api/protocol_gen/model/dto_study';
import { useAppSelector } from '@/shared/hooks';

import { getImageSrc } from '@/entities/assets';
import { i18n } from '@/entities/study';
import { reportsModel } from '@/entities/reports';
import { IOXRayImagesInterfaceModel } from '@/entities/IOXRayImagesMatrix';

import styles from './PanowingsPreview.module.scss';

type PanowingsPreviewProps = {
  reportID: string;
};

export const PanowingsPreview = (props: PanowingsPreviewProps) => {
  const { reportID } = props;

  const { formatMessage } = useIntl();

  const panoDisplayAssetID = useAppSelector(
    reportsModel.selectors.selectPanowingDisplayAssetIDByReportID(reportID),
  );
  // TODO: Need to properly configure return types
  const previewImages = useAppSelector(
    IOXRayImagesInterfaceModel.selectors.selectIOXRayPreviewImages(reportID),
  );

  const previewImageSrc = panoDisplayAssetID
    ? getImageSrc(panoDisplayAssetID, 'preview')
    : '';

  return (
    <div className={styles.container}>
      <div className={styles.mainImageContainer}>
        <img
          className={styles.mainImage}
          src={previewImageSrc}
          alt={formatMessage(
            i18n.studyTypes[StudyType.StudyType_PanoramicXRay],
          )}
        />
      </div>

      <div className={styles.bitewings}>
        {(previewImages as unknown as { src: string }[]).map(
          (bitewingImage) => {
            return (
              <img
                key={bitewingImage.src}
                src={bitewingImage.src}
                className={styles.bitewingImage}
              />
            );
          },
        )}
      </div>
    </div>
  );
};
