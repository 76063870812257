import { useParams } from 'react-router';

import { ReportLayout } from '@/entities/reports';
import { ConfirmModal } from '@/entities/modal';

import { useReportDataStream } from '@/features/reportStream';

import { Header } from '@/widgets/Header';
import { Conclusion } from '@/widgets/Conclusion';
import { AddConditionModal } from '@/widgets/AddConditionModal';
import { ReportActions } from '@/widgets/ReportActions';
import { Report2DToothChart } from '@/widgets/Report2DToothChart';

import styles from './PanoReport.module.scss';
import { PanoToothCardList } from './PanoToothCardList/PanoToothCardList';
import { PanoReportRender } from './PanoReportRender/PanoReportRender';
import { PanoChangeTeethNumberModal } from './PanoChangeTeethNumberModal/PanoChangeTeethNumberModal';

export const PanoReport = () => {
  const { patientID, reportID } = useParams();

  // TODO: This should be placed on parent route for the report
  useReportDataStream(reportID as string);

  return (
    <ReportLayout.Container>
      <Header fullWidth />
      <ReportLayout.Main>
        <ReportLayout.LeftColumn>
          <h1>Pano AI Report</h1>
          <PanoReportRender reportID={reportID as string} />
        </ReportLayout.LeftColumn>

        <ReportLayout.RightColumn>
          <Report2DToothChart />

          <PanoToothCardList
            patientID={patientID as string}
            reportID={reportID as string}
          />

          <Conclusion />

          <ReportActions
            className={styles.reportActions}
            patientID={patientID as string}
            reportID={reportID as string}
          />
        </ReportLayout.RightColumn>
      </ReportLayout.Main>
      {/* Modals */}
      <ConfirmModal />
      <AddConditionModal />
      <PanoChangeTeethNumberModal />
    </ReportLayout.Container>
  );
};
