import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Button } from '@/shared/ui';

import { toothModel } from '@/entities/tooth';
import { useCheckReportSignature } from '@/entities/reports';

export const ToothChartCustomModeToggle = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const customMode = useAppSelector(
    toothModel.selectors.selectToothChartCustomMode,
  );
  const updatingROI = useAppSelector(toothModel.selectors.selectUpdatingROI);

  const { checkReportSignature } = useCheckReportSignature();

  const toggleCustomMode = () => {
    if (customMode) {
      checkReportSignature({
        onSignatureChecked: async () => {
          dispatch(toothModel.thunks.updateCurrentReportROI()).then(() => {
            dispatch(toothModel.actions.setToothChartCustomMode(!customMode));
          });
        },
      });
      return;
    }

    dispatch(toothModel.actions.setToothChartCustomMode(!customMode));
  };

  const buttonText = customMode
    ? formatMessage({
        id: 'toothChart.customModeEnabled',
        defaultMessage: 'Save',
      })
    : formatMessage({
        id: 'toothChart.customModeDisabled',
        defaultMessage: 'Custom',
      });

  return (
    <Button
      variant="secondary"
      size="small"
      loading={updatingROI}
      onClick={toggleCustomMode}
    >
      {buttonText}
    </Button>
  );
};
