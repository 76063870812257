/* eslint-disable */
import Long from 'long';
import * as _m0 from 'protobufjs/minimal';
import { FloatTuple2D } from '../model/dto_common_geometry';
import { MedicalImageFeatures } from '../model/dto_common_image_medical_features';
import { Localization } from '../model/dto_report_localization';

export const protobufPackage = 'com.diagnocat.model';

export enum Report2DImageType {
  Report_2D_ImageType_InvalidValue = 0,
  Report_2D_ImageType_IntraoralXRay = 1,
  Report_2D_ImageType_DentalPhoto = 2,
  Report_2D_ImageType_PanoramicXRay = 3,
  Report_2D_ImageType_CBCTSlice = 4,
  UNRECOGNIZED = -1,
}

export function report2DImageTypeFromJSON(object: any): Report2DImageType {
  switch (object) {
    case 0:
    case 'Report_2D_ImageType_InvalidValue':
      return Report2DImageType.Report_2D_ImageType_InvalidValue;
    case 1:
    case 'Report_2D_ImageType_IntraoralXRay':
      return Report2DImageType.Report_2D_ImageType_IntraoralXRay;
    case 2:
    case 'Report_2D_ImageType_DentalPhoto':
      return Report2DImageType.Report_2D_ImageType_DentalPhoto;
    case 3:
    case 'Report_2D_ImageType_PanoramicXRay':
      return Report2DImageType.Report_2D_ImageType_PanoramicXRay;
    case 4:
    case 'Report_2D_ImageType_CBCTSlice':
      return Report2DImageType.Report_2D_ImageType_CBCTSlice;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Report2DImageType.UNRECOGNIZED;
  }
}

export function report2DImageTypeToJSON(object: Report2DImageType): string {
  switch (object) {
    case Report2DImageType.Report_2D_ImageType_InvalidValue:
      return 'Report_2D_ImageType_InvalidValue';
    case Report2DImageType.Report_2D_ImageType_IntraoralXRay:
      return 'Report_2D_ImageType_IntraoralXRay';
    case Report2DImageType.Report_2D_ImageType_DentalPhoto:
      return 'Report_2D_ImageType_DentalPhoto';
    case Report2DImageType.Report_2D_ImageType_PanoramicXRay:
      return 'Report_2D_ImageType_PanoramicXRay';
    case Report2DImageType.Report_2D_ImageType_CBCTSlice:
      return 'Report_2D_ImageType_CBCTSlice';
    case Report2DImageType.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export enum Report2DAnatomy {
  Report_2D_Anatomy_InvalidValue = 0,
  Report_2D_Anatomy_AlveolarBone = 1,
  Report_2D_Anatomy_MaxillarySinus = 2,
  Report_2D_Anatomy_MandibularCanal = 3,
  Report_2D_Anatomy_NasalCavity = 4,
  Report_2D_Anatomy_Root = 5,
  Report_2D_Anatomy_RootCanal = 6,
  Report_2D_Anatomy_Crown = 7,
  Report_2D_Anatomy_IncisalEdge = 8,
  Report_2D_Anatomy_PulpChamber = 9,
  Report_2D_Anatomy_Enamel = 10,
  UNRECOGNIZED = -1,
}

export function report2DAnatomyFromJSON(object: any): Report2DAnatomy {
  switch (object) {
    case 0:
    case 'Report_2D_Anatomy_InvalidValue':
      return Report2DAnatomy.Report_2D_Anatomy_InvalidValue;
    case 1:
    case 'Report_2D_Anatomy_AlveolarBone':
      return Report2DAnatomy.Report_2D_Anatomy_AlveolarBone;
    case 2:
    case 'Report_2D_Anatomy_MaxillarySinus':
      return Report2DAnatomy.Report_2D_Anatomy_MaxillarySinus;
    case 3:
    case 'Report_2D_Anatomy_MandibularCanal':
      return Report2DAnatomy.Report_2D_Anatomy_MandibularCanal;
    case 4:
    case 'Report_2D_Anatomy_NasalCavity':
      return Report2DAnatomy.Report_2D_Anatomy_NasalCavity;
    case 5:
    case 'Report_2D_Anatomy_Root':
      return Report2DAnatomy.Report_2D_Anatomy_Root;
    case 6:
    case 'Report_2D_Anatomy_RootCanal':
      return Report2DAnatomy.Report_2D_Anatomy_RootCanal;
    case 7:
    case 'Report_2D_Anatomy_Crown':
      return Report2DAnatomy.Report_2D_Anatomy_Crown;
    case 8:
    case 'Report_2D_Anatomy_IncisalEdge':
      return Report2DAnatomy.Report_2D_Anatomy_IncisalEdge;
    case 9:
    case 'Report_2D_Anatomy_PulpChamber':
      return Report2DAnatomy.Report_2D_Anatomy_PulpChamber;
    case 10:
    case 'Report_2D_Anatomy_Enamel':
      return Report2DAnatomy.Report_2D_Anatomy_Enamel;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return Report2DAnatomy.UNRECOGNIZED;
  }
}

export function report2DAnatomyToJSON(object: Report2DAnatomy): string {
  switch (object) {
    case Report2DAnatomy.Report_2D_Anatomy_InvalidValue:
      return 'Report_2D_Anatomy_InvalidValue';
    case Report2DAnatomy.Report_2D_Anatomy_AlveolarBone:
      return 'Report_2D_Anatomy_AlveolarBone';
    case Report2DAnatomy.Report_2D_Anatomy_MaxillarySinus:
      return 'Report_2D_Anatomy_MaxillarySinus';
    case Report2DAnatomy.Report_2D_Anatomy_MandibularCanal:
      return 'Report_2D_Anatomy_MandibularCanal';
    case Report2DAnatomy.Report_2D_Anatomy_NasalCavity:
      return 'Report_2D_Anatomy_NasalCavity';
    case Report2DAnatomy.Report_2D_Anatomy_Root:
      return 'Report_2D_Anatomy_Root';
    case Report2DAnatomy.Report_2D_Anatomy_RootCanal:
      return 'Report_2D_Anatomy_RootCanal';
    case Report2DAnatomy.Report_2D_Anatomy_Crown:
      return 'Report_2D_Anatomy_Crown';
    case Report2DAnatomy.Report_2D_Anatomy_IncisalEdge:
      return 'Report_2D_Anatomy_IncisalEdge';
    case Report2DAnatomy.Report_2D_Anatomy_PulpChamber:
      return 'Report_2D_Anatomy_PulpChamber';
    case Report2DAnatomy.Report_2D_Anatomy_Enamel:
      return 'Report_2D_Anatomy_Enamel';
    case Report2DAnatomy.UNRECOGNIZED:
    default:
      return 'UNRECOGNIZED';
  }
}

export interface Report2DImageMeta {
  ID: string;
  Type: Report2DImageType;
  /** `StudyAssetID`: [required] >> Asset.ID with AssetType_Study_IntraoralXRay */
  StudyAssetID: string;
  /** `GeneratedAssetID`: [optional] >> Asset.ID with AsssetContent_Generated_IOXRay_Image */
  GeneratedAssetID: string;
  /** `Scale`: [optional]: Defines number of millimeters per pixel for corresponding axis. */
  Scale: FloatTuple2D | undefined;
  /**
   * `OrientationAngle`: [required]: A counter clockwise angle to rotate an image into
   * correct orientation.
   */
  OrientationAngle: number;
  MedicalImageFeatures: MedicalImageFeatures | undefined;
}

export interface Report2DAnatomyLocalization {
  /** `Anatomy`: [required] */
  Anatomy: Report2DAnatomy;
  /** `Localization`: [required] */
  Localization: Localization | undefined;
}

function createBaseReport2DImageMeta(): Report2DImageMeta {
  return {
    ID: '',
    Type: 0,
    StudyAssetID: '',
    GeneratedAssetID: '',
    Scale: undefined,
    OrientationAngle: 0,
    MedicalImageFeatures: undefined,
  };
}

export const Report2DImageMeta = {
  encode(
    message: Report2DImageMeta,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.ID !== '') {
      writer.uint32(10).string(message.ID);
    }
    if (message.Type !== 0) {
      writer.uint32(16).int32(message.Type);
    }
    if (message.StudyAssetID !== '') {
      writer.uint32(26).string(message.StudyAssetID);
    }
    if (message.GeneratedAssetID !== '') {
      writer.uint32(34).string(message.GeneratedAssetID);
    }
    if (message.Scale !== undefined) {
      FloatTuple2D.encode(message.Scale, writer.uint32(42).fork()).ldelim();
    }
    if (message.OrientationAngle !== 0) {
      writer.uint32(49).double(message.OrientationAngle);
    }
    if (message.MedicalImageFeatures !== undefined) {
      MedicalImageFeatures.encode(
        message.MedicalImageFeatures,
        writer.uint32(90).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Report2DImageMeta {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReport2DImageMeta();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.ID = reader.string();
          break;
        case 2:
          message.Type = reader.int32() as any;
          break;
        case 3:
          message.StudyAssetID = reader.string();
          break;
        case 4:
          message.GeneratedAssetID = reader.string();
          break;
        case 5:
          message.Scale = FloatTuple2D.decode(reader, reader.uint32());
          break;
        case 6:
          message.OrientationAngle = reader.double();
          break;
        case 11:
          message.MedicalImageFeatures = MedicalImageFeatures.decode(
            reader,
            reader.uint32(),
          );
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Report2DImageMeta {
    return {
      ID: isSet(object.ID) ? String(object.ID) : '',
      Type: isSet(object.Type) ? report2DImageTypeFromJSON(object.Type) : 0,
      StudyAssetID: isSet(object.StudyAssetID)
        ? String(object.StudyAssetID)
        : '',
      GeneratedAssetID: isSet(object.GeneratedAssetID)
        ? String(object.GeneratedAssetID)
        : '',
      Scale: isSet(object.Scale)
        ? FloatTuple2D.fromJSON(object.Scale)
        : undefined,
      OrientationAngle: isSet(object.OrientationAngle)
        ? Number(object.OrientationAngle)
        : 0,
      MedicalImageFeatures: isSet(object.MedicalImageFeatures)
        ? MedicalImageFeatures.fromJSON(object.MedicalImageFeatures)
        : undefined,
    };
  },

  toJSON(message: Report2DImageMeta): unknown {
    const obj: any = {};
    message.ID !== undefined && (obj.ID = message.ID);
    message.Type !== undefined &&
      (obj.Type = report2DImageTypeToJSON(message.Type));
    message.StudyAssetID !== undefined &&
      (obj.StudyAssetID = message.StudyAssetID);
    message.GeneratedAssetID !== undefined &&
      (obj.GeneratedAssetID = message.GeneratedAssetID);
    message.Scale !== undefined &&
      (obj.Scale = message.Scale
        ? FloatTuple2D.toJSON(message.Scale)
        : undefined);
    message.OrientationAngle !== undefined &&
      (obj.OrientationAngle = message.OrientationAngle);
    message.MedicalImageFeatures !== undefined &&
      (obj.MedicalImageFeatures = message.MedicalImageFeatures
        ? MedicalImageFeatures.toJSON(message.MedicalImageFeatures)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Report2DImageMeta>, I>>(
    object: I,
  ): Report2DImageMeta {
    const message = createBaseReport2DImageMeta();
    message.ID = object.ID ?? '';
    message.Type = object.Type ?? 0;
    message.StudyAssetID = object.StudyAssetID ?? '';
    message.GeneratedAssetID = object.GeneratedAssetID ?? '';
    message.Scale =
      object.Scale !== undefined && object.Scale !== null
        ? FloatTuple2D.fromPartial(object.Scale)
        : undefined;
    message.OrientationAngle = object.OrientationAngle ?? 0;
    message.MedicalImageFeatures =
      object.MedicalImageFeatures !== undefined &&
      object.MedicalImageFeatures !== null
        ? MedicalImageFeatures.fromPartial(object.MedicalImageFeatures)
        : undefined;
    return message;
  },
};

function createBaseReport2DAnatomyLocalization(): Report2DAnatomyLocalization {
  return { Anatomy: 0, Localization: undefined };
}

export const Report2DAnatomyLocalization = {
  encode(
    message: Report2DAnatomyLocalization,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.Anatomy !== 0) {
      writer.uint32(8).int32(message.Anatomy);
    }
    if (message.Localization !== undefined) {
      Localization.encode(
        message.Localization,
        writer.uint32(18).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): Report2DAnatomyLocalization {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReport2DAnatomyLocalization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.Anatomy = reader.int32() as any;
          break;
        case 2:
          message.Localization = Localization.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): Report2DAnatomyLocalization {
    return {
      Anatomy: isSet(object.Anatomy)
        ? report2DAnatomyFromJSON(object.Anatomy)
        : 0,
      Localization: isSet(object.Localization)
        ? Localization.fromJSON(object.Localization)
        : undefined,
    };
  },

  toJSON(message: Report2DAnatomyLocalization): unknown {
    const obj: any = {};
    message.Anatomy !== undefined &&
      (obj.Anatomy = report2DAnatomyToJSON(message.Anatomy));
    message.Localization !== undefined &&
      (obj.Localization = message.Localization
        ? Localization.toJSON(message.Localization)
        : undefined);
    return obj;
  },

  fromPartial<I extends Exact<DeepPartial<Report2DAnatomyLocalization>, I>>(
    object: I,
  ): Report2DAnatomyLocalization {
    const message = createBaseReport2DAnatomyLocalization();
    message.Anatomy = object.Anatomy ?? 0;
    message.Localization =
      object.Localization !== undefined && object.Localization !== null
        ? Localization.fromPartial(object.Localization)
        : undefined;
    return message;
  },
};

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & Record<
        Exclude<keyof I, KeysOfUnion<P>>,
        never
      >;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
