import { CBCTAnatomy } from '@/shared/api/protocol_gen/model/dto_report_common';

export const getSegmentsOfAllCBCTAnatomies = (): { CBCTAnatomy: number }[] =>
  Object.entries(CBCTAnatomy)
    .filter(
      ([, value]) =>
        !isNaN(Number(value)) &&
        value !== CBCTAnatomy.CBCT_Anatomy_InvalidValue &&
        value !== CBCTAnatomy.UNRECOGNIZED,
    )
    .map(([, value]) => ({
      CBCTAnatomy: Number(value),
    }));
