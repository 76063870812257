import { Decision } from '@/shared/api/protocol_gen/model/dto_report_common';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';

import { LOW_PROBABILITY_CONDITION_CODES } from '../config';

import { isConditionUncertain } from './isConditionUncertain';

export const shouldConditionItemBeShown =
  (showLowProbability: boolean = false) =>
  (condition?: Condition): boolean => {
    if (!condition) {
      return false;
    }

    const { Certainty } = condition;

    if (
      showLowProbability &&
      isConditionUncertain(condition) &&
      LOW_PROBABILITY_CONDITION_CODES.includes(condition.Code)
    ) {
      return true;
    }

    // EngineDecision
    if (Certainty?.EngineDecision === Decision.PositiveDecision) {
      return true;
    }
    if (Certainty?.EngineDecision === Decision.NegativeDecision) {
      return false;
    }

    // UserDecision
    if (Certainty?.UserDecision === Decision.PositiveDecision) {
      return true;
    }
    if (Certainty?.UserDecision === Decision.NegativeDecision) {
      return false;
    }

    // ModelDecision
    if (Certainty?.ModelDecision === Decision.PositiveDecision) {
      return true;
    }
    if (Certainty?.ModelDecision === Decision.NegativeDecision) {
      return false;
    }

    return false;
  };
