import _range from 'lodash/range';

import { Localization } from '@/shared/api/protocol_gen/model/dto_report_localization';

export type LocalizationWithISO = Localization & { ISONumber: number };

// Can we unite and unify this with condition colors?
export type ToothType =
  | 'unhealthy'
  | 'lowProbability'
  | 'treated'
  | 'healthy'
  | 'missing'
  | 'all';

export type ToothTypesSet = Record<string, ToothType>;

export enum DentalNotations {
  ISO = 'ISO',
  UNIVERSAL = 'UNIVERSAL',
}

export type DentalNotation = keyof typeof DentalNotations;

export type TeethChartFilter = {
  type: ToothType;
  title: string;
  ISONumbers: number[];
  active: boolean;
};

export type ToothWithLocalization = {
  toothID: string;
  ISONumber: number;
  Localization: Localization;
};

export type RudimentTeeth =
  | 55
  | 54
  | 53
  | 52
  | 51
  | 61
  | 62
  | 63
  | 64
  | 65
  | 71
  | 72
  | 73
  | 74
  | 75
  | 85
  | 84
  | 83
  | 82;

export interface JawQuartilesRanges {
  [key: string]: number[];
}

export enum JawQuartiles {
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
  topLeftPrimary = 'topLeftPrimary',
  topRightPrimary = 'topRightPrimary',
  bottomLeftPrimary = 'bottomLeftPrimary',
  bottomRightPrimary = 'bottomRightPrimary',
}

export const jawQuartilesRanges: JawQuartilesRanges = {
  [JawQuartiles.topLeft]: _range(18, 10),
  [JawQuartiles.topRight]: _range(21, 29),
  [JawQuartiles.bottomLeft]: _range(48, 40),
  [JawQuartiles.bottomRight]: _range(31, 39),
  topLeftPrimary: _range(55, 50),
  topRightPrimary: _range(61, 66),
  bottomLeftPrimary: _range(85, 80),
  bottomRightPrimary: _range(71, 76),
};

export const UPPER_JAW_PERMANENT = [
  ...jawQuartilesRanges.topLeft,
  ...jawQuartilesRanges.topRight,
];

export const UPPER_JAW_PRIMARY = [
  ...jawQuartilesRanges.topLeftPrimary,
  ...jawQuartilesRanges.topRightPrimary,
];

export const LOWER_JAW_PERMANENT = [
  ...jawQuartilesRanges.bottomLeft,
  ...jawQuartilesRanges.bottomRight,
];

export const LOWER_JAW_PRIMARY = [
  ...jawQuartilesRanges.bottomLeftPrimary,
  ...jawQuartilesRanges.bottomRightPrimary,
];
