import { convertToRaw, RawDraftContentBlock } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { includes } from 'ramda';

import { getTextTagsFromHTML } from './getTextTagsFromHTML';

export const getDraftJsStyles = (editorValue: string) => {
  const tags = getTextTagsFromHTML(editorValue);
  const raw = convertToRaw(stateFromHTML(editorValue));

  raw.blocks.forEach((block: RawDraftContentBlock, index) => {
    if (!block?.data) {
      return;
    }

    const currentTag = tags[index];
    if (includes('ql-align-center', currentTag)) {
      block.data['text-align'] = 'center';
    } else if (includes('ql-align-right', currentTag)) {
      block.data['text-align'] = 'right';
    } else if (includes('ql-align-justify', currentTag)) {
      block.data['text-align'] = 'justify';
    }
  });

  return raw;
};
