import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';

import { Icon, Switch, Tooltip } from '@/shared/ui';
import { useAppSelector } from '@/shared/hooks';
import { FeatureFlag } from '@/shared/api/protocol_gen/model/dto_organization';

import { organizationModel } from '@/entities/organization';

import { useCheckReportSignature, useReportSettings } from '../../hooks';

import styles from './ReportSettingsToggle.module.scss';

type ReportSettingsToggleProps = {
  className?: string;
};

export const ReportSettingsToggle: FC<ReportSettingsToggleProps> = (props) => {
  const { className } = props;

  const { formatMessage } = useIntl();
  const { isLoading, settings, updateSettings } = useReportSettings();

  const hideProbability = useAppSelector(
    organizationModel.selectors.selectIsFeatureActiveByName(
      FeatureFlag.FeatureFlag_Hide_Probabilities,
    ),
  );

  const { checkReportSignature } = useCheckReportSignature();

  const lowProbabilityModeToggle = () => {
    checkReportSignature({
      onSignatureChecked: async () =>
        updateSettings({
          LowProbabilityMode: !settings.LowProbabilityMode,
        }),
    });
  };

  return (
    <div className={cn(styles.contaner, className)}>
      {!hideProbability && (
        <div className={styles.lowProbability}>
          <Switch
            checked={settings.LowProbabilityMode}
            disabled={isLoading}
            onChange={lowProbabilityModeToggle}
          >
            <FormattedMessage
              id="reportSettings.showLowProbability"
              defaultMessage="Show low probability"
            />
          </Switch>

          <Tooltip.Primary
            content={formatMessage({
              id: 'lowProbabilityInfo',
              defaultMessage:
                'Switch on to see caries signs and periradicular pathologies, with probability rate 30-50%',
            })}
          >
            <div className={styles.lowProbabilityInfoIcon}>
              <Icon name="info" size={24} />
            </div>
          </Tooltip.Primary>
        </div>
      )}

      {/* DISABLED WHILE REQUIREMENTS UPDATE */}
      {/* <Switch
        checked={settings.ShowSimpleConditionView}
        disabled={isLoading}
        onChange={() =>
          updateSettings({
            ShowSimpleConditionView: !settings.ShowSimpleConditionView,
          })
        }
      >
        <FormattedMessage
          id="reportSettings.showSimpleConditionView"
          defaultMessage="Simple condition view"
        />
      </Switch> */}
    </div>
  );
};
