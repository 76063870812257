import { createSelector } from '@reduxjs/toolkit';
import { compose, map, prop, uniq } from 'ramda';

import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';

import { RootState } from '@/app/model/store';

import {
  filterTeethByPatientID,
  filterTeethByReportID,
  filterTeethByReportIDAndROI,
  findTeethByReportID,
  isPrimaryTooth,
  mapTeethToISONumbers,
} from '../lib';

import { toothAdapter } from './toothSlice';

const toothSelectors = toothAdapter.getSelectors(
  (state: RootState) => state.tooth,
);

export const { selectById, selectEntities } = toothSelectors;

export const selectToothByID = (toothID: string) => (state: RootState) =>
  selectById(state, toothID);

// IMPORTANT!!!
// TOOTH 404 USED FOR DELETED DETECTIONS
export const selectAll = createSelector(toothSelectors.selectAll, (teeth) =>
  teeth.filter((tooth) => tooth?.Numeration?.ISO !== 404),
);

export const selectAllIds = createSelector(selectAll, (teeth) =>
  teeth.map((tooth) => tooth.ID),
);

export const select404ByReportID = (reportID: string) =>
  createSelector(selectAllDeleted, (teeth) => {
    const deletedToothByReportID = teeth.find(
      (tooth) => tooth.ReportID === reportID,
    );

    return deletedToothByReportID;
  });

export const selectAllDeleted = (state: RootState) =>
  toothSelectors
    .selectAll(state)
    .filter((tooth) => tooth?.Numeration?.ISO === 404);

// Do we need select teeth by patient ID?
export const selectByPatientID = (patientID: string) =>
  createSelector(selectAll, (teeth) =>
    filterTeethByPatientID(teeth, patientID),
  );

export const selectISONumbers = createSelector(selectAll, (teeth) =>
  compose(uniq, mapTeethToISONumbers)(teeth),
);

export const selectByReportID = (reportID: string) =>
  createSelector(selectAll, (teeth) => filterTeethByReportID(teeth, reportID));

export const selectDeletedByReportID = (reportID: string) =>
  createSelector(selectAllDeleted, (teeth) =>
    findTeethByReportID(teeth, reportID),
  );

export const selectByReportIDAndROI = (reportID: string) =>
  createSelector(selectAll, (teeth) =>
    filterTeethByReportIDAndROI(teeth, reportID),
  );

export const selectROITeeth = createSelector(selectAll, (teeth) =>
  teeth.filter((tooth) => tooth.IsInROI),
);

export const selectAllTeethIsApproved = createSelector(
  selectROITeeth,
  (teeth) => teeth.every((tooth) => tooth.IsApproved),
);

export const selectROITeethIDs = createSelector(selectROITeeth, (teeth) =>
  teeth.map((tooth) => tooth.ID),
);

export const selectISONumbersByReportIDAndROI = (reportID: string) =>
  createSelector(selectAll, (teeth) =>
    compose(mapTeethToISONumbers, filterTeethByReportIDAndROI)(teeth, reportID),
  );

export const selectLocalROITeethISONumbers = (state: RootState) =>
  state.tooth.localROITeethISONumbers;

export const selectLocalROITeethIDs = (state: RootState) =>
  state.tooth.localROITeethIDs;

export const selectToothChartCustomMode = (state: RootState) =>
  state.tooth.toothChartCustomMode;

export const selectUpdatingROI = (state: RootState) => state.tooth.updatingROI;

export const selectLoading = (state: RootState) => state.tooth.loading;

export const selectDisplaySlicesByToothID = (toothID: string) =>
  createSelector(
    selectEntities,
    (toothEntities) => toothEntities[toothID]?.DisplaySlices ?? [],
  );

const collectPrimaryTeethISONumbers = (teeth: Tooth[]) =>
  teeth.reduce(
    (collection, tooth) => {
      const ISONumber = tooth.Numeration?.ISO ?? 0;

      if (isPrimaryTooth(ISONumber)) {
        collection[ISONumber] = ISONumber;
      }

      return collection;
    },
    {} as Record<number, number>,
  );

export const selectTeethToShow = (reportID: string) =>
  createSelector(selectByReportIDAndROI(reportID), (teeth) => {
    const primaryISONumbers = collectPrimaryTeethISONumbers(teeth);

    return teeth.filter((tooth) => {
      const ISONumber = (tooth.Numeration?.ISO ?? 0) + 40;

      if (ISONumber in primaryISONumbers) {
        return false;
      }

      return true;
    });
  });

export const selectTeethIDsToShow = (reportID: string) =>
  createSelector(
    selectTeethToShow(reportID),
    map<Tooth, string>(prop<keyof Tooth>('ID')),
  );
