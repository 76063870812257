import { useEffect, useRef } from 'react';
import { Subscription } from 'rxjs';
import { useLocation, useNavigate } from 'react-router';

import { Study } from '@/shared/api/protocol_gen/model/dto_study';
import { Report } from '@/shared/api/protocol_gen/model/dto_report';
import api from '@/shared/api/api';
import { PATHS, StreamDataAccumulatorKey } from '@/shared/config';
import { useAppDispatch } from '@/shared/hooks';
import { Tooth } from '@/shared/api/protocol_gen/model/dto_report_tooth';
import { Condition } from '@/shared/api/protocol_gen/model/dto_report_condition';
import { Asset } from '@/shared/api/protocol_gen/model/dto_asset';
import { Invitation } from '@/shared/api/protocol_gen/model/dto_access';

import { studyModel } from '@/entities/study';
import { reportsModel } from '@/entities/reports';
import { patientModel } from '@/entities/patient';
import { toothModel } from '@/entities/tooth';
import { assetsModel } from '@/entities/assets';
import { accessModel } from '@/entities/access';

type PatientProfileStreamDataAccumulators = {
  [StreamDataAccumulatorKey.study]: Study[];
  [StreamDataAccumulatorKey.reports]: Report[];
  [StreamDataAccumulatorKey.conditions]: Condition[];
  [StreamDataAccumulatorKey.teeth]: Tooth[];
  [StreamDataAccumulatorKey.assets]: Asset[];
  [StreamDataAccumulatorKey.access]: Invitation[];
};

const dataAccumulators: PatientProfileStreamDataAccumulators = {
  [StreamDataAccumulatorKey.study]: [],
  [StreamDataAccumulatorKey.reports]: [],
  [StreamDataAccumulatorKey.conditions]: [],
  [StreamDataAccumulatorKey.teeth]: [],
  [StreamDataAccumulatorKey.assets]: [],
  [StreamDataAccumulatorKey.access]: [],
};

export const usePatientProfileStream = (
  id: string,
  onError?: (error: Error) => void,
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const patientProfileStream = useRef<Subscription>();

  const openPatientProfileStream = () => {
    dispatch(patientModel.actions.setLoading('pending'));
    dispatch(studyModel.actions.setLoading('pending'));
    dispatch(reportsModel.actions.setLoading('pending'));
    dispatch(assetsModel.actions.setLoading('pending'));
    dispatch(accessModel.actions.setLoading('pending'));

    patientProfileStream.current = api.patient
      .PatientProfileStream({ PatientID: id })
      .subscribe({
        next: (data) => {
          // Patient handling: start
          if (data.HistoricalPatient) {
            dispatch(patientModel.actions.addOne(data.HistoricalPatient));
            dispatch(patientModel.actions.setLoading('succeeded'));
          }

          if (data.UpdatedPatient) {
            dispatch(patientModel.actions.setNewestOne(data.UpdatedPatient));
          }
          // Patient handling: end

          // Study handling: start
          if (data.HistoricalStudy) {
            dataAccumulators[StreamDataAccumulatorKey.study].push(
              data.HistoricalStudy,
            );
          }

          if (data.EndOfHistoricalStudies) {
            dispatch(
              studyModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.study],
              ),
            );
            dispatch(studyModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.study] = [];
          }

          if (data.UpdatedStudy) {
            dispatch(studyModel.actions.setNewestOne(data.UpdatedStudy));
          }
          // Study handling: end

          // Report handling: start
          if (data.HistoricalReport) {
            dataAccumulators[StreamDataAccumulatorKey.reports].push(
              data.HistoricalReport,
            );
          }

          if (data.EndOfHistoricalReports) {
            dispatch(
              // For historical entities use only addMany function and setMany for updated entities only
              reportsModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.reports],
              ),
            );
            dispatch(reportsModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.reports] = [];
          }

          if (data.UpdatedReport) {
            dispatch(reportsModel.actions.setNewestOne(data.UpdatedReport));
            dispatch(reportsModel.actions.setLoading('succeeded'));
          }
          // Report handling: end

          // Tooth handling: start
          if (data.HistoricalTooth) {
            dataAccumulators[StreamDataAccumulatorKey.teeth].push(
              data.HistoricalTooth,
            );
          }

          if (data.EndOfHistoricalTeeth) {
            dispatch(
              toothModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.teeth],
              ),
            );
            dataAccumulators[StreamDataAccumulatorKey.teeth] = [];
          }

          if (data.UpdatedTooth) {
            dispatch(toothModel.actions.setNewestOne(data.UpdatedTooth));
          }
          // Tooth handling: end

          if (data.HistoricalAsset) {
            dataAccumulators[StreamDataAccumulatorKey.assets].push(
              data.HistoricalAsset,
            );
          }

          if (data.EndOfHistoricalAssets) {
            dispatch(
              assetsModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.assets],
              ),
            );
            dispatch(assetsModel.actions.setLoading('succeeded'));
            dataAccumulators[StreamDataAccumulatorKey.assets] = [];
          }

          // Invitation handling: start
          if (data.HistoricalInvitation) {
            dataAccumulators[StreamDataAccumulatorKey.access].push(
              data.HistoricalInvitation,
            );
          }

          if (data.EndOfHistoricalInvitations) {
            dispatch(
              accessModel.actions.addMany(
                dataAccumulators[StreamDataAccumulatorKey.access],
              ),
            );

            dispatch(accessModel.actions.setLoading('succeeded'));

            dataAccumulators[StreamDataAccumulatorKey.access] = [];
          }

          if (data.UpdatedInvitation) {
            dispatch(accessModel.actions.setNewestOne(data.UpdatedInvitation));
          }
          // Invitation handling: end
        },
        error: (error) => {
          if (typeof onError === 'function') onError(error);

          if (error.type === 'UnauthenticatedError') {
            navigate(PATHS.signIn, { state: { from: location?.pathname } });
          }

          if (error.type === 'NotFoundError') {
            navigate(PATHS.patients);
          }
        },
        complete: () => {
          // Do nothin
        },
      });
  };

  const closePatientProfileStream = () => {
    if (patientProfileStream.current) {
      patientProfileStream.current.unsubscribe();

      dispatch(assetsModel.actions.removeAll());
      dispatch(reportsModel.actions.removeAll());
      dispatch(studyModel.actions.removeAll());
      dispatch(toothModel.actions.removeAll());
    }
  };

  useEffect(() => {
    openPatientProfileStream();

    return () => {
      closePatientProfileStream();
    };
  }, []);
};
